/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { openChat } from "../../../TawkTo";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";

function NavBarsLivraire({ isdivScroll }) {
    const { pathname } = useLocation();
    const AminMarket = /^\/Admin\/Market.*/

    const [activeIndex, setActiveIndex] = useState(null);

    const [BouttonLogin, setBouttonLogin] = useState(false)



    const dropdownItems = [
        { text: 'Compte', link: '/Admin/Profil' },
        { text: 'Se déconnecter', link: '#' }
    ];


    const location = useLocation();
    const [number, setNumber] = useState(0);
    const [loadings, setloading] = useState(true);



    const history = useNavigate();

    const [UserDrop, SetUserDrop] = useState(false);
    const [AppsDrop, SetAppsDrop] = useState(false);

    function Deconnection(links) {
        if (links === '#') {
            localStorage.removeItem("t");
            localStorage.removeItem("userid");
            localStorage.removeItem("userRole");
            history("/");
            window.location.reload();
        }
    }

    const id = localStorage.getItem("userid");
    const [user, SetUser] = useState({});
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`)
            .then((response) => {
                SetUser(response.data);
                const timer = setTimeout(() => {
                    setLoading(false);
                }, 100);
                return () => clearTimeout(timer);

            }).catch((err) => {
                console.log(err.message);
            })
    }, []);

    const afficheChart = () => {
        openChat()
    }
    const [imageLoading, SetImageLoading] = useState(true)




    useEffect(() => {
        axios
            .get("https://apiv1.burundientempsreel.com/market/admin/standbuynumberseller")
            .then((Response) => {
                setNumber(Response.data);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            })
            .catch((err) => {
                console.error(err.message);
            });
    });





    const [rotationNavBars, SetRotationNavBars] = useState(window.innerWidth < 1137)
    const [tropTail, SettropTail] = useState(window.innerWidth < 422.97364448849393)
    const [hideName, SethideName] = useState(window.innerWidth < 300.97364448849393)

    const [menuClicked, setMenuClicked] = useState(false)
    useEffect(() => {
        const hiddenOnget = () => {
            setMenuClicked(false)
            setBouttonLogin(false)
            SetUserDrop(false)
        };


        function hundleScrolls() {
            setMenuClicked(false)
            setBouttonLogin(false)
            SetUserDrop(false)
        }

        const hiddenresize = () => {
            SetRotationNavBars(window.innerWidth < 1138)
            SettropTail(window.innerWidth < 422.97364448849393)
            SethideName(window.innerWidth < 300.97364448849393)
        }

        window.addEventListener('click', hiddenOnget);
        window.addEventListener('resize', hiddenresize);

        if (isdivScroll.current) {
            isdivScroll.current.addEventListener('scroll', hundleScrolls);

        }

        return () => {
            window.removeEventListener('click', hiddenOnget);
            window.removeEventListener('resize', hiddenresize);

            if (isdivScroll.current) {
                isdivScroll.current.removeEventListener('scroll', hundleScrolls);
            }
        };
    }, []);

    return (
        <>
            {loadings && <SpinnerDemarage />}
            <div className={` h-[10vh] bg-gray-100 ${rotationNavBars ? 'border-b-[1px]' : ' border-b-2 '} border-orange-500  z-50 `}>
                <div className="flex  h-full w-full flex-col ">
                    <div className="h-full  flex border-b-2" >
                        <div className="flex items-center sm:w-[100%]  w-max  ">
                            <div className="flex items-center w-max ">
                                <Link to="/" className="h-[4.5em]  rotate-45 w-[4.5em] transition-all  relative overflow-hidden ml-3 rounded ">
                                    {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading(false)
                                        }, 1000)
                                    }} src={`https://apiv1.burundientempsreel.com/uploads/logo/OnexS.png`} alt="" className='h-full w-full -rotate-45 object-cover object-center' />
                                </Link>
                                <div className={` ${tropTail ? 'hidden' : 'block'}  font-serif text-[30px] ml-1  transition-all`}><span className='text-orange-800'>Onex-</span><span className='text-orange-600'>S</span></div>
                            </div>

                        </div>

                        <div className="flex  w-full justify-end">
                            {/* Pour affiche le acceuil et historique */}
                            {tropTail ? (
                                <div className=" w-max flex  items-center">
                                    {AminMarket.test(pathname) || pathname == '/' ? (
                                        <Link
                                            to="/"
                                            class={`no-underline relative   flex justify-center items-center i `}
                                        >
                                            <i class={`bi  text-[25px] font-bold transition-all text-orange-600   bi-house  `}></i>

                                        </Link>
                                    ) : (
                                        <Link
                                            to="/"
                                            class={`no-underline relative   flex justify-center items-center i `}
                                        >
                                            <i class={`bi bi-house hover:bg-gray-300 text-[25px] py-1 px-2 rounded-lg `}></i>

                                        </Link>

                                    )}


                                </div>) : null}
                            <div className={` ${hideName ? 'w-[5em]' : ''}  mr-2 h-full flex justify-end items-center`}>
                                <nav class=" rounded-t-xl md:rounded-none  flex  justify-between items-center md:items-center pb-1   scale-y-1">

                                    <i onClick={afficheChart} class="bi bi-chat-dots-fill max-md:mr-6 cursor-pointer text-[1.5em] font-bold "></i>

                                    <button onClick={(e) => { SetUserDrop(!UserDrop); e.stopPropagation() }}
                                        class="  md:w-auto mt-2 md:ml-4 md:flex items-center justify-center text-right"
                                    >

                                        {user.photo && (
                                            <div>
                                                <Link class=""                                        >
                                                    <img draggable='false'
                                                        className="inline-block h-8 w-8 md:h-10 md:w-10 border border-orange-500 rounded-full"
                                                        src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                                                        alt=""
                                                    />
                                                </Link>
                                            </div>
                                        )}



                                        <div class="hidden md:flex  md:items-center  ml-4">
                                            <span class="text-gray-700 text-sm mr-2">{user.nom + " " + user.prenom}</span>
                                            <div>
                                                <i class="bi bi-caret-down-fill"></i>
                                            </div>
                                        </div>
                                    </button>
                                </nav>
                                {UserDrop ? (
                                    <><div
                                        className="md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16 mt-2 w-48 rounded bg-gray-100 border border-gray-300 shadow-xl"
                                    >
                                        {dropdownItems.map((item, index) => (
                                            <Link
                                                key={index}
                                                to={item.link}
                                                className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-500 hover:text-white w-full text-left ${index === activeIndex ? 'bg-orange-600 text-white' : ''}`}
                                                onClick={() => {
                                                    setActiveIndex(index); Deconnection(item.link);
                                                }}
                                            >
                                                {item.text}
                                            </Link>
                                        ))}
                                    </div><div class="flex ">
                                        </div>

                                    </>

                                ) : null}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default NavBarsLivraire;








