/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { openChat } from "../../../TawkTo";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import { GridLoader } from 'react-spinners'

function NavBarsTopAdmiSeller({ isdivScroll }) {
    const { pathname } = useLocation();
    const AminMarket = /^\/Admin\/Market.*/
    const ManagerOrder = /^\/Admin\/ManageOrderMarket.*/
    const RegisterSeller = /^\/Admin\/RegisterMarket.*/
    const standBy = /^\/Admin\/StandBuy.*/
    const livreur = /^\/Admin\/Livreurs.*/
    const historique = /^\/Admin\/market\/History.*/
    const Inspecteur = /^\/Admin\/Inspecteur.*/
    const commentaire = /^\/Admin\/commentaire.*/

    const [activeIndex, setActiveIndex] = useState(null);
    const [menuClicked, setMenuClicked] = useState(false)
    const [BouttonLogin, setBouttonLogin] = useState(false)


    const dropdownItems = [
        { text: 'Compte', link: '/Admin/Profil' },
        { text: 'Se déconnecter', link: '#' }
    ];


    const location = useLocation();
    const [number, setNumber] = useState(0);
    const [nseller, setNseller] = useState(0);
    const [commande, setCommande] = useState(0)
    const [loadings, setloading] = useState(true);
    useEffect(() => {
        axios
            .get("https://apiv1.burundientempsreel.com/market/admin/standbuynumberseller")
            .then((Response) => {
                setNumber(Response.data);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            })
            .catch((err) => {
                console.error(err.message);
            });
    });
    useEffect(() => {
        axios
            .get("https://apiv1.burundientempsreel.com/user/notseller/countall")
            .then((Response) => {
                setNseller(Response.data);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            })
            .catch((err) => {
                console.error(err.message);
            });
    });
    useEffect(() => {
        axios
            .get("https://apiv1.burundientempsreel.com/market/commandes/countall")
            .then((Response) => {
                setCommande(Response.data);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            })
            .catch((err) => {
                console.error(err.message);
            });
    });


    const [mobillle, SetMobillle] = useState(window.innerWidth < 1110)
    const [mobillle1, SetMobillle1] = useState(window.innerWidth < 600)
    const [mobillle2, SetMobillle2] = useState(window.innerWidth < 400)
    const [mobillle3, SetMobillle3] = useState(window.innerWidth < 330)

    useEffect(() => {
        const hiddenOnget = () => {
            setMenuClicked(false)
            setBouttonLogin(false)
            SetUserDrop(false)
        };


        function hundleScrolls() {
            setMenuClicked(false)
            setBouttonLogin(false)
            SetUserDrop(false)
        }

        const hiddenresize = () => {
            SetMobillle(window.innerWidth < 1110)
            SetMobillle1(window.innerWidth < 600)
            SetMobillle2(window.innerWidth < 400)
            SetMobillle3(window.innerWidth < 330)
        }

        window.addEventListener('click', hiddenOnget);
        window.addEventListener('resize', hiddenresize);

        if (isdivScroll.current) {
            isdivScroll.current.addEventListener('scroll', hundleScrolls);

        }

        return () => {
            window.removeEventListener('click', hiddenOnget);
            window.removeEventListener('resize', hiddenresize);

            if (isdivScroll.current) {
                isdivScroll.current.removeEventListener('scroll', hundleScrolls);
            }
        };
    }, []);



    const history = useNavigate();

    const [UserDrop, SetUserDrop] = useState(false);


    function Deconnection(links) {
        if (links === '#') {
            localStorage.removeItem("t");
            localStorage.removeItem("userid");
            localStorage.removeItem("userRole");
            history("/");
            window.location.reload();
        }
    }

    const id = localStorage.getItem("userid");
    const [user, SetUser] = useState({});
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`)
            .then((response) => {
                SetUser(response.data);

                const timer = setTimeout(() => {
                    setLoading(false);
                }, 100);
                return () => clearTimeout(timer);

            }).catch((err) => {
                console.log(err.message);
            })
    }, [id]);

    const afficheChart = () => {
        openChat()
    }
    const [imageLoading, SetImageLoading] = useState(true)
    return (
        <>
            {loadings && <SpinnerDemarage />}
            <div className={`  bg-gray-100 w-full sticky top-0 z-50  border-b ${mobillle3 ? 'h-[14vh]' : 'h-[15vh]'} border-orange-500`}>
                <div className="flex  h-full w-full flex-col ">
                    <div className={`  ${mobillle3 ? 'h-[60%]' : 'h-1/2'} flex border-b-2`} >
                        <div className="flex items-center sm:w-[100%]   max-w-[20em]  ">
                            <div className="flex items-center w-max ">
                                <Link to="/" className="h-[4.3em]  rotate-45 w-[4.3em] transition-all  relative overflow-hidden ml-3 rounded ">
                                    {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                    <img draggable='false' onLoad={() => {
                                        setTimeout(() => {
                                            SetImageLoading(false)
                                        }, 1000)
                                    }} src={`https://apiv1.burundientempsreel.com/uploads/logo/OnexS.png`} alt="" className='h-full w-full -rotate-45 object-cover object-center' />
                                </Link>
                                <div className={`${mobillle2 ? 'hidden' : 'block'}   font-serif text-[30px] ml-1  transition-all`}><span className='text-orange-800'>Onex-</span><span className='text-orange-600'>S</span></div>
                            </div>

                        </div>
                        <div className=" w-[100%] mr-2  h-full flex justify-end items-center">
                            <nav class=" flex  justify-between items-center ">
                                <svg onClick={afficheChart} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots-fill mr-2 cursor-pointer h-5 w-5" viewBox="0 0 16 16">
                                    <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>

                                {mobillle1 ? (
                                    <>
                                        <div class="flex ">
                                            {livreur.test(pathname) ? (
                                                <Link
                                                    to="/Admin/Livreurs"
                                                    class={`no-underline mr-2 w-[30px] h-[30px]   flex relative justify-center text-orange-600   items-center`}

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" h-6 w-6 bi  bi-person-walking" viewBox="0 0 16 16">
                                                        <path d="M9.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM6.44 3.752A.75.75 0 0 1 7 3.5h1.445c.742 0 1.32.643 1.243 1.38l-.43 4.083a1.75 1.75 0 0 1-.088.395l-.318.906.213.242a.75.75 0 0 1 .114.175l2 4.25a.75.75 0 1 1-1.357.638l-1.956-4.154-1.68-1.921A.75.75 0 0 1 6 8.96l.138-2.613-.435.489-.464 2.786a.75.75 0 1 1-1.48-.246l.5-3a.75.75 0 0 1 .18-.375l2-2.25Z" />
                                                        <path d="M6.25 11.745v-1.418l1.204 1.375.261.524a.75.75 0 0 1-.12.231l-2.5 3.25a.75.75 0 1 1-1.19-.914l2.345-3.048Zm4.22-4.215-.494-.494.205-1.843a1.93 1.93 0 0 0 .006-.067l1.124 1.124h1.44a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.531-.22Z" />
                                                    </svg>

                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/Admin/Livreurs"
                                                    class={`no-underline mr-2 w-[30px] h-[30px]   flex relative justify-center text-gray-500   items-center`}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" h-6 w-6  bi bi-person-walking" viewBox="0 0 16 16">
                                                        <path d="M9.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM6.44 3.752A.75.75 0 0 1 7 3.5h1.445c.742 0 1.32.643 1.243 1.38l-.43 4.083a1.75 1.75 0 0 1-.088.395l-.318.906.213.242a.75.75 0 0 1 .114.175l2 4.25a.75.75 0 1 1-1.357.638l-1.956-4.154-1.68-1.921A.75.75 0 0 1 6 8.96l.138-2.613-.435.489-.464 2.786a.75.75 0 1 1-1.48-.246l.5-3a.75.75 0 0 1 .18-.375l2-2.25Z" />
                                                        <path d="M6.25 11.745v-1.418l1.204 1.375.261.524a.75.75 0 0 1-.12.231l-2.5 3.25a.75.75 0 1 1-1.19-.914l2.345-3.048Zm4.22-4.215-.494-.494.205-1.843a1.93 1.93 0 0 0 .006-.067l1.124 1.124h1.44a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.531-.22Z" />
                                                    </svg>

                                                </Link>
                                            )}
                                        </div>

                                        <div class="flex ">
                                            {Inspecteur.test(pathname) ? (
                                                <Link
                                                    to="/Admin/Inspecteur"
                                                    class={`no-underline mr-2 w-[30px] h-[30px]   flex relative justify-center text-orange-600   items-center`}

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" h-10 w-10  bi bi-eyeglasses" viewBox="0 0 16 16">
                                                        <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                                                    </svg>

                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/Admin/Inspecteur"
                                                    class={`no-underline mr-2 w-[30px] h-[30px]   flex relative justify-center text-gray-500   items-center`}

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi h-10 w-10  bi-eyeglasses" viewBox="0 0 16 16">
                                                        <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                                                    </svg>

                                                </Link>
                                            )}
                                        </div>


                                        <div class="flex ">
                                            {commentaire.test(pathname) ? (
                                                <Link
                                                    to="/Admin/commentaire"
                                                    class={`no-underline mr-2 w-[30px] h-[30px]   flex relative justify-center text-orange-600   items-center`}

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-5 h-5 bi-chat-right-text-fill" viewBox="0 0 16 16">
                                                        <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                                                    </svg>

                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/Admin/commentaire"
                                                    class={`no-underline mr-2 w-[30px] h-[30px]   flex relative justify-center text-gray-500   items-center`}

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-5 h-5 bi-chat-right-text-fill" viewBox="0 0 16 16">
                                                        <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                                                    </svg>
                                                </Link>
                                            )}
                                        </div>

                                    </>
                                ) : null}

                                <button onClick={(e) => { SetUserDrop(!UserDrop); e.stopPropagation() }}
                                    class="  md:w-auto  md:ml-4 md:flex items-center cursor-pointer justify-center text-right"
                                >
                                    <div>
                                        <Link class=""                                        >
                                            <img draggable='false'
                                                className="inline-block h-7 w-7 md:h-10 md:w-10 border border-orange-500 rounded-full"
                                                src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div class="hidden md:flex  md:items-center  ml-4">
                                        <span class="text-gray-700 text-sm mr-2">{user.nom + " " + user.prenom}</span>
                                        <div>
                                            <i class="bi bi-caret-down-fill"></i>
                                        </div>
                                    </div>
                                </button>
                            </nav>

                            {UserDrop ? (
                                <div
                                    className="md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16  w-48 rounded bg-gray-100 border border-gray-300 shadow-xl"
                                >
                                    {dropdownItems.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={item.link}
                                            className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-500 hover:text-white w-full text-left ${index === activeIndex ? 'bg-orange-600 text-white' : ''}`}
                                            onClick={() => {
                                                setActiveIndex(index); Deconnection(item.link)
                                            }}
                                        >
                                            {item.text}
                                        </Link>
                                    ))}
                                </div>

                            ) : null}
                        </div>
                    </div>

                    <div className="w-full  h-1/2   flex  flex-col justify-center items-center  text-[12px] ">
                        <div className={`  w-[100%] h-full only:flex justify-around items-end  text-[15px]`}>
                            <div className="  flex px-1 w-[100%] justify-around">
                                {
                                    mobillle1 ? (
                                        <>
                                            {AminMarket.test(pathname) || pathname == '/' ? (
                                                <Link
                                                    to="/Admin/Market"
                                                    class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-window-split h-6 w-6" viewBox="0 0 16 16">
                                                        <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                                                        <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2Zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12ZM1 13V6h6.5v8H2a1 1 0 0 1-1-1Zm7.5 1V6H15v7a1 1 0 0 1-1 1H8.5Z" />
                                                    </svg>
                                                    <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>
                                                        Vendeur
                                                    </span>
                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/Admin/Market"

                                                    class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}


                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-window-split h-6 w-6" viewBox="0 0 16 16">
                                                        <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                                                        <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2Zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12ZM1 13V6h6.5v8H2a1 1 0 0 1-1-1Zm7.5 1V6H15v7a1 1 0 0 1-1 1H8.5Z" />
                                                    </svg>

                                                    <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>

                                                        Vendeur
                                                    </span>

                                                </Link>

                                            )}

                                            <div class="flex">
                                                {RegisterSeller.test(pathname) ? (
                                                    <Link
                                                        to="/Admin/RegisterMarket"
                                                        class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                    >
                                                        <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                            {nseller > 99 ? "99+" : nseller}
                                                        </span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="h-6 w-6 fill-current mr-2"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                                            />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Enreg_vendeur</span>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to="/Admin/RegisterMarket"
                                                        class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}
                                                    >
                                                        <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                            {nseller > 99 ? "99+" : nseller}
                                                        </span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="h-6 w-6 fill-current mr-2"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                                            />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Enreg_vendeur</span>
                                                    </Link>
                                                )}
                                            </div>
                                            <div class="flex ">
                                                {ManagerOrder.test(pathname) ? (
                                                    <Link
                                                        to="/Admin/ManageOrderMarket"
                                                        class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                    >
                                                        <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                            {commande > 99 ? "99+" : commande}
                                                        </span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="w-6 h-6 mr-2"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                                                            />
                                                        </svg>

                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Commandes</span>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to="/Admin/ManageOrderMarket"
                                                        class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}
                                                    >
                                                        <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                            {commande > 99 ? "99+" : commande}
                                                        </span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="w-6 h-6 mr-2"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                                                            />
                                                        </svg>

                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Commandes</span>
                                                    </Link>
                                                )}
                                            </div>
                                            <div class="flex ">
                                                {standBy.test(pathname) ? (
                                                    <Link
                                                        to="/Admin/StandBuy"
                                                        class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                    >
                                                        <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                            {number > 99 ? "99+" : number}
                                                        </span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="h-6 w-6 fill-current mr-2"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                                                            <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>StandBuy</span>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to="/Admin/StandBuy"
                                                        class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}
                                                    >
                                                        <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                            {number > 99 ? "99+" : number}
                                                        </span>

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="h-6 w-6 fill-current mr-2"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                                                            <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>StandBuy</span>
                                                    </Link>
                                                )}
                                            </div>



                                            <div class="flex ">
                                                {historique.test(pathname) ? (
                                                    <Link
                                                        to="/Admin/market/History"
                                                        class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class={`  ? 'w-[25px] h-[25px] ' : 'mr-2 w-4 h-4'} h-6 w-6 `} viewBox="0 0 16 16">
                                                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Historique</span>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to="/Admin/market/History"
                                                        class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}

                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class={`  ? 'w-[25px] h-[25px] ' : 'mr-2 w-4 h-4'} h-6 w-6 `} viewBox="0 0 16 16">
                                                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Historique</span>
                                                    </Link>
                                                )}
                                            </div>

                                        </>
                                    ) :
                                        (
                                            <>
                                                {AminMarket.test(pathname) || pathname == '/' ? (
                                                    <Link
                                                        to="/Admin/Market"
                                                        class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-window-split h-6 w-6" viewBox="0 0 16 16">
                                                            <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                                                            <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2Zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12ZM1 13V6h6.5v8H2a1 1 0 0 1-1-1Zm7.5 1V6H15v7a1 1 0 0 1-1 1H8.5Z" />
                                                        </svg>
                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>
                                                            Vendeur
                                                        </span>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to="/Admin/Market"

                                                        class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}


                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-window-split h-6 w-6" viewBox="0 0 16 16">
                                                            <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
                                                            <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2Zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12ZM1 13V6h6.5v8H2a1 1 0 0 1-1-1Zm7.5 1V6H15v7a1 1 0 0 1-1 1H8.5Z" />
                                                        </svg>

                                                        <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>

                                                            Vendeur
                                                        </span>

                                                    </Link>

                                                )}

                                                <div class="flex">
                                                    {RegisterSeller.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/RegisterMarket"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                                {nseller > 99 ? "99+" : nseller}
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="h-6 w-6 fill-current mr-2"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                                                />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Enreg_vendeur</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/RegisterMarket"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}
                                                        >
                                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                                {nseller > 99 ? "99+" : nseller}
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="h-6 w-6 fill-current mr-2"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                                                />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Enreg_vendeur</span>
                                                        </Link>
                                                    )}
                                                </div>
                                                <div class="flex ">
                                                    {ManagerOrder.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/ManageOrderMarket"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                                {commande > 99 ? "99+" : commande}
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-6 h-6 mr-2"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                                                                />
                                                            </svg>

                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Commandes</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/ManageOrderMarket"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}
                                                        >
                                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                                {commande > 99 ? "99+" : commande}
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="1.5"
                                                                stroke="currentColor"
                                                                class="w-6 h-6 mr-2"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                                                                />
                                                            </svg>

                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Commandes</span>
                                                        </Link>
                                                    )}
                                                </div>
                                                <div class="flex ">
                                                    {standBy.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/StandBuy"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                                {number > 99 ? "99+" : number}
                                                            </span>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="h-6 w-6 fill-current mr-2"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                                                                <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>StandBuy</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/StandBuy"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}
                                                        >
                                                            <span className="w-[20px] h-[20px] flex justify-center items-center -right-1 bg-orange-900 text-white text-[10px] absolute -top-1 rounded-full p-1">
                                                                {number > 99 ? "99+" : number}
                                                            </span>

                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="h-6 w-6 fill-current mr-2"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                                                                <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>StandBuy</span>
                                                        </Link>
                                                    )}
                                                </div>
                                                <div class="flex ">
                                                    {livreur.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/Livreurs"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" h-6 w-6 bi bi-person-walking" viewBox="0 0 16 16">
                                                                <path d="M9.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM6.44 3.752A.75.75 0 0 1 7 3.5h1.445c.742 0 1.32.643 1.243 1.38l-.43 4.083a1.75 1.75 0 0 1-.088.395l-.318.906.213.242a.75.75 0 0 1 .114.175l2 4.25a.75.75 0 1 1-1.357.638l-1.956-4.154-1.68-1.921A.75.75 0 0 1 6 8.96l.138-2.613-.435.489-.464 2.786a.75.75 0 1 1-1.48-.246l.5-3a.75.75 0 0 1 .18-.375l2-2.25Z" />
                                                                <path d="M6.25 11.745v-1.418l1.204 1.375.261.524a.75.75 0 0 1-.12.231l-2.5 3.25a.75.75 0 1 1-1.19-.914l2.345-3.048Zm4.22-4.215-.494-.494.205-1.843a1.93 1.93 0 0 0 .006-.067l1.124 1.124h1.44a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.531-.22Z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Livreur</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/Livreurs"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" h-6 w-6  bi bi-person-walking" viewBox="0 0 16 16">
                                                                <path d="M9.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM6.44 3.752A.75.75 0 0 1 7 3.5h1.445c.742 0 1.32.643 1.243 1.38l-.43 4.083a1.75 1.75 0 0 1-.088.395l-.318.906.213.242a.75.75 0 0 1 .114.175l2 4.25a.75.75 0 1 1-1.357.638l-1.956-4.154-1.68-1.921A.75.75 0 0 1 6 8.96l.138-2.613-.435.489-.464 2.786a.75.75 0 1 1-1.48-.246l.5-3a.75.75 0 0 1 .18-.375l2-2.25Z" />
                                                                <path d="M6.25 11.745v-1.418l1.204 1.375.261.524a.75.75 0 0 1-.12.231l-2.5 3.25a.75.75 0 1 1-1.19-.914l2.345-3.048Zm4.22-4.215-.494-.494.205-1.843a1.93 1.93 0 0 0 .006-.067l1.124 1.124h1.44a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.531-.22Z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Livreur</span>
                                                        </Link>
                                                    )}
                                                </div>

                                                <div class="flex ">
                                                    {Inspecteur.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/Inspecteur"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class=" h-6 w-6  bi bi-eyeglasses" viewBox="0 0 16 16">
                                                                <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Inspecteur</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/Inspecteur"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi h-6 w-6  bi-eyeglasses" viewBox="0 0 16 16">
                                                                <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Inspecteur</span>
                                                        </Link>
                                                    )}
                                                </div>


                                                <div class="flex ">
                                                    {commentaire.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/commentaire"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-6 h-6 bi-chat-right-text-fill" viewBox="0 0 16 16">
                                                                <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Commentaire</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/commentaire"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi w-6 h-6 bi-chat-right-text-fill" viewBox="0 0 16 16">
                                                                <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Commentaire</span>
                                                        </Link>
                                                    )}
                                                </div>


                                                <div class="flex ">
                                                    {historique.test(pathname) ? (
                                                        <Link
                                                            to="/Admin/market/History"
                                                            class={`no-underline    rounded-t-lg flex relative ${mobillle ? 'flex-col border-b-2 justify-end items-center text-orange-600 border-orange-500' : ' px-4 bg-orange-500 py-2 items-center mr-1  text-white '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class={`  ? 'w-[25px] h-[25px] ' : 'mr-2 w-4 h-4'} h-6 w-6 `} viewBox="0 0 16 16">
                                                                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Historique</span>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/Admin/market/History"
                                                            class={`no-underline mr-1  text-gray-500  rounded-t-lg flex relative  ${mobillle ? 'flex-col items-center  justify-end hover:text-gray-400' : 'hover:text-white items-center px-4  py-2 hover:bg-orange-400   '}`}

                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class={`  ? 'w-[25px] h-[25px] ' : 'mr-2 w-4 h-4'} h-6 w-6 `} viewBox="0 0 16 16">
                                                                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                            </svg>
                                                            <span class={` ${mobillle ? 'text-[10px]' : 'ml-2'} ${mobillle3 ? 'hidden' : ''} text-center`}>Historique</span>
                                                        </Link>
                                                    )}
                                                </div>

                                            </>
                                        )
                                }







                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavBarsTopAdmiSeller;








