/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Content_Container from "./Content_Container";
function ProfileComponent({getThemes,isdivScroll}) {

  return (
    <>
      <div className="">
        <Content_Container getThemes={getThemes} isdivScroll={isdivScroll} />
      </div>
    </>
  );
}

export default ProfileComponent;
