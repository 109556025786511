import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import { FadeLoader } from 'react-spinners'
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";


function InspecteurNouvell({ getThemes, SetSignPopUp }) {
    const [boutLoading, setboutLoading] = useState(false);
    const history = useNavigate()
    const [numerIdentite, SetnumerIdentite] = useState('')
    const [animationClassnumerIdentite, setAnimationClassnumerIdentite] = useState('');
    const elemetRefnumerIdentite = useRef(null)



    const [photosAvantCNI, SetphotosAvantCNI] = useState('')
    const [fileAvantCNI, SetfileAvantCNI] = useState('')
    const [animationClassphotosAvantCNI, setAnimationClassphotosAvantCNI] = useState('');



    const [photosApresCNI, SetphotosApresCNI] = useState('')
    const [fileApresCNI, SetfileApresCNI] = useState(null)

    const [animationClassphotosApresCNI, setAnimationClassphotosApresCNI] = useState('');


    let [loading, setLoading] = useState(false);
    const { id } = useParams()
    const [user, SetUser] = useState({});

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`)
            .then((response) => {
                SetUser(response.data);

                const timer = setTimeout(() => {
                    setLoading(false);
                }, 100);
                return () => clearTimeout(timer);

            }).catch((err) => {
                console.log(err.message);
            })
    }, [id]);


    const handleSubmit = (e) => {
        e.preventDefault()
        if (numerIdentite == '') {
            toast.warning("Le numéro d'identité est obligatoire !!", {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassnumerIdentite('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassnumerIdentite(' ')
            }, 3000)
            elemetRefnumerIdentite.current.focus();
            return
        }

        else if (photosAvantCNI == '') {
            toast.warning("Deux photos d'identité sont obligatoire !!", {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassphotosAvantCNI('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500  border-orange-700')
            setTimeout(() => {
                setAnimationClassphotosAvantCNI(' ')
            }, 3000)
            return
        }

        else if (photosApresCNI == '') {
            toast.warning("Deux photos  d'identité  sont obligatoire !!", {
                autoClose: 2000,
                position: "top-center",
            });
            setAnimationClassphotosApresCNI('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassphotosApresCNI(' ')
            }, 3000)
            return
        }

        const formData = new FormData();
        formData.append("ident", numerIdentite);
        formData.append("photoavant", fileAvantCNI);
        formData.append("photoariere", fileApresCNI);
        formData.append("usersId", id);


        axios.post('https://apiv1.burundientempsreel.com/user/newaggent', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            toast.success('vous avec ajouter le nouveau inspecteur')
            SetfileApresCNI(null)
            SetfileAvantCNI(null)
            SetphotosApresCNI('')
            SetphotosAvantCNI('')
            SetnumerIdentite('')
            history('/Admin/Inspecteur/ListeEmployer')
        }).catch((err) => {
            if (err.response) {
                toast.warning(err.response.data)
            } else {
                toast.warning('Echec d\'ajouter le nouveau inspecteur')
                console.log(err.message)
            }

        })

    }



    const [imageLoading, SetImageLoading] = useState(true)


    return (
        <>
            {loading && <SpinnerDemarage />}
            <div className={` flex justify-center relative rounded-2xl overflow-hidden items-center `}>
                <Link to='/Admin/Inspecteur' className="absolute top-1 left-1 bg-orange-400 text-white hover:bg-orange-600 transition-all px-2 py-1 rounded">Retour</Link>
                <div className='w-full  h-full overflow-auto flex justify-center  p-4'>
                    <div className={`${getThemes ? 'bg-black' : 'bg-white'} rounded-3xl p-2 border shadow-2xl border-orange-600 w-[30em] h-max`}>
                        <div className="hidden justify-end px-4 items-center">
                            <div onClick={() => { SetSignPopUp(false) }} className="bg-orange-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>
                        </div>

                        <div className="w-full flex justify-center rounded-full overflow-hidden items-center">
                            <div className="h-[10em]  w-[10em] transition-all   relative overflow-hidden ml-3 rounded ">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }} src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`} alt="" className='h-full w-full  object-cover object-center' />
                            </div>
                        </div>
                        <h2 className=' p-2 text-center  font-serif text-gray-400 '>Formulaire d'enregistrement d'inspecteur <span className="text-yellow-900">{user.nom} {user.prenom} </span> </h2>

                        <div className="p-2 my-2 rounded w-full  h-max ">
                            <form onSubmit={handleSubmit}>
                                <div className="flex mb-6 flex-col">
                                    <div className=" transition-all mt-10 flex  justify-between ">
                                        <div className={`flex items-center  relative w-full`}>
                                            <input value={numerIdentite} ref={elemetRefnumerIdentite}
                                                onInput={(e) => SetnumerIdentite(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''} h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassnumerIdentite}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Numéro de Carte d'Identité
                                            </label>
                                        </div>

                                    </div>
                                    <div className=" mt-5">
                                        <div className="text-gray-500 cursor-pointe">Deux photos de votre CNI:</div>
                                        <div className="flex justify-around mt-2">
                                            <div className={`${animationClassphotosAvantCNI}`}>
                                                <div className="flex items-center">
                                                    <label for="profile11" className="text-gray-500 cursor-pointer">Face avant</label>
                                                    <label for="profile11" className='inline-flex text-white w-max cursor-pointer bg-orange-500 px-1 ml-4 rounded '>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-image-fill" viewBox="0 0 16 16">
                                                            <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                                                        </svg>
                                                    </label>
                                                </div>
                                                <input type="file" accept='image/*' hidden id='profile11' onChange={(e) => { const UrlFile = e.target.files[0]; SetfileAvantCNI(UrlFile); SetphotosAvantCNI(URL.createObjectURL(UrlFile)) }} />
                                                {
                                                    photosAvantCNI ? (
                                                        <div className={`${photosAvantCNI == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[10em] h-[10em] bg-white overflow-hidden mt-[20px] rounded-2xl border   border-orange-600`}>

                                                            {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                            }

                                                            <img draggable='false' src={photosAvantCNI}

                                                                onLoad={() => {
                                                                    setTimeout(() => {
                                                                        SetImageLoading(false)
                                                                    }, 1000)
                                                                }}
                                                                alt="" className={`w-full h-full object-cover`} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>
                                            <div className={`${animationClassphotosApresCNI}`}>
                                                <div className="flex items-center">
                                                    <label for="profiles" className="text-gray-500 cursor-pointer">Face arrière</label>
                                                    <label for="profiles" className='inline-flex text-white w-max cursor-pointer bg-orange-500 px-1 ml-4 rounded '>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-image-fill" viewBox="0 0 16 16">
                                                            <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                                                        </svg>
                                                    </label>
                                                </div>
                                                <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetfileApresCNI(UrlFile); SetphotosApresCNI(URL.createObjectURL(UrlFile)) }} />
                                                {
                                                    photosApresCNI ? (
                                                        <div className={`${photosApresCNI == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[10em] h-[10em] bg-white overflow-hidden mt-[20px] rounded-2xl border   border-orange-600`}>

                                                            {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                            }

                                                            <img draggable='false' src={photosApresCNI}

                                                                onLoad={() => {
                                                                    setTimeout(() => {
                                                                        SetImageLoading(false)
                                                                    }, 1000)
                                                                }}
                                                                alt="" className={`w-full h-full object-cover`} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="flex justify-end items-center">
                                    {boutLoading ? (
                                        <>
                                            <label className="  cursor-pointer w-max relative  flex justify-center  items-center   bg-blue-800 pointer-events-none   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Enregistre' className='cursor-pointer' />
                                                <i class="bi bi-send ml-2 "></i>
                                                <div className='absolute bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    /></div>
                                            </label>
                                        </>
                                    ) : (<>
                                        <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Enregistre' className='cursor-pointer'></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </>)}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default InspecteurNouvell;


