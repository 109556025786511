/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
function InspecteurDetail() {
    const [mobile, SetMobile] = useState(window.innerWidth < 684)
    const [mobile1, SetMobile1] = useState(window.innerWidth < 1029)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 684)
            SetMobile1(window.innerWidth < 1029)

        }
        window.addEventListener('resize', hundleSize)
        return () => {

            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    console.log(mobile1)
    const { id } = useParams()
    const [agentdet, setAgentDet] = useState({})
    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/market/admin/getAgentById/${id}`).then((result) => {
            setAgentDet(result.data)
        }).catch((error) => {
            console.log(error.message)
        })
    }, [id])
    function formatDate(createdAt) {
        const formattedDate = new Date(createdAt).toLocaleString("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        });
        return formattedDate;
    }
    return (
        <div className="min-h-[90vh] pt-2 select-none">

            <Link className=" px-2 py-1 mt-2 ml-2 text-white bg-orange-700 rounded" to='/Admin/Inspecteur'>Retour</Link>
            <div class=" bg-white mt-3  text-gray-700 border-b shadow mb-6">

                <div class="w-full flex p-4 flex-col  items-center">
                    <div class="relative my-4 flex  flex-col space-y-3 rounded-2xl  md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                        <div>
                            <h2 class="text-[30px] font-semibold text-left  leading-tight">
                                Informations sur l'inspecteur
                            </h2>
                        </div>
                    </div>

                    {agentdet && (
                        <div class="relative flex w-full flex-col  items-start space-y-3 rounded-2xl border border-gray-300  shadow-lg ">

                            <div className={`flex justify-center ${mobile ? 'flex-col p-2' : ''} w-full`}>

                                <div class="bg-white p-3 border border-orange-700 mt-4 shadow-sm rounded-2xl">
                                    <div className='w-full text-center font-bold text-xl mb-3'>Information professionel</div>

                                    <div class="flex items-center  justify-center">
                                        <div class="flex-shrink-0 border rounded-xl w-32 h-32">

                                            <img
                                                draggable='false'

                                                src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${agentdet.photo}`}

                                                className=" w-full h-full rounded object-contain object-center"

                                            />

                                        </div>

                                    </div>
                                    <div class="text-gray-700">
                                        <div class="grid text-sm">
                                            <div class="grid grid-cols-2">
                                                <div class="px-4 py-2 ">
                                                    Nom
                                                </div>
                                                <div class="px-4 py-2 font-bold">{agentdet.nom}</div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="px-4 py-2 ">
                                                    Prenom
                                                </div>
                                                <div class="px-4 py-2  font-bold">{agentdet.prenom}</div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="px-4 py-2 ">
                                                    Tel
                                                </div><div class="px-4 py-2  font-bold">{agentdet.tel}</div>
                                            </div>
                                            <div class="grid grid-cols-2">
                                                <div class="px-4 py-2 font-semibold">
                                                    Address
                                                </div>
                                                <div class="px-4 py-2  font-bold">
                                                    {agentdet.address}
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <div class="px-4 py-2 font-semibold">
                                                    Email.
                                                </div>
                                                <div class="px-4 py-2  font-bold">
                                                    <a
                                                        className="text-orange-800"
                                                        href={`mailto:${agentdet.email}`}
                                                    >
                                                        {agentdet.email}
                                                    </a>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {agentdet.agentterain && (
                                    <div class="bg-white p-3 border border-orange-700 mt-4 ml-3 shadow-sm rounded-2xl">
                                        <div className='w-full text-center font-bold text-xl mb-3'>Information professionel</div>
                                        <div class="grid text-sm">
                                            <div class="grid grid-cols-2">
                                                <div class="px-4 py-2 lowercase first-letter:uppercase font-semibold">
                                                    Numero d'idnetite
                                                </div>
                                                <div class="px-4 py-2  font-bold">
                                                    {agentdet.agentterain.ident}
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-1">
                                                <div class="px-4 py-2 lowercase first-letter:uppercase font-semibold">
                                                    Deux photos de votre CNI:
                                                </div>
                                                <div class="px-4 py-2  font-bold">
                                                    <div class="px-4 py-2 flex font-bold">
                                                        <div class="flex items-center m-1 justify-center">
                                                            <div class="flex-shrink-0 border rounded-xl w-32 h-32">
                                                                <img draggable='false' src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${agentdet.agentterain.photoavant}`}

                                                                    className=" w-full h-full rounded object-contain object-center"

                                                                />
                                                            </div>

                                                        </div>

                                                        <div class="flex items-center m-1  justify-center">
                                                            <div class="flex-shrink-0 border rounded-xl w-32 h-32">
                                                                <img draggable='false' src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${agentdet.agentterain.photoariere}`}

                                                                    className=" w-full h-full rounded object-contain object-center"

                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                )}
                            </div>
                            <div class="bg-white rounded-lg shadow-md w-full  p-6">
                                <h2 class="text-xl font-bold mb-4">Liste des inspections effectuées par {agentdet.nom} {" "}{agentdet.prenom}</h2>
                                <div className='w-[100vw] overflow-x-auto flex justify-center overflow-y-hidden'>
                                    <div className={`${mobile1 ? 'w-[100em]' : ''}`}>
                                        <table class="w-full">
                                            <thead >
                                                <tr>
                                                    <th class=" border px-4 py-2 text-left">Produit</th>
                                                    <th class=" border px-4 py-2 text-left">Nom du vendeur</th>
                                                    <th class=" border px-4 py-2 text-left">Quantite</th>
                                                    <th class=" border px-4 py-2 text-left">Priorité du produits</th>
                                                    <th class=" border px-4 py-2 text-left">Date d'inspection</th>
                                                    <th class=" border px-4 py-2 text-left hidden">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {agentdet.agentterain && agentdet.agentterain.produitvisits && agentdet.agentterain.produitvisits.length > 0 && (

                                                    agentdet.agentterain.produitvisits.map((produitvisit, index) => (
                                                        <tr key={index}>
                                                            <td className="border px-4 py-2">{produitvisit.produit.titre}</td>
                                                            <td className="border px-4 py-2">{produitvisit.produit.seller.user.nom} {produitvisit.produit.seller.user.prenom}</td>
                                                            <td className="border px-4 py-2">{produitvisit.quantite}</td>
                                                            <td className="border px-4 py-2">{produitvisit.priorie}</td>
                                                            <td className="border px-4 py-2">{formatDate(produitvisit.createdAt)}</td>
                                                            <td className="border px-4 py-2 hidden">
                                                                <Link to="/Admin/Livraison/Detail">Détail</Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}





                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}




                </div>
            </div>
        </div>
    )
}

export default InspecteurDetail
