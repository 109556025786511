/* eslint-disable no-unused-vars */
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Empty from '../Empty/Empty'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage'

function DepenseMarcheUser({ ajoutQuantProduit, SetajoutQuantProduit }) {

    const { pathname } = useLocation()
    const [loading, SetLoading] = useState(true)
    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)
    const [isList, SetiseList] = useState(true)
    const [isGraphy, SetGraphy] = useState(true)

    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);
    const userId = localStorage.getItem("userid");



    const [listoffdepenses, setListDepenses] = useState([]);
    const [element, setElement] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (event) => {
        const selectedItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    const firstEntry = (currentPage - 1) * itemsPerPage + 1;
    const lastEntry = Math.min(currentPage * itemsPerPage, element);

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/depense/getall/${userId}?page=${currentPage}&size=${itemsPerPage}`, {
            headers: {
                accessToken: localStorage.getItem("t"),
            }
        })
            .then((response) => {
                setElement(response.data.totalItems);
                setListDepenses(response.data.depenses);
                setTotalPages(response.data.totalPages);
                setTimeout(() => {
                    SetLoading(false)
                }, 1000);
            }).catch((error) => {
                console.error(error.message);
                if (error.response) {
                    console.log(error.response.data);
                }

            });
    }, [currentPage, itemsPerPage, userId]);

    const formatDate = (dateString) => {
        const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit' };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('fr-FR', optionsDate);
        const formattedTime = date.toLocaleTimeString('fr-FR', optionsTime);

        return `Le ${formattedDate} à ${formattedTime}`;
    };

    const formatAmount = (amount) => {
        // Convertir le montant en nombre à virgule flottante
        const numberAmount = parseFloat(amount);

        // Vérifier si le montant est un nombre valide
        if (isNaN(numberAmount)) {
            return "Montant invalide";
        }

        // Formater le montant en utilisant les séparateurs de milliers et la virgule décimale
        const formattedAmount = numberAmount.toLocaleString('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formattedAmount;
    };


    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value)
    };

    const filterData = (data, searchValue) => {
        if (!searchValue.trim()) {
            return data; // Aucune recherche en cours, retourner toutes les données
        }

        return data.filter((depense) => {
            // Vérifier si le montant (colonne "Montant") contient la valeur de recherche
            if (depense.montant.toString().includes(searchValue)) {
                return true; // Correspondance trouvée dans la colonne "Montant"
            }

            // Vérifier si la date et l'heure (colonne "Date et heure") contiennent la valeur de recherche
            const formattedDate = formatDate(depense.createdAt);
            if (formattedDate.includes(searchValue)) {
                return true; // Correspondance trouvée dans la colonne "Date et heure"
            }

            // Aucune correspondance trouvée dans toutes les colonnes
            return false;
        });
    };

    const filteredData = filterData(listoffdepenses, searchValue);

    console.log(listoffdepenses)
    return (
        <>
            {loading && <SpinnerDemarage />}
            <div className='bg-gray-200 sticky top-0 flex z-10 justify-between px-10 items-center border-orange-300 border-b paddings'>
                <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                    <div className="text-gray-500 mot1">   Affichage des depenses</div>
                </div>
                <div class="bg-green-500 py-1 px-2 rounded text-white mr-3">
                    <Link to='/User/Depenses/AjoutDepense' title="Ajouter une nouvelle produit" className='text-[20px]'> <i class="bi bi-plus-circle"></i></Link>
                </div>
            </div>


            {listoffdepenses.length > 0 ? (
                <div class={` ${isList ? 'flex' : 'hidden'} flex flex-col h-[35em] items-end`}>

                    <div className="  flex justify-between p-1 m-1 rounded  ">

                        <div className="w-full h-10 overflow-hidden">
                            <form className='flex  h-full  w-full rounded-l-[50px] '>
                                <input type="text"
                                    placeholder='Rechercher...'
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    className='p-1  border border-orange-700 rounded-l-[50px] pl-4 rounded transition-all outline-none focus:border focus:border-orange-500' id="" />
                            </form>
                        </div>
                        <div className="relative h-11 z-0">
                            <select
                                class="px-2 h-10 text-black rounded-r-[50px] border border-orange-700 bg-neutral-50 relative font-serif rounded transition cursor-pointer"
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                            >
                                <option>5</option>
                                <option>10</option>
                                <option>20</option>
                                {(listoffdepenses.length > 20) && (<option>{listoffdepenses.length}</option>)}
                            </select>

                        </div>
                    </div>
                    <div className="w-full overflow-x-auto overflow-y-hidden mt-4">
                        <div className={` ${filteredData.length === 0 ? 'w-[98%]' : 'widthTabAdmiProdui'} overflow-hidden  mr-4`}>

                            <table className="w-full mx-2 overflow-hidden" cellspacing="0 " border="0 ">
                                <thead class=" h-11 select-none bg-orange-500 border-b-2 border-orange-400 ">
                                    <tr>
                                        <th className='border-r border-gray-300 text-[white] w-[1em]'>Montant</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3px]'>Date et heure</th>
                                        <th className='border-r border-gray-300 text-[white] w-[3em]'>Action</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-5000">


                                    {filteredData.length === 0 ? (
                                        <tr className='w-full'>
                                            <td colSpan="4" className='w-full' ><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                                                Aucune correspondanct à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                                            </div></td>
                                        </tr>
                                    ) : (
                                        filteredData.map((dep) => {
                                            return (
                                                <tr className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '>
                                                    <td className=' border-r border-gray-300'>{formatAmount(dep.montant)}</td>
                                                    <td className=' border-r border-gray-300'>{formatDate(dep.createdAt)}</td>
                                                    <td className='flex justify-center items-center h-10'>
                                                        <div class="flex justify-center items-center h-full w-[70px] border rounded-lg border-orange-400 pt-2 ">
                                                            <Link to={`/User/Depenses/Dettail/${dep.id}`} class=" w-full  h-full flex justify-around">
                                                                Détaill
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )}






                                </tbody>
                            </table>
                            <div class="px-5 py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Showing {firstEntry} to {lastEntry} of {element} Entries
                                </span>

                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            )
                :
                (
                    <>

                        <Empty item="depense" />
                    </>
                )
            }


        </>
    )
}

export default DepenseMarcheUser
