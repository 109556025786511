/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";

function ClientComment() {


    const [mobilem, Setmobile] = useState(window.innerWidth < 1119)
    const [mobilem1, Setmobile1] = useState(window.innerWidth < 424)

    useEffect(() => {
        const hundleSIze = () => {
            Setmobile(window.innerWidth < 1119)
            Setmobile1(window.innerWidth < 424)
        }
        window.addEventListener('resize', hundleSIze)
        return () => {
            window.removeEventListener('resize', hundleSIze)
        }
    }, [])
    const [loading, SetLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        // Effectuez ici votre requête API
        fetch("https://apiv1.burundientempsreel.com/market/livraisonwithcoment")
            .then((response) => response.json())
            .then((responseData) => {
                setData(responseData);
                SetLoading(false);
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des données:", error);
                SetLoading(false);
            });
    }, []);
    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('fr-FR', options);
        return formattedDate;
    }

    return (
        <div className="min-h-[95vh]">
            {loading && <SpinnerDemarage />}
            <div class=" bg-white border-t m-4 p-3 text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
                <div class=" mx-auto px-2 ">
                    <div class="">
                        <div>
                            <h2 class="text-2xl font-semibold  text-orange-500 leading-tight">
                                Commentaire du client
                            </h2>
                        </div>
                        <div class="my-2 flex flex-row ">
                            <div class="flex flex-row mb-1 sm:mb-0">
                                <div className="relative">
                                    <select
                                        className="appearance-none h-full rounded-l-2xl border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"

                                    >
                                        <option>5</option>
                                        <option>10</option>
                                        <option>20</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>

                                <div class="relative">
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            class="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="block relative">
                                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                    <svg
                                        viewBox="0 0 24 24"
                                        class="h-4 w-4 fill-current text-black"
                                    >
                                        <path d="M10 4a6 6 0100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                                    </svg>
                                </span>
                                <input
                                    placeholder="Rechercher"


                                    class="appearance-none rounded-r-2xl rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                />



                            </div>
                        </div>
                        <div class="  overflow-x-auto">
                            <div class="inline-block min-w-full relative shadow rounded-lg overflow-hidden">
                                <table class={` leading-normal ${mobilem ? 'w-[70em] mr-10' : 'min-w-full'}`}>
                                    <thead>
                                        <tr>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                N.Commande
                                            </th>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Nom complet du client
                                            </th>

                                            <th class="px-5 py-3 border-r-2 lowercase first-letter:uppercase border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Nom complet du livreur
                                            </th>
                                            <th class="px-5 py-3 border-r-2 lowercase first-letter:uppercase border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Date et Heure de Livraison
                                            </th>


                                            <th class="px-5 py-3  border-r-2 lowercase first-letter:uppercase border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider"> Produit</th>
                                            <th class="px-5 py-3  border-r-2 lowercase first-letter:uppercase border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider"> Quantite</th>

                                            <th class="px-5 py-3  border-r-2 lowercase first-letter:uppercase border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider"> Avis du Client </th>

                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>



                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index} className="border border-gray-200">
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        <p className="ml-1">{item.id}</p>
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {item.user.prenom} {item.user.nom}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {item.livrason.livreur.user.prenom} {item.livrason.livreur.user.nom}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {formatDate(item.livrason.updatedAt)}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {item.commandemarketcontents[0].produit.titre}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {item.commandemarketcontents[0].quantite}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {item.livrason.Avis.length > 25 ? item.livrason.Avis.slice(0, 25) + "..." : item.livrason.Avis}
                                                    </p>
                                                </td>
                                                <td className="py-5 border border-gray-200 bg-white text-sm">
                                                    <span className="relative inline-block py-1 font-semibold text-green-900 leading-tight">
                                                        <span aria-hidden className="absolute inset-0 opacity-50 rounded-full">
                                                            <Link to={`/Admin/commentaire/Detail/${item.id}`} className="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover-bg-yellow-500 hover-text-white focus-outline-none">
                                                                Details
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>




                            </div>
                        </div>
                        <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                            <span className="text-xs xs:text-sm text-gray-900">
                                Affichage de 1 à 6 sur 3 entrées

                            </span>

                            <div className="inline-flex mt-2 xs:mt-0">
                                <button
                                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"


                                >
                                    Prev
                                </button>
                                <button
                                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"

                                >
                                    Suiv
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientComment;
