/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import Content_ContainerSeller from "./Content_ContainerSeller";
function MarketComponentSeller({ isVisitorLogged, SetSignPopUpSeller, SetLoginPopUp, isdivScroll }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={isMobile && isVisitorLogged ? "  " : "  "}>
        <Content_ContainerSeller isdivScroll={isdivScroll} SetSignPopUpSeller={SetSignPopUpSeller} isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />
      </div>
    </>
  );
}

export default MarketComponentSeller;
