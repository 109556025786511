/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import PropTypes from "prop-types";

function MarketList() {


  const [listoffSellers, setListOffSellers] = useState([]);
  const [element, setElement] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };
  const firstEntry = (currentPage - 1) * itemsPerPage + 1;
  const lastEntry = Math.min(currentPage * itemsPerPage, element);

  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/admin/getallsellers?page=${currentPage}&size=${itemsPerPage}`, {
        headers: {
          accessToken: localStorage.getItem("t"),
        }
      })
      .then((response) => {
        setElement(response.data.totalItems);
        setListOffSellers(response.data.users);
        setTotalPages(response.data.totalPages);
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      }).catch((err) => {
        console.error(err.message);
        console.log(err.response)
      })
  }, [currentPage, itemsPerPage]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);




  const [loadingImages, setLoadingImages] = useState([]);

  const handleImageLoad = (index) => {

    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      updatedLoadingImages[index] = true;
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 50000);
      return updatedLoadingImages;
    });

  };





  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  const [mobilem, Setmobile] = useState(window.innerWidth < 1119)
  const [mobilem1, Setmobile1] = useState(window.innerWidth < 424)

  useEffect(() => {
    const hundleSIze = () => {
      Setmobile(window.innerWidth < 1119)
      Setmobile1(window.innerWidth < 424)
    }
    window.addEventListener('resize', hundleSIze)
    return () => {
      window.removeEventListener('resize', hundleSIze)
    }
  }, [])





  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filterData = (data, searchValue) => {
    if (!searchValue.trim()) {
      return data;
    }
    return data.filter((value) => {
      const nomComplet = `${value.nom} ${value.prenom}`
      if (nomComplet.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
      if (value.email.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      if (value.tel.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }
      if (value.address.toLowerCase().includes(searchValue.toLowerCase())) {
        return true
      }

      // Vérifier si la date (colonne "Date") contient la valeur de recherche
      const formattedDate = new Date(value.createdAt).toLocaleDateString('en-US') + ' ' + new Date(value.createdAt).toLocaleTimeString('en-US');
      if (formattedDate.includes(searchValue)) {
        return true;
      }


      return false;
    });
  };

  const filteredData = filterData(listoffSellers, searchValue);


  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class=" bg-white border-t min-h-[90vh] text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
        <div class=" mx-auto px-2 ">
          <div class="">
            <div>
              <h2 class="text-2xl font-semibold  text-orange-500 leading-tight">
                Vendeur
              </h2>
            </div>
            <div class="my-2 flex flex-row ">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div className="relative">
                  <select
                    className="appearance-none h-full rounded-l-2xl border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>

                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    class="h-4 w-4 fill-current text-black"
                  >
                    <path d="M10 4a6 6 0100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <input
                  placeholder="Rechercher"
                  value={searchValue}
                  onChange={handleSearchChange}


                  class="appearance-none rounded-r-2xl rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                />



              </div>
            </div>
            <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div class="inline-block min-w-full relative shadow rounded-lg overflow-hidden">
                <table class={` leading-normal ${mobilem ? 'w-[70em] mr-10' : 'min-w-full'}`}>
                  <thead>
                    <tr>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Vendeur
                      </th>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Email
                      </th>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Tel
                      </th>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Addresse
                      </th>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Rejoignez-nous sur
                      </th>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>


                    {filteredData.length === 0 ? (
                      <>
                        <tr >
                          <td colSpan="6"><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                            Aucune correspondanct à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                          </div></td>
                        </tr>

                      </>
                    ) : (
                      <>
                        {filteredData.map((value, key) => {
                          return (
                            <tr>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div class="flex items-center">
                                  <div class="flex-shrink-0 w-10 h-10">


                                    <div key={value.id} className='relative  h-full w-full '>
                                      {loadingImages[key] ? (

                                        <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                      ) :
                                        (
                                          <LoadingImage
                                            draggable='false'
                                            src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${value.photo}`}

                                            className=" w-full h-full rounded object-contain object-center"
                                            onLoad={() => handleImageLoad(key)}
                                          />
                                        )}

                                    </div>



                                  </div>


                                  <div class="ml-3">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                      {value.nom + '  ' + value.prenom}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {value.email}</p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {value.tel}
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {value.address}
                                </p>
                              </td>
                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {value.createdAt && (
                                    <tr >
                                      {new Date(value.createdAt).toLocaleDateString('en-US') + ' ' + new Date(value.createdAt).toLocaleTimeString('en-US')}
                                    </tr>
                                  )}
                                </p>
                              </td>


                              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <span class="relative inline-block  py-1 font-semibold text-green-900 leading-tight">
                                  <span
                                    aria-hidden
                                    class="absolute inset-0  opacity-50 rounded-full"
                                  ><Link to={`/Admin/Market/Detail/${value.id}`} class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-yellow-500 hover:text-white focus:outline-none">
                                      Details
                                    </Link>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}

                  </tbody>
                </table>




              </div>
            </div>
            <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">
                Affichage de {firstEntry} à {lastEntry} sur {element} entrées

              </span>

              <div className="inline-flex mt-2 xs:mt-0">
                <button
                  className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Prev
                </button>
                <button
                  className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Suiv
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketList;
