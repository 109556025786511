/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import { pdfjs } from 'react-pdf';
import { ToastContainer } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css';


import NavBarsVisitor from './component/Visitor/NavBarsVisitors/NavBarsVisitor';
import NavbarVisitorLogged from './component/Visitor/NavBarsVisitors/NavbarVisitorLogged';
import CopyRight from './component/Visitor/FooterVisitor/CopyRight';
import PageNotFind from './component/PageNotFind/PageNotFind';
import ProfilePageVisitor from './page/VisitorPage/MarcheVisitorPage/ProfilePageVisitor';
import ConfirmLivraison from './component/Visitor/Auth/ConfirmLivraison';
import InscriptionLivreur from './component/Visitor/Auth/InscriptionLivreur';
import InscriptionInspecteur from './component/Visitor/Auth/InscriptionInspecteur';

//pour la marche visitor
import MarcheVisitorPage from './page/VisitorPage/MarcheVisitorPage/MarcheVisitorPage';
import ProductDetailMarcheVisitorPage from './page/VisitorPage/MarcheVisitorPage/ProductDetailMarcheVisitorPage';
import MyordersPage from './page/VisitorPage/MarcheVisitorPage/MyordersPage';
import OrderDetailPage from './page/VisitorPage/MarcheVisitorPage/OrderDetailPage';
import AllCartsPage from './page/VisitorPage/MarcheVisitorPage/AllCartsPage';
import PaymentMarcheVisitorPage from './page/VisitorPage/MarcheVisitorPage/PaymentMarcheVisitorPage';
import LogImage from './component/LogImage/LogImage';
import InscriptionSellerMarche from './component/Visitor/Auth/InscriptionSellerMarche';

// pour utilisateur Marche only
import NavBarMarketUsersOnly from './component/User/UserMarketOnly/UserMarketOnlyNaVBars/NavBarMarketUsersOnly';
import UserMarketOnlyPage from './page/UserPage/MarcheUserPage/UserMarketOnlyPage';
import UpdateFormOnlyPage from './page/UserPage/UserPageOnly/UpdateFormOnlyPage';
import UpdateFormPlusOnlyPage from './page/UserPage/UserPageOnly/UpdateFormPlusOnlyPage';
import DetailProdUserOnlyPage from './page/UserPage/UserPageOnly/DetailProdUserOnlyPage';
import RegisterOnlyPage from './page/UserPage/UserPageOnly/RegisterOnlyPage';
import OrderUserOnlyPage from './page/UserPage/UserPageOnly/OrderUserOnlyPage';
import OrderUserOnlyDetailPage from './page/UserPage/UserPageOnly/OrderUserOnlyDetailPage';


// pour utilisateur Marche
import SinscrireMarcheUser from './component/Visitor/Auth/SinscrireMarcheUser';
import SeconnectMArcheUser from './component/Visitor/Auth/SeconnectMArcheUser';
import NavBarMarketUsers from './component/User/UserMarket/UserNavBar/NavBarMarketUsers';
import AcceuilMarcheUserPage from './page/UserPage/MarcheUserPage/AcceuilMarcheUserPage';
import DetteEnregistr from './component/User/UserMarket/DetteMarcheUser/DetteEnregistr'
import ModifierVenteMarcheUserPage from './page/UserPage/MarcheUserPage/ModifierVenteMarcheUserPage';
import DetteMarcheUserPage from './page/UserPage/MarcheUserPage/DetteMarcheUserPage';
import DetteMarcheUserDetaillPage from './page/UserPage/MarcheUserPage/DetteMarcheUserDetaillPage';
import DepenseMarcheUserPage from './page/UserPage/MarcheUserPage/DepenseMarcheUserPage';
import DepenseMarcheUserDetaillPage from './page/UserPage/MarcheUserPage/DepenseMarcheUserDetaillPage';
import NewDepenesMarcheUserPage from './page/UserPage/MarcheUserPage/NewDepenesMarcheUserPage';
import ModifDepenesMarcheUserPage from './page/UserPage/MarcheUserPage/ModifDepenesMarcheUserPage';
import MarketBonusPage from './page/UserPage/MarcheUserPage/MarketBonusPage';
import ProductBonusDetaillPage from './page/UserPage/MarcheUserPage/ProductBonusDetaillPage';
import ChoixEnreg from './component/User/UserMarket/Marche/PaymentSeller/ChoixEnreg';
import ListClientUserSellerPage from './page/UserPage/MarcheUserPage/ListClientUserSellerPage';
import CartsBonusPage from './page/UserPage/MarcheUserPage/CartsBonusPage';
import DashBoardUserMarchePage from './page/UserPage/MarcheUserPage/DashBoardUserMarchePage';
import RegisterProductMarcheUserPage from './page/UserPage/MarcheUserPage/RegisterProductMarcheUserPage';
import UpdateProdMacheUserPage from './page/UserPage/MarcheUserPage/UpdateProdMacheUserPage';
import UpdatePhotoMarcheUserPage from './page/UserPage/MarcheUserPage/UpdatePhotoMarcheUserPage';
import DetailProductorMarcheUserPage from './page/UserPage/MarcheUserPage/DetailProductorMarcheUserPage';
import CompteMarcheUsePage from './page/UserPage/MarcheUserPage/CompteMarcheUsePage';
import OrderDetailUserMarcheurPage from './page/UserPage/MarcheUserPage/OrderDetailUserMarcheurPage';
import ClientBonusPage from './page/UserPage/MarcheUserPage/ClientBonusPage';
import NewClientUserSellerPage from './page/UserPage/MarcheUserPage/NewClientUserSellerPage';
import VenteSellerPage from './page/UserPage/MarcheUserPage/VenteSellerPage';
import ProductSellerDetaillPage from './page/UserPage/MarcheUserPage/ProductSellerDetaillPage';
import AllCartsSellerPage from './page/UserPage/MarcheUserPage/AllCartsSellerPage';
import PaymentSellerPage from './page/UserPage/MarcheUserPage/PaymentSellerPage';
import ManageOrderMarketPage from './page/UserPage/MarcheUserPage/ManageOrderMarketPage';

//pour admin market
import NavBarsTopAdmiSeller from './component/Admin/AdminMarket/NavBarsTopAdmiSeller/NavBarsTopAdmiSeller';
import ManageMarketAdminPage from './page/AdminPage/AdminMarket/ManageMarketAdminPage';
import DetailManageMarketAdminPage from './page/AdminPage/AdminMarket/DetailManageMarketAdminPage';
import RegisterMarketAdminPage from './page/AdminPage/AdminMarket/RegisterMarketAdminPage';
import ManageOrderMarketAdminPage from './page/AdminPage/AdminMarket/ManageOrderMarketAdminPage';
import DetailleAdminMarketManageOrderPage from './page/AdminPage/AdminMarket/DetailleAdminMarketManageOrderPage';
import StandByAdminMArketPage from './page/AdminPage/AdminMarket/StandByAdminMArketPage';
import DetaillenewsellerPage from './page/AdminPage/AdminMarket/DetaillenewsellerPage';

import DetailPoductorAdminMarketPage from './page/AdminPage/AdminMarket/DetailPoductorAdminMarketPage';
import HistoryAdminMarketPage from './page/AdminPage/AdminMarket/HistoryAdminMarketPage';
import AdminMarketProfil from './component/Admin/AdminMarket/AdminMarketProfil/AdminMarketProfil';
import DetailHistoryAdminMarketPage from './page/AdminPage/AdminMarket/DetailHistoryAdminMarketPage';
import ApexChart from './ApexChart';
import NavBarsLivraire from './component/User/UserLivraire/NavBarsLivraire/NavBarsLivraire';
import LivraireurAcceuilPage from './page/UserPage/Livraires/LivraireurAcceuilPage';
import CommandEtenteDeatilPage from './page/UserPage/Livraires/CommandEtenteDeatilPage';
import LivreursAdminPage from './page/AdminPage/AdminMarket/LivreursAdminPage';
import LivreursAdminDetailPage from './page/AdminPage/AdminMarket/LivreursAdminDetailPage';
import ListLivraisonPage from './page/AdminPage/AdminMarket/ListLivraisonPage';
import ListLivraisonDetailPage from './page/AdminPage/AdminMarket/ListLivraisonDetailPage';
import ValidationProductPage from './page/AdminPage/AdminMarket/ValidationProductPage';
import ListeEmployerLivrPage from './page/AdminPage/AdminMarket/ListeEmployerLivrPage';
import ClientCommentPage from './page/AdminPage/AdminMarket/ClientCommentPage';
import ClientCommentDetailPage from './page/AdminPage/AdminMarket/ClientCommentDetailPage';

import InspecteurPage from './page/AdminPage/AdminMarket/InspecteurPage';
import InspecteurNouvellPage from './page/AdminPage/AdminMarket/InspecteurNouvellPage';
import InspecteurDetailPage from './page/AdminPage/AdminMarket/InspecteurDetailPage';
import UserInspectateurNavBars from './component/User/UserInspectateur/UserInspectateurNavBars/UserInspectateurNavBars';
import UserInspectateurPage from './page/UserPage/UserInspectateurPage/UserInspectateurPage';
import ListeEmployerPage from './page/AdminPage/AdminMarket/ListeEmployerPage';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function App() {
  const [isVisitor, SetisVisitor] = useState(false)
  const [isVisitorLogged, SetisVisitorLogged] = useState(false);
  const [isMarcheUser, SetisMarcheUser] = useState(false)
  const [isLivreur, SetisLivreur] = useState(false)
  const [isInspecteur, SetisInspecteur] = useState(false)
  const [isSellerOnly, SetisSellerOnly] = useState(false)
  const [isAdminmarket, setAdminmarket] = useState(false);


  const [LoginPopUp, SetLoginPopUp] = useState(false);
  const [SignPopUp, SetSignPopUp] = useState(false);
  const [SignPopUpSeller, SetSignPopUpSeller] = useState(false);

  const [questionUser, SetQuestionUser] = useState(false);

  const [dettereEnregistr, SetdettereEnregistr] = useState(false);


  const divScroll = useRef(null)
  const [isScroll, SetisScroll] = useState(false)
  const [isScrollRemoveBarEcranPetit, SetisScrollRemoveBarEcranPetit] = useState(false)
  const [isdivScroll, SetdivScroll] = useState(divScroll)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 778.97364448849393);

  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth < 778.97364448849393);
    };

    const hundleScroll = (e) => {
      const value = e.target.scrollTop
      if (value > 100) {
        SetisScroll(true)
      } else {
        SetisScroll(false)
      }

    }

    if (divScroll.current) {
      divScroll.current.addEventListener('scroll', hundleScroll);

    }
    window.addEventListener("resize", handleResize);
    return () => {
      if (divScroll.current) {
        divScroll.current.removeEventListener('scroll', hundleScroll);
      }
      window.removeEventListener("resize", handleResize);
    }
  }, [])



  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);




  const [displayPage, setDisplayPage] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const numberPage = (page) => {
    setDisplayPage(page);

  };
  const handleDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
  };




  const userRole = localStorage.getItem("userRole");
  const idexch = localStorage.getItem("idexch");
  const idSuperagent = localStorage.getItem("idSuperagent");
  useEffect(() => {
    if (userRole === "admin") {
      SetisVisitor(false);
      setAdminmarket(true);
    }
    else if (userRole === "seller") {
      SetisMarcheUser(true);
      SetisVisitor(false);
    } else if (userRole === "livreur") {
      SetisLivreur(true);
      SetisVisitor(false);
    } else if (userRole === "agent") {
      SetisInspecteur(true);
      SetisVisitor(false);
    }
    else if (userRole === "vendeur") {
      SetisSellerOnly(true);
      SetisVisitor(false);
    }
    else if (userRole === "Visitor") {
      SetisVisitor(true);
      SetisVisitorLogged(true);
    }
    else {
      SetisVisitor(true);
    }
  });
  const [getSize, setdetSize] = useState()
  function SizeMobile(size) {
    setdetSize(size);
  }
  function getMobille1(size) {
    setdetSize(size);
  }

  function getSrollowerSize(valuers) {
    if (valuers > 100) {
      SetisScroll(true);

    } else {
      SetisScroll(false)
    }
  }


  const [itemsPerPage, setItemsPerPage] = useState();
  const [titre, settitre] = useState();
  const [category, secategory] = useState();

  const [getThemes, SetgetThemes] = useState();
  const [photos, SetPhotos] = useState();
  const getSelectProducts = (number) => {
    setItemsPerPage(number)
  }
  const getSearchProducts = (title) => {
    settitre(title)
  }
  const getcategoryProducts = (categorys) => {
    secategory(categorys)
  }

  const getTheme = (theme) => {
    SetgetThemes(theme);
  }


  const getProfil = (photo) => {
    SetPhotos(photo)
  }


  return isVisitor ? (
    <>
      {
        LoginPopUp ? (
          <>
            <SeconnectMArcheUser
              LoginPopUp={LoginPopUp}
              SetLoginPopUp={SetLoginPopUp}
              SignPopUp={SignPopUp}
              SetSignPopUp={SetSignPopUp}
              SetisMarcheUser={SetisMarcheUser}
              setAdminmarket={setAdminmarket}
              SetisVisitor={SetisVisitor}
              SetisLivreur={SetisLivreur}
              SetisInspecteur={SetisInspecteur}
              SetisVisitorLogged={SetisVisitorLogged}
              SetisSellerOnly={SetisSellerOnly}
              getThemes={getThemes}
            />
          </>
        ) : SignPopUp ? (
          <>
            <SinscrireMarcheUser getThemes={getThemes} LoginPopUp={LoginPopUp} SetLoginPopUp={SetLoginPopUp} SetSignPopUp={SetSignPopUp} SignPopUp={SignPopUp} />
          </>
        ) : SignPopUpSeller ? (
          <>
            <InscriptionSellerMarche photos={photos} getThemes={getThemes} SetSignPopUpSeller={SetSignPopUpSeller} SetLoginPopUp={SetLoginPopUp} isVisitorLogged={isVisitorLogged} />
          </>
        ) : null
      }


      <div className='w-[100%] h-[100vh]  overflow-hidden'>
        {isVisitorLogged ? (
          <NavbarVisitorLogged
            LoginPopUp={LoginPopUp}
            SetLoginPopUp={SetLoginPopUp}
            SignPopUp={SignPopUp}
            SetSignPopUp={SetSignPopUp}
            isdivScroll={isdivScroll}
            getMobille1={getMobille1}
            getSrollowerSize={getSrollowerSize}
            getSelectProducts={getSelectProducts}
            getSearchProducts={getSearchProducts}
            getcategoryProducts={getcategoryProducts}
            getTheme={getTheme}
            getProfil={getProfil}
          />
        ) : (
          <NavBarsVisitor
            isdivScroll={isdivScroll}
            LoginPopUp={LoginPopUp}
            SetLoginPopUp={SetLoginPopUp}
            SignPopUp={SignPopUp}
            SetSignPopUp={SetSignPopUp}
            getMobille1={getMobille1}
            getSrollowerSize={getSrollowerSize}
            getSelectProducts={getSelectProducts}
            getSearchProducts={getSearchProducts}
            getcategoryProducts={getcategoryProducts}
            getTheme={getTheme}
          />
        )}
        <div className={` relative w-full h-full flex flex-col  justify-between overflow-hidden`}>
          <div ref={divScroll} className={`${isMobile ? 'h-[80vh]' : 'h-[85vh]'} ${getThemes ? 'bg-black text-white' : 'bg-gray-50'}   overflow-x-hidden overflow-y-auto`}>
            <div onClick={() => {
              divScroll.current.scrollTop = 3
              divScroll.current.style.scrollBehavior = 'smooth'
            }}
              className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 bottom-2 bg-orange-400 hover:bg-orange-600  duration-75 text-white z-[30]`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
                <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1z" />
              </svg>
            </div>
            <Routes>
              <Route path="/" element={<MarcheVisitorPage getThemes={getThemes} category={category} titre={titre} itemsPerPage={itemsPerPage} isVisitorLogged={isVisitorLogged} isdivScroll={isdivScroll} SetSignPopUpSeller={SetSignPopUpSeller} SetLoginPopUp={SetLoginPopUp} />} />
              <Route path="/ConfirmLivraison" element={<ConfirmLivraison getThemes={getThemes} category={category} titre={titre} itemsPerPage={itemsPerPage} isVisitorLogged={isVisitorLogged} isdivScroll={isdivScroll} SetSignPopUpSeller={SetSignPopUpSeller} SetLoginPopUp={SetLoginPopUp} />} />
              <Route path="/InscriptionInspecteur" element={<InscriptionInspecteur getThemes={getThemes} category={category} titre={titre} itemsPerPage={itemsPerPage} isVisitorLogged={isVisitorLogged} isdivScroll={isdivScroll} SetSignPopUpSeller={SetSignPopUpSeller} SetLoginPopUp={SetLoginPopUp} />} />
              <Route path="/Marche/product/:idp" element={<ProductDetailMarcheVisitorPage getThemes={getThemes} isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />} />
              <Route path="*" element={<PageNotFind />} />
              <Route path="/Profile" element={<ProfilePageVisitor getThemes={getThemes} isdivScroll={isdivScroll} />} />
              <Route path="/Commande" element={<MyordersPage getThemes={getThemes} />} />
              <Route path="/Commande/:id" element={<OrderDetailPage />} />
              <Route path="/Orders" element={<AllCartsPage getThemes={getThemes} />} />
              <Route path="/Orders/Paiement" element={<PaymentMarcheVisitorPage getThemes={getThemes} isVisitorLogged={isVisitorLogged} />} />
              <Route path="/image" Component={LogImage} />
            </Routes>
            <CopyRight />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  ) : isMarcheUser ? (
    <>
      {dettereEnregistr ? (
        <>
          <DetteEnregistr SetdettereEnregistr={SetdettereEnregistr} />
        </>
      ) : questionUser ? (
        <>
          <ChoixEnreg SetQuestionUser={SetQuestionUser} />
        </>
      ) : null}

      <div className='w-[100%] h-[100vh] overflow-hidden'>
        <NavBarMarketUsers isdivScroll={isdivScroll} SizeMobile={SizeMobile} getSrollowerSize={getSrollowerSize} getMobille1={getMobille1} />
        <div ref={divScroll} className={`${getSize ? 'h-[90vh]' : 'h-[80vh]'}  bg-gray-100 w-full  overflow-x-hidden overflow-y-auto`}>

          <Routes>

            <Route path="/" element={<AcceuilMarcheUserPage isdivScroll={isdivScroll} />} />
            <Route path="/seller" element={<AcceuilMarcheUserPage isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes" element={<VenteSellerPage isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes/productDetail/:id" element={<ProductSellerDetaillPage isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes/Carts" element={<AllCartsSellerPage SetQuestionUser={SetQuestionUser} isdivScroll={isdivScroll} />} />
            <Route path="/User/Ventes/Payment" element={<PaymentSellerPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Ventes/AjoutClient' element={<NewClientUserSellerPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
            <Route path='/User/Ventes/ModifierVente' element={<ModifierVenteMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
            <Route path='/User/Ventes/ClientExistant' element={<ListClientUserSellerPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />

            <Route path='/User/Dettes' element={<DetteMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Dettes/Details/:id' element={<DetteMarcheUserDetaillPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Bonus' element={<MarketBonusPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Bonus/Detail/:id' element={<ProductBonusDetaillPage isdivScroll={isdivScroll} />} />

            <Route path='/User/Bonus/Carts' element={<CartsBonusPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} />} />
            <Route path='/User/Bonus/Client' element={<ClientBonusPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Jours' element={<ApexChart isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetdettereEnregistr={SetdettereEnregistr} />} />


            <Route path='/User/Depenses' element={<DepenseMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Depenses/AjoutDepense' element={<NewDepenesMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetdettereEnregistr={SetdettereEnregistr} />} />
            <Route path='/User/Depenses/Dettail/:id' element={<DepenseMarcheUserDetaillPage isdivScroll={isdivScroll} />} />
            <Route path='/User/Depenses/ModifDepense' element={<ModifDepenesMarcheUserPage isScrollRemoveBarEcranPetit={isScrollRemoveBarEcranPetit} SetisScrollRemoveBarEcranPetit={SetisScrollRemoveBarEcranPetit} isdivScroll={isdivScroll} SetdivScroll={SetdivScroll} SetdettereEnregistr={SetdettereEnregistr} />} />

            <Route path="/User/Marche" element={<DashBoardUserMarchePage isdivScroll={isdivScroll} />} />
            <Route path="/User/Profile" element={<CompteMarcheUsePage isdivScroll={isdivScroll} />} />
            <Route path="/User/Marche/Modif/:id" Component={UpdateProdMacheUserPage} />
            <Route path="/User/Marche/PlusPhotos/:id" Component={UpdatePhotoMarcheUserPage} />
            <Route path="/User/Marche/PlusPhotos/Detail/:id" Component={DetailProductorMarcheUserPage} />
            <Route path="/User/Orders/Detail/:ida/:idb" Component={OrderDetailUserMarcheurPage} />
            <Route path="/User/Orders" Component={ManageOrderMarketPage} />
            <Route path="/User/AjouProduct" Component={RegisterProductMarcheUserPage} />

          </Routes>
          <CopyRight />
        </div>
      </div>
    </>
  ) :
    isSellerOnly ? (<>
      <div className='w-[100%] h-[100vh] overflow-hidden'>
        <NavBarMarketUsersOnly isdivScroll={isdivScroll} SizeMobile={SizeMobile} getSrollowerSize={getSrollowerSize} getMobille1={getMobille1} />
        <div ref={divScroll} className={`${getSize ? 'h-[90vh]' : 'h-[80vh]'}  bg-gray-100 w-full  overflow-x-hidden overflow-y-auto`}>
          <Routes>
            <Route path="/UserOnly/Marche" element={<UserMarketOnlyPage isdivScroll={isdivScroll} />} />
            <Route path="/UserOnly/Marche/updateForm/:id" element={<UpdateFormOnlyPage isdivScroll={isdivScroll} />} />
            <Route path="/UserOnly/Marche/updatePlusPhotos/:id" Component={UpdateFormPlusOnlyPage} />
            <Route path="/UserOnly/Marche/PlusPhotos/Detail/:id" Component={DetailProdUserOnlyPage} />
            <Route path="/UserOnly/enregist" Component={RegisterOnlyPage} />
            <Route path="/UserOnly/Order" element={<OrderUserOnlyPage isdivScroll={isdivScroll} />} />
            <Route path="/UserOnly/Order/Detail/:ida/:idb" element={<OrderUserOnlyDetailPage isdivScroll={isdivScroll} />} />


            <Route path="/User/Marche" element={<DashBoardUserMarchePage isdivScroll={isdivScroll} />} />
            <Route path="/User/Profile" element={<CompteMarcheUsePage isdivScroll={isdivScroll} />} />


          </Routes>
          <CopyRight />
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
    ) :
      isLivreur ? (<>

        <div className='w-[100%] h-[100vh] overflow-hidden'>
          <NavBarsLivraire isdivScroll={isdivScroll} />
          <div className="relative w-full h-full flex justify-between overflow-hidden">
            <div ref={divScroll} className="h-[90vh] bg-gray-100 w-full  overflow-x-hidden overflow-y-auto">
              <Routes>
                <Route path='/' element={<LivraireurAcceuilPage isdivScroll={isdivScroll} />} />
                <Route path='/liv' element={<LivraireurAcceuilPage isdivScroll={isdivScroll} />} />
                <Route path='/livraireur/Detail/:id' element={<CommandEtenteDeatilPage />} />
              </Routes>
              <CopyRight />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />

      </>
      ) :
        isInspecteur ?
          (
            <>
              <div className='w-[100%] h-[100vh] overflow-hidden'>
                <UserInspectateurNavBars isdivScroll={isdivScroll} />
                <div className="relative w-full h-full flex justify-between overflow-hidden">
                  <div ref={divScroll} className="h-[90vh] bg-gray-100 w-full  overflow-x-hidden overflow-y-auto">
                    <Routes>
                      <Route path='/' element={<UserInspectateurPage isdivScroll={isdivScroll} />} />
                      <Route path='/ins' element={<UserInspectateurPage isdivScroll={isdivScroll} />} />
                      <Route path='/Validation/:id' element={<ValidationProductPage isdivScroll={isdivScroll} />} />
                    </Routes>
                    <CopyRight />
                  </div>
                </div>
              </div>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />

            </>) :
          isAdminmarket ? (
            <>
              <div className='w-[100%] h-[100vh] overflow-hidden'>
                <NavBarsTopAdmiSeller isdivScroll={isdivScroll} />
                <div className="relative w-full h-full flex justify-between overflow-hidden">
                  <div ref={divScroll} className="h-[85vh] bg-gray-100 w-full  overflow-x-hidden overflow-y-auto">
                    <Routes>
                      <Route path='/Admin/Profil' element={<AdminMarketProfil />} />
                      <Route path='/Admin/Market' element={<ManageMarketAdminPage />} />
                      <Route path='/' element={<ManageMarketAdminPage />} />
                      <Route path='/Admin/Livreurs/InscriptionLivreur/:id' element={<InscriptionLivreur />} />
                      <Route path='/Admin/Market/Detail/:id' element={<DetailManageMarketAdminPage />} />
                      <Route path='/Admin/RegisterMarket' element={<RegisterMarketAdminPage />} />
                      <Route path='/Admin/RegisterMarket/detail/:id' element={<DetaillenewsellerPage />} />
                      <Route path='/Admin/ManageOrderMarket' element={<ManageOrderMarketAdminPage />} />
                      <Route path='/Admin/ManageOrderMarket/Detail/:id' element={<DetailleAdminMarketManageOrderPage />} />
                      <Route path='/Admin/StandBuy' element={<StandByAdminMArketPage />} />
                      <Route path='/Admin/StandBuy/Detail/:id' element={<DetailPoductorAdminMarketPage />} />
                      <Route path='/Admin/Livreurs' element={<LivreursAdminPage />} />
                      <Route path='/Admin/Livreurs/ListeEmployerLivr' element={<ListeEmployerLivrPage />} />
                      <Route path='/Admin/Livreurs/Details/:id' element={<LivreursAdminDetailPage />} />
                      <Route path='/Admin/Livreurs/Details/Detail/:id' element={<ListLivraisonDetailPage />} />
                      <Route path='/Admin/Livraison' element={<ListLivraisonPage />} />
                      <Route path='/Admin/Inspecteur' element={<InspecteurPage />} />
                      <Route path='/Admin/Inspecteur/ListeEmployer' element={<ListeEmployerPage />} />
                      <Route path='/Admin/Inspecteur/News/:id' element={<InspecteurNouvellPage />} />
                      <Route path='/Admin/Inspecteur/Detail/:id' element={<InspecteurDetailPage />} />
                      <Route path='/Admin/commentaire' element={<ClientCommentPage />} />
                      <Route path='/Admin/commentaire/Detail/:id' element={<ClientCommentDetailPage />} />

                      <Route path='/Admin/market/History' element={<HistoryAdminMarketPage />} />
                      <Route path='/Admin/market/History/Detail/:id' element={<DetailHistoryAdminMarketPage />} />



                    </Routes>
                    <CopyRight />
                  </div>
                </div>
              </div>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            </>
          )
            : null;
}

export default App;






