import React from 'react'
import LivraireurAcceuil from './LivraireurAcceuil'
function LivraireurComponent({ isdivScroll }) {
  return (
    <div>
      <LivraireurAcceuil isdivScroll={isdivScroll} />
    </div>
  )
}

export default LivraireurComponent
