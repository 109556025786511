/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import Content_Container from './Content_Container'

function DashBoardUserMarcheComponent({ isdivScroll }) {
	return (


		<div className="">
			<div className="">
				<Content_Container isdivScroll={isdivScroll} />
			</div>
		</div>

	)
}

export default DashBoardUserMarcheComponent