/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DELETE } from "../../../Redux/Action/Action";
import axios from "axios";
import { openChat } from "../../TawkTo";
import { BarLoader } from "react-spinners";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";

function NavbarVisitorLogged({
  getcategoryProducts,
  getSearchProducts,
  getSelectProducts,
  isdivScroll,
  getSrollowerSize,
  getMobille1,
  getTheme,
  getProfil
}) {
  const Orders = /^\/Orders.*/;
  const { pathname } = useLocation();
  const [menuClicked1, setMenuClicked1] = useState(false);
  const [BouttonLogin, setBouttonLogin] = useState(false);
  const [showNotifi, SetShowNotifi] = useState(false);

  const [resposiveNavs, SetResposiveNavs] = useState(
    window.innerWidth < 1145.97364448849393
  );
  const [resposiveNavsHodeName, SetResposiveNavsHideName] = useState(
    window.innerWidth < 1030.97364448849393
  );
  const [NavsMobile, SetNavsMobile] = useState(
    window.innerWidth < 778.97364448849393
  );

  const [isMobile, SetMobile] = useState(
    window.innerWidth < 570.97364448849393
  );
  const [isMobileTros1, SetMobileTros1] = useState(
    window.innerWidth < 365.97364448849393
  );
  const [showSearc, SetshowSearc] = useState(false);

  const [imageLoading1, SetImageLoading1] = useState(true);

  getMobille1(isMobile);

  useEffect(() => {
    const hiddenOnget = () => {
      setMenuClicked1(false);
      setBouttonLogin(false);
    };

    function hundleScrolls(e) {
      setMenuClicked1(false);
      setBouttonLogin(false);
      getSrollowerSize(e.target.scrollTop);
    }
    const hiddenresize = () => {
      SetResposiveNavs(window.innerWidth < 1145.97364448849393);
      SetResposiveNavsHideName(window.innerWidth < 1030.97364448849393);
      SetNavsMobile(window.innerWidth < 778.97364448849393);
      SetMobile(window.innerWidth < 570.97364448849393);
      SetMobileTros1(window.innerWidth < 362.97364448849393);
    };

    window.addEventListener("resize", hiddenresize);
    window.addEventListener("click", hiddenOnget);
    if (isdivScroll.current) {
      isdivScroll.current.addEventListener("scroll", hundleScrolls);
    }

    return () => {
      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener("scroll", hundleScrolls);
      }
      window.removeEventListener("click", hiddenOnget);
      window.removeEventListener("resize", hiddenresize);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const dropdownItems = [
    { text: "Compte", link: "/Profile" },
    { text: "Se déconnecter", link: "#" },
  ];

  const [UserDrop, SetUserDrop] = useState(false);

  const history = useNavigate();

  const [price, setPrice] = useState(0);

  const getData = useSelector((state) => state.CartsReducer.Carts);


  const [isClicked, setIsClicked] = useState(false);

  getMobille1(isMobile);

  useEffect(() => {
    const hiddenOnget = () => {
      setBouttonLogin(false);
      SetUserDrop(false);
    };

    function hundleScrolls(e) {
      setBouttonLogin(false);
      SetUserDrop(false);
      getSrollowerSize(e.target.scrollTop);
    }

    const hiddenresize = () => {
      SetMobile(window.innerWidth < window.innerWidth < 570.97364448849393);
    };

    window.addEventListener("click", hiddenOnget);
    window.addEventListener("resize", hiddenresize);

    if (isdivScroll.current) {
      isdivScroll.current.addEventListener("scroll", hundleScrolls);
    }

    return () => {
      window.removeEventListener("click", hiddenOnget);
      window.removeEventListener("resize", hiddenresize);

      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener("scroll", hundleScrolls);
      }
    };
  }, []);

  function test() {
    history("/Orders");
  }

  function close() {
    setIsClicked(!isClicked);
  }

  const dispatch = useDispatch();

  const dlt = (id) => {
    dispatch(DELETE(id));
  };

  const total = () => {
    let prices = 0;
    getData.map((ele, index) => {
      prices = Math.trunc(ele.price) * 1000 * ele.quantity + prices;
    });
    setPrice(Math.floor(prices).toLocaleString("fr-FR"));
  };
  useEffect(() => {
    total();
  }, [total]);

  function Deconnection(links) {
    if (links === "#") {
      localStorage.removeItem("t");
      localStorage.removeItem("userid");
      localStorage.removeItem("userRole");
      history("/");
      window.location.reload();
    }
  }

  const id = localStorage.getItem("userid");
  const [user, SetUser] = useState({});
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`).then((response) => {
      SetUser(response.data);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 100);
      return () => clearTimeout(timer);
    });
  }, []);

  const HandleOpenChat = () => {
    openChat();
  };

  getProfil(user.photo)

  const [itemsPerPage, setItemsPerPage] = useState(72);
  const [titreseach, settitreseach] = useState("");
  const [imageLoading, SetImageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchs, setsearch] = useState();
  const [categoryMenu, setcategoryMenu] = useState(false);
  const [category, setCategory] = useState("");

  const search = (title) => {
    setsearch(title);
  };

  getSelectProducts(itemsPerPage);
  getSearchProducts(searchs);
  getcategoryProducts(category);

  getMobille1(isMobile);

  useEffect(() => {
    const hiddenOnget = () => {
      setMenuClicked1(false);
      setBouttonLogin(false);
      SetShowNotifi(false)
    };

    function hundleScrolls(e) {
      setMenuClicked1(false);
      setBouttonLogin(false);
      SetShowNotifi(false)
      getSrollowerSize(e.target.scrollTop);
    }
    const hiddenresize = () => {
      SetMobile(window.innerWidth < 570.97364448849393);
    };

    window.addEventListener("resize", hiddenresize);
    window.addEventListener("click", hiddenOnget);
    if (isdivScroll.current) {
      isdivScroll.current.addEventListener("scroll", hundleScrolls);
    }

    return () => {
      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener("scroll", hundleScrolls);
      }
      window.removeEventListener("click", hiddenOnget);
      window.removeEventListener("resize", hiddenresize);
    };
  }, []);

  const handleItemsPerPageChange = (membre) => {
    const selectedItemsPerPage = parseInt(membre.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };

  const [micro, setMicro] = useState(false);

  const [support, Setsupport] = useState(true);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [copiedItems, setCopiedItems] = useState([]);
  const [isCopied, setCopied] = useState(false);
  const [isMicroSupported, setMicroSupported] = useState(true);

  const inputRef = useRef(null);

  // Définissez setInputText pour mettre à jour la valeur de l'input et déclencher manuellement l'événement input
  const setInputTextAndUpdateInput = (newText) => {
    if (inputRef.current) {
      inputRef.current.value = newText;
      inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
    }
  };



  useEffect(() => {
    setInputTextAndUpdateInput(transcript);
  }, [transcript]);

  useEffect(() => {
    const clearTimer = setTimeout(() => {
      resetTranscript();
    }, 1000);

    return () => {
      clearTimeout(clearTimer);
    };
  }, []);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(transcript)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
        setCopiedItems([...copiedItems, transcript]);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la copie dans le presse-papiers :",
          error
        );
      });
  };

  const handleClearTranscriptClick = () => {
    resetTranscript();
    search("");
    settitreseach("");
  };

  const handlePasteFromClipboard = () => {
    navigator.clipboard
      .readText()
      .then((pastedText) => {
        setInputTextAndUpdateInput(pastedText);
      })
      .catch((error) => {
        console.error("Erreur lors de la lecture du presse-papiers :", error);
      });
  };

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      Setsupport(true);
    } else {
      Setsupport(false);
    }
  }, [browserSupportsSpeechRecognition]);

  const handleCategoryClick = (category) => {
    setCategory(category);
    setcategoryMenu(false);
  };

  useEffect(() => {
    function hundleScrolls() {
      setcategoryMenu(false);
    }
    function hundleClick() {
      setcategoryMenu(false);
    }

    if (isdivScroll.current) {
      isdivScroll.current.addEventListener("scroll", hundleScrolls);
    }
    window.addEventListener("click", hundleClick);

    return () => {
      if (isdivScroll.current) {
        isdivScroll.current.removeEventListener("scroll", hundleScrolls);
        window.removeEventListener("click", hundleClick);
      }
    };
  }, []);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedTheme = localStorage.getItem("mode");
    return storedTheme === "ombre";
  });

  getTheme(isDarkMode);
  const InputCheck = useRef(null);

  useEffect(() => {
    const handleCheckboxChange = (event) => {
      if (event.target.checked) {
        localStorage.setItem("mode", "ombre");
        setIsDarkMode(event.target.checked);
      } else {
        localStorage.removeItem("mode");
        setIsDarkMode(event.target.checked);
      }
    };

    const handleLocalStorage = () => {
      const mode = localStorage.getItem("mode");

      if (mode == "ombre") {
        InputCheck.current.checked = true;
        setIsDarkMode(mode === "ombre");
      } else {
        InputCheck.current.checked = false;
      }
    };

    if (InputCheck.current) {
      InputCheck.current.addEventListener("change", handleCheckboxChange);
    }

    handleLocalStorage();

    return () => {
      if (InputCheck.current) {
        InputCheck.current.removeEventListener("change", handleCheckboxChange);
      }
    };
  }, []);

  const [showVetem, SetshowVetem] = useState(false)
  const [showAlimentation, SetshowAlimentation] = useState(false)
  const [showAccessoires, SetshowAccessoires] = useState(false)
  const [showAppariels, SetshowAppariels] = useState(false)
  const [showMobilier, SetshowMobilier] = useState(false)
  const [showVéhicules, SetshowVéhicules] = useState(false)

  return (
    <>
      {/* Pour les grande */}
      {!NavsMobile ? (
        <>
          <div
            className={` ${isDarkMode ? "bg-black " : "bg-gray-100 "
              } h-[15vh]   rounded-b-1 border-b-2 border-orange-500`}
          >
            <div className="flex  h-full justify-between w-full items-center ">
              <div className="flex  items-center">
                <div
                  onClick={(e) => {
                    setcategoryMenu(!categoryMenu);
                    e.stopPropagation();
                  }}
                  className="ml-2 cursor-pointer h-max  p-1 text-white rounded bg-gradient-to-r from-orange-500 to-orange-300 hover:from-orange-700 hover:to-orange-500 transition-all"
                >
                  {categoryMenu ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-list"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                      </svg>
                    </>
                  )}
                </div>

                <div className="flex items-center w-max ">
                  <Link to='/' className="h-[5em]  rotate-45 w-[5em] transition-all   relative overflow-hidden ml-3 rounded logo">
                    {imageLoading && (
                      <div className="absolute w-full h-full  overflow-hidden ">
                        <img
                          src="https://www.eliananunes.com/images/lazy_loader.gif"
                          className="w-full  p-3 h-full object-cover"
                        />
                      </div>
                    )}
                    <img
                      draggable="false"
                      onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading(false);
                        }, 1000);
                      }}
                      src={`https://apiv1.burundientempsreel.com/uploads/logo/OnexS.png`}
                      className="h-full w-full  -rotate-45 object-cover object-center"
                    />
                  </Link>
                  <div
                    className={` ${resposiveNavs ? "hidden" : "block"
                      } font-serif text-[30px] ml-3  transition-all`}
                  >
                    <span className="text-orange-800">Onex-</span>
                    <span className="text-orange-600">S</span>
                  </div>
                </div>
              </div>

              <div className="flex  items-center  pr-4">
                <div
                  class={` flex rounded-full h-11 px-4 py-2  m-1 bg-gray-200 border border-orange-300 p-2 sm:w-max md:w-[17em]  relative space-x-4 inputwidth `}
                >
                  <div className=" flex justify-center items-center ml-2 relative">
                    {support ? (
                      <div
                        onClick={() => {
                          toast.warning(
                            "Votre navigateur ne prend pas en charge la reconnaissance vocale , utilise l'autre "
                          );
                        }}
                        className="p-1 rounded-full cursor-pointer absolute"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-mic-mute-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                          <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                        </svg>
                      </div>
                    ) : (
                      <>
                        {micro ? (
                          <>
                            <div
                              onClick={() => {
                                SpeechRecognition.stopListening();
                                setMicro(false);
                              }}
                              className="p-1 rounded-full cursor-pointer absolute background"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-mic-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                                <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                              </svg>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => {
                                SpeechRecognition.startListening({
                                  continuous: true,
                                  language: "fr-FR",
                                });
                                setMicro(true);
                              }}
                              className="p-1 rounded-full cursor-pointer  absolute"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-mic-mute-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                                <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                              </svg>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <input
                    value={titreseach}
                    onChange={(e) => {
                      settitreseach(e.target.value);
                      search(e.target.value);
                      if (SpeechRecognition.startListening) {
                        SpeechRecognition.stopListening();
                        setMicro(false);
                      }
                    }}
                    onInput={(e) => {
                      settitreseach(e.target.value);
                      search(e.target.value);
                    }}
                    ref={inputRef}
                    class="bg-transparent outline-none w-full pl-2"
                    type="text"
                    placeholder="Nom de l'article ou titre..."
                  />

                  {titreseach.length > 0 && (
                    <div
                      onClick={() => {
                        handleClearTranscriptClick();
                      }}
                      className="bg-red-200 cursor-pointer hover:bg-red-600  transition-all rounded-full text-white top-1/3 mr-2 flex justify-center items-center absolute right-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  )}
                </div>
                <select
                  class="px-1  text-black border focus:ring-0 border-orange-300 focus:border-orange-300 blur:border-orange-300 h-10 font-serif rounded transition cursor-pointer"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option>5</option>
                  <option>10</option>
                  <option>12</option>
                  <option>20</option>
                  <option>24</option>
                  <option>30</option>
                  <option>36</option>
                  <option>40</option>
                  <option>48</option>
                  <option>50</option>
                  <option>60</option>
                  <option>70</option>
                  <option>72</option>

                  {data.length > 72 && <option>{data.length}</option>}
                </select>

                {pathname == "/Commande" ? (
                  <>
                    <Link
                      to="/Commande"
                      title="Votre commande"
                      className={` ${isDarkMode ? "" : ""
                        } cursor-pointer flex justify-center items-center text-white bg-gradient-to-t from-orange-600 to-orange-400 hover:bg-gray-200 rounded px-3  transition-all p-2 m-1 `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi  bi-basket-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                      </svg>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/Commande"
                      title="Votre commande"
                      className={` ${isDarkMode ? "text-white hover:text-black" : ""
                        } cursor-pointer  hover:bg-gray-200 rounded-full   transition-all p-2 m-1 `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi  bi-basket-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                      </svg>
                    </Link>
                  </>
                )}

                <div
                  title="Notification"
                  onClick={(e) => { SetShowNotifi(!showNotifi); SetUserDrop(false); e.stopPropagation() }}
                  className={` ${isDarkMode ? "text-white hover:text-black" : ""
                    } cursor-pointer  hover:bg-gray-200 rounded-full  relative pointer-events-none  transition-all p-2 m-1 `}
                ><span className=" hidden absolute -top-1 z-10 hover:scale-110 right-0   w-[20px] h-[20px] rounded-[500%]  justify-center items-center text-white  bg-orange-800 text-[10px]">
                    9+
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-bell-fill "
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                  </svg>

                  {resposiveNavsHodeName ? (
                    <>
                      <div className={` ${showNotifi ? 'block' : 'hidden'} ${isDarkMode ? 'bg-black shadow-white shadow-2xl text-white' : 'bg-gray-100 text-black'} absolute top-[10vh] cursor-default -left-[10em] z-[1000000] w-[25em] p-2  rounded-xl     shadow-2xl`}>
                        <h2 className="w-full  text-center  font-bold text-[22px]">Notification</h2>
                        <div onClick={(e) => { SetShowNotifi(false); e.stopPropagation() }} className="absolute w-[20px] h-[20px] bg-red-400 cursor-pointer hover:bg-red-600 rounded-full text-white transition-all flex justify-center items-center pb-1 right-3 top-1">x</div>
                        <div className="w-full h-[0.2vh] bg-orange-600 mb-5"></div>
                        <div className="w-full max-h-[75vh]  overflow-y-auto overflow-x-hidden">

                          <div className={`${isDarkMode ? 'bg-black text-white' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max `}>
                            <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                              <img src="image/btr.png" />
                            </div>
                            <div className="w-[80%] ml-2">
                              <div className="flex w-ful justify-between">
                                <p className={`font-serif ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Online Exhange Solutions</p>
                                <p className="text-orange-600">10h15'</p>
                              </div>
                              <div className={`${isDarkMode ? 'text-gray-100' : 'text-gray-500'} `}>
                                Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                              </div>
                            </div>
                          </div>
                          <div className="w-full h-[0.2vh] bg-gray-500 mb-1"></div>
                          <div className={`${isDarkMode ? 'bg-black text-white' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max `}>
                            <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                              <img src="image/btr.png" />
                            </div>
                            <div className="w-[80%] ml-2">
                              <div className="flex w-ful justify-between">
                                <p className={`font-serif ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Online Exhange Solutions</p>
                                <p className="text-orange-600">10h15'</p>
                              </div>
                              <div className={`${isDarkMode ? 'text-gray-100' : 'text-gray-500'} `}>
                                Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </>) : (
                    <div className={` ${showNotifi ? 'block' : 'hidden'} ${isDarkMode ? 'bg-black text-white shadow-2xl shadow-white' : 'bg-gray-100 text-black'} absolute top-[10vh] cursor-default -left-full z-[1000000] w-[25em] p-2  rounded-xl     shadow-2xl`}>
                      <h2 className={`w-full t  text-center font-bold text-[22px]`}>Notification</h2>
                      <div onClick={(e) => { SetShowNotifi(false); e.stopPropagation() }} className="absolute w-[20px] h-[20px] bg-red-400 cursor-pointer hover:bg-red-600 rounded-full text-white transition-all flex justify-center items-center pb-1 right-3 top-1">x</div>
                      <div className="w-full h-[0.2vh] bg-orange-600 mb-5"></div>
                      <div className="w-full max-h-[75vh]  overflow-y-auto overflow-x-hidden">

                        <div className={`${isDarkMode ? 'bg-black text-white' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max `}>
                          <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                            <img src="image/btr.png" />
                          </div>
                          <div className="w-[80%] ml-2">
                            <div className="flex w-ful justify-between">
                              <p className={`font-serif ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Online Exhange Solutions</p>
                              <p className="text-orange-600">5 jours</p>
                            </div>
                            <div className={`${isDarkMode ? 'text-gray-100' : 'text-gray-500'} `}>
                              Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                            </div>
                          </div>
                        </div>
                        <div className="w-full h-[0.2vh] bg-gray-500 mb-1"></div>

                        <div className={`${isDarkMode ? 'bg-black text-white' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max `}>
                          <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                            <img src="image/btr.png" />
                          </div>
                          <div className="w-[80%] ml-2">
                            <div className="flex w-ful justify-between">
                              <p className={`font-serif ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Online Exhange Solutions</p>
                              <p className="text-orange-600">5 jours</p>
                            </div>
                            <div className={`${isDarkMode ? 'text-gray-100' : 'text-gray-500'} `}>
                              Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}



                </div>


                <div
                  onClick={HandleOpenChat}
                  className={` ${isDarkMode ? "text-white hover:text-black" : ""
                    } cursor-pointer  hover:bg-gray-200 rounded-full  transition-all p-2 m-1 `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi  bi-chat-dots-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                </div>
                {getData.length ? (
                  <div className="  p-1 text-sm text-center  mr-2   text-gray-700 rounded-full  relative items-center ">
                    {Orders.test(pathname) ? (
                      <>
                        <Link
                          to="/Orders"
                          onClick={test}
                          className="cursor-pointer flex justify-center items-center text-white bg-gradient-to-t from-orange-600 to-orange-400 hover:bg-gray-200 rounded px-3  transition-all p-2 m-1"
                        >
                          <span className=" absolute -top-1 z-10 hover:scale-110 right-2   w-[20px] h-[20px] rounded-[500%] flex justify-center items-center text-white  bg-orange-800 text-[10px]">
                            {getData.length}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-cart4"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                          </svg>
                        </Link>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={test}
                          className={` ${isDarkMode ? "text-white hover:text-black" : ""
                            } cursor-pointer  hover:bg-gray-200 rounded-full   transition-all p-2 m-1 `}
                        >
                          <span className=" absolute -top-1 z-10 hover:scale-110 right-2   w-[20px] h-[20px] rounded-[500%] flex justify-center items-center text-white  bg-orange-800 text-[10px]">
                            {getData.length}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-cart4"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                          </svg>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      toast.warning("Votre panier est vide");
                    }}
                    className={` ${isDarkMode ? "text-white hover:text-black" : ""
                      } cursor-pointer  hover:bg-gray-200 rounded-full  transition-all p-2 m-1 `}
                  >
                    <div className="cursor-pointer  hover:bg-gray-200 rounded-full   transition-all  m-1 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-cart4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                      </svg>
                    </div>
                  </div>
                )}

                <label class="relative inline-flex items-center mr-1 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    ref={InputCheck}
                    class="sr-only peer"
                  />

                  <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-1 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                </label>

                <button
                  onClick={(e) => {
                    SetUserDrop(!UserDrop);
                    SetShowNotifi(false)
                    e.stopPropagation();
                  }}
                  class="  md:w-auto  md:ml-2 md:flex items-center justify-center text-right"
                >
                  <div>
                    <Link class=" overflow-hidden flex justify-centerm items-center relative">
                      {imageLoading1 && (
                        <div className="absolute w-full h-full ">
                          <img
                            src="https://www.eliananunes.com/images/lazy_loader.gif"
                            className="w-full h-full object-cover scale-[5]"
                          />
                        </div>
                      )}

                      <img
                        draggable="false"
                        onLoad={() => {
                          setTimeout(() => {
                            SetImageLoading1(false);
                          }, 1000);
                        }}
                        className="inline-block h-8 w-8 md:h-10 md:w-10 border object-cover border-orange-500 rounded-full"
                        src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                        alt=""
                      />
                    </Link>
                  </div>

                  {resposiveNavsHodeName ? (
                    <></>
                  ) : (
                    <>
                      <div
                        className={` ${isDarkMode ? "text-white" : ""
                          } mr-3  md:flex md:items-center ml-4`}
                      >
                        {user.nom && user.prenom ? (
                          <>
                            <span
                              className={` ${isDarkMode ? "text-white" : ""
                                } text-gray-700 text-sm mr-2`}
                            >
                              {user.nom} {user.prenom}
                            </span>
                            <div>
                              <i className="bi bi-caret-down-fill"></i>
                            </div>
                          </>
                        ) : (
                          <>
                            <BarLoader color="blue" width={50} height={2} />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </button>

                {UserDrop ? (
                  <div
                    className={`md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16 mt-2 w-48 rounded ${isDarkMode ? "bg-black" : "bg-gray-100"
                      }  border border-gray-300 shadow-xl`}
                  >
                    {dropdownItems.map((item, index) => (
                      <Link
                        key={index}
                        to={item.link}
                        className={`block px-4 py-2 text-sm capitalize ${isDarkMode ? "text-white" : "text-gray-700"
                          } hover:bg-orange-500 hover:text-white w-full text-left ${index === activeIndex
                            ? "bg-orange-600 text-white"
                            : ""
                          }`}
                        onClick={() => {
                          setActiveIndex(index);
                          Deconnection(item.link);
                        }}
                      >
                        {item.text}
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>

            <>
              <div
                className={`sm:w-[20em] w-[100%] slideMarche ${categoryMenu
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
                  }  top-[15vh]  h-[85vh] overflow-auto   left-0 fixed z-[2000]   ${isDarkMode ? "bg-black" : "bg-gray-100"
                  } transition-all border-r-2 border-orange-500`}
              >
                <div
                  className={` w-full flex overflow-hidden `}
                >
                  <div className="w-full   font-serif">
                    <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("") }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 ${category === "" ? "bg-orange-600 text-white hover:bg-orange-600" : ""}`} >
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Tous les catégories</p>
                    </div>
                    <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(!showVetem);; SetshowAlimentation(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Habits des Hommes" || category == 'Habits Enfants Féminins' || category == 'Habits Enfants Masculins' || category == 'Kit Nouveau Né Féminins' || category == 'Kit Nouveau Né Masculins' || category == 'Habits des bébés Féminins ' || category == 'Habits des bébés Masculins ' || category == 'Habits Sports ' || category == 'Chaussures des Femmes' || category == 'Chaussures des Hommes' || category == 'Habits des Femmes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Vêtements</p>

                      <div className={`absolute ${showVetem ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </div>
                    </div>
                    <div className={`text-gray-500 ${showVetem ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Hommes") }}>Habits des Hommes</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Femmes") }}>Habits des Femmes</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Hommes") }}>Chaussures des Hommes</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Femmes") }}>Chaussures des Femmes</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Sports ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Sports ") }}>Habits Sports </div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Masculins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Masculins ") }}>Habits des bébés Masculins </div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Féminins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Féminins ") }}>Habits des bébés Féminins </div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Masculins") }}>Kit Nouveau Né Masculins</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Féminins") }}>Kit Nouveau Né Féminins</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Masculins") }}>Habits Enfants Masculins</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Féminins") }}>Habits Enfants Féminins</div>
                    </div>

                    <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(!showAlimentation); SetshowVetem(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les céréales" || category == 'Les légumineuses' || category == 'Les tubercules' || category == 'Les farines' || category == 'Les légumes' || category == 'Les fruits' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Alimentation</p>

                      <div className={`absolute ${showAlimentation ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </div>
                    </div>
                    <div className={`text-gray-500 ${showAlimentation ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les céréales' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les céréales") }}>Les céréales</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumineuses' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumineuses") }}>Les légumineuses</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les tubercules' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les tubercules") }}>Les tubercules</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les farines' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les farines") }}>Les farines</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les fruits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les fruits") }}>Les fruits</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumes") }}>Les légumes</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les autres produits alimentaires' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les autres produits alimentaires") }}>Les autres produits alimentaires</div>
                    </div>


                    <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(!showAccessoires); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les valises" || category == 'Les sacs à main des femmes' || category == 'Les sacs à dos ' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Accessoires </p>

                      <div className={`absolute ${showAccessoires ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </div>
                    </div>
                    <div className={`text-gray-500 ${showAccessoires ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les valises' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les valises") }}>Les valises</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à main des femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à main des femmes") }}>Les sacs à main des femmes</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à dos ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à dos ") }}>Les sacs à dos </div>
                    </div>


                    <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(!showAppariels); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les appareils électroniques" || category == 'Les appareils électroménagers' || category == 'Autres équipements de ménage' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Électronique et électroménage</p>

                      <div className={`absolute ${showAppariels ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </div>
                    </div>
                    <div className={`text-gray-500 ${showAppariels ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroniques' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroniques") }}>Les appareils électroniques</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroménagers' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroménagers") }}>Les appareils électroménagers</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres équipements de ménage' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres équipements de ménage") }}>Autres équipements de ménage</div>
                    </div>


                    <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(!showMobilier); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Matériels de décors" || category == 'Les salons' || category == 'Les salles à manger' || category == 'Les lits' || category == 'Les salons des visiteurs' || category == 'Autres produits de menuiserie et soudure' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Mobilier</p>

                      <div className={`absolute ${showMobilier ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </div>
                    </div>
                    <div className={`text-gray-500 ${showMobilier ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Matériels de décors' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Matériels de décors") }}>Matériels de décors</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons") }}>Les salons</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salles à manger' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salles à manger") }}>Les salles à manger</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les lits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les lits") }}>Les lits</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons des visiteurs' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons des visiteurs") }}>Les salons des visiteurs</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres produits de menuiserie et soudure' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres produits de menuiserie et soudure") }}>Autres produits de menuiserie et soudure</div>
                    </div>


                    <div onClick={(e) => { SetshowVéhicules(!showVéhicules); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les automobiles" || category == 'Les motos et bicyclettes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                      <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                      <p className="sizeText">Véhicules</p>

                      <div className={`absolute ${showVéhicules ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                        </svg>
                      </div>
                    </div>
                    <div className={`text-gray-500 ${showVéhicules ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les automobiles' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les automobiles") }}>Les automobiles</div>
                      <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les motos et bicyclettes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les motos et bicyclettes") }}>Les motos et bicyclettes</div>

                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      ) : (
        <>
          <div
            className={`  ${isDarkMode ? "bg-black " : "bg-gray-100 "
              } h-[20vh]   rounded-b-1 border-b-2 border-orange-500`}
          >
            <div className="h-[10vh] flex justify-between  w-full">
              <div className="flex  h-full items-center">
                <div
                  onClick={(e) => {
                    setcategoryMenu(!categoryMenu);
                    e.stopPropagation();
                  }}
                  className="ml-2 cursor-pointer h-max  p-1 text-white rounded bg-gradient-to-r from-orange-500 to-orange-300 hover:from-orange-700 hover:to-orange-500 transition-all"
                >
                  {categoryMenu ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-list"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                      </svg>
                    </>
                  )}
                </div>
                <div className="flex items-center w-max ">
                  <div className="h-[3.5em] rotate-45 w-[3.5em] transition-all   relative overflow-hidden ml-3 rounded ">
                    {imageLoading && (
                      <div className="absolute w-full h-full ">
                        <img
                          src="https://www.eliananunes.com/images/lazy_loader.gif"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <img
                      draggable="false"
                      onLoad={() => {
                        setTimeout(() => {
                          SetImageLoading(false);
                        }, 1000);
                      }}
                      src={`https://apiv1.burundientempsreel.com/uploads/logo/OnexS.png`}
                      alt=""
                      className="h-full w-full -rotate-45 object-cover object-center"
                    />
                  </div>
                  <div
                    className={` ${resposiveNavs ? "hidden" : "block"
                      } font-serif text-[30px] ml-3  transition-all`}
                  >
                    <span className="text-orange-800">Onex-</span>
                    <span className="text-orange-600">S</span>
                  </div>
                </div>
              </div>
              <div className="flex  items-center  pr-4">
                {isMobileTros1 ? (
                  <>
                    <div
                      onClick={HandleOpenChat}
                      className={`cursor-pointer ${isDarkMode ? "text-white hover:text-black" : ""
                        }  hover:bg-gray-200 rounded-full   transition-all p-2  `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi  bi-chat-dots-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={(e) => { SetShowNotifi(!showNotifi); SetUserDrop(false); e.stopPropagation() }}
                      title="Notification"
                      className={`cursor-pointer  ${isDarkMode ? "text-white hover:text-black" : ""
                        } hover:bg-gray-200 rounded-full px-3  relative transition-all p-2 m-1 pointer-events-none `}
                    >
                      <span className=" absolute hidden -top-1 z-10 hover:scale-110    w-[20px] h-[20px] rounded-[500%]  justify-center items-center text-white  bg-orange-800 text-[10px]">
                        9+
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-bell-fill "
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                      </svg>
                      <div className={` ${showNotifi ? 'block' : 'hidden'} ${isDarkMode ? 'bg-black text-white' : 'bg-gray-200 text-gray-800'} fixed top-[10vh] cursor-default  left-2 z-[1000000] w-[95%] p-2  rounded-xl     shadow-2xl`}>
                        <h2 className="w-full  text-center  font-bold text-[22px]">Notification</h2>
                        <div onClick={(e) => { SetShowNotifi(false); e.stopPropagation() }} className="absolute w-[20px] h-[20px] bg-red-400 cursor-pointer hover:bg-red-600 rounded-full text-white transition-all flex justify-center items-center pb-1 right-3 top-1">x</div>
                        <div className="w-full h-[0.2vh] bg-orange-600 mb-5"></div>
                        <div className="w-full max-h-[75vh]  overflow-y-auto overflow-x-hidden">

                          <div className={`${isDarkMode ? 'bg-black' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max`}>
                            <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                              <img src="image/btr.png" />
                            </div>
                            <div className="w-[80%] ml-2">
                              <div className="flex w-ful justify-between">
                                <p className="font-serif">Online Exhange Solutions</p>
                                <p className="text-orange-600">10h15'</p>
                              </div>
                              <div className={` ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                                Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                              </div>
                            </div>
                          </div>
                          <div className="w-full h-[0.2vh] bg-gray-500 mb-1"></div>
                          <div className={`${isDarkMode ? 'bg-black' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max`}>
                            <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                              <img src="image/btr.png" />
                            </div>
                            <div className="w-[80%] ml-2">
                              <div className="flex w-ful justify-between">
                                <p className="font-serif">Online Exhange Solutions</p>
                                <p className="text-orange-600">10h15'</p>
                              </div>
                              <div className={` ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                                Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div
                      onClick={HandleOpenChat}
                      className={` ${isDarkMode
                        ? "text-white hover:text-black"
                        : "text-gray-900"
                        } cursor-pointer  hover:bg-gray-200 rounded-full   transition-all p-2  `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi  bi-chat-dots-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                    </div>
                  </>
                )}

                <>
                  <div
                    className={`sm:w-[20em] w-[100%] slideMarche ${categoryMenu
                      ? "opacity-100 pointer-events-auto"
                      : "opacity-0 pointer-events-none"
                      }  top-[10vh]  h-[90vh] overflow-auto   left-0 fixed z-[2000]    ${isDarkMode ? "bg-black" : "bg-gray-100"
                      } transition-all border-r-2 border-orange-500`}
                  >
                    <div
                      className={` w-full flex overflow-hidden `}
                    >
                      <div className="w-full   font-serif">
                        <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("") }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 ${category === "" ? "bg-orange-600 text-white hover:bg-orange-600" : ""}`} >
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Tous les catégories</p>
                        </div>
                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(!showVetem);; SetshowAlimentation(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Habits des Hommes" || category == 'Habits Enfants Féminins' || category == 'Habits Enfants Masculins' || category == 'Kit Nouveau Né Féminins' || category == 'Kit Nouveau Né Masculins' || category == 'Habits des bébés Féminins ' || category == 'Habits des bébés Masculins ' || category == 'Habits Sports ' || category == 'Chaussures des Femmes' || category == 'Chaussures des Hommes' || category == 'Habits des Femmes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Vêtements</p>

                          <div className={`absolute ${showVetem ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                          </div>
                        </div>
                        <div className={`text-gray-500 ${showVetem ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Hommes") }}>Habits des Hommes</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Femmes") }}>Habits des Femmes</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Hommes") }}>Chaussures des Hommes</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Femmes") }}>Chaussures des Femmes</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Sports ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Sports ") }}>Habits Sports </div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Masculins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Masculins ") }}>Habits des bébés Masculins </div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Féminins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Féminins ") }}>Habits des bébés Féminins </div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Masculins") }}>Kit Nouveau Né Masculins</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Féminins") }}>Kit Nouveau Né Féminins</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Masculins") }}>Habits Enfants Masculins</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Féminins") }}>Habits Enfants Féminins</div>
                        </div>

                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(!showAlimentation); SetshowVetem(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les céréales" || category == 'Les légumineuses' || category == 'Les tubercules' || category == 'Les farines' || category == 'Les légumes' || category == 'Les fruits' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Alimentation</p>

                          <div className={`absolute ${showAlimentation ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                          </div>
                        </div>
                        <div className={`text-gray-500 ${showAlimentation ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les céréales' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les céréales") }}>Les céréales</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumineuses' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumineuses") }}>Les légumineuses</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les tubercules' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les tubercules") }}>Les tubercules</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les farines' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les farines") }}>Les farines</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les fruits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les fruits") }}>Les fruits</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumes") }}>Les légumes</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les autres produits alimentaires' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les autres produits alimentaires") }}>Les autres produits alimentaires</div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(!showAccessoires); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les valises" || category == 'Les sacs à main des femmes' || category == 'Les sacs à dos ' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Accessoires </p>

                          <div className={`absolute ${showAccessoires ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                          </div>
                        </div>
                        <div className={`text-gray-500 ${showAccessoires ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les valises' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les valises") }}>Les valises</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à main des femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à main des femmes") }}>Les sacs à main des femmes</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à dos ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à dos ") }}>Les sacs à dos </div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(!showAppariels); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les appareils électroniques" || category == 'Les appareils électroménagers' || category == 'Autres équipements de ménage' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Électronique et électroménage</p>

                          <div className={`absolute ${showAppariels ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                          </div>
                        </div>
                        <div className={`text-gray-500 ${showAppariels ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroniques' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroniques") }}>Les appareils électroniques</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroménagers' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroménagers") }}>Les appareils électroménagers</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres équipements de ménage' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres équipements de ménage") }}>Autres équipements de ménage</div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(!showMobilier); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Matériels de décors" || category == 'Les salons' || category == 'Les salles à manger' || category == 'Les lits' || category == 'Les salons des visiteurs' || category == 'Autres produits de menuiserie et soudure' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Mobilier</p>

                          <div className={`absolute ${showMobilier ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                          </div>
                        </div>
                        <div className={`text-gray-500 ${showMobilier ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Matériels de décors' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Matériels de décors") }}>Matériels de décors</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons") }}>Les salons</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salles à manger' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salles à manger") }}>Les salles à manger</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les lits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les lits") }}>Les lits</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons des visiteurs' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons des visiteurs") }}>Les salons des visiteurs</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres produits de menuiserie et soudure' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres produits de menuiserie et soudure") }}>Autres produits de menuiserie et soudure</div>
                        </div>


                        <div onClick={(e) => { SetshowVéhicules(!showVéhicules); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les automobiles" || category == 'Les motos et bicyclettes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                          <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                          <p className="sizeText">Véhicules</p>

                          <div className={`absolute ${showVéhicules ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                            </svg>
                          </div>
                        </div>
                        <div className={`text-gray-500 ${showVéhicules ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les automobiles' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les automobiles") }}>Les automobiles</div>
                          <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les motos et bicyclettes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les motos et bicyclettes") }}>Les motos et bicyclettes</div>

                        </div>
                      </div>
                    </div>
                  </div>
                </>

                <label class="relative inline-flex items-center mr-1 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    ref={InputCheck}
                    class="sr-only peer"
                  />

                  <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-1 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                </label>

                <button
                  onClick={(e) => {
                    SetUserDrop(!UserDrop);
                    SetShowNotifi(false)
                    e.stopPropagation();
                  }}
                  class="flex items-center justify-center text-right"
                >
                  <div>
                    <Link class=" overflow-hidden flex justify-centerm items-center relative">
                      {imageLoading1 && (
                        <div className="absolute w-full h-full ">
                          <img
                            src="https://www.eliananunes.com/images/lazy_loader.gif"
                            className="w-full h-full object-cover scale-[5]"
                          />
                        </div>
                      )}

                      <img
                        draggable="false"
                        onLoad={() => {
                          setTimeout(() => {
                            SetImageLoading1(false);
                          }, 1000);
                        }}
                        className="inline-block h-10 w-10 border object-cover border-orange-500 rounded-full"
                        src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                        alt=""
                      />
                    </Link>
                  </div>

                  {resposiveNavsHodeName ? (
                    <></>
                  ) : (
                    <>
                      <div className=" mr-3  md:flex md:items-center ml-4">
                        {user.nom && user.prenom ? (
                          <>
                            <span className="text-gray-700 text-sm mr-2">
                              {user.nom} {user.prenom}
                            </span>
                          </>
                        ) : (
                          <>
                            <BarLoader color="blue" width={50} height={2} />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </button>

                {UserDrop ? (
                  <div className="md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16 mt-2 w-48 rounded bg-gray-100 border border-gray-300 shadow-xl">
                    {dropdownItems.map((item, index) => (
                      <Link
                        key={index}
                        to={item.link}
                        className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-500 hover:text-white w-full text-left ${index === activeIndex
                          ? "bg-orange-600 text-white"
                          : ""
                          }`}
                        onClick={() => {
                          setActiveIndex(index);
                          Deconnection(item.link);
                        }}
                      >
                        {item.text}
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="h-[10vh] border-t   w-full">
              <div className="flex justify-between  items-center  px-2">
                <div className="flex w-1/2">
                  {pathname == "/Commande" ? (
                    <>
                      <Link
                        to="/Commande"
                        title="Votre commande"
                        className="cursor-pointer flex justify-center items-center text-white bg-gradient-to-t from-orange-600 to-orange-400 hover:bg-gray-200 rounded px-3  transition-all p-2 m-1 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi  bi-basket-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                        </svg>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/Commande"
                        title="Votre commande"
                        className={`cursor-pointer  ${isDarkMode ? "text-white hover:text-black" : ""
                          } hover:bg-gray-200 rounded-full px-3  transition-all p-2 m-1 `}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi  bi-basket-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z" />
                        </svg>
                      </Link>
                    </>
                  )}
                  {getData.length ? (
                    <div className="  p-1 text-sm text-center  mr-2   text-gray-700 rounded-full  relative items-center ">
                      {Orders.test(pathname) ? (
                        <>
                          <Link
                            to="/Orders"
                            onClick={test}
                            className="cursor-pointer flex justify-center items-center text-white bg-gradient-to-t from-orange-600 to-orange-400 hover:bg-gray-200 rounded px-3  transition-all p-2 m-1"
                          >
                            <span className=" absolute -top-1 z-10 hover:scale-110 right-2   w-[20px] h-[20px] rounded-[500%] flex justify-center items-center text-white  bg-orange-800 text-[10px]">
                              {getData.length}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-cart4"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                            </svg>
                          </Link>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={test}
                            className={`cursor-pointer ${isDarkMode ? "text-white hover:text-black" : ""
                              }  hover:bg-gray-200 rounded-full px-1 py-2  transition-all m-1 `}
                          >
                            <span className=" absolute -top-1 z-10 hover:scale-110 right-2   w-[20px] h-[20px] rounded-[500%] flex justify-center items-center text-white  bg-orange-800 text-[10px]">
                              {getData.length}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-cart4"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                            </svg>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        toast.warning("Votre panier est vide");
                      }}
                      className={` p-1 ${isDarkMode ? "text-white hover:text-black" : ""
                        } text-sm text-center   text-gray-700 rounded-full `}
                    >
                      <div className="cursor-pointer  hover:bg-gray-200 rounded-full   transition-all p-2 m-1 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-cart4"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>

                {isMobileTros1 ? (
                  <>
                    <div
                      onClick={(e) => { SetShowNotifi(!showNotifi); SetUserDrop(false); e.stopPropagation() }}
                      title="Notification"
                      className={`cursor-pointer  ${isDarkMode ? "text-white hover:text-black" : ""
                        } hover:bg-gray-200 rounded-full px-3  relative transition-all p-2 m-1 pointer-events-none`}
                    >
                      <span className=" absolute hidden -top-1 z-10 hover:scale-110    w-[20px] h-[20px] rounded-[500%]  justify-center items-center text-white  bg-orange-800 text-[10px]">
                        9+
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-bell-fill "
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                      </svg>
                      <div className={` ${showNotifi ? 'block' : 'hidden'} ${isDarkMode ? 'bg-black text-white' : 'bg-gray-200 text-gray-800'} fixed top-[18vh] cursor-default  left-2 z-[1000000] w-[95%] p-2  rounded-xl     shadow-2xl`}>
                        <h2 className="w-full  text-center  font-bold text-[22px]">Notification</h2>
                        <div onClick={(e) => { SetShowNotifi(false); e.stopPropagation() }} className="absolute w-[20px] h-[20px] bg-red-400 cursor-pointer hover:bg-red-600 rounded-full text-white transition-all flex justify-center items-center pb-1 right-3 top-1">x</div>
                        <div className="w-full h-[0.2vh] bg-orange-600 mb-5"></div>
                        <div className="w-full max-h-[75vh]  overflow-y-auto overflow-x-hidden">

                          <div className={`${isDarkMode ? 'bg-black' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max`}>
                            <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                              <img src="image/btr.png" />
                            </div>
                            <div className="w-[80%] ml-2">
                              <div className="flex w-ful justify-between">
                                <p className="font-serif">Online Exhange Solutions</p>
                                <p className="text-orange-600">10h15'</p>
                              </div>
                              <div className={` ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                                Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                              </div>
                            </div>
                          </div>
                          <div className="w-full h-[0.2vh] bg-gray-500 mb-1"></div>
                          <div className={`${isDarkMode ? 'bg-black' : 'bg-white'} my-1 p-1 shadow-2xl rounded-lg flex h-max`}>
                            <div className="w-[4em] h-[4em] bg-red-500 rounded-full">
                              <img src="image/btr.png" />
                            </div>
                            <div className="w-[80%] ml-2">
                              <div className="flex w-ful justify-between">
                                <p className="font-serif">Online Exhange Solutions</p>
                                <p className="text-orange-600">10h15'</p>
                              </div>
                              <div className={` ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                                Bien sûr, voici un paragraphe informatif sur la réinitialisation d'une carte réseau dans un système d'exploitation Windows :
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="flex items-center">
                  {isMobileTros1 ? (
                    <>
                      <div
                        onClick={() => {
                          SetshowSearc(true);
                        }}
                        className={` ${isDarkMode ? "text-white hover:text-black" : ""
                          } p-1 text-sm text-center   text-gray-700 rounded-full `}
                      >
                        <div className="cursor-pointer  hover:bg-gray-200 rounded-full   transition-all p-2 m-1 ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                        </div>
                      </div>

                      {showSearc && (
                        <div className="fixed w-full left-0 flex items-center justify-center h-[10vh] bg-[#0000007c]">
                          <div
                            class={` flex rounded-full h-11 px-4 py-2  m-1 bg-gray-200 border border-orange-300 p-2 sm:w-max md:w-[17em]  relative space-x-4 inputwidth `}
                          >
                            <div className=" flex justify-center items-center ml-2 relative">
                              {support ? (
                                <div
                                  onClick={() => {
                                    toast.warning(
                                      "Votre navigateur ne prend pas en charge la reconnaissance vocale , utilise l'autre "
                                    );
                                  }}
                                  className="p-1 rounded-full cursor-pointer absolute"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-mic-mute-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                                    <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                                  </svg>
                                </div>
                              ) : (
                                <>
                                  {micro ? (
                                    <>
                                      <div
                                        onClick={() => {
                                          SpeechRecognition.stopListening();
                                          setMicro(false);
                                        }}
                                        className="p-1 rounded-full cursor-pointer absolute background"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-mic-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                                          <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                                        </svg>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        onClick={() => {
                                          SpeechRecognition.startListening({
                                            continuous: true,
                                            language: "fr-FR",
                                          });
                                          setMicro(true);
                                        }}
                                        className="p-1 rounded-full cursor-pointer  absolute"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-mic-mute-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                                          <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                                        </svg>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <input
                              value={titreseach}
                              onChange={(e) => {
                                settitreseach(e.target.value);
                                search(e.target.value);
                                if (SpeechRecognition.startListening) {
                                  SpeechRecognition.stopListening();
                                  setMicro(false);
                                }
                              }}
                              onInput={(e) => {
                                settitreseach(e.target.value);
                                search(e.target.value);
                              }}
                              ref={inputRef}
                              class="bg-transparent outline-none w-full pl-2"
                              type="text"
                              placeholder="Nom de l'article ou titre..."
                            />

                            {titreseach.length > 0 && (
                              <div
                                onClick={() => {
                                  handleClearTranscriptClick();
                                }}
                                className="bg-red-200 cursor-pointer hover:bg-red-600  transition-all rounded-full text-white top-1/3 mr-2 flex justify-center items-center absolute right-1"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-x"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </div>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              SetshowSearc(false);
                            }}
                            className="bg-gray-400 mr-2 cursor-pointer hover:bg-gray-600  transition-all rounded-full text-white   flex justify-center items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </div>{" "}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        class={` flex rounded-full h-11 px-4 py-2  m-1 bg-gray-200 border border-orange-300 p-2 sm:w-max md:w-[17em]  relative space-x-4 inputwidth `}
                      >
                        <div className=" flex justify-center items-center ml-2 relative">
                          {support ? (
                            <div
                              onClick={() => {
                                toast.warning(
                                  "Votre navigateur ne prend pas en charge la reconnaissance vocale , utilise l'autre "
                                );
                              }}
                              className="p-1 rounded-full cursor-pointer absolute"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-mic-mute-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                                <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                              </svg>
                            </div>
                          ) : (
                            <>
                              {micro ? (
                                <>
                                  <div
                                    onClick={() => {
                                      SpeechRecognition.stopListening();
                                      setMicro(false);
                                    }}
                                    className="p-1 rounded-full cursor-pointer absolute background"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-mic-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
                                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                                    </svg>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    onClick={() => {
                                      SpeechRecognition.startListening({
                                        continuous: true,
                                        language: "fr-FR",
                                      });
                                      setMicro(true);
                                    }}
                                    className="p-1 rounded-full cursor-pointer  absolute"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-mic-mute-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
                                      <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
                                    </svg>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <input
                          value={titreseach}
                          onChange={(e) => {
                            settitreseach(e.target.value);
                            search(e.target.value);
                            if (SpeechRecognition.startListening) {
                              SpeechRecognition.stopListening();
                              setMicro(false);
                            }
                          }}
                          onInput={(e) => {
                            settitreseach(e.target.value);
                            search(e.target.value);
                          }}
                          ref={inputRef}
                          class="bg-transparent outline-none w-full pl-2"
                          type="text"
                          placeholder="Nom de l'article ou titre..."
                        />

                        {titreseach.length > 0 && (
                          <div
                            onClick={() => {
                              handleClearTranscriptClick();
                            }}
                            className="bg-red-200 cursor-pointer hover:bg-red-600  transition-all rounded-full text-white top-1/3 mr-2 flex justify-center items-center absolute right-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <select
                    class="px-1  text-black border  focus:ring-0 border-orange-300 focus:border-orange-300 blur:border-orange-300 h-10 font-serif rounded transition cursor-pointer"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>12</option>
                    <option>20</option>
                    <option>24</option>
                    <option>30</option>
                    <option>36</option>
                    <option>40</option>
                    <option>48</option>
                    <option>50</option>
                    <option>60</option>
                    <option>70</option>
                    <option>72</option>

                    {data.length > 72 && <option>{data.length}</option>}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NavbarVisitorLogged;
