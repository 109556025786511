import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
function OrderDetail() {
  const [price, setPrice] = useState(0);
  const history = useNavigate();
  const { id } = useParams();
  const [Payment, setPayment] = useState(false);
  const [Shipping, setShipping] = useState(false);
  const [Received, setReceived] = useState(false);
  const [Commande, setCommande] = useState(true);
  const [data, setData] = useState({});
  const [status, setstatus] = useState('');

  useEffect(() => {
    axios.get(`https://apiv1.burundientempsreel.com/market/Orders/${id}`).then((response) => {
      setData(response.data);
      setstatus(response.data.status);
    }).catch((error) => {
      console.log(error.message);
    });

    // Déplacez la condition à l'intérieur de useEffect pour éviter la boucle infinie
    if (status === "pending") {
      setCommande(true);
    } else if (status === "payed") {
      setPayment(true)
    } else if (status == "shipping") {
      setShipping(true)
    } else if (status == "completed") {
      setReceived(true)
    }
  }, [id, status]);

  const handleReceve = async (id) => {
    try {
      await axios.put(`https://apiv1.burundientempsreel.com/market/admin/recevecommande/${id}`);
      history('/Commande');
      toast.success("votre confirmation que la commande reçue a réussi");
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la confirmation que la commande est reçue");
    }
  };


  const [isVisible, setIsVisible] = useState(false);
  const [comment, setComment] = useState('');

  const handleCommentSubmit = () => {
    if (comment.trim() === '') {
      toast.error('Le commentaire ne peut pas être vide');
    } else {
      const data = { commandemarketsId: id, Avis: comment }
      try {
        axios.put('https://apiv1.burundientempsreel.com/market/admin/recevecommandewithcomment', data);
        history('/Commande');
        setComment('');
        toast.success("votre confirmation que la commande avec commentaire reçue a réussi");
      } catch (error) {
        toast.error("Une erreur s'est produite lors de la confirmation que la commande est reçue");
      }

    }
  };
  return (
    <div>
      {data ? (
        <>
          <div className="my-8">
            <section className="flex flex-col md:flex-row  justify-center ">
              <div className="bg-gray-100 w-full shadow-lg border rounded-2xl flex flex-col max-w-5xl p-5 items-center">
                <div className="py-8 px-8 w-full">
                  <h2 className="font-bold text-3xl text-orange-500">

                    Détails d'expédition Onex-S
                  </h2>
                  <p className="text-sm mt-4 text-orange-500">

                    Vos informations d'expédition
                  </p>

                  <div
                    className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
                  >
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Pays</div><div class="px-4 py-2">{data.pays}</div></div>
                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Province</div><div class="px-4 py-2">{data.province}</div></div>
                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Commune</div><div class="px-4 py-2">{data.commune}</div></div>
                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Zone</div><div class="px-4 py-2">{data.zone}</div></div>
                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Quartier</div><div class="px-4 py-2">{data.quartier}</div></div>
                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Avenue</div><div class="px-4 py-2">{data.avenue}</div></div>

                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">HouseNumber</div><div class="px-4 py-2">{data.housernamber}</div></div>


                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">MobileNumber</div><div class="px-4 py-2">{data.mobile}</div></div>


                    </div>
                    <div className="flex flex-row">
                      <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Description</div><div class="px-4 py-2">{data.description}</div></div>


                    </div>
                  </div>


                  <hr class="h-px my-8 bg-gray-50 border-0 dark:bg-gray-700" />
                  <div className="flex text-orange-500 justify-between">
                    <p className="text-lg font-bold">Montant total </p>
                    <div className="">
                      <p className="mb-1 text-xl font-bold"> {data.prixtotal} Fbu</p>
                    </div>
                  </div>

                  <h2 className=" pt-8  font-bold text-2xl text-orange-500">
                    Votre image de preuve
                  </h2>


                  <div
                    className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
                  >
                    <div className="w-32 h-32 rounded overflow-hidden mb-4">
                      <img draggable='false'
                        src={`https://apiv1.burundientempsreel.com/uploads/market/${data.proofimage}`}
                        alt=" "
                        className="w-full h-full object-cover rounded-2xl border"
                      />
                    </div>
                  </div>
                  {data.commandemarketcontents ? (
                    <div className="h-full ">
                      <h1 className="mb-10 text-left text-2xl hidden font-bold">
                        Tous les articles du panier
                      </h1>
                      <div className="">
                        <h2 className="font-bold text-2xl text-orange-500">

                          Produits commandés
                        </h2>

                      </div>
                      <hr class="h-px my-8 bg-gray-300 border-0 dark:bg-gray-700" />
                      <div className="mx-auto max-w-2xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                        <div className="rounded-lg w-full">
                          {data.commandemarketcontents.map((e) => {
                            return (
                              <>
                                <div className="justify-between mb-6 rounded-lg border border-gray-200 bg-gray-100 p-6 shadow-md sm:flex sm:justify-start">
                                  <img draggable='false'
                                    src={`https://apiv1.burundientempsreel.com/uploads/market/${e.produit.profil}`}
                                    alt="product-image"
                                    className="w-full rounded-lg sm:w-40"
                                  />
                                  <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                    <div className="mt-5 sm:mt-0 md:w-48">

                                      <div className="flex flex-row justify-between "><h2 className="text-lg font-bold text-gray-900">
                                        {e.produit.titre.length >= 40
                                          ? e.produit.titre.substring(0, 40) + "...."
                                          : e.produit.titre}
                                      </h2>
                                      </div>
                                      <p className="mt-1 text-base text-gray-700">
                                        Quantite: {e.quantite}
                                      </p>
                                      <p className="mt-1 text-base text-gray-700">
                                        Categories:   {e.produit.categorie}
                                      </p>
                                      <p className="mt-1 text-base text-gray-700">
                                        Déscription:   {e.produit.description.length >= 70
                                          ? e.produit.description.substring(0, 70) + "...."
                                          : e.produit.description}
                                      </p>

                                    </div>
                                    <div className="mt-4  flex  md:flex-row flex-row-reverse justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">

                                      <div className="flex items-center space-x-4">
                                        <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                                          <p class="text-xl text-primary font-semibold">
                                            {Math.floor(e.produit.prix).toLocaleString("fr-FR")} Fbu
                                          </p>
                                          <p class="text-base text-gray-400 line-through">
                                            {Math.floor(e.produit.prix + (e.produit.prix * 0.2)).toLocaleString("fr-FR")} Fbu
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                      </div>
                    </div>
                  ) : null}
                </div>
                <div class="relative mb-4 mt-8 flex max-w-xs flex-col space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                  <p class="font-bold text-gray-400">
                    Suivi du processus de livraison</p>
                </div>
                <div class="relative flex max-w-xs mb-8 flex-col w-full items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
                  <div class="relative flex max-w-xs w-full flex-col items-start space-y-3 rounded-2xl p-3 md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                    {/* <!-- component --> */}
                    <div class="w-full py-6">
                      <div class="flex">
                        <div class="w-1/4">
                          <div class="relative mb-2">
                            <div
                              class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Commande
                                ? " bg-orange-500"
                                : " border-2 border-gray-200 bg-white text-gray-600 "
                                }`}
                            >
                              <span class="w-full text-center text-white">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="3"
                                  stroke="currentColor"
                                  class="w-full p-2"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>

                          <div class="text-center text-xs md:text-base">Commande</div>
                        </div>

                        <div class="w-1/4">
                          <div class="relative mb-2">
                            <div
                              class="align-center absolute flex content-center items-center align-middle"
                              style={{
                                width: " calc(100% - 2.5rem - 1rem)",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                                <div
                                  class={` rounded bg-orange-300 py-1 ${Commande ? "w-[100%]" : "w-[0%]"
                                    }`}
                                ></div>
                              </div>
                            </div>

                            <div
                              class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Payment || Shipping || Received
                                ? " bg-orange-500"
                                : " border-2 border-gray-200 bg-white "
                                }`}
                            >
                              {(Payment || Shipping || Received) ? (
                                <span class="w-full text-center   text-white">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="3"
                                    stroke="currentColor"
                                    class="w-full p-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 12.75l6 6 9-13.5"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span class="w-full text-center  text-gray-600">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="p-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="text-center text-xs md:text-base">
                            Paiement</div>
                        </div>

                        <div class="w-1/4">
                          <div class="relative mb-2">
                            <div
                              class="align-center absolute flex content-center items-center align-middle"
                              style={{
                                width: " calc(100% - 2.5rem - 1rem)",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                                <div
                                  class={` rounded bg-orange-300 py-1 ${Payment || Shipping || Received ? "w-[100%]" : "w-[0%]"
                                    }`}
                                ></div>
                              </div>
                            </div>

                            <div
                              class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Shipping || Received
                                ? " bg-orange-500"
                                : " border-2 border-gray-200 bg-white "
                                }`}
                            >
                              {Shipping || Received ? (
                                <span class="w-full text-center   text-white">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="3"
                                    stroke="currentColor"
                                    class="w-full p-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 12.75l6 6 9-13.5"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span class="w-full text-center  text-gray-600">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="p-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="text-center text-xs md:text-base">
                            Expédition</div>
                        </div>

                        <div class="w-1/4">
                          <div class="relative mb-2">
                            <div
                              class="align-center absolute flex content-center items-center align-middle"
                              style={{
                                width: "calc(100% - 2.5rem - 1rem)",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                                <div
                                  class={` rounded bg-orange-300 py-1 ${Shipping || Received ? "w-[100%]" : "w-[0%]"
                                    }`}
                                ></div>
                              </div>
                            </div>

                            <div
                              class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Received
                                ? " bg-orange-500"
                                : " border-2 border-gray-200 bg-white text-gray-600 "
                                }`}
                            >
                              {Received ? (
                                <span class="w-full text-center   text-white">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="3"
                                    stroke="currentColor"
                                    class="w-full p-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M4.5 12.75l6 6 9-13.5"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span class="w-full text-center  text-gray-600">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="p-2"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="text-center text-xs md:text-base">
                            Reçue</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Received ? "" : (
                    <p class="text-base text-black md:text-lg">
                      Vous êtes autorisé à confirmer la réception après l'activation de l'expédition
                    </p>)}

                  {(data.status === 'shipping') ? (
                    <p class="text-base text-black font-medium md:text-lg">
                      Cliquez sur l'étape pour activer
                    </p>) : (
                    Received ? "" : (
                      < p class="text-base  font-medium md:text-lg  text-orange-500">
                        Attendez l'étape pour activer l'expédition
                      </p>
                    )

                  )
                  }
                  {data.status === 'shipping' && !isVisible && (
                    <div class="flex py-5 justify-between w-full space-x-5 px-5">
                      <button
                        onClick={() => setIsVisible(!isVisible)}
                        className="block py-2 w-56 text-center text-white bg-green-500 border border-green-500 rounded-2xl hover:bg-transparent hover:text-green-500 transition"
                      >
                        Avis ou commentaire
                      </button>
                      <button
                        onClick={() => handleReceve(data.id)}
                        class="block  py-2 w-56  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
                      >
                        Confirmation reçue
                      </button>

                    </div>
                  )}
                </div>

                {isVisible && (
                  <div class="flex flex-col items-center justify-center min-h-screen">
                    <textarea
                      value={comment} onChange={(e) => setComment(e.target.value)}
                      class="w-full p-4 mb-4 rounded-md border border-gray-300"
                      placeholder="Saisissez votre avis ou commentaire"
                    ></textarea>
                    <div class="flex justify-between w-full space-x-4">
                      <button
                        onClick={() => setIsVisible(!isVisible)}
                        class="flex-1 py-2 text-center text-white bg-green-500 border border-green-500 rounded-md hover:bg-transparent hover:text-green-500 transition"
                      >
                        Annuler
                      </button>
                      <button
                        onClick={handleCommentSubmit}
                        class="flex-1 py-2 text-center text-white bg-orange-500 border border-orange-500 rounded-md hover:bg-transparent hover:text-orange-500 transition"
                      >
                        Confirmation reçue avec commantaire
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </>
      ) : null
      }
    </div >
  );
}

export default OrderDetail;
