/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react'
import { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Slider from 'react-slick'
import { toast } from 'react-toastify'
import Empty from '../../../User/UserMarket/Empty/Empty'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage'
import { BarLoader } from 'react-spinners'
import { chiffrement } from '../../../../module'
import { useDispatch, useSelector } from "react-redux";
import { ADD } from "../../../../Redux/Action/Action";

function Product_List({
  SetSignPopUpSeller,
  SetLoginPopUp,
  isVisitorLogged,
  itemsPerPage,
  getThemes,
  titre,
  category,
  photos
}) {




  let [loading, setLoading] = useState(true)
  const [productSearch, setproductSearch] = useState([])
  const [searching, setSearching] = useState(false);

  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };



  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)




  const [datanotsearch, setdatanotsearch] = useState(false)

  const [titreseach, settitreseach] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      setproductSearch([])
      try {
        if (category) {
          const response = await axios.get(`https://apiv1.burundientempsreel.com/market/user/productsbycategory/${category}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(false)
        } else {
          const response = await axios.get(`https://apiv1.burundientempsreel.com/market/user/getallproduct?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(true)
          const timer = setTimeout(() => {
            setLoading(false);
          }, 100);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        console.log(error.message);
        if (error.response) {
          console.log(error.response.data);
        }
      }
    };

    fetchData();
  }, [category, currentPage, itemsPerPage]);


  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page)
  }

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? 'text-sm font-medium leading-none cursor-pointer bg-orange-600 text-white w-[30px] h-[30px] pb-[20px] rounded-lg dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
              : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-2xl dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-orange-400 pt-3 mr-4 px-2'
          }
        >
          {i}
        </button>,
      )
    }
    return pageNumbers
  }



  useEffect(() => {
    if (titre) {
      setSearching(true);
      axios
        .get("https://apiv1.burundientempsreel.com/market/user/searchproductbuytitle", {
          params: { titre: titre },
        })
        .then((response) => {
          if (response.data === "Produit introuvable !") {
            toast.error("Produit est introuvable", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setproductSearch([]); // Mettre à jour avec un tableau vide
          } else {
            setproductSearch(response.data);
          }
          setSearching(false); // Indiquer que la recherche est terminée
        })
        .catch((err) => {
          console.error("Erreur lors de la recherche du produit", err);
          setproductSearch([]);
          setSearching(false);
        });
    } else {
      setproductSearch([]);
    }
  }, [titre])


  const [loadingImages, setLoadingImages] = useState([]);


  const handleImageLoad = (index) => {
    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 5000);
      return updatedLoadingImages;
    });
  };

  function BecomeSeller() {
    if (isVisitorLogged) {
      SetSignPopUpSeller(true)
    } else {
      SetLoginPopUp(true)
    }

  }


  var settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  const [listoffInvestors, setListOffInvestors] = useState([]);
  const [element, setElement] = useState(0);

  const [currentPagep, setCurrentPagep] = useState(1);
  const [itemsPerPagep, setItemsPerPagep] = useState(10);
  const [totalPagesp, setTotalPagesp] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPagep(page);
  };

  const handleItemsPerPageChangep = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPagep(selectedItemsPerPage);
  };
  const firstEntry = (currentPagep - 1) * itemsPerPagep + 1;
  const lastEntry = Math.min(currentPagep * itemsPerPagep, element);
  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/user/getallseller?page=${currentPagep}&size=${itemsPerPagep}`)
      .then((response) => {
        setElement(response.data.totalItems);
        setListOffInvestors(response.data.users);
        setTotalPagesp(response.data.totalPages);

      });
  }, [currentPagep, itemsPerPagep]);


  const [produittendance, setProduittendance] = useState([])
  useEffect(() => {
    axios.get('https://apiv1.burundientempsreel.com/market/users/getproduittendance').then((response) => {
      setProduittendance(response.data)
    })
  }, [])




  const dispatch = useDispatch();

  function send(e) {

    if (isVisitorLogged) {
      dispatch(ADD(e));
      toast.success(
        `Felicitation vous avez ajouter ${e.titre} de nos produit au panier`

      );
    } else {
      SetLoginPopUp(true)

    }

  }



  const [troisBlock, settroisBlock] = useState(window.innerWidth < 929)
  const [troisBlock1, settroisBlock1] = useState(window.innerWidth < 501)
  const [troisBlock2, settroisBlock2] = useState(window.innerWidth < 310)
  useEffect(() => {
    const handleResize = () => {
      settroisBlock(window.innerWidth < 929)
      settroisBlock1(window.innerWidth < 501)
      settroisBlock2(window.innerWidth < 310)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])



  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class="w-full relative ">



        <div className="Container_Market md:p-5 py-5 px-1 md:pt-10 pb-32 ">
          {productSearch.length > 0 || data.length === 0 ? (
            <></>
          ) : (
            datanotsearch && (
              <div className={` ${getThemes ? 'bg-black text-white' : 'bg-gray-100'} flex flex-col space-y-3 mb-10 p-3  rounded-2xl`}>
                <h2 class="text-base md:text-2xl font-bold ml-3 ">
                  Produit tendance
                </h2>
                <div>
                  <Slider

                    {...settings}
                  >
                    {produittendance.length > 0 &&
                      produittendance.map((item, index) => {
                        return (

                          <Link to={`Marche/product/${chiffrement(item.id)}`}>
                            <div class={`  ${getThemes ? 'bg-black text-white' : 'bg-white'} border overflow-hidden border-orange-700 mx-2 rounded-2xl flex flex-col items-center pt-1 shadow`}>
                              <div
                                className="md:w-[14em] h-24  bg-gray-200 rounded-2xl overflow-hidden md:h-[15em]"
                                key={item.id}
                              >
                                {loadingImages[index] ? (
                                  <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                ) : (
                                  <LoadingImage
                                    alt=''
                                    src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil}`}
                                    onLoad={() => handleImageLoad(index)}
                                    className='w-full h-full object-fill'

                                  />
                                )}

                              </div>

                              <div >
                                <a className="w-full flex flex-col items-center justify-center" >
                                  {item.titre ? (
                                    <>
                                      <h5 class="my-2 text-xs md:text-xl tracking-tight ">
                                        {' '}
                                        {item.titre.length >= 10
                                          ? item.titre.substring(0, 10) + '....'
                                          : item.titre}
                                      </h5>
                                    </>
                                  ) : (
                                    <>
                                      <BarLoader color="blue" width={30} height={2} />
                                    </>
                                  )}



                                  <p className="mb-1 text-xs md:text-base text-orange-500 font-semibold">
                                    {item.prix ? (
                                      <>
                                        {item.prix} Fbu
                                      </>
                                    ) : (
                                      <>
                                        <BarLoader color="blue" width={50} height={2} />
                                      </>
                                    )}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </Link>

                        )
                      })}
                  </Slider>
                </div>
              </div>
            )
          )}

          <div className=" border-t-2 ">
            <h2 className={`${getThemes ? ' text-white ' : 'text-gray-400'} text-base md:text-2xl font-bold py-4 ml-3`} >
              {productSearch.length > 0 || !datanotsearch
                ? 'Résultats de la recherche'
                : 'Magasinez les dernières nouveautés'}
            </h2>

            {productSearch.length > 0 ? (
              <div class={`grid  md:px-2 ${troisBlock ? 'grid-cols-3' : 'grid-cols-4 '} md:gap-6 gap-3`}>
                {

                  productSearch.map((item, index) => {
                    return (
                      <div class="border rounded-2xl p-2 block  overflow-hidden ">
                        <Link to={`Marche/product/${chiffrement(item.id)}`}>
                          <div className="relative w-full  overflow-hidden">
                            <div className={` ${troisBlock1 ? 'h-24' : 'h-52 md:h-56'}   `}>
                              <LoadingImage
                                key={index}
                                className='w-full h-full object-contain object-center'
                                src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil} `}

                              />
                            </div>
                          </div>
                        </Link>
                        <div class="w-full ">
                          <Link to={`Marche/product/${chiffrement(item.id)}`} className="pt-3 md:pb-3 px-0 md:px-4">
                            {item.titre ? (
                              <>


                                {troisBlock ? (<>
                                  <h4 className={`${getThemes ? ' text-white hover:text-orange-500' : 'text-gray-400 hover:text-orange-500'} text-xs font-medium  md:text-base mb-2   transition `}>
                                    {item.titre.length >= 10
                                      ? item.titre.substring(0, 10) + '....'
                                      : item.titre}
                                  </h4>
                                </>) : (<>
                                  <h4 className={`${getThemes ? ' text-white hover:text-orange-500' : 'text-gray-400 hover:text-orange-500'} text-xs font-medium  md:text-base mb-2   transition `}>
                                    {item.titre.length >= 30
                                      ? item.titre.substring(0, 30) + '....'
                                      : item.titre}
                                  </h4>
                                </>)}
                              </>
                            ) : (
                              <>
                                <BarLoader color="blue" width={30} height={2} />
                              </>
                            )}

                            <div className={`  flex  ${troisBlock1 ? 'flex-col items-start' : 'justify-between'}   items-baseline mb-1 space-x-2`}>
                              <p className={`text-[11px]  md:text-base text-orange-500 font-semibold`}>

                                {item.prix ? (
                                  <>
                                    {item.prix} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                              <p className="text-[10px] text-right md:text-sm text-red-400 line-through">
                                {item.prix ? (
                                  <>
                                    {item.prix + (item.prix * 10) / 100} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            {troisBlock1 ? (<>
                              <div className='text-gray-700 text-center  max-sm:text-[12px] '>
                                {item.description.length >= 10
                                  ? item.description.substring(0, 10) + '....'
                                  : item.description}
                              </div>
                            </>) : (<>
                              <div className='text-gray-700 text-center  max-sm:text-[12px] '>
                                {item.description.length >= 30
                                  ? item.description.substring(0, 30) + '....'
                                  : item.description}
                              </div>
                            </>)}

                            <div className="md:flex hidden items-baseline my-1 space-x-2">
                              <p className={`${getThemes ? ' text-white ' : 'text-gray-500'} text-xs  font-thin`}>
                                Disponible en stock :


                                {item.quanite ? (
                                  <>
                                    {item.quanite} articles
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="flex gap-1 text-sm text-orange-400">
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                              </div>
                            </div>
                          </Link>


                          <div className='w-full flex justify-center items-center'>
                            <button
                              onClick={() => { send(item) }}
                              class=" sm:w-max sm:py-2 py-1 px-2 w-full mt-4  flex sm:px-4  justify-center items-center  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi pointer-events-none bi-cart4 mr-1 sm:mr-3" viewBox="0 0 16 16">
                                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                              </svg>
                              <span className={`pointer-events-none ${troisBlock1 ? 'hidden' : 'block'} max-sm:text-[12px]`}> Ajouter au panier</span>
                            </button>
                          </div>
                        </div>
                      </div>

                    )
                  })
                }
              </div>
            ) : data.length > 0 ? (
              <div class={`grid  md:px-2 ${troisBlock ? 'grid-cols-3' : 'grid-cols-4 '} px-1 md:gap-6 gap-1`}>











                {
                  data.map((item, index) => {
                    return (
                      <div class="border rounded-2xl p-2 block  overflow-hidden ">
                        <Link to={`Marche/product/${chiffrement(item.id)}`}>
                          <div className="relative w-full  overflow-hidden">
                            <div className={` ${troisBlock1 ? 'h-24' : 'h-52 md:h-56'}   `}>
                              <LoadingImage
                                key={index}
                                className='w-full h-full object-contain object-center'
                                src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil} `}

                              />
                            </div>
                          </div>
                        </Link>
                        <div class="w-full ">
                          <Link to={`Marche/product/${chiffrement(item.id)}`} className="pt-3 md:pb-3 px-0 md:px-4">
                            {item.titre ? (
                              <>


                                {troisBlock ? (<>
                                  <h4 className={`${getThemes ? ' text-white hover:text-orange-500' : 'text-gray-400 hover:text-orange-500'} text-xs font-medium  md:text-base mb-2   transition `}>
                                    {item.titre.length >= 10
                                      ? item.titre.substring(0, 10) + '....'
                                      : item.titre}
                                  </h4>
                                </>) : (<>
                                  <h4 className={`${getThemes ? ' text-white hover:text-orange-500' : 'text-gray-400 hover:text-orange-500'} text-xs font-medium  md:text-base mb-2   transition `}>
                                    {item.titre.length >= 10
                                      ? item.titre.substring(0, 30) + '....'
                                      : item.titre}
                                  </h4>
                                </>)}
                              </>
                            ) : (
                              <>
                                <BarLoader color="blue" width={30} height={2} />
                              </>
                            )}

                            <div className={`  flex  ${troisBlock1 ? 'flex-col items-start' : 'justify-between'}   items-baseline mb-1 space-x-2`}>
                              <p className={`text-[11px]  md:text-base text-orange-500 font-semibold`}>

                                {item.prix ? (
                                  <>
                                    {item.prix} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                              <p className="text-[10px] text-right md:text-sm text-red-400 line-through">
                                {item.prix ? (
                                  <>
                                    {item.prix + (item.prix * 10) / 100} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            {troisBlock1 ? (<>
                              <div className='text-gray-700 text-center  max-sm:text-[12px] '>
                                {item.description.length >= 10
                                  ? item.description.substring(0, 10) + '....'
                                  : item.description}
                              </div>
                            </>) : (<>
                              <div className='text-gray-700 text-center  max-sm:text-[12px] '>
                                {item.description.length >= 30
                                  ? item.description.substring(0, 30) + '....'
                                  : item.description}
                              </div>
                            </>)}

                            <div className="md:flex hidden items-baseline my-1 space-x-2">
                              <p className={`${getThemes ? ' text-white ' : 'text-gray-500'} text-xs  font-thin`}>
                                Disponible en stock :


                                {item.quanite ? (
                                  <>
                                    {item.quanite} articles
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="flex gap-1 text-sm text-orange-400">
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                              </div>
                            </div>
                          </Link>


                          <div className='w-full flex justify-center items-center'>
                            <button
                              onClick={() => { send(item) }}
                              class=" sm:w-max sm:py-2 py-1 px-2 w-full mt-4  flex sm:px-4  justify-center items-center  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi pointer-events-none bi-cart4 mr-1 sm:mr-3" viewBox="0 0 16 16">
                                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                              </svg>
                              <span className={`pointer-events-none ${troisBlock1 ? 'hidden' : 'block'} max-sm:text-[12px]`}> Ajouter au panier</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            ) : (
              <div className='w-[100%] h-[70vh] flex justify-center items-center '>
                <Empty item="produit" />
              </div>
            )}

          </div>
          {productSearch.length > 0 || (!datanotsearch && data.length === 0) ? (
            <></>
          ) : (
            <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
              <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                <div className={`flex   ${getThemes ? 'text-wite' : 'text-gray-600  hover:text-orange-700'} items-center pt-3 mr-4  dark:text-gray-200  cursor-pointer`}>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1665 4H12.8332"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.1665 4L4.49984 7.33333"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.1665 4.00002L4.49984 0.666687"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <button
                    onClick={previousPage}
                    disabled={currentPage === 1}
                    className={` text-sm ml-3  cursor-pointer`}
                  >
                    Prec
                  </button>
                </div>
                <div className="sm:flex mt-3">{generatePageNumbers()}</div>
                <div className={`flex   ${getThemes ? 'text-wite' : 'text-gray-600  hover:text-orange-700'} items-center pt-3 mr-4  dark:text-gray-200  cursor-pointer`}>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="text-sm font-medium leading-none mr-3"
                  >
                    Suiv
                  </button>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1665 4H12.8332"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 7.33333L12.8333 4"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 0.666687L12.8333 4.00002"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {!datanotsearch && data.length === 0 ? (
            <></>
          ) : (
            <div className="flex flex-col space-y-3 mb-10 mt-10 p-3 shadow rounded-2xl ">
              <h2 class="text-base md:text-2xl font-bold mb-5 ml-3  text-orange-700">
                Les partenaires
              </h2>
              <div className='w-full'>
                <select
                  class=" py-2 px-5 text-black border border-orange-700 font-serif rounded transition duration-3000 cursor-pointer"
                  value={itemsPerPagep}
                  onChange={handleItemsPerPageChangep}
                >
                  <option>5</option>
                  <option>10</option>
                  <option>12</option>
                  <option>20</option>
                </select>
              </div>

              <div class="p-2 gap-3 flex flex-wrap">
                {listoffInvestors.map((item, index) => {
                  return (
                    <>
                      <div class="w-[10em] border border-dotted border-orange-700 p-2  rounded-2xl flex flex-col items-center justify-center ">
                        <div className="h-24 w-24 md:h-32 md:w-32 ">
                          <LoadingImage
                            key={index}
                            alt=" "
                            src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${item.photo}`}
                          />
                        </div>

                        <div class="">
                          <a className="w-full flex flex-col items-center justify-center">
                            <h5 class={`my-2  ${getThemes ? 'text-white' : 'text-gray-900  hover:text-orange-700'}  text-center text-xs md:text-lg font-semibold  `}>



                              {item.prenom && item.nom ? (
                                <>
                                  {item.nom}

                                  {item.prenom}
                                </>
                              ) : (
                                <>
                                  <BarLoader color="blue" width={30} height={2} />
                                </>
                              )}

                            </h5>
                          </a>
                        </div>
                      </div>
                    </>

                  )
                })}
              </div>


              <div class={`px-5 ${getThemes ? 'text-white' : 'text-gray-800'} py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between`}>
                <span className="text-xs xs:text-sm ">


                  {firstEntry && lastEntry && element ? (
                    <>
                      Montrant {firstEntry} à {lastEntry} de {element} partenaires
                    </>
                  ) : (
                    <>

                      Montrant {<BarLoader color="blue" width={20} height={2} />} à {<BarLoader color="blue" width={20} height={2} />} de {<BarLoader color="blue" width={20} height={2} />} partenaires
                    </>
                  )}




                </span>

                <div className="inline-flex mt-2 xs:mt-0">
                  <button
                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                    disabled={currentPagep === 1}
                    onClick={() => handlePageChange(currentPagep - 1)}
                  >
                    Précédent
                  </button>
                  <button
                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                    disabled={currentPagep === totalPagesp}
                    onClick={() => handlePageChange(currentPagep + 1)}
                  >
                    Suivant
                  </button>
                </div>

              </div>


            </div>
          )}






          <div class={`flex ${getThemes ? 'bg-black' : 'bg-white'} items-center justify-center p-5 w-full `}>
            <div class="text-center flex flex-col items-center ">
              <div class="inline-flex rounded-full bg-orange-100 p-4">
                <div class="rounded-full stroke-orange-600 bg-orange-200 p-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-shop"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                  </svg>
                </div>
              </div>
              <button class="mt-5 text-base md:text-2xl font-bold text-slate-800 ">
                Commencez à vendre gratuitement
              </button>
              <p class="text-slate-600 mt-5 text-xs  md:text-lg ">
                Rejoignez notre marché en ligne et libérez votre potentiel de
                réussite en ligne.
                <br />
                Vendez facilement, atteignez de nouveaux clients et développez
                votre activité <br />
                avec nous aujourd'hui !
              </p>
              <div class="w-full mt-6 flex items-center justify-center">
                <button
                  onClick={BecomeSeller}
                  class="block w-max p-2  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
                >
                  Commencer à vendre
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Product_List
