/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
// Import des dépendances
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import EmptyOnly from "../EmptyOnly/EmptyOnly";
import PropTypes from "prop-types";

function UserMarketOnly({ isdivScroll }) {
    const history = useNavigate();
    // État pour stocker les données des produits
    const [data, setData] = useState([]);
    // État pour stocker le numéro de page actuel
    const [currentPage, setCurrentPage] = useState(1);
    // État pour stocker le nombre total de pages
    const [totalPages, setTotalPages] = useState(1);
    let [loading, setLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(72);


    const [productSearch, setproductSearch] = useState([]);
    const [categoryMenu, setcategoryMenu] = useState(false)



    useEffect(() => {
        function hundleScrolls() {
            setcategoryMenu(false)
        }
        function hundleClick() {
            setcategoryMenu(false)
        }

        if (isdivScroll.current) {
            isdivScroll.current.addEventListener('scroll', hundleScrolls)
        }
        window.addEventListener('click', hundleClick)

        return () => {
            if (isdivScroll.current) {
                isdivScroll.current.removeEventListener('scroll', hundleScrolls)
                window.removeEventListener('click', hundleClick)
            }
        }
    }, [])

    const handleItemsPerPageChange = (membre) => {
        const selectedItemsPerPage = parseInt(membre.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    const iduser = localStorage.getItem("userid");
    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={currentPage === i ? "text-sm font-medium leading-none cursor-pointer bg-orange-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2" : "text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-orange-400 pt-3 mr-4 px-2"}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };


    function handleDelete(id, index) {
        Swal.fire({
            title: 'Confirmation',
            text: 'Etes-vous sûr que vous voulez supprimer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'SUPPRIMER',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'swal-confirm-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`https://apiv1.burundientempsreel.com/market/seller/deleteproduct/${id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('le produit a été supprimé avec succès');
                            const alldata = [...data]
                            alldata.splice(index, 1)
                            setData(alldata)
                        } else {
                            toast.error("Une erreur s'est produite lors de la suppression du produit");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        toast.error("Une erreur s'est produite lors de la suppression du produit verifier si votre produit est commandé");
                    });
            }
        });
    }
    const LazyImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };

        const elementInViewPort = () => {
            // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
            const clientRect = imageRef.current.getBoundingClientRect();

            return (
                clientRect.top >= 0 &&
                clientRect.left >= 0 &&
                clientRect.bottom - 100 <=
                (window.innerHeight || document.documentElement.clientHeight) &&
                clientRect.right <=
                (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LazyImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };






    const [titreseach, settitreseach] = useState("");
    const [searching, setSearching] = useState(false);
    // Fonction de recherche
    const search = (titre) => {
        if (titre) {
            setSearching(true);
            axios
                .get("https://apiv1.burundientempsreel.com/market/seller/searchproductbuytitle", {
                    params: { id: iduser, titre: titre },
                })
                .then((response) => {
                    if (response.data === "Produit introuvable !") {
                        toast.error("Produit est introuvable", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setproductSearch([]);
                        setTimeout(() => {
                            setLoading(false)
                        }, 1000);
                        // Mettre à jour avec un tableau vide
                    } else {
                        setproductSearch(response.data);
                    }
                    setSearching(false); // Indiquer que la recherche est terminée
                })
                .catch((err) => {
                    console.error("Erreur lors de la recherche du produit", err);
                    setproductSearch([]);
                    setSearching(false);
                });
        } else {
            setproductSearch([]);
        }
    };
    const [category, setCategory] = useState("");
    const [datanotsearch, setdatanotsearch] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setproductSearch([])
            try {
                if (category) {
                    const response = await axios.get(`https://apiv1.burundientempsreel.com/market/seller/productsbycategory/${iduser}/${category}?page=${currentPage}&size=${itemsPerPage}`);
                    const { products, totalPages } = response.data;
                    setData(products);
                    setTotalPages(totalPages);
                    setdatanotsearch(false)
                } else {
                    const response = await axios.get(`https://apiv1.burundientempsreel.com/market/seller/getallproduct/${iduser}?page=${currentPage}&size=${itemsPerPage}`);
                    const { products, totalPages } = response.data;
                    setData(products);
                    setTotalPages(totalPages);
                    setdatanotsearch(true)
                    const timer = setTimeout(() => {
                        setLoading(false);
                    }, 100);
                    return () => clearTimeout(timer);
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchData();
    }, [category, currentPage, itemsPerPage]);

    const handleCategoryClick = (category) => {
        setCategory(category);
        setcategoryMenu(false);
    };




    const [isDarkMode, setIsDarkMode] = useState(() => {
        const storedTheme = localStorage.getItem("mode");
        return storedTheme === "ombre";
    });

    // getTheme(isDarkMode);
    const InputCheck = useRef(null);

    useEffect(() => {
        const handleCheckboxChange = (event) => {
            if (event.target.checked) {
                localStorage.setItem("mode", "ombre");
                setIsDarkMode(event.target.checked);
            } else {
                localStorage.removeItem("mode");
                setIsDarkMode(event.target.checked);
            }
        };

        const handleLocalStorage = () => {
            const mode = localStorage.getItem("mode");

            if (mode == "ombre") {
                InputCheck.current.checked = true;
                setIsDarkMode(mode === "ombre");
            } else {
                InputCheck.current.checked = false;
            }
        };

        if (InputCheck.current) {
            InputCheck.current.addEventListener("change", handleCheckboxChange);
        }

        handleLocalStorage();

        return () => {
            if (InputCheck.current) {
                InputCheck.current.removeEventListener("change", handleCheckboxChange);
            }
        };
    }, []);

    const [showVetem, SetshowVetem] = useState(false)
    const [showAlimentation, SetshowAlimentation] = useState(false)
    const [showAccessoires, SetshowAccessoires] = useState(false)
    const [showAppariels, SetshowAppariels] = useState(false)
    const [showMobilier, SetshowMobilier] = useState(false)
    const [showVéhicules, SetshowVéhicules] = useState(false)




    return (
        <>
            <div class="w-full relative">
                {loading && <SpinnerDemarage />}
                <div class="flex items-center z-10 sticky top-0 space-y-3 p-3 bg-gray-100 ">
                    <div class="flex justify-between items-center gap-2  pb-1 w-full ml-auto">
                        <div
                            onClick={(e) => {
                                setcategoryMenu(!categoryMenu)
                                e.stopPropagation()

                            }}
                            class="bg-cream h-10           
              items-center bi bi-list-nested py-1 px-2 text-[30px] pb-11 bg-orange-400 text-white font-semibold rounded-lg hover:shadow-lg transition duration-3000 cursor-pointer"
                        >

                        </div>




                        <label class="relative hidden  items-center mr-1 cursor-pointer">
                            <input
                                type="checkbox"
                                value=""
                                ref={InputCheck}
                                class="sr-only peer"
                            />

                            <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-1 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                        </label>
                        <>
                            <div
                                className={`sm:w-[20em] w-[100%] slideMarche ${categoryMenu
                                    ? "opacity-100 pointer-events-auto"
                                    : "opacity-0 pointer-events-none"
                                    }  top-[20vh]  h-[80vh] overflow-auto   left-0 fixed z-[2000]  ${isDarkMode ? "bg-black" : "bg-gray-100"
                                    } transition-all border-r-2 border-orange-500`}
                            >
                                <div className="w-full flex justify-between items-center pl-4 pr-2 h-4 my-3 mr-4">
                                    <span className="text-[23px] text-orange-500">Notre cotégories</span>
                                    <span className="bg-orange-500 text-white  cursor-pointer p-2 rounded-full "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-skip-backward-fill" viewBox="0 0 16 16">
                                        <path d="M.5 3.5A.5.5 0 0 0 0 4v8a.5.5 0 0 0 1 0V8.753l6.267 3.636c.54.313 1.233-.066 1.233-.697v-2.94l6.267 3.636c.54.314 1.233-.065 1.233-.696V4.308c0-.63-.693-1.01-1.233-.696L8.5 7.248v-2.94c0-.63-.692-1.01-1.233-.696L1 7.248V4a.5.5 0 0 0-.5-.5z" />
                                    </svg>
                                    </span>
                                </div>
                                <div className={` w-full flex overflow-hidden `} >
                                    <div className="w-full   font-serif">
                                        <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("") }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 ${category === "" ? "bg-orange-600 text-white hover:bg-orange-600" : ""}`} >
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Tous les catégories</p>
                                        </div>
                                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(!showVetem);; SetshowAlimentation(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Habits des Hommes" || category == 'Habits Enfants Féminins' || category == 'Habits Enfants Masculins' || category == 'Kit Nouveau Né Féminins' || category == 'Kit Nouveau Né Masculins' || category == 'Habits des bébés Féminins ' || category == 'Habits des bébés Masculins ' || category == 'Habits Sports ' || category == 'Chaussures des Femmes' || category == 'Chaussures des Hommes' || category == 'Habits des Femmes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Vêtements</p>

                                            <div className={`absolute ${showVetem ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`text-gray-500 ${showVetem ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Hommes") }}>Habits des Hommes</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Femmes") }}>Habits des Femmes</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Hommes") }}>Chaussures des Hommes</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Femmes") }}>Chaussures des Femmes</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Sports ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Sports ") }}>Habits Sports </div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Masculins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Masculins ") }}>Habits des bébés Masculins </div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Féminins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Féminins ") }}>Habits des bébés Féminins </div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Masculins") }}>Kit Nouveau Né Masculins</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Féminins") }}>Kit Nouveau Né Féminins</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Masculins") }}>Habits Enfants Masculins</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Féminins") }}>Habits Enfants Féminins</div>
                                        </div>

                                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(!showAlimentation); SetshowVetem(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les céréales" || category == 'Les légumineuses' || category == 'Les tubercules' || category == 'Les farines' || category == 'Les légumes' || category == 'Les fruits' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Alimentation</p>

                                            <div className={`absolute ${showAlimentation ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`text-gray-500 ${showAlimentation ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les céréales' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les céréales") }}>Les céréales</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumineuses' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumineuses") }}>Les légumineuses</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les tubercules' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les tubercules") }}>Les tubercules</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les farines' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les farines") }}>Les farines</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les fruits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les fruits") }}>Les fruits</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumes") }}>Les légumes</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les autres produits alimentaires' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les autres produits alimentaires") }}>Les autres produits alimentaires</div>
                                        </div>


                                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(!showAccessoires); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les valises" || category == 'Les sacs à main des femmes' || category == 'Les sacs à dos ' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Accessoires </p>

                                            <div className={`absolute ${showAccessoires ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`text-gray-500 ${showAccessoires ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les valises' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les valises") }}>Les valises</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à main des femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à main des femmes") }}>Les sacs à main des femmes</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à dos ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à dos ") }}>Les sacs à dos </div>
                                        </div>


                                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(!showAppariels); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les appareils électroniques" || category == 'Les appareils électroménagers' || category == 'Autres équipements de ménage' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Électronique et électroménage</p>

                                            <div className={`absolute ${showAppariels ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`text-gray-500 ${showAppariels ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroniques' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroniques") }}>Les appareils électroniques</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroménagers' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroménagers") }}>Les appareils électroménagers</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres équipements de ménage' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres équipements de ménage") }}>Autres équipements de ménage</div>
                                        </div>


                                        <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(!showMobilier); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Matériels de décors" || category == 'Les salons' || category == 'Les salles à manger' || category == 'Les lits' || category == 'Les salons des visiteurs' || category == 'Autres produits de menuiserie et soudure' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Mobilier</p>

                                            <div className={`absolute ${showMobilier ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`text-gray-500 ${showMobilier ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Matériels de décors' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Matériels de décors") }}>Matériels de décors</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons") }}>Les salons</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salles à manger' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salles à manger") }}>Les salles à manger</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les lits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les lits") }}>Les lits</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons des visiteurs' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons des visiteurs") }}>Les salons des visiteurs</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres produits de menuiserie et soudure' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres produits de menuiserie et soudure") }}>Autres produits de menuiserie et soudure</div>
                                        </div>


                                        <div onClick={(e) => { SetshowVéhicules(!showVéhicules); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les automobiles" || category == 'Les motos et bicyclettes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                                            <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                                            <p className="sizeText">Véhicules</p>

                                            <div className={`absolute ${showVéhicules ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className={`text-gray-500 ${showVéhicules ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les automobiles' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les automobiles") }}>Les automobiles</div>
                                            <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les motos et bicyclettes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les motos et bicyclettes") }}>Les motos et bicyclettes</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>


                        <div className="flex">



                            <div className="flex">
                                <div class="flex  h-12 bg-white border border-orange-600 px-2 rounded-l-2xl overflow-hidden sm:w-[20em] items-center  inputwidth ">
                                    <i class="bi bi-search"></i>
                                    <input
                                        value={titreseach}
                                        onChange={(e) => {
                                            settitreseach(e.target.value);
                                            search(e.target.value);
                                        }}
                                        class="bg-transparent outline-none w-full pl-2"
                                        type="text"
                                        placeholder="Nom de l'article ou titre..."

                                    />


                                </div>
                                <select
                                    class="px-2  text-black border border-orange-700 font-serif rounded-r-2xl transition cursor-pointer"
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                >
                                    <option>5</option>
                                    <option>10</option>
                                    <option>12</option>
                                    <option>20</option>
                                    <option>24</option>
                                    <option>30</option>
                                    <option>36</option>
                                    <option>40</option>
                                    <option>48</option>
                                    <option>50</option>
                                    <option>60</option>
                                    <option>70</option>
                                    <option>72</option>
                                    {data.length > 72 && (
                                        <option>{data.length}</option>
                                    )}
                                </select>







                            </div>




                        </div>
                    </div>
                </div>



                {(data.length > 0 || productSearch.length > 0) ? (
                    productSearch.length > 0 ? (
                        <div className="col-span-3  m-3">
                            <div className="grid  sm:grid-cols-3  md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5 grid-cols-2 clsgrid gap-6">
                                {productSearch.map((item, index) => {
                                    return (
                                        <div className="rounded-2xl border border-gray-300 shadow overflow-hidden group">
                                            <div className="relative h-30 overflow-hidden">
                                                <div className="h-56">
                                                    <LazyImage
                                                        key={index}
                                                        alt=''
                                                        src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil}`}
                                                    />
                                                </div>

                                            </div>
                                            <div className="pt-4 pb-3 px-4">
                                                <Link to={`/UserOnly/Mar(1 + / 100)che/PlusPhotos/Detail/${item.id}`}>
                                                    <h4 className="font-medium text-base mb-2 text-gray-800 hover:text-orange-500 transition">
                                                        {item.titre.length >= 40
                                                            ? item.titre.substring(0, 40) + "...."
                                                            : item.titre}
                                                        {item.titre.length < 20 ? <div className="py-3"></div> : null}
                                                    </h4>
                                                </Link>
                                                <div className="flex items-baseline mb-1 space-x-2">
                                                    <p className="text-base line-through text-orange-500 font-semibold">
                                                        {item.prix} Fbu
                                                    </p>
                                                    <p className="text-sm text-gray-400 ">
                                                        {parseInt(item.prix / (1 + 5 / 100))} Fbu
                                                    </p>
                                                </div>
                                                <div className="flex items-baseline mb-1 space-x-2">
                                                    <p className="text-xs text-gray-400 font-thin">
                                                        Disponible en stock :{item.quanite} articles
                                                    </p>
                                                    <p className="text-xs text-gray-400 flex font-thin">
                                                        Valide :   {(item.Active == 1) ? (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all  text-white bg-green-500 rounded-full" viewBox="0 0 16 16">
                                                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                                        </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x  text-white bg-red-900 rounded-full" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>)}
                                                    </p>
                                                </div>

                                                <div className="flex items-center">
                                                    <div className="flex gap-1 text-sm text-orange-400">
                                                        <span>
                                                            <i className="fa-solid fa-star"></i>
                                                        </span>
                                                        <span>
                                                            <i className="fa-solid fa-star"></i>
                                                        </span>
                                                        <span>
                                                            <i className="fa-solid fa-star"></i>
                                                        </span>
                                                        <span>
                                                            <i className="fa-solid fa-star" />
                                                        </span>
                                                        <span>
                                                            <i className="fa-solid fa-star" />
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="w-full text-white bg-gray-200 rounded-2xl  p-1 flex justify-between items-center "  >
                                                <Link to={`/UserOnly/Marche/updateForm/${item.id}`} className=" bg-orange-500 p-2 rounded-2xl hover:bg-orange-600" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-down-left" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M9.636 2.5a.5.5 0 0 0-.5-.5H2.5A1.5 1.5 0 0 0 1 3.5v10A1.5 1.5 0 0 0 2.5 15h10a1.5 1.5 0 0 0 1.5-1.5V6.864a.5.5 0 0 0-1 0V13.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                                        <path fill-rule="evenodd" d="M5 10.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H6.707l8.147-8.146a.5.5 0 0 0-.708-.708L6 9.293V5.5a.5.5 0 0 0-1 0v5z" />
                                                    </svg>
                                                </Link>

                                                <Link to={`/UserOnly/Marche/updatePlusPhotos/${item.id}`} className=" bg-orange-500 p-2 rounded-2xl hover:bg-orange-600" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                    </svg>
                                                </Link>
                                                <button
                                                    onClick={() => handleDelete(item.id, index)}
                                                    className="bg-red-700 hover:bg-red-800 p-2 rounded-2xl cursor-pointer"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div >
                    )
                        : data.length > 0 && (
                            <div className="col-span-3  m-3">
                                <div className="grid  sm:grid-cols-3  md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5 grid-cols-2 clsgrid gap-6">
                                    {data.map((item, index) => {
                                        return (
                                            <div className="rounded-2xl border border-gray-300 shadow overflow-hidden group">
                                                <div className="relative h-30 overflow-hidden">
                                                    <div className="h-56">
                                                        <LazyImage
                                                            key={index}
                                                            alt=''
                                                            src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil}`}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="pt-4 pb-3 px-4">
                                                    <Link to={`/UserOnly/Marche/PlusPhotos/Detail/${item.id}`}>
                                                        <h4 className="font-medium text-base mb-2 text-gray-800 hover:text-orange-500 transition">
                                                            {item.titre.length >= 40
                                                                ? item.titre.substring(0, 40) + "...."
                                                                : item.titre}
                                                            {item.titre.length < 20 ? <div className="py-3"></div> : null}
                                                        </h4>
                                                    </Link>
                                                    <div className="flex items-baseline mb-1 space-x-2">
                                                        <p className="text-base line-through text-orange-500 font-semibold">
                                                            {item.prix} Fbu
                                                        </p>
                                                        <p className="text-sm text-gray-400 ">
                                                            {parseInt(item.prix / (1 + 5 / 100))} Fbu
                                                        </p>
                                                    </div>
                                                    <div className="flex items-baseline mb-1 space-x-2">
                                                        <p className="text-xs text-gray-400 font-thin">
                                                            Disponible en stock :{item.quanite} articles
                                                        </p>
                                                        <p className="text-xs text-gray-400 flex font-thin">
                                                            Valide :   {(item.Active == 1) ? (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all  text-white bg-green-500 rounded-full" viewBox="0 0 16 16">
                                                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                                            </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x  text-white bg-red-900 rounded-full" viewBox="0 0 16 16">
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>)}
                                                        </p>
                                                    </div>

                                                    <div className="flex items-center">
                                                        <div className="flex gap-1 text-sm text-orange-400">
                                                            <span>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                            <span>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                            <span>
                                                                <i className="fa-solid fa-star"></i>
                                                            </span>
                                                            <span>
                                                                <i className="fa-solid fa-star" />
                                                            </span>
                                                            <span>
                                                                <i className="fa-solid fa-star" />
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="w-full text-white bg-gray-200 rounded-2xl  p-1 flex justify-between items-center "  >
                                                    <Link to={`/UserOnly/Marche/updateForm/${item.id}`} className=" bg-orange-500 p-2 rounded-2xl hover:bg-orange-600" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-down-left" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M9.636 2.5a.5.5 0 0 0-.5-.5H2.5A1.5 1.5 0 0 0 1 3.5v10A1.5 1.5 0 0 0 2.5 15h10a1.5 1.5 0 0 0 1.5-1.5V6.864a.5.5 0 0 0-1 0V13.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                                            <path fill-rule="evenodd" d="M5 10.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H6.707l8.147-8.146a.5.5 0 0 0-.708-.708L6 9.293V5.5a.5.5 0 0 0-1 0v5z" />
                                                        </svg>
                                                    </Link>

                                                    <Link to={`/UserOnly/Marche/updatePlusPhotos/${item.id}`} className=" bg-orange-500 p-2 rounded-2xl hover:bg-orange-600" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                        </svg>
                                                    </Link>
                                                    <button
                                                        onClick={() => handleDelete(item.id, index)}
                                                        className="bg-red-700 hover:bg-red-800 p-2 rounded-2xl cursor-pointer"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                        </svg>
                                                    </button>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                                    <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                                        <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-orange-700 cursor-pointer">
                                            <svg
                                                width="14"
                                                height="8"
                                                viewBox="0 0 14 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.1665 4H12.8332"
                                                    stroke="currentColor"
                                                    stroke-width="1.25"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M1.1665 4L4.49984 7.33333"
                                                    stroke="currentColor"
                                                    stroke-width="1.25"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M1.1665 4.00002L4.49984 0.666687"
                                                    stroke="currentColor"
                                                    stroke-width="1.25"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <button onClick={previousPage} disabled={currentPage === 1} className="text-sm ml-3 text-black  hover:text-orange-700 cursor-pointer">Previous</button>
                                        </div>
                                        <div className="flex mt-3">
                                            {generatePageNumbers()}

                                        </div>
                                        <div className="flex items-center pt-3 ml-4 text-black  hover:text-orange-700 cursor-pointer">
                                            <button onClick={nextPage} disabled={currentPage === totalPages} className="text-sm font-medium leading-none mr-3">Next</button>
                                            <svg
                                                width="14"
                                                height="8"
                                                viewBox="0 0 14 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.1665 4H12.8332"
                                                    stroke="currentColor"
                                                    stroke-width="1.25"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M9.5 7.33333L12.8333 4"
                                                    stroke="currentColor"
                                                    stroke-width="1.25"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M9.5 0.666687L12.8333 4.00002"
                                                    stroke="currentColor"
                                                    stroke-width="1.25"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                )

                    :
                    <EmptyOnly item="produit" />
                }
            </div>
        </>
    );
}

export default UserMarketOnly;


