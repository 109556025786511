import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';

const ApexChart = () => {
    const [data, setData] = useState({});
    const userId = localStorage.getItem("userid");
    const [numberOfDays, setNumberOfDays] = useState(7);

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/autovente/getStatisticLastDaysWithClient/${numberOfDays}/${userId}`)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [numberOfDays, userId]);

    const handleNumberOfDaysChange = (event) => {
        const newNumberOfDays = event.target.value;
        setNumberOfDays(newNumberOfDays);
    };

    const selectOptions = [
        { value: 7, label: '7 jours' },
        { value: 10, label: '10 jours' },
        { value: 20, label: '20 jours' },
        { value: 30, label: '30 jours' },
        { value: 60, label: '60 jours' },
        { value: 120, label: '120 jours' },
        { value: 150, label: '150 jours' },
        { value: 180, label: '180 jours' },
    ];

    const labels = Object.keys(data.statisticsByDate || {});
    const prixTotalData = Object.values(data.statisticsByDate || {}).map((item) => item.prixtotal || 0);
    const montantDejaPayeData = Object.values(data.statisticsByDate || {}).map((item) => item.montantdejapaye || 0);
    const detteTotalData = Object.values(data.statisticsByDate || {}).map((item) => item.detteTotal || 0);

    const statisticsChartData = {
        options: {
            xaxis: {
                categories: labels,
            },
            stroke: {
                curve: 'smooth',
            },
            chart: {
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                    },
                },
                zoom: {
                    enabled: false,
                },
                pan: {
                    enabled: false,
                },
            },
        },
        series: [
            {
                name: 'Prix Total',
                data: prixTotalData,
            },
            {
                name: 'Montant Déjà Payé',
                data: montantDejaPayeData,
            },
            {
                name: 'Dette Total',
                data: detteTotalData,
            },
        ],
    };

    const ventecontentsData = {
        options: {
            xaxis: {
                categories: labels.length > 0 ? data.statisticsByDate[labels[0]].ventecontents.map((item) => item.titre) : [],
            },
            stroke: {
                curve: 'smooth',
            },
            chart: {
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                    },
                },
                zoom: {
                    enabled: false,
                },
                pan: {
                    enabled: false,
                },
            },
        },
        series: labels.map((date) => ({
            name: date,
            data: data.statisticsByDate[date]?.ventecontents.map((item) => item.quantite) || [],
        })),
    };

    const produitsTendancesData = {
        options: {
            xaxis: {
                categories: data.produitsTendances?.filter(product => product.totalVendu > 0).map((product) => product.titre) || [],
            },
        },
        series: [
            {
                name: 'Total Vendu',
                data: data.produitsTendances?.filter(product => product.totalVendu > 0).map((product) => product.totalVendu || 0) || [],
            },
        ],
    };

    return (
        <div>

            <div className="mb-4">
                <label htmlFor="numberOfDays" className="text-lg font-semibold text-orange-500 mb-2">Nombre de jours:</label>
                <select
                    id="numberOfDays"
                    name="numberOfDays"
                    value={numberOfDays}
                    onChange={handleNumberOfDaysChange}
                    className="border border-gray-300 rounded-md px-3 py-2"
                >
                    {selectOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 p-4">
                    <h2 className="text-3xl font-semibold text-orange-500 mb-4">Statistiques Numériques</h2>
                    <ApexCharts options={statisticsChartData.options} series={statisticsChartData.series} type="line" width={350} height={200} />
                </div>

                <div className="w-full md:w-1/2 p-4">
                    <h2 className="text-3xl font-semibold text-orange-500 mb-4">Produits Tendances</h2>
                    {data.produitsTendances ? (
                        <ApexCharts options={produitsTendancesData.options} series={produitsTendancesData.series} type="bar" width={350} height={200} />
                    ) : (
                        <p>Loading produits tendances...</p>
                    )}
                </div>

                <div className="w-full md:w-1/2 p-4">
                    <h2 className="text-3xl font-semibold text-orange-500 mb-4">Le contenu des ventes</h2>
                    <ApexCharts options={ventecontentsData.options} series={ventecontentsData.series} type="line" width={350} height={200} />
                </div>
            </div>
        </div>
    );
};

export default ApexChart;
