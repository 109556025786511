import React from "react";
import { Link } from "react-router-dom";
function HeadProductBonus() {
  return (
    <>
      {/* <!-- breadcrumb --> */}
      <div class=" py-2 border border-gray-300  mt-[0.3px] px-4  rounded-b-2xl justify-between flex items-center gap-3">
        <Link to='/User/Bonus' className="flex py-1 px-2 rounded bg-orange-500 text-white">
          <i class="bi bi-arrow-return-left mr-3"></i>
          <p class=" font-bold">Retour</p>
        </Link>
        <p class="text-gray-400 font-serif text-center max-sm:text-xs">Onex-S marche en ligne</p>
      </div>
      {/* <!-- ./breadcrumb --> */}
    </>
  );
}

export default HeadProductBonus;
