/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { FadeLoader } from 'react-spinners'

const Options = [
  { value: '', label: 'Catégorie du produit' },
  { value: 'Habits des Hommes', label: 'Habits des Hommes' },
  { value: 'Habits Enfants Féminins', label: 'Habits Enfants Féminins' },
  { value: 'Habits Enfants Masculins', label: 'Habits Enfants Masculins' },
  { value: 'Kit Nouveau Né Féminins', label: 'Kit Nouveau Né Féminins' },
  { value: 'Kit Nouveau Né Masculins', label: 'Kit Nouveau Né Masculins' },
  { value: 'Habits des bébés Féminins', label: 'Habits des bébés Féminins' },
  { value: 'Habits des bébés Masculins', label: 'Habits des bébés Masculins' },
  { value: 'Habits Sports', label: 'Habits Sports' },
  { value: 'Chaussures des Femmes', label: 'Chaussures des Femmes' },
  { value: 'Chaussures des Hommes', label: 'Chaussures des Hommes' },
  { value: 'Habits des Femmes', label: 'Habits des Femmes' },


  { value: 'Les céréales', label: 'Les céréales' },
  { value: 'Les légumineuses', label: 'Les légumineuses' },
  { value: 'Les farines', label: 'Les farines' },
  { value: 'Les tubercules', label: 'Les tubercules' },
  { value: 'Les légumes', label: 'Les légumes' },
  { value: 'Les fruits', label: 'Les fruits' },
  { value: 'Les autres produits alimentaires', label: 'Les autres produits alimentaires' },


  { value: 'Les sacs à dos', label: 'Les sacs à dos' },
  { value: 'Les sacs à main des femmes', label: 'Les sacs à main des femmes' },
  { value: 'Les valises', label: 'Les valises' },

  { value: 'Les appareils électroniques', label: 'Les appareils électroniques' },
  { value: 'Les appareils électroménagers', label: 'Les appareils électroménagers' },
  { value: 'Autres équipements de ménage', label: 'Autres équipements de ménage' },

  { value: 'Matériels de décors', label: 'Matériels de décors' },
  { value: 'Les salles à manger', label: 'Les salles à manger' },
  { value: 'Les salons', label: 'Les salons' },
  { value: 'Les lits', label: 'Les lits' },
  { value: 'Les salons des visiteurs', label: 'Les salons des visiteurs' },
  { value: 'Autres produits de menuiserie et soudure', label: 'Autres produits de menuiserie et soudure' },


  { value: 'Les motos et bicyclettes', label: 'Les motos et bicyclettes' },
  { value: 'Les automobiles', label: 'Les automobiles' },

  { value: 'Matériels de construction', label: 'Matériels de construction' },
  { value: 'Véhicules', label: 'Véhicules' },




]


const defaultValue = Options[0];
const optionsWithoutFirst = Options.slice(1);


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderBottom: '2px solid gray',
    borderRadius: '0px',
    backgroundColor: "transparent",
    top: '7px',
    with: '100%',
    boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
    borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
    '&:hover': {
      borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'blue' : 'transparent',
    backgroundColor: state.isSelected ? 'blue' : 'transparent',
    color: state.isSelected ? 'white' : '#666',
    '&:hover': {
      color: state.isSelected ? 'white' : '#666',
      backgroundColor: state.isSelected ? 'blue' : 'transparent',
    },
    position: 'static',
    zIndex: '10000',
  }),
}


function RegisterForm() {
  const [spinnerButton, SetSpinnerButton] = useState(false)

  const history = useNavigate();
  const useId = localStorage.getItem("userid");

  const [titre, Settitre] = useState('')
  const [animationClasstitre, setAnimationClasstitre] = useState('');
  const elemetReftitre = useRef(null)

  const [prix, Setprix] = useState(0)
  const [animationClassprix, setAnimationClassprix] = useState('');
  const elemetRefprix = useRef(null)

  const [quantit, Setquantit] = useState(0)
  const [animationClassquantit, setAnimationClassquantit] = useState('');
  const elemetRefquantit = useRef(null)


  const [isTextarea, setIsTextarea] = useState('');
  const [animationClassTextarea, setAnimationClassTextarea] = useState('');
  const textaresRef = useRef(null)

  const [autoScrollHeight, setAutoScrollHeight] = useState(50);
  const [valueTextarea, setValueTextarea] = useState('');

  const [SelectValue, SetSelectValue] = useState([defaultValue])
  const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');
  const elemetRefSelect = useRef(null)

  const [profil, SetProfil] = useState("")
  const [photo, SetPhoto] = useState(null)


  const handleSubmit = (e) => {
    e.preventDefault()
    if (titre.trim() === '') {
      toast.warning('Le titre de produit est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClasstitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setAnimationClasstitre(' ')
      }, 3000)
      elemetReftitre.current.focus()

      return false
    } else if (prix == 0) {
      toast.warning('Entre le prix du produit', {
        autoClose: 2000
      });
      setAnimationClassprix('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setAnimationClassprix(' ')
      }, 3000)
      elemetRefprix.current.focus()

      return false
    }
    else if (quantit == 0) {
      toast.warning("Entre la qunatité du produits!!", {
        autoClose: 2000
      });
      setAnimationClassquantit('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
      setTimeout(() => {
        setAnimationClassquantit(' ')
      }, 3000)
      elemetRefquantit.current.focus()

      return false
    }
    else if (SelectValue.value == undefined) {
      toast.warning('Sélectionnez la catégorie de produit', {
        autoClose: 2000
      });
      setanimationClassSelecteValue('animate__animated animate__shakeX border-b-2 border-red-500')
      setTimeout(() => {
        setanimationClassSelecteValue(' ')
      }, 3000)
      return;
    } else if (isTextarea.trim() === '') {
      toast.warning('La description de vos produits', {
        autoClose: 2000
      });
      setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
      setTimeout(() => {
        setAnimationClassTextarea('');
      }, 3000);
      textaresRef.current.focus()
      return;
    } else if (profil == '') {
      toast.warning('Le photos de produits est obligatoire !!', {
        autoClose: 2000
      })
    } else {
      SetSpinnerButton(true)
      const formData = new FormData();
      formData.append("Title", titre);
      formData.append("Price", prix);
      formData.append("Quantity", quantit);
      formData.append("Category", SelectValue.value);
      formData.append("Description", isTextarea);
      formData.append("SellerId", useId);
      formData.append("File", photo);

      axios
        .post('https://apiv1.burundientempsreel.com/market/seller/addproduct', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
          Settitre('')
          Setprix()
          Setquantit()
          SetSelectValue('')
          setIsTextarea('')
          SetProfil('')
          history('/User/Marche');
        }).catch((error) => {
          console.error(error.message);
          if (error.response) {
            toast.error(error.response.data.error);
          }
          console.error(error.response.data);

        }).finally(() => {
          SetSpinnerButton(false)
        });

    }
  }

  const [loadingImage, setLoadingImage] = useState(true);

  return (
    <>
      <div class="rounded-2xl flex flex-col items-center border h-max  border-orange-500 w-[500px] p-2 shadow-sm">
        <div
          class="flex items-center space-x-1 font-semibold  text-gray-900"
        >
          <h2 class="my-1 text-lg leading-8 tracking-wide text-orange-500">
            Ajouter un nouveau produit
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
            <div class="relative h-11 w-full mb-6  flexFormulInput ">
              <input ref={elemetReftitre} value={titre} onInput={(e) => Settitre(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasstitre}`} placeholder=" " />
              <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                Tritre du produits
              </label>
            </div>
          </div>

          <div className=" mt-[10px] transition-all flex w-full justify-between  flexFormul">
            <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
              <input value={prix} type="number" ref={elemetRefprix} onInput={(e) => Setprix(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassprix}`} placeholder=" " />
              <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                Prix du produit
              </label>
            </div>
            <div class="relative h-11 flexFormulInput w-1/2">
              <input value={quantit} type="number" ref={elemetRefquantit}
                onInput={(e) => Setquantit(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassquantit}`} placeholder=" "
              />
              <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                Le quantite du produit
              </label>
            </div>
          </div>
          <div class="relative h-11  w-full   mt-[20px] ">
            <div className={`flex flex-col  h-11 w-full relative responsive31_temoignSelect ${animationClassSelecteValue}`}>
              <Select ref={elemetRefSelect} isClearable={false} onChange={SetSelectValue} defaultValue={SelectValue} options={optionsWithoutFirst} styles={customStyles} >
              </Select>
            </div>
          </div>

          <div className="relative h-full w-full mt-[2em]">
            <textarea ref={textaresRef}
              value={isTextarea}
              onChange={(e) => {
                setIsTextarea(e.target.value);
                setAutoScrollHeight(e.target.scrollHeight);
                setValueTextarea(e.target.value.trim().length);
                if (!valueTextarea) {
                  setAutoScrollHeight(50);
                }
              }}
              placeholder=" "
              style={{ height: `${autoScrollHeight}px` }}
              className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
            />
            <label
              className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-orange-gray-500`}
            >
              Déscription du produit
            </label>
          </div>
          <div className="mt-10">
            <div className="text-gray-500">
              <label htmlFor="profiles" className='cursor-pointer bg-orange-500 px-1 py-2  ml-4 rounded text-white'>Photos de produits</label>
            </div>
            <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetPhoto(UrlFile); SetProfil(URL.createObjectURL(UrlFile)) }} />
            <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[50%] relative h-[50%] bg-gray-200 overflow-hidden mt-[20px]  border rounded-2xl border-orange-600`}>
              {loadingImage && (
                <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
              )}
              <img draggable='false'
                onLoad={() => {
                  setTimeout(() => {
                    setLoadingImage(false);
                  }, 1000);
                }}
                src={profil} alt="" className={`w-full h-full object-cover`} />
            </div>
          </div>


          <div className="flex justify-end items-center">





            {spinnerButton ? (
              <>
                <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                  <div className='absolute bg-transparent   pt-5 pl-5  w-full h-full flex justify-center items-center z-50'>
                    <FadeLoader
                      color="rgb(255, 255, 255)"
                      height={10}
                      margin={-9}
                      radius={100}
                      speedMultiplier={1}
                      width={1}
                    /></div>
                  <input type="submit" id="send" value="Ajouter" class=" transition-all "></input>
                  <i class="bi bi-send ml-2 "></i>
                </div>
              </>
            ) : (
              <>
                <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                  <input type="submit" id="send" value='Ajouter' className='cursor-pointer '></input>
                  <i class="bi bi-send ml-2 "></i>
                </label>

              </>
            )}


          </div>







        </form>


        <div
          class="container-mr mt-4 w-full flex items-center justify-end bg-gray-100 p-8 py-4 text-sm"
        >

        </div>

      </div>
    </>
  );
}

export default RegisterForm;
