import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

function ClientCommentDetail() {
    const [mobile, SetMobile] = useState(window.innerWidth < 901);
    const [data, setData] = useState(null);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 901);
        }
        window.addEventListener('resize', hundleSize);
        return () => {
            window.removeEventListener('resize', hundleSize);
        }
    }, []);
    const { id } = useParams()
    useEffect(() => {
        // Effectuez ici votre requête API
        fetch(`https://apiv1.burundientempsreel.com/market/livraisonwithcomenbyID/${id}`)
            .then((response) => response.json())
            .then((responseData) => {
                setData(responseData);
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des données:", error);
            });
    }, [id]);
    function formatDate(dateTimeString) {
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const formattedDate = new Date(dateTimeString).toLocaleDateString('fr-FR', options);
        return formattedDate;
    }

    return (
        <div className="min-h-[90vh] pt-2  select-none">
            <Link className="px-2 py-1 mt-2 ml-2 text-white bg-orange-700 rounded" to='/Admin/commentaire'>Retour</Link>
            {data && (
                <div className="bg-white mt-3  flex justify-center text-gray-700    mb-6">
                    <div className={`w-[95%] flex p-4 flex-col mt-4  items-center rounded-2xl border border-gray-300  shadow-lg `}>
                        <div className="relative flex w-full flex-col  items-start space-y-3 ">
                            <div className={`flex justify-start  w-full pl-10`}>
                                <div className="bg-white p-3  mt-4  rounded-2xl">
                                    <div className="flex items-center justify-center">
                                        <div className="flex-shrink-0 rounded-xl border border-orange-700 max-h-[30em] max-w-[30em] overflow-hidden">
                                            <img
                                                draggable='false'
                                                src={`https://apiv1.burundientempsreel.com/uploads/market/${data.commandemarketcontents[0].produit.profil}`}
                                                className="w-full h-full rounded object-contain object-center"
                                                alt=''
                                            />
                                        </div>
                                    </div>

                                    <div className="text-gray-700 mt-10">
                                        <div className="grid text-sm">
                                            <div className="grid grid-cols-2">
                                                <div className="px-4 py-2 font-bold">
                                                    N.Commande
                                                </div>
                                                <div className="px-4 py-2 font-semibold">{data.id}</div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="px-4 py-2 font-bold">
                                                    Nom complet du client:
                                                </div>
                                                <div className="px-4 py-2 font-semibold">{data.user.prenom} {data.user.nom}</div>
                                            </div>
                                            <div className="grid grid-cols-2 font-bold">
                                                <div className="px-4 py-2 ">
                                                    Nom complet du livreur
                                                </div>
                                                <div className="px-4 py-2  font-semibold">  {data.livrason.livreur.user.prenom} {data.livrason.livreur.user.nom}</div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="px-4 py-2 font-bold">
                                                    Date et Heure de Livraison
                                                </div>
                                                <div className="px-4 py-2  font-semibold">
                                                    {formatDate(data.livrason.updatedAt)}
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-2">
                                                <div className="px-4 py-2 font-bold ">
                                                    Titre du produits:
                                                </div>
                                                <div className="px-4 py-2  font-semibold">{data.commandemarketcontents[0].produit.titre}</div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="px-4 py-2 font-bold">
                                                    Categorie:
                                                </div>
                                                <div className="px-4 py-2  font-semibold">
                                                    {data.commandemarketcontents[0].produit.categorie}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="px-4 py-2 font-bold">
                                                    Quantite:
                                                </div>
                                                <div className="px-4 py-2 font-semibold">
                                                    {data.commandemarketcontents[0].quantite}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="px-4 py-2 font-bold">
                                Avis du client:
                            </div>
                            <div className="px-4 py-1 font-sans text-[18px]">
                                {data.livrason.Avis}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ClientCommentDetail;
