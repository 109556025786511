import React from "react";
import RegisterFormOnly from "./RegisterFormOnly";
function RegisterInvComponentOnly() {
  return (
    <>
      <div class="flex flex-row justify-center container w-full pt-6 pb-8 px-2">
        <RegisterFormOnly />
      </div>
    </>
  );
}

export default RegisterInvComponentOnly;
