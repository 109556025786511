import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';

function AdminMarketProfil() {

    const [UserData, setUserData] = useState({});
    const [loading, setloading] = useState(true);
    const id = localStorage.getItem('userid');

    const [nom, Setnom] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');
    const elemetRefNom = useRef(null)

    const [prenom, Setprenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');
    const elemetRefPrenom = useRef(null)

    const [tel, SetTel] = useState('')
    const [animationClassTel, setAnimationClassTel] = useState('');
    const elemetRefTel = useRef(null)

    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [emailValue, setEmailValue] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetRefEmail = useRef(null)

    const [codeAddress, SetcodeAddress] = useState('')
    const [animationClasscodeAddress, setanimationClasscodeAddress] = useState('');
    const elemetRefcodeAddress = useRef(null)


    const [animationClassConfPass, setAnimationClassConfPass] = useState("");
    const [ConfpasswordVisible, setConfPasswordVisible] = useState(false);
    const elemetRefConfPass = useRef(null);

    const [animationClassPassword, setAnimationClassPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const elemetRefPass = useRef(null);




    const [profil, SetProfil] = useState(null)
    const [photo, SetPhoto] = useState(null)
    const [modifierBtn, SetmodifierBtn] = useState(false)
    const [EditorPass, SetEditorPass] = useState(false)

    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    useEffect(() => {
        // Fonction pour récupérer les données du vendeur en utilisant Axios
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`);
                setUserData(response.data);
                setEmailValue(response.data.email);
                SetTel(response.data.tel)
                Setprenom(response.data.prenom);
                Setnom(response.data.nom);
                SetcodeAddress(response.data.address)
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                }

            }
        };

        fetchUserData();
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (nom.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            elemetRefNom.current.focus()

            return;
        } else if (prenom.trim() === '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            elemetRefPrenom.current.focus()

            return;
        }
        else if (tel.trim() == "") {
            toast.warning("Le numero de téléphone  est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassTel('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassTel(' ')
            }, 3000)
            elemetRefTel.current.focus()

            return
        }
        else if (emailValue.trim() == '') {
            toast.warning("L'address email est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();

            return
        } else if (!emailValue.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();

            return
        }
        else if (codeAddress.trim() == '') {
            toast.warning('Le code Address est obligatoire', {
                autoClose: 2000
            });
            setanimationClasscodeAddress('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setanimationClasscodeAddress(' ')
            }, 3000)
            elemetRefcodeAddress.current.focus();
            return;
        }
        const formData = new FormData();
        formData.append('nom', nom)
        formData.append('email', emailValue)
        formData.append('prenom', prenom)
        formData.append('tel', tel)
        formData.append('address', codeAddress)
        if (photo !== null) {
            formData.append('photo', photo);
        }

        axios.put(`https://apiv1.burundientempsreel.com/user/modifyaccount/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            toast.success("Les identifiants ont été modifiés avec succès !");
            const executeAfter5Seconds = () => {
                window.location.reload();
            };
            setTimeout(executeAfter5Seconds, 5000);
        }).catch((error) => {
            console.log(error.message);
            if (error.response) {
                console.error(error.response.data);
            }
        });

    }


    const [password, setPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [confumePassword, setConfirmPassword] = useState("");
    function validatePassword(password) {
        const PasswordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;

        return PasswordRegex.test(password);
    }
    const handleChangePassword = async () => {
        if (!validatePassword(password) || !validatePassword(newpassword)) {
            toast.warning(
                "Les mots de passe sont invalides. Assurez-vous qu'ils contiennent  une majuscule, une minuscule, un caractère spécial et un chiffre",
                {
                    duration: 7000, // Durée de 7 secondes en millisecondes
                }
            );
            return;
        }
        if (newpassword !== confumePassword) {
            toast.warning(
                "Le nouveau mot de passe et la confirmation ne correspondent pas."
            );
            return;
        }
        try {
            const response = await axios.put(
                `https://apiv1.burundientempsreel.com/user/changepass`,
                {
                    password: password,
                    newPassword: newpassword,
                    id: id,
                }
            );

            if (response.data.success) {
                toast.success("Le mot de passe a été modifié avec succès !");
                setPassword("");
                setNewPassword("");
                setConfirmPassword("");
                const executeAfter5Seconds = () => {
                    window.location.reload();
                };
                setTimeout(executeAfter5Seconds, 5000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error.message);
            console.error(error.response.data);
            if (error.response) {
                toast.error(error.response.data);
            }

        }
    };



    return (
        <>
            {loading && <SpinnerDemarage />}
            <div className='flex justify-center items-center p-2'>
                <div className="bg-gray-100 border border-orange-600 shadow-2xl p-2 rounded widtg">
                    {UserData && (
                        <>
                            <div className={`${modifierBtn ? 'hidden' : 'flex'}   flex-col items-center m-2`}>
                                <div className="w-[9em] h-[9em]  m-2 rounded-full relative overflow-hidden">
                                    <img draggable='false' src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${UserData.photo}`} alt="" className='w-full h-full object-cover object-center ' />
                                </div>
                                <div className="text-gray-500 containera">
                                    <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Nom<span>:</span></div><div>{UserData.nom}</div></div>
                                    <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Prenom<span>:</span></div><div>{UserData.prenom}</div></div>
                                    <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Telephone <span>:</span></div><div>{UserData.tel}</div></div>
                                    <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Email valide  <span>:</span></div><div>{UserData.email}</div></div>
                                    <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Addresse  <span>:</span></div><div>{UserData.address}</div></div>
                                    <div className="flex leading-[2em] flexDetail"> <div className="font-bold mr-1 flex  w-[13em] justify-between">Mot de passe  <span>:</span></div><div className="flex">******* <div title='Modifier le password ' onClick={() => { SetEditorPass(!EditorPass) }} className="bi cursor-pointer ml-4 bi-pencil-square"></div></div></div>
                                </div>
                                <div className="w-full  flex justify-end"><div onClick={() => { SetmodifierBtn(true) }} className={` ${!modifierBtn ? 'block' : 'hidden'}  px-2 py-1 bg-orange-400 text-white rounded cursor-pointer hover:bg-orange-600 transition-all`}>Modifier</div></div>
                            </div>

                        </>
                    )}

                    {/* autre champs */}
                    <div className={`${modifierBtn ? '' : 'hidden'}    `}>
                        <h2 className=' p-2 text-center mb-[1em] text-[30px] font-serif mot1'><p className='text-orange-600 p-4'>Modifier votre information</p></h2>
                        <div className=" m-3 p-4 rounded w-[35em] addClient h-max inscriptvisitions">
                            <form onSubmit={handleSubmit}>

                                <div className="flex mb-6 flex-col">

                                    <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                                        <div class="relative h-11 w-1/2 flexFormulInput mr-2 ">
                                            <input ref={elemetRefNom} value={nom} onInput={(e) => Setnom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Nom
                                            </label>
                                        </div>

                                        <div class="relative h-11 flexFormulInput w-1/2  mr-2 ">
                                            <input value={prenom} ref={elemetRefPrenom}
                                                onInput={(e) => Setprenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Prenom
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" mt-[10px] transition-all flex w-[100%] justify-between  flexFormul">
                                        <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
                                            <input value={tel} ref={elemetRefTel} onInput={(e) => SetTel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTel}`} placeholder=" " />
                                            <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Téléphone
                                            </label>
                                        </div>
                                        <div class="relative h-11 flexFormulInput w-1/2">
                                            <input value={emailValue} ref={elemetRefEmail}
                                                onInput={(e) => setEmailValue(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Email valide
                                            </label>
                                        </div>
                                    </div>
                                    <div class="relative h-11 mt-[10px]  w-full contactInput  mr-2 ">
                                        <div class="relative h-11 flexFormulInput w-full">
                                            <input value={codeAddress} ref={elemetRefcodeAddress}
                                                onInput={(e) => SetcodeAddress(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscodeAddress}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Addresse
                                            </label>
                                        </div>
                                    </div>


                                </div>
                                <div className="ro">
                                    <div className="la">
                                        <span>Photos de profil</span>
                                        <label for="profiles" className='bi bi-image-fill cursor-pointer bg-orange-500 px-1 ml-4 rounded text-white'></label>
                                    </div>
                                    <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetPhoto(UrlFile); SetProfil(URL.createObjectURL(UrlFile)) }} />
                                    <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[70%] h-[70%] bg-gray-300 overflow-hidden mt-[20px] rounded border-4 border-double border-orange-600`}>
                                        <img draggable='false' src={profil ? profil : `https://apiv1.burundientempsreel.com/uploads/photosuser/${UserData.photo}`} alt="" className={`w-full h-full object-cover`} />
                                    </div>
                                </div>
                                <div className="flex justify-end items-center mt-4">
                                    <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Modifier' className='cursor-pointer '></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className={`${EditorPass ? 'block' : 'hidden'}  ${modifierBtn ? 'hidden' : ''}`}>
                        <div className=" transition-all mt-10 flex w-[100%] flex-col">
                            <h2 className="font-serif text-gray-400 text-[10px] cursor-default mb-5">
                                Modifier le password
                            </h2>
                            <div className={`relative  w-[100%] p-1 `}>
                                <div className={`relative mt-3 `}>
                                    <div class="relative h-11 w-full bloksInput">
                                        <input
                                            ref={elemetRefPass}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            type={passwordVisible ? "text" : "password"}
                                            className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                                            placeholder=" "
                                        />
                                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                            Ancien mot de passe
                                        </label>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setPasswordVisible(!passwordVisible);
                                        }}
                                        className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                                    >
                                        <i
                                            class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                                                } absolute`}
                                        ></i>
                                    </div>
                                </div>

                                <div className={`relative mt-3 `}>
                                    <div class="relative h-11 w-full bloksInput">
                                        <input
                                            ref={elemetRefPass}
                                            onChange={(e) => {
                                                setNewPassword(e.target.value);
                                            }}
                                            type={passwordVisible ? "text" : "password"}
                                            className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`}
                                            placeholder=" "
                                        />
                                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                            Nouveau mot de passe
                                        </label>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setPasswordVisible(!passwordVisible);
                                        }}
                                        className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 "
                                    >
                                        <i
                                            class={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"
                                                } absolute`}
                                        ></i>
                                    </div>
                                </div>

                                <div className={`relative mt-3 `}>
                                    <div class="relative h-11 w-full bloksInput ">
                                        <input
                                            ref={elemetRefConfPass}
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value);
                                            }}
                                            type={ConfpasswordVisible ? "text" : "password"}
                                            className={`peer  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassConfPass}`}
                                            placeholder=" "
                                        />
                                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                            Confirme le mot de passe
                                        </label>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setConfPasswordVisible(!ConfpasswordVisible);
                                        }}
                                        className=" cursor-pointer w-[20px] h-[20px] top-[20px] absolute flex justify-center items-center right-2 "
                                    >
                                        <i
                                            class={`bi ${ConfpasswordVisible
                                                ? "bi-eye-fill"
                                                : "bi-eye-slash-fill"
                                                } absolute`}
                                        ></i>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center mt-3">
                                    <button
                                        onClick={() => {
                                            handleChangePassword();
                                        }}
                                        className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white"
                                    >
                                        Modifier <i class="bi bi-send ml-2 "></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AdminMarketProfil










