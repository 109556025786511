import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
function CommandEtenteDeatil() {
    const [price, setPrice] = useState(0);
    const history = useNavigate();
    const { id } = useParams();
    const [Payment, setPayment] = useState(false);
    const [Shipping, setShipping] = useState(false);
    const [Received, setReceived] = useState(false);
    const [Commande, setCommande] = useState(true);
    const [data, setData] = useState({});
    const [status, setstatus] = useState('');

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/market/Orders/${id}`).then((response) => {
            setData(response.data);
            setstatus(response.data.status);
        }).catch((error) => {
            console.log(error.message);
        });

        // Déplacez la condition à l'intérieur de useEffect pour éviter la boucle infinie
        if (status === "pending") {
            setCommande(true);
        } else if (status === "payed") {
            setPayment(true)
        } else if (status == "shipping") {
            setShipping(true)
        } else if (status == "completed") {
            setReceived(true)
        }
    }, [id, status]);

    const handleValidation = () => {

        try {
            axios.put(`https://apiv1.burundientempsreel.com/market/livreur/livrecommande/${id}`);
            history('/');
            toast.success("votre confirmation de livreson  a réussi");
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la confirmation de livreson");
        }
    };




    return (
        <div className="min-h-[90vh] pt-2">

            <Link className=" px-2 py-1 mt-2 ml-2 text-white bg-orange-700 rounded" to='/'>Retour</Link>
            <div class=" bg-white mt-3  text-gray-700 border-b shadow mb-6">

                <div className="py-8 px-8 w-full">
                    <h2 className="font-bold text-3xl text-orange-500">

                        Détails d'expédition Onex-S
                    </h2>
                    <p className="text-sm mt-4 text-orange-500">

                        Vos informations d'expédition
                    </p>

                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
                    >
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Pays</div><div class="px-4 py-2">{data.pays}</div></div>
                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Province</div><div class="px-4 py-2">{data.province}</div></div>
                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Commune</div><div class="px-4 py-2">{data.commune}</div></div>
                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Zone</div><div class="px-4 py-2">{data.zone}</div></div>
                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Quartier</div><div class="px-4 py-2">{data.quartier}</div></div>
                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Avenue</div><div class="px-4 py-2">{data.avenue}</div></div>

                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">HouseNumber</div><div class="px-4 py-2">{data.housernamber}</div></div>


                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">MobileNumber</div><div class="px-4 py-2">{data.mobile}</div></div>


                        </div>
                        <div className="flex flex-row">
                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Description</div><div class="px-4 py-2">{data.description}</div></div>


                        </div>
                    </div>


                    <hr class="h-px my-8 bg-gray-50 border-0 dark:bg-gray-700" />
                    <div className="flex text-orange-500 justify-between">
                        <p className="text-lg font-bold">Montant total </p>
                        <div className="">
                            <p className="mb-1 text-xl font-bold"> {data.prixtotal} Fbu</p>
                        </div>
                    </div>

                    <h2 className=" pt-8  font-bold text-2xl text-orange-500">
                        Votre image de preuve
                    </h2>


                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
                    >
                        <div className="w-32 h-32 rounded overflow-hidden mb-4">
                            <img draggable='false'
                                src={`https://apiv1.burundientempsreel.com/uploads/market/${data.proofimage}`}
                                alt=" "
                                className="w-full h-full object-cover rounded-2xl border"
                            />
                        </div>
                    </div>
                    {data.commandemarketcontents ? (
                        <div className="h-full ">
                            <h1 className="mb-10 text-left text-2xl hidden font-bold">
                                Tous les articles du panier
                            </h1>
                            <div className="">
                                <h2 className="font-bold text-2xl text-orange-500">

                                    Produits commandés
                                </h2>

                            </div>
                            <hr class="h-px my-8 bg-gray-300 border-0 dark:bg-gray-700" />
                            <div className="mx-auto max-w-2xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                                <div className="rounded-lg w-full">
                                    {data.commandemarketcontents.map((e) => {
                                        return (
                                            <>
                                                <div className="justify-between mb-6 rounded-lg border border-gray-200 bg-gray-100 p-6 shadow-md sm:flex sm:justify-start">
                                                    <img draggable='false'
                                                        src={`https://apiv1.burundientempsreel.com/uploads/market/${e.produit.profil}`}
                                                        alt="product-image"
                                                        className="w-full rounded-lg sm:w-40"
                                                    />
                                                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                                        <div className="mt-5 sm:mt-0 md:w-48">

                                                            <div className="flex flex-row justify-between "><h2 className="text-lg font-bold text-gray-900">
                                                                {e.produit.titre.length >= 40
                                                                    ? e.produit.titre.substring(0, 40) + "...."
                                                                    : e.produit.titre}
                                                            </h2>
                                                            </div>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Quantite: {e.quantite}
                                                            </p>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Categories:   {e.produit.categorie}
                                                            </p>
                                                            <p className="mt-1 text-base text-gray-700">
                                                                Déscription:   {e.produit.description.length >= 70
                                                                    ? e.produit.description.substring(0, 70) + "...."
                                                                    : e.produit.description}
                                                            </p>

                                                        </div>
                                                        <div className="mt-4  flex  md:flex-row flex-row-reverse justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">

                                                            <div className="flex items-center space-x-4">
                                                                <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                                                                    <p class="text-xl text-primary font-semibold">
                                                                        {Math.floor(e.produit.prix).toLocaleString("fr-FR")} Fbu
                                                                    </p>
                                                                    <p class="text-base text-gray-400 line-through">
                                                                        {Math.floor(e.produit.prix + (e.produit.prix * 0.2)).toLocaleString("fr-FR")} Fbu
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>


                            </div>
                        </div>
                    ) : null}
                </div>
                <button
                    onClick={handleValidation}
                    className="w-full flex justify-end mt-4"
                >
                    <div className="py-1 px-2 bg-orange-700 rounded cursor-pointer text-white">
                        Valider la livraison
                    </div>
                </button>
















            </div>
        </div>

    );
}

export default CommandEtenteDeatil;
