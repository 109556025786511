/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NaVbarsOngletOnly from "./NaVbarsOngletOnly";
import { openChat } from "../../../TawkTo";
import { useDispatch, useSelector } from "react-redux";
import { DELETE } from "../../../../Redux/Action/Action";
import { BarLoader } from 'react-spinners'

function NavBarMarketUsersOnly({ isdivScroll, SizeMobile, getMobille1, getSrollowerSize }) {

    const [imageLoading2, SetImageLoading2] = useState(true)
    const { pathname } = useLocation()
    const [menuClicked1, setMenuClicked1] = useState(false)
    const [BouttonLogin, setBouttonLogin] = useState(false)
    const [isMobile, SetMobile] = useState(window.innerWidth < 570.97364448849393)
    const [isMobileTros, SetMobileTros] = useState(window.innerWidth < 292.97364448849393)
    const [tropTail, SettropTail] = useState(window.innerWidth < 414.97364448849393)

    const [activeIndex, setActiveIndex] = useState(null);
    const [showManue, setshowManue] = useState(false)

    const dropdownItems = [
        { text: 'Compte', link: '/User/Profile' },
        { text: 'Se déconnecter', link: '#' }
    ];











    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)





    const history = useNavigate();

    const [UserDrop, SetUserDrop] = useState(false);

    function Deconnection(links) {
        if (links === '#') {
            localStorage.removeItem("t");
            localStorage.removeItem("userid");
            localStorage.removeItem("userRole");
            history("/");
            window.location.reload();
        }
    }

    const id = localStorage.getItem("userid");
    const [user, SetUser] = useState({});
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`).then((response) => {
            SetUser(response.data);
            const timer = setTimeout(() => {
                setLoading(false);
            }, 100);
            return () => clearTimeout(timer);

        }).catch((err) => {
            console.log(err.message);
        });
    }, []);



    const hundleTawkto = () => {
        openChat()
    }

    const url = window.location.href;
    const urlObject = new URL(url);
    const chemin = urlObject.pathname;
    const panierVentes = /^\/User\/Ventes.*/;
    const panierBonus = /^\/User\/Bonus.*/;




    const [price, setPrice] = useState(0);
    const getData = useSelector((state) => state.CartsReducer.Carts);
    const [isClicked, setIsClicked] = useState(false);
    const [dropmenu, setdropmenu] = useState(false);

    getMobille1(isMobile)

    useEffect(() => {
        const hiddenOnget = () => {
            setshowManue(false)
            setBouttonLogin(false)
            SetUserDrop(false)
            setMenuClicked1(false)
        };


        function hundleScrolls(e) {

            setBouttonLogin(false)
            SetUserDrop(false)
            setMenuClicked1(false)
            getSrollowerSize(e.target.scrollTop)


        }

        const hiddenresize = () => {
            SetMobile(window.innerWidth < 555.90292837373)
            SetMobile(window.innerWidth < 573.97364448849393)
            SettropTail(window.innerWidth < 414.97364448849393)
            SetMobileTros(window.innerWidth < 292.97364448849393)

            SetMobile(window.innerWidth < 570.97364448849393)
            SettropTail(window.innerWidth < 414.97364448849393)
            SetMobileTros(window.innerWidth < 292.97364448849393)

            SetMobile(window.innerWidth < 570.97364448849393)
            SettropTail(window.innerWidth < 414.97364448849393)
            SetMobileTros(window.innerWidth < 328.97364448849393)
            SetMobile(window.innerWidth < 555.90292837373)
        }




        window.addEventListener('click', hiddenOnget);
        window.addEventListener('resize', hiddenresize);

        if (isdivScroll.current) {
            isdivScroll.current.addEventListener('scroll', hundleScrolls);

        }

        return () => {
            window.removeEventListener('click', hiddenOnget);
            window.removeEventListener('resize', hiddenresize);

            if (isdivScroll.current) {
                isdivScroll.current.removeEventListener('scroll', hundleScrolls);
            }
        };
    }, []);

























    function close() {
        setIsClicked(!isClicked);
    }

    const dispatch = useDispatch();

    const dlt = (id) => {
        dispatch(DELETE(id));
    };

    const total = () => {
        let prices = 0;
        getData.map((ele) => {
            prices = Math.trunc(ele.price) * 1000 * ele.quantity + prices;
        });
        setPrice(Math.floor(prices).toLocaleString("fr-FR"));
    };
    useEffect(() => {
        total();
    }, [total]);






    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/user/Byid/${id}`).then((response) => {
            SetUser(response.data);
            const timer = setTimeout(() => {
                setLoading(false);
            }, 100);
            return () => clearTimeout(timer);
        });
    }, []);
    const [hideName, SethideName] = useState(window.innerWidth < 383.97364448849393)

    useEffect(() => {


        const hiddenresize = () => {
            SethideName(window.innerWidth < 383.97364448849393)

        }
        window.addEventListener('resize', hiddenresize);
        return () => {

            window.removeEventListener('resize', hiddenresize);
        }

    }, [])
    return (
        <div className={` ${isMobile ? 'h-[10vh] bg-gray-100 border-b-2 border-white' : 'h-[20vh] bg-gray-100   border-b-2 border-orange-500'} `}>

            <div className="flex  h-full w-full flex-col ">
                <div className={`${isMobile ? 'h-full' : 'h-1/2 '}  flex border-b-2 items-center`} >
                    <div className="flex items-center sm:w-[100%]  w-[70em]  ">
                        <div className="flex items-center w-max ">
                            <Link to="/UserOnly/Marche" className="h-[4.5em]  rotate-45 w-[4.5em] transition-all  relative overflow-hidden ml-3 rounded ">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }} src={`https://apiv1.burundientempsreel.com/uploads/logo/OnexS.png`} alt="" className='h-full w-full -rotate-45 object-cover object-center' />
                            </Link>
                            <div className={` ${hideName ? 'hidden' : 'block'}  font-serif text-[30px] ml-1  transition-all`}><span className='text-orange-800'>Onex-</span><span className='text-orange-600'>S</span></div>
                        </div>

                    </div>
                    <div className=" w-[100%] mr-2  h-full flex justify-end ">
                        <nav class=" rounded-t-xl md:rounded-none w-max   flex  justify-between items-center  pb-1   scale-y-1">
                            <i onClick={hundleTawkto} class="bi  bi-chat-dots-fill text-[1.5em] mr-3 cursor-pointer font-bold "></i>


                            <button onClick={(e) => { SetUserDrop(!UserDrop); e.stopPropagation() }}
                                class="  md:w-auto mt-2   md:ml-4 md:flex items-center justify-center text-right"
                            >
                                <div>
                                    <Link class=" overflow-hidden flex justify-centerm items-center relative"                                        >
                                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[5]" /></div>}

                                        <img draggable='false' onLoad={() => {
                                            setTimeout(() => {
                                                SetImageLoading1(false);
                                            }, 1000);
                                        }}

                                            className="inline-block h-8 w-8 md:h-10 md:w-10 border object-cover border-orange-500 rounded-full"
                                            src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                                            alt=""

                                        />
                                    </Link>
                                </div>
                                <div class="hidden md:flex  md:items-center  ml-4">
                                    <div className="hidden mr-3 md:flex md:items-center ml-4">
                                        {user.nom && user.prenom ? (
                                            <>
                                                <span className="text-gray-700 text-sm mr-2">
                                                    {user.nom} {user.prenom}
                                                </span>
                                                <div>
                                                    <i className="bi bi-caret-down-fill"></i>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <BarLoader color="blue" width={50} height={2} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </button>
                        </nav>
                        {UserDrop ? (
                            <div
                                className="md:block z-[1000] absolute overflow-hidden p-2 right-5 top-16 mt-2 w-48 rounded bg-gray-100 border border-gray-300 shadow-xl"
                            >
                                {dropdownItems.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item.link}
                                        className={`block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-orange-500 hover:text-white w-full text-left ${index === activeIndex ? 'bg-orange-600 text-white' : ''}`}
                                        onClick={() => {
                                            setActiveIndex(index); Deconnection(item.link)
                                        }}
                                    >
                                        {item.text}
                                    </Link>
                                ))}
                            </div>

                        ) : null}
                    </div>
                    <div className={` ${!isMobile ? 'hidden' : 'flex'} bg-gray-200 w-[40px] hover:bg-orange-600 hover:text-white transition-all text-orange-600 p-2 h-[35px]  justify-center items-center cursor-pointer rounded mr-2`}>
                        <div onClick={(e) => { setshowManue(!showManue); e.stopPropagation() }}><i class={` ${showManue ? 'bi bi-x transition-all duration-700' : 'bi bi-list transition-all duration-700'}  text-[22px] font-bold`}></i></div>

                    </div>
                </div>
                <NaVbarsOngletOnly />
            </div>
        </div>
    );
}

export default NavBarMarketUsersOnly;







