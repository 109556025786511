import React from 'react'
import DetailProducteUserMarket from './DetailProducteUserMarket'
function DetailProductorComponent() {
    return (
        <div className=' min-h-[75vh] w-full flex justify-center p-4 '>
            <DetailProducteUserMarket />
        </div>
    )
}

export default DetailProductorComponent