/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import Empty from '../../../User/UserMarket/Empty/Empty';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
import PropTypes from 'prop-types'
function StandByAdminMArket() {
    const [data, setData] = useState([]);
    const standVide = 'standbuy'
    // État pour stocker le numéro de page actuel
    const [currentPage, setCurrentPage] = useState(1);
    // État pour stocker le nombre total de pages
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setloading] = useState(true);

    const { pathname } = useLocation()

    useEffect(() => {
        // Récupérer l'ID de l'utilisateur depuis le stockage local
        const iduser = localStorage.getItem("userid");

        // Effectuer une requête GET pour récupérer les produits du vendeur avec pagination
        axios
            .get(`https://apiv1.burundientempsreel.com/market/admin/standbuyproduct?page=${currentPage}`)
            .then((response) => {
                // Extraire les données de la réponse
                const { products, totalPages } = response.data;
                setTimeout(() => {
                    setloading(false)
                }, 1000);
                // Mettre à jour les produits et le nombre total de pages dans l'état
                setData(products);
                setTotalPages(totalPages);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [currentPage]);

    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Fonction pour passer à la page précédente
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={currentPage === i ? "text-sm font-medium leading-none cursor-pointer bg-orange-600 text-white w-[30px] h-[30px] pb-[20px] rounded-full dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2" : "text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-full dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-orange-400 pt-3 mr-4 px-2"}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };


    const [isMobile, setIsMobile] = useState(window.innerWidth < 726);
    const [isMobile1, setIsMobile1] = useState(window.innerWidth < 434);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 726);
            setIsMobile1(window.innerWidth < 434);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);



    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };


    return (
        <>
            {loading && <SpinnerDemarage />}
            {data.length > 0 ? (
                <div className="min-h-[90vh]">
                    <p className='w-full  py-2 text-center sm:text-xl text-[15px] font-serif text-orange-600 md:text-3xl '>Les comptes qui vous devrez activer</p>
                    <div className=" flex gap-1 m-3 flex-auto flex-wrap">
                        {data.map((item, index) => {
                            return (
                                <div key={index} className=" border-orange-500 rounded-2xl w-max flex flex-col items-center h-max border m-2 shadow overflow-hidden group">
                                    <div className="relative h-full overflow-hidden">
                                        <div className={`  ${isMobile ? 'w-[10em] h-[10em]' : 'w-[15em] h-[15em]'} ${isMobile1 ? 'w-full h-full' : isMobile}`}>
                                            {loadingImages[index] ? (

                                                <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                            ) :
                                                (
                                                    <LoadingImage
                                                        draggable='false'
                                                        src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil}`}

                                                        className=" w-full h-full rounded object-cover object-center"
                                                        onLoad={() => handleImageLoad(index)}
                                                    />
                                                )}

                                        </div>
                                        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-500 transition">
                                            <a
                                                href="##"
                                                className="text-white text-lg w-9 h-8 rounded-full bg-orange-500 flex items-center justify-center hover:bg-gray-800 transition"
                                                title="view product"
                                            >
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                            </a>
                                            <a
                                                href="##"
                                                className="text-white text-lg w-9 h-8 rounded-full bg-orange-500 flex items-center justify-center hover:bg-gray-800 transition"
                                                title="add to wishlist"
                                            >
                                                <i className="fa-solid fa-heart"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="pt-4 pb-3 px-4">

                                        {pathname == "/" ? (
                                            <Link to={`/Admin/StandBuy/Detail/${item.id}`}>
                                                <h4 className="font-medium text-base mb-2 text-gray-800 hover:text-red-500 transition">
                                                    {item.titre.length >= 40
                                                        ? item.titre.substring(0, 40) + "...."
                                                        : item.titre}
                                                    {item.titre.length < 20 ? <div className="py-3"></div> : null}
                                                </h4>
                                            </Link>
                                        ) : (<Link to={`/Admin/StandBuy/Detail/${item.id}`}>
                                            <h4 className="font-medium text-base mb-2 text-gray-800 hover:text-red-500 transition">
                                                {item.titre.length >= 40
                                                    ? item.titre.substring(0, 40) + "...."
                                                    : item.titre}
                                                {item.titre.length < 20 ? <div className="py-3"></div> : null}
                                            </h4>
                                        </Link>)}
                                        <div className="flex items-baseline mb-1 space-x-2">
                                            <p className="text-base text-orange-500 font-semibold">
                                                {item.prix} Fbu
                                            </p>
                                            <p className="text-sm text-gray-400 line-through">
                                                {item.prix + (item.prix * 20 / 100)} Fbu
                                            </p>
                                        </div>
                                        <div className="flex items-baseline mb-1 space-x-2">
                                            <p className="text-xs text-gray-400 font-thin">
                                                Available In Stock :{item.quanite} items
                                            </p>
                                        </div>

                                        <div className="flex items-center">
                                            <div className="flex gap-1 text-sm text-orange-400">
                                                <span>
                                                    <i className="fa-solid fa-star"></i>
                                                </span>
                                                <span>
                                                    <i className="fa-solid fa-star"></i>
                                                </span>
                                                <span>
                                                    <i className="fa-solid fa-star"></i>
                                                </span>
                                                <span>
                                                    <i className="fa-solid fa-star" />
                                                </span>
                                                <span>
                                                    <i className="fa-solid fa-star" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            );
                        })}
                    </div>

                    <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                        <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                            <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-orange-700 cursor-pointer">
                                <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.1665 4H12.8332"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M1.1665 4L4.49984 7.33333"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M1.1665 4.00002L4.49984 0.666687"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <button onClick={previousPage} disabled={currentPage === 1} className="text-sm ml-3 text-black  hover:text-orange-700 cursor-pointer">Previous</button>
                            </div>
                            <div className="sm:flex mt-3">
                                {generatePageNumbers()}

                            </div>
                            <div className="flex items-center pt-3 ml-4 text-black  hover:text-orange-700 cursor-pointer">
                                <button onClick={nextPage} disabled={currentPage === totalPages} className="text-sm font-medium leading-none mr-3">Next</button>
                                <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.1665 4H12.8332"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9.5 7.33333L12.8333 4"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M9.5 0.666687L12.8333 4.00002"
                                        stroke="currentColor"
                                        stroke-width="1.25"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div >
            ) : (
                <div className='flex border justify-center items-center h-[75vh]'>
                    <Empty items={standVide} />
                </div>
            )
            }


        </>
    )
}

export default StandByAdminMArket
