import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
function DetailPoductor() {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [photo, setPhoto] = useState([]);
    const history = useNavigate();

    const [loading, SetLoading] = useState(true)

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/market/admin/getonproduct/${id}`).then((response) => {

            setProduct(response.data);
            setPhoto(response.data.produitfiles);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }, []).catch((error) => {
            console.error(error.message);
            if (error.response) {
                console.error(error.response.data);
            }
        });
    }, [id]);
    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://apiv1.burundientempsreel.com/market/seller/deleteproduct/${id}`);
            history('/Admin/StandBuy');
            toast.success("l'annulation du produit est faite avec succes");
        } catch (error) {
            toast.error("Une erreur s'est produite lors de l'annulation du produit");

        }
    };
    const handleValidate = async (id) => {
        try {
            await axios.put(`https://apiv1.burundientempsreel.com/market/admin/validateproduct/${id}`);
            history('/Admin/StandBuy');
            toast.success('la validation du produit est faite avec succes');
        } catch (error) {
            toast.error("Une erreur s'est produite lors de la validation du produit");
        }
    };
    return (
        <div className="p-4 min-h-[75vh]">
            {loading && <SpinnerDemarage />}
            <Link className="ml-4 mt-4  px-2 text-white bg-orange-700 rounded" to='/Admin/StandBuy'>Retour</Link>

            <div class=" flex max-md:flex-col md:grid md:grid-cols-2 gap-6 mt-1 border border-orange-500 pb-10 pt-10 px-5 rounded-2xl bg-gray-100"
            >
                <div>
                    <div className="border border-orange-600 bg-white rounded-2xl overflow-hidden flex justify-center items-center">
                        <img draggable='false'
                            src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
                            alt="product 1"
                            class="object-contain object-center h-full w-full rounded-2xl"
                        />
                    </div>
                    <div class="grid grid-cols-5  gap-4 mt-4">

                        {photo && photo.map((photo) => {
                            return (
                                <>
                                    <div key={photo} className="border border-orange-600 bg-white rounded-2xl overflow-hidden flex justify-center items-center"><img draggable='false'
                                        src={`https://apiv1.burundientempsreel.com/uploads/market/${photo.file}`}
                                        alt=""
                                        class="w-full rounded-md cursor-pointer object-contain object-center border" />
                                    </div>
                                </>
                            )
                        })}

                    </div>


                    {product && product.produitvisit && (
                        <div className="border border-orange-700 p-4 mt-8  rounded-2xl">
                            <h2 className="font-bold sm:text-3xl text-xl font-serif w-full text-center py-5">Inspecteur</h2>
                            <div className="flex gap-10 items-center flex-wrap">

                                {product.produitvisit.agentterain && (
                                    <div className="p-4">
                                        <p className="sm:text-xl font-semibold">Information personnel</p>
                                        <p className="mt-2"><span className="font-semibold text-gray-600">Nom </span>:{product.produitvisit.agentterain.user.nom} </p>
                                        <p className="mt-2"><span className="font-semibold text-gray-600">Prenom</span>: {product.produitvisit.agentterain.user.prenom} </p>
                                        <p className="mt-2"><span className="font-semibold text-gray-600">Tel</span>: {product.produitvisit.agentterain.user.tel} </p>
                                    </div>
                                )}


                                <div>
                                    <p className="sm:text-xl font-semibold">Information d'inspection</p>
                                    <p className="mt-2"><span className="font-semibold text-gray-600"> Disponibilite</span>: {product.produitvisit.dispo} </p>
                                    <p className="mt-2"><span className="font-semibold text-gray-600"> Quatite </span>:{product.produitvisit.quantite}  </p>
                                    <p className="mt-2"><span className="font-semibold text-gray-600">priorie</span>:   {product.produitvisit.priorie} </p>
                                </div>

                            </div>
                            <p className="mt-2"><span className="font-bold underline text-gray-600">Commentaire</span>:<p className="pl-3"> {product.produitvisit.comment}</p> </p>
                        </div>
                    )}



                </div>

                <div>
                    <h4 class="font-medium text-3xl mb-2">{product.titre}</h4>

                    <div class="espace-y-2">
                        <p class="text-gray-800 font-semibold space-x-2">
                            <span>Disponibilité : </span>
                            <span class="text-olive-500">
                                {product.quanite} articles en stock
                            </span>
                        </p>

                        <p class="espace-x-2">
                            <span class="text-gray-800 font-semibold">Catégorie : </span>
                            <span class="text-gray-600">{product.categorie}</span>
                        </p>
                        <p class="espace-x-2">
                            <span class="text-gray-800 font-semibold">Prix du Vendeur: </span>
                            <span class="text-gray-600">{parseInt(product.prix / (1 + 5 / 100))}</span>
                        </p>
                        <p class="espace-x-2">
                            <span class="text-gray-800 font-semibold">Prix aux clients : </span>
                            <span class="text-gray-600">{product.prix}</span>
                        </p>
                    </div>


                    <p class="mt-4 text-gray-600">
                        <span class="text-gray-800 font-semibold">Déscription : </span>
                        <span class="text-gray-600"> {product.description}</span>
                    </p>
                </div>


            </div>
            <div className="mt-2 flex w-full justify-end">
                <button onClick={() => handleValidate(id)} className="transition-all mr-4  bg-orange-400 hover:bg-orange-700 text-white px-7 py-1 rounded">Valid</button>
                <button onClick={() => handleDelete(id)} className="transition-all  bg-red-500 hover:bg-red-700 text-white px-7 py-1 rounded">Cancel</button>
            </div>

        </div>
    );
}

export default DetailPoductor;

