/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, ADD, REMOVE, CLEAR_CART } from "../../../../Redux/Action/Action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";




function ConfirmPayment({getThemes}) {
    const dispatch = useDispatch();
    const history = useNavigate();
    const getData = useSelector((state) => state.CartsReducer.Carts);

    const [pay, SetPay] = useState('')
    const [animationClasspay, setAnimationClasspay] = useState('');
    const elemetRefpay = useRef(null)

    const [provin, Setprovin] = useState('')
    const [animationClassprovin, setAnimationClassprovin] = useState('');
    const elemetRefprovin = useRef(null)

    const [comune, Setcomune] = useState('')
    const [animationClasscomune, setAnimationClasscomune] = useState('');
    const elemetRefcomune = useRef(null)


    const [zone, setzone] = useState('')
    const [animationClasszone, setAnimationClasszone] = useState('');
    const elemetRefzone = useRef(null)

    const [CollineQuartie, SetCollineQuartie] = useState('')
    const [animationClassCollineQuartie, setanimationClassCollineQuartie] = useState('');
    const elemetRefCollineQuartie = useRef(null)

    const [avenue, Setavenue] = useState('')
    const [animationClassavenue, setanimationClassavenue] = useState('');
    const elemetRefavenue = useRef(null)


    const [NUmMaison, SetNUmMaison] = useState('')
    const [animationClassNUmMaison, setanimationClassNUmMaison] = useState('');
    const elemetRefNUmMaison = useRef(null)


    const [tel, Settel] = useState('')
    const [animationClasstel, setanimationClasstel] = useState('');
    const elemetReftel = useRef(null)


    const [isTextarea, setIsTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');


    const [profil, SetProfil] = useState("")
    const [photo, SetPhoto] = useState(null)

    const handleClose = () => {
        toast.dismiss();
    };
    const [price, setPrice] = useState(0);
    const UserId = localStorage.getItem('userid');
    const total = () => {
        let prices = 0;
        getData.map((ele, index) => {
            prices = Math.trunc(ele.prix) * ele.quantity + prices;
        });
        setPrice(prices);
    };
    useEffect(() => {
        total();
    }, [total]);


    function handleSubmit(e) {
        e.preventDefault()

        if (pay.trim() === '') {
            toast.warning('Votre pay de livraison', {
                autoClose: 2000
            });
            setAnimationClasspay('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClasspay(' ')
            }, 3000)
            elemetRefpay.current.focus()

            return;
        } else if (provin.trim() === '') {
            toast.warning('Le  pronvice de livraison est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassprovin('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassprovin(' ')
            }, 3000)
            elemetRefprovin.current.focus()

            return;
        }
        else if (comune.trim() == "") {
            toast.warning("Commune de livraison est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClasscomune('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClasscomune(' ')
            }, 3000)
            elemetRefcomune.current.focus()

            return;
        }
        else if (zone.trim() == '') {
            toast.warning("Zone de livraison est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClasszone('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClasszone(' ')
            }, 3000)
            elemetRefzone.current.focus();
            return;
        } else if (CollineQuartie.trim() == '') {
            toast.error("La colline ou quartier de livraison est obligatoire", {
                autoClose: 2000
            });
            setanimationClassCollineQuartie('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setanimationClassCollineQuartie(' ')
            }, 3000)
            elemetRefCollineQuartie.current.focus();

            return;
        } else if (avenue.trim() == '') {
            toast.warning('Avenue de livraison est obligatoire', {
                autoClose: 2000
            });
            setanimationClassavenue('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setanimationClassavenue(' ')
            }, 3000)
            elemetRefavenue.current.focus();

            return;
        } else if (NUmMaison.trim() == '') {
            toast.warning('Numero du maison de livraison est obligatoire', {
                autoClose: 2000
            });
            setanimationClassNUmMaison('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setanimationClassavenue(' ')
            }, 3000)
            elemetRefNUmMaison.current.focus();

            return;
        }
        else if (tel.trim() == '') {
            toast.warning('Le numero de telephone est obligatoire', {
                autoClose: 2000
            });
            setanimationClasstel('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setanimationClasstel(' ')
            }, 3000)
            elemetReftel.current.focus();

            return;
        }


        else if (isTextarea.trim() == '') {
            toast.warning('la description des produit commandés est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setAnimationClassTextarea(' ')
            }, 3000)
            textaresRef.current.focus();
            return;
        }



        const formData = new FormData();
        formData.append('Pays', pay)
        formData.append('Province', provin);
        formData.append('Commune', comune);
        formData.append('Zone', zone);        
        formData.append('Quartier', CollineQuartie);
        formData.append('Avenue', avenue);
        formData.append('HouseNumber', NUmMaison);
        formData.append('MobileNumber', tel);
        formData.append('description', isTextarea);
        formData.append('price', price);
        formData.append('UserId', UserId);
        formData.append('photo', photo);
        formData.append('getData', JSON.stringify(getData));
        axios.post('https://apiv1.burundientempsreel.com/market/user/commande', formData, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          })
          .then((response) => {
            // Succès de la requête
            toast.success(response.data.message, {
              position: "top-center",
              autoClose: 7000,
              hideProgressBar: false, 
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              onClick: handleClose,
            });
            
            SetPhoto(null);
            SetProfil(null)    
            // Effacer le panier
            dispatch(CLEAR_CART());
            history('/')
          })
          .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                toast.error(error.response.data);
              }
          });
          


}


return (
<>
  
  <div  className="mt-5 text-center">
    <h1 className=" max-sm:text-[18px] text-[25px] font-semibold">
    CONFIRMEZ VOS DÉTAILS DE PAIEMENT ET D'EXPÉDITION
    </h1>
    <p className={`${getThemes?'text-gray-300':'text-gray-900'}  my-5`}>
    Entrez les informations qui prouvent votre paiement et fournissez-nous une adresse de livraison
    </p>
  </div>

  
  <div  className="">
  <form onSubmit={handleSubmit} className="w-full">
    <section className="flex flex-col md:flex-row  justify-center ">
    <div className={` ${getThemes ?'bg-black':'bg-gray-200'} w-full shadow-lg border rounded-2xl flex flex-col max-w-3xl p-4 items-center`}>
        <div className="py-8 px-1 w-full">
          <h2 className="font-bold text-3xl max-sm:text-[20px] text-center text-orange-500">
            
          Details d'expédition-BTR 
          </h2>
          <p className="text-sm mt-4 text-orange-500">
          Entrez vos informations d'expédition.
          </p>

          <div
            className="w-full"
      >


 
        
        <div className="flex mb-6 flex-col  w-full">

            <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                <div class="relative h-11 w-1/2 flexFormulInput mr-2 ">
                    <input ref={elemetRefpay} value={pay} onInput={(e) => SetPay(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasspay}`} placeholder=" " />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                       Pays
                    </label>
                </div>

                <div class="relative h-11 flexFormulInput w-1/2  mr-2 ">
                    <input value={provin} ref={elemetRefprovin}
                        onInput={(e) => Setprovin(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassprovin}`} placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Province
                    </label>
                </div>
            </div>
            <div className=" mt-[10px] transition-all flex w-[100%] justify-between  flexFormul">
                <div class="relative h-11 w-1/2 flexFormulInput  mr-2 ">
                    <input value={comune} ref={elemetRefcomune} onInput={(e) => Setcomune(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscomune}`} placeholder=" " />
                    <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Communine
                    </label>
                </div>
                <div class="relative h-11 flexFormulInput w-1/2">
                    <input value={zone} ref={elemetRefzone}
                        onInput={(e) => setzone(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasszone}`} placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Zone
                    </label>
                </div>
            </div>

            <div className=" mt-[10px] transition-all flex w-[100%] justify-between flexFormul">

                <div class="relative h-11 flexFormulInput w-1/2  mr-2">
                    <input value={CollineQuartie} ref={elemetRefCollineQuartie}
                        onInput={(e) => SetCollineQuartie(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassCollineQuartie}`} placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Quartier/Colline
                    </label>
                </div>

                <div class="relative h-11 flexFormulInput w-1/2">
                    <input value={avenue} ref={elemetRefavenue}
                        onInput={(e) => Setavenue(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassavenue}`} placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Avenue
                    </label>
                </div>
            </div>
            <div className=" mt-[10px]  transition-all flex w-[100%] justify-between  flexFormul">

                <div class="relative h-11 flexFormulInput w-1/2 mr-2">
                    <input value={NUmMaison} ref={elemetRefNUmMaison}
                        onInput={(e) => SetNUmMaison(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNUmMaison}`} placeholder=" "
                    />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Le numero de la maison
                    </label>
                </div>


                <div class="relative h-11 flexFormulInput w-1/2">
                    <input value={tel} ref={elemetReftel}
                        onInput={(e) => Settel(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasstel}`} placeholder=" "
                  type="tel"
                  />
                    <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Numero de telephone actuel
                    </label>
                </div>

            </div>
            <div className="relative h-full w-full mt-[2em]">
                    <textarea ref={textaresRef}
                        value={isTextarea}
                        onChange={(e) => {
                            setIsTextarea(e.target.value);
                            setAutoScrollHeight(e.target.scrollHeight);
                            setValueTextarea(e.target.value.trim().length);
                            if (!valueTextarea) {
                                setAutoScrollHeight(50);
                            }
                        }}
                        placeholder=" "
                        style={{ height: `${autoScrollHeight}px` }}
                        className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                    />
                    <label
                        className={`pointer-events-none absolute flex-nowrap left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-orange-gray-500`}
                    >
                      La description de commandés
                    </label>
                </div>
        </div>
       
        
      
   
 
           
          </div>


          
          <div className="flex text-orange-500 justify-between">
            <p className="text-lg font-bold">
Montant total </p>
            <div className="">
              <p className="mb-1 text-xl font-bold"> { Math.floor(price).toLocaleString("fr-FR")} Fbu</p>
            </div>
          </div>
 
          <h2 className=" pt-8 max-sm:text-[20px] text-center font-bold text-3xl text-orange-500">
          Confirmez votre paiement
          </h2>
          <p className="text-sm mt-4 text-orange-500">
            Fournissez-nous vos informations de preuve de paiement.
          </p>

          <div
            className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
          >
           
                <div>
          <div className="mb-4">



                  <div className="ro">
            <div className="la">
            <label
                      title="Ajouter un video"
                      class="bg-orange-500 px-2 py-1 text-white rounded cursor-pointer text-center"
                      for="profiles"
                    >
                      
                        Choisissez l'image de la preuve
                    </label>
                 </div>

            <input type="file" accept='image/*' hidden id='profiles' onChange={(e) => { const UrlFile = e.target.files[0]; SetPhoto(UrlFile); SetProfil(URL.createObjectURL(UrlFile)) }} />
            <div className={`${profil == "" ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'} w-[70%] h-[70%] p-2 overflow-hidden mt-[20px] rounded border-4  border-orange-600`}>
                <img draggable='false' src={profil} alt="" className={`w-full h-full object-cover`} />
            </div>
        </div>


                
                </div>
                </div>
            


           
          </div>


          <div className="flex justify-end items-center mt-4">
            <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                <input type="submit" id="send" value='Envoyer' className='cursor-pointer '></input>
                <i class="bi bi-send ml-2 "></i>
            </label>
        </div>
        </div>
       
      </div>
    </section>  
    </form>
  </div>     
</>
);
}

export default ConfirmPayment;











// function SinscrireAdmin() {




 



 




//     const handleSubmit = (e) => {
   

//     return (
//         <>
  
    // </>
//     )
// }

// export default SinscrireAdmin

