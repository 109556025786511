/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { FadeLoader } from 'react-spinners'
function ModifDepenesMarcheUser() {
    const [boutLoading, setboutLoading] = useState(false);
    const { pathname } = useLocation()
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)


    const [nomProduits, setnomProduits] = useState('');
    const [animationClassnomProduits, setAnimationClassnomProduits] = useState('');
    const nomProduitsRef = useRef(null)

    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const Client = /^\/Client.*/
    const Produits = /^\/Produits.*/

    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false);
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, [])


    const handleSubmit = (e) => {


        if (nomProduits.trim() === '') {
            toast.warning('Entre le motant de la depense', {
                autoClose: 2000
            });
            setAnimationClassnomProduits('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassnomProduits('');
            }, 3000);
            nomProduitsRef.current.focus()
            e.preventDefault();
            return;
        }

        if (textarea.trim() === '') {
            toast.warning('Le motif de la depense est nécessaire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            e.preventDefault();
            return;
        }
    }


    return (
        <>
            <div className={`${clickSlideHidden ? 'slideBars20 ' : 'slideBars2'}  w-[11em] z-50  top-0 hidden absolute   h-full bg-gray-300 transition-all border-r-2 border-orange-300 slideBars2`}>
                <div className="w-full relative h-12 flex justify-center items-end font-serif text-gray-600 border-b border-gray-200 ">
                    <button onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className='bi bi-chevron-double-left text-black hover:bg-black  hover:text-white transition-all font-bold absolute top-0 right-2  px-[4px] py-[1px] rounded '></button>
                    <p>Tous navigation</p>
                </div>
                <div className="w-[11em]">
                    <div className="w-full overflow-y-scroll overflow-x-hidden">
                        <div className="w-full h-[77vh] font-serif">
                            {pathname == "/" ? (
                                <Link to='/' className='flex  justify-start items-center  p-1 w-full h-12    pb-2 bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Acceuil</p>
                                </Link>
                            ) : (
                                <Link to='/' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-houses-fill text-[20px] mr-4"></i>
                                    <p className='sizeText'>Acceuil</p>
                                </Link>
                            )}

                            {Client.test(pathname) ? (
                                <Link to='/Client' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-person text-[20px] mr-4"></i>
                                    <p className='sizeText'>Clients</p>
                                </Link>

                            ) : (
                                <Link to='/Client' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-person text-[20px] mr-4"></i>
                                    <p className='sizeText'>Clients</p>
                                </Link>
                            )}

                            {Produits.test(pathname) ? (
                                <Link to='/Produits' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Produits</p>
                                </Link>

                            ) : (
                                <Link to='/Produits' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Produits</p>
                                </Link>
                            )}

                            {pathname == "" || pathname == "" || pathname == "" || pathname == "/Admin/History/Detail" ? (
                                <Link to='' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Ventes</p>
                                </Link>

                            ) : (
                                <Link to='' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Ventes</p>
                                </Link>
                            )}


                            {pathname == "" || pathname == "" || pathname == "" || pathname == "/Admin/History/Detail" ? (
                                <Link to='' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Dettes</p>
                                </Link>

                            ) : (
                                <Link to='' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Dettes</p>
                                </Link>
                            )}


                            {pathname == "" || pathname == "" || pathname == "" || pathname == "/Admin/History/Detail" ? (
                                <Link to='' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Depenses</p>
                                </Link>

                            ) : (
                                <Link to='' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Depenses</p>
                                </Link>
                            )}



                            {pathname == "" || pathname == "" || pathname == "" || pathname == "/Admin/History/Detail" ? (
                                <Link to='' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Bonus</p>
                                </Link>

                            ) : (
                                <Link to='' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Bonus</p>
                                </Link>
                            )}


                            {pathname == "" || pathname == "" || pathname == "" || pathname == "/Admin/History/Detail" ? (
                                <Link to='' className='flex   justify-start items-center  p-1 w-full h-12    pb-2  bg-orange-600 text-fuchsia-50  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Action du jours</p>
                                </Link>

                            ) : (
                                <Link to='' className='flex text-gray-800  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 '>
                                    <i class="bi bi-file-earmark-ppt text-[20px] mr-4"></i>
                                    <p className='sizeText'>Action du jours</p>
                                </Link>
                            )}
                        </div>

                    </div>
                </div>
            </div>

            <div className='bg-gray-200 sticky top-0 z-1 flex justify-between px-10 items-center border-orange-300 border-b paddings'>
                <div onClick={(e) => { setclickSlideHidden(!clickSlideHidden); e.stopPropagation(); }} className=" ml-2 menus bi bi-list cursor-pointer hover:bg-black hidden  hover:text-white transition-all h-max text-[20px] px-2 py-1"></div>
                <div className="w-full overflow-hidden h-11 flex justify-center items-center mr-2">
                    <div className="text-gray-500 mot1">   Modifier depenses</div>
                </div>

            </div>




            <div className="w-full  flex justify-center pb-20">
                <div className={`border-2 rounded border-orange-600 p-2 mt-3   w-[25em] responsive3Form `}>
                    <form onSubmit={handleSubmit}>
                        <div className="mt-6 w-full ">
                            <div className="transition-all  w-[100%]">
                                <div className="relative h-11 w-full InputsContact mr-2">
                                    <input ref={nomProduitsRef}
                                        value={nomProduits}
                                        onInput={(e) => setnomProduits(e.target.value)}
                                        className={`peer h-full w-full border-b-2 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClassnomProduits}`}
                                        placeholder=" "
                                    />
                                    <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                        Le Montant
                                    </label>
                                </div>
                                <div className="relative h-full w-full mt-10">
                                    <textarea ref={textaresRef}
                                        value={textarea}
                                        onChange={(e) => {
                                            setTextarea(e.target.value);
                                            setAutoScrollHeight(e.target.scrollHeight);
                                            setValueTextarea(e.target.value.trim().length);
                                            if (!valueTextarea) {
                                                setAutoScrollHeight(50);
                                            }
                                        }}
                                        placeholder=" "
                                        style={{ height: `${autoScrollHeight}px` }}
                                        className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                    />
                                    <label
                                        className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-orange-gray-500`}
                                    >
                                        Motif
                                    </label>
                                </div>

                                <div className="flex justify-end items-center mt-[20px]">
                                    {boutLoading ? (
                                        <>
                                            <label className="  cursor-pointer w-max mt-9 relative  flex justify-center  items-center   bg-blue-800 pointer-events-none   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Modifier' className='cursor-pointer' />
                                                <div className='absolute bg-transparent pt-4   w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    /></div>
                                            </label>
                                        </>
                                    ) : (<>

                                        <div className="flex bg-orange-600 rounded cursor-pointer pl-2 py-1 text-white">
                                            <input type="submit" id="send" hidden />
                                            <label htmlFor="send" className="w-max flex justify-end p-1 cursor-pointer mx-1 ">
                                                <span className='mr-2'>Modifier</span>
                                                <div className="bi bi-send"></div>
                                            </label>
                                        </div>
                                    </>)}

                                </div>


                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ModifDepenesMarcheUser
