import React from "react";
import UpdateFormOnly from "./UpdateFormOnly";
function UpdateProdComponentOnly() {
  return (
    <>


      <div class="flex flex-row justify-center container w-full pt-6 pb-8 px-2">
        <UpdateFormOnly />
      </div>
    </>
  );
}

export default UpdateProdComponentOnly;
