import React from 'react'
import ConfirmPayment from './ConfirmPayment'
function ContainerContentPayment({ getThemes }) {
  return (
    <>
      <ConfirmPayment getThemes={getThemes} />
    </>
  )
}

export default ContainerContentPayment
