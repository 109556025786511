/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, ADD, REMOVE } from "../../../../Redux/Action/Action";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from "prop-types";

function AllCarts({ getThemes }) {

  const [price, setPrice] = useState(0);
  const history = useNavigate();
  const getData = useSelector((state) => state.CartsReducer.Carts);
  const [isClicked, setIsClicked] = useState(false);
  const [quntite, SetQuntite] = useState(true);

  function test() {
    setIsClicked(!isClicked);
  }



  const dispatch = useDispatch();

  const dlt = (id) => {
    dispatch(DELETE(id));

  };

  //supprime un à un dans une commande

  function remove(e) {
    dispatch(REMOVE(e));

  }



  function send(e) {
    dispatch(ADD(e, 1));
  }

  const total = () => {
    let prices = 0;
    getData.map((ele, index) => {
      prices = Math.trunc(ele.prix) * ele.quantity + prices;
    });

    setPrice(Math.floor(prices).toLocaleString("fr-FR"));
  };
  useEffect(() => {
    total();
  }, [total]);


  if (getData.length < 1) {
    history('/')
  }

  function handleDelete(e) {
    if (getData.length > 1) {
      dlt(e)

    }
    else {
      dlt(e)
      history('/')
    }
  }

  const handleClose = () => {
    toast.dismiss();
  };

  const [mobile1, Setmobile1] = useState(window.innerWidth < 377.9292828290293)
  const [mobile2, Setmobile2] = useState(window.innerWidth < 552.9292828290293)
  const [mobile, Setmobile] = useState(window.innerWidth < 961.9292828290293)

  const [LoadingPagePhotos1, SetLoadingPagePhotos1] = useState(true)
  const [LoadingPagePhotos2, SetLoadingPagePhotos2] = useState(true)
  const [LoadingPagePhotos3, SetLoadingPagePhotos3] = useState(true)
  const [LoadingPagePhotos4, SetLoadingPagePhotos4] = useState(true)
  const [LoadingPagePhotos5, SetLoadingPagePhotos5] = useState(true)
  const [LoadingPagePhotos6, SetLoadingPagePhotos6] = useState(true)

  useEffect(() => {
    const hundleResize = () => {
      Setmobile1(window.innerWidth < 377.9292828290293)
      Setmobile(window.innerWidth < 961.9292828290293)
      Setmobile2(window.innerWidth < 552.9292828290293)
    }

    window.addEventListener('resize', hundleResize)

    return () => {
      window.removeEventListener('resize', hundleResize)
    }
  }, [])

  const LazyImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };

    const lazyLoad = () => {
      setImgSrc(src);
    };

    const elementInViewPort = () => {
      // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
      const clientRect = imageRef.current.getBoundingClientRect();

      return (
        clientRect.top >= 0 &&
        clientRect.left >= 0 &&
        clientRect.bottom - 100 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        clientRect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };


  return (
    <>
      {getData.length ? (
        <div className="h-full ">
          <div class=" py-2 border border-gray-300  px-4 w-full  rounded-b-2xl justify-between flex items-center gap-3">
            <Link to='/' className="flex py-1 px-2 justify-center items-center rounded bg-orange-500 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi mr-2 bi-arrow-return-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
              </svg>
              <p class=" font-bold">Retour</p>
            </Link>
            <p class="text-gray-400 font-serif text-center max-sm:text-xs">Online Exchange Solution</p>
          </div>
          <h1 className="mb-10 text-orange-500 font-serif text-left text-2xl max-sm:text-center max-sm:text-xl font-bold">

            Tous les articles du panier
          </h1>




          <div className={` ${mobile ? 'flex-col' : ''} mx-auto justify-center flex    xl:flex md:space-x-6 xl:px-0`}>
            <div className={` ${mobile ? 'w-full' : 'xl:w-2/3'} rounded-lg   parentGrandss`}>

              {getData.map((e) => {

                return (
                  <>
                    <div className={`${getThemes ? 'border border-white' : 'bg-gray-50'} flex  shadow-2xl m-2 rounded-xl justify-between parentwidths p-2 w-[50em]`}>
                      <div class={` ${mobile2 ? 'flex' : 'hidden'}   justify-end`}>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-5 w-5 cursor-pointer duration-150  hover:text-red-500"
                          onClick={() => handleDelete(e.id)}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div className=" p-2 relative overflow-hidden w-[30em] h-[30em] widths">

                        <img

                          src={`https://apiv1.burundientempsreel.com/uploads/market/${e.profil}`}
                          alt="product-image"
                          className="w-full rounded-lg sm:w-40"
                        />
                      </div>
                      <div className=" w-[18em] mt-5 widsss">
                        <div class={` ${mobile2 ? 'hidden' : ' flex'}   justify-end`}>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-5 w-5 cursor-pointer duration-150  hover:text-red-500"
                            onClick={() => handleDelete(e.id)}
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>

                        <div className={` ${getThemes ? 'text-white' : 'text-gray-800'} mt-5 sm:mt-0 md:w-48`}>

                          <div className="flex flex-row justify-between "><h2 className="text-lg font-bold ">
                            {e.titre.length >= 40
                              ? e.titre.substring(0, 40) + "...."
                              : e.titre}
                          </h2>

                          </div>
                          <p className="mt-1 text-base">
                            Quantite: {e.quanite}
                          </p>
                        </div>
                        <div className="mt-4  flex  md:flex-row flex-row-reverse justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">

                          <div className="flex items-center border-gray-500">
                            <span
                              onClick={
                                () => {
                                  if (e.quantity < 2) { dlt(e.id); SetQuntite(e.quantity) }
                                  else { remove(e) }
                                }}
                              className="cursor-pointer rounded-l bg-gray-200 py-1 px-3.5 duration-500 hover:bg-orange-500 hover:text-orange-50"
                            >
                              {" "}
                              -{" "}
                            </span>
                            <input
                              className="h-8 w-8 border bg-white text-center text-xs outline-none"
                              type="text"
                              value={e.quantity}
                              min="1"
                            />
                            <span
                              onClick={() => {
                                if (e.quantity < e.quanite) {
                                  send(e)
                                }
                                else {
                                  toast.error("Vous avez depense la limite des quantites ", {
                                    position: "top-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    onClick: handleClose, // Utilisation de la référence à la fonction handleClose
                                  });
                                }
                              }}
                              className="cursor-pointer rounded-r bg-gray-200 py-1 px-3 duration-500 hover:bg-orange-500 hover:text-orange-50"
                            >
                              {" "}
                              +{" "}
                            </span>
                          </div>
                          <div className="flex items-center space-x-4">
                            <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                              <p class="text-xl text-primary font-semibold">
                                {Math.floor(e.prix).toLocaleString("fr-FR")} Fbu
                              </p>
                              <p class="text-base text-red-400 line-through">
                                {Math.floor(e.prix) + (Math.floor(e.prix) * 20) / 100} Fbu
                              </p>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </>
                );
              })}
            </div>
            {/* <!-- Sub total --> */}

            <div className={`mt-6 ${getThemes ? 'border border-white text-white' : 'bg-white text-gray-700'}  h-full p-8 rounded-lg border shadow-2xl   lg:w-[40em]`}>
              <div className="flex justify-between">
                <p className="">Expédition</p>
                <p className="">0 Fbu</p>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Total</p>
                <div className="">
                  <p className="mb-1 text-lg font-bold"> {price} Fbu</p>
                </div>
              </div>

              <Link
                to="/Orders/Paiement"
                class="block w-full mt-6 py-2  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
              >
                Vérifier
              </Link>

              <div class="flex flex-col items-start 00  max-md:w-full max-md:items-center">

                <p class="text-gray-400 max-md:w-full  font-bold my-2">Paiement en ligne
                  :</p>
                <div class={` ${mobile1 ? 'grid grid-cols-2' : ' '} flex flex-wrap gap-2`}>
                  <a href="#" class={` ${mobile1 ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
                    {LoadingPagePhotos1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-cover w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos1(false)
                      }}
                      src="../../../image/ecocashe.jpg" />
                  </a>

                  <a href="#" class={` ${mobile1 ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
                    {LoadingPagePhotos2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-cover w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos2(false)
                      }} src="../../../image/lumitel.png" />
                  </a>
                  <a href="#" class={` ${mobile1 ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
                    {LoadingPagePhotos3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos3(false)
                      }}
                      src="../../../image/mobinotel.png"
                    />
                  </a>
                  <a href="#" class={` ${mobile1 ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
                    {LoadingPagePhotos4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos4(false)
                      }}
                      src="../../../image/feza.png"
                    />
                  </a>
                  <a href="#" class={` ${mobile1 ? 'w-24 p-1 h-24' : 'w-max p-1 h-max'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
                    {LoadingPagePhotos5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-24 h-24"
                      onLoad={() => {
                        SetLoadingPagePhotos5(false)
                      }} src="../../../image/New-logo-CECM-01.jpg" />
                  </a>
                  <a href="#" class={` ${mobile1 ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
                    {LoadingPagePhotos6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                    <img draggable='false'
                      className="rounded-xl object-fill w-full h-full"
                      onLoad={() => {
                        SetLoadingPagePhotos6(false)
                      }} src="../../../image/BancobueNoti.jpg" />
                  </a>
                </div>
              </div>


            </div>

          </div>
        </div >
      ) : null
      }

    </>
  );
}

export default AllCarts;

