/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'

function CopyRight() {
    const LogImage = 'https://apiv1.burundientempsreel.com/uploads/logo/btr.png'
    const [loading, SetLoading] = useState(true)

    return (
        <div className="copy p-4 bg-[rgba(10,1,67,0.93)] inline-flex  w-full text-center justify-center items-center  text-white">
            CopyRight ,developpée par la sociéte
            <div className="w-[40px]  p-1 h-[40px] mx-2  border-4  relative flex justify-center items-center border-double border-orange-600 rounded-full overflow-hidden">
                {loading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover scale-[3]" /></div>}

                <img src={LogImage}
                    onLoad={() => {
                        setTimeout(() => {
                            SetLoading(false)
                        }, 1000)
                    }}
                />

            </div> Burundi en Temps Réel
        </div>
    )
}

export default CopyRight
