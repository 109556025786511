import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Empty from "../Empty/Empty";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
function MarketList() {
  const id = localStorage.getItem("userid")
  const [listoffOrders, setListOffOrders] = useState([]);
  const [element, setElement] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };
  const firstEntry = (currentPage - 1) * itemsPerPage + 1;
  const lastEntry = Math.min(currentPage * itemsPerPage, element);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1179);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1179);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/myorderseller/${id}?page=${currentPage}&size=${itemsPerPage}`)
      .then((response) => {
        setElement(response.data.totalItems);
        setListOffOrders(response.data.orders);
        setTotalPages(response.data.totalPages);

        setTimeout(() => {
          setLoading(false)
        }, 1000);

      }).catch((error) => {
        console.error(error);
        if (error.response) {
          console.log(error.response.data);
        }
      });
  }, [currentPage, itemsPerPage, id]);



  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filterData = (data, searchValue) => {
    if (!searchValue.trim()) {
      return data; // Aucune recherche en cours, retourner toutes les données
    }

    return data.filter((value) => {
      // Vérifier si le numéro de commande contient la valeur de recherche
      if (value.commandemarket.id.toString().includes(searchValue)) {
        return true; // Correspondance trouvée dans la colonne "Numéro de commande"
      }

      // Vérifier si le montant payé (colonne "Montant payé") contient la valeur de recherche
      if (value.produit.prix.toString().includes(searchValue)) {
        return true; // Correspondance trouvée dans la colonne "Montant payé"
      }

      // Vérifier si les informations du produit (colonne "Information produit") contiennent la valeur de recherche
      const productInfo = `${value.produit.titre} ${value.produit.description}`;
      if (productInfo.toLowerCase().includes(searchValue.toLowerCase())) {
        return true; // Correspondance trouvée dans la colonne "Information produit"
      }

      // Vérifier si le statut (colonne "Status") contient la valeur de recherche
      if (value.commandemarket.status.toLowerCase().includes(searchValue.toLowerCase())) {
        return true; // Correspondance trouvée dans la colonne "Status"
      }

      // Vérifier si la date (colonne "Date") contient la valeur de recherche
      const formattedDate = new Date(value.createdAt).toLocaleDateString('en-US') + ' ' + new Date(value.createdAt).toLocaleTimeString('en-US');
      if (formattedDate.includes(searchValue)) {
        return true; // Correspondance trouvée dans la colonne "Date"
      }

      // Aucune correspondance trouvée dans toutes les colonnes
      return false;
    });
  };

  const filteredData = filterData(listoffOrders, searchValue);

  return (
    <div className="min-h-[35em] w-full">
      {loading && <SpinnerDemarage />}
      {listoffOrders.length > 0 ?
        (<>

          <div class=" w-full  text-gray-700 border-b overflow-x-auto">

            <div class="">
              <div class="">
                <div className="flex justify-between px-4 py-2">
                  <div className="pl-4">

                    <h2 class="sm:text-2xl text-[15px] font-semibold   text-orange-500 leading-tight">
                      Gérer les commandes
                    </h2>
                  </div>
                  <div class=" flex flex-row pl-2 sm:pl-4 ">
                    <div class="flex flex-row mb-1 sm:mb-0">
                      <div className="relative">
                        <select
                          className="appearance-none h-full rounded-l-2xl border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option>5</option>
                          <option>10</option>
                          <option>20</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>


                    </div>
                    <div class="block relative">
                      <input
                        type='text'
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearchChange}
                        class="appearance-none rounded-r-2xl rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                      />
                    </div>
                  </div>
                </div>


                <div class="w-full p-2  overflow-x-auto">
                  <div class={`inline-block  ${isMobile ? 'w-[70em]' : 'min-w-full'} px-4 rounded-lg overflow-hidden`}>
                    <table class="min-w-full leading-normal ">
                      <thead className=" bg-orange-600 text-white">

                        <tr>
                          <th class="px-5 py-3 border-r border-gray-200  text-left  font-semibold text-white  tracking-wider">
                            Numéro de commande
                          </th>
                          <th class="px-5 py-3 border-r border-gray-200  text-left  font-semibold text-white  tracking-wider">
                            Montant payé
                          </th>
                          <th class="px-5 py-3 border-r border-gray-200  text-left  font-semibold text-white  tracking-wider">
                            Information produit
                          </th>
                          <th class="px-5 py-3 border-r border-gray-200  text-left  font-semibold text-white  tracking-wider">
                            Status
                          </th>
                          <th class="px-5 py-3 border-r border-gray-200  text-left  font-semibold text-white  tracking-wider">
                            Date
                          </th>
                          <th class="px-5 py-3 border-r border-gray-200  text-left  font-semibold text-white  tracking-wider">
                            Action
                          </th>
                        </tr>
                      </thead>


                      <tbody>
                        {filteredData.length === 0 ? (<>
                          <tr >
                            <td colSpan="6"><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                              Aucune correspondanct à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                            </div></td>
                          </tr>
                        </>) : (<></>)
                        }
                        {filteredData.map((value, key) => (
                          <tr key={key}>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.commandemarket.id}
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.produit.prix}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                <span className="text-orange-900">
                                  {value.produit.titre}
                                </span>
                                <span className="text-orange-300 ml-2">
                                  {value.produit.description.length > 20 ? `${value.produit.description.substring(0, 20)}...` : value.produit.description}
                                </span>
                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">
                                {value.commandemarket.status === "pending" && (
                                  <span class="bg-orange-100 text-orange-800  font-medium mr-2 px-5 py-2  dark:bg-gray-700 dark:text-orange-300 rounded-2xl border border-orange-300">pending</span>
                                )}
                                {value.commandemarket.status === "payed" && (
                                  <span class="bg-orange-100 text-orange-800  font-medium mr-2 px-5 py-2  dark:bg-gray-700 dark:text-orange-300 rounded-2xl border border-orange-300">payed</span>
                                )}
                                {value.commandemarket.status === "shipping" && (
                                  <span class="bg-green-100 text-green-800  font-medium mr-2 px-5 py-2  dark:bg-gray-400 dark:text-green-800 rounded-2xl border border-green-400">Shipping</span>
                                )}
                                {value.commandemarket.status === "completed" && (
                                  <span class="bg-green-100 text-green-800  font-medium mr-2 px-5 py-2  dark:bg-gray-700 dark:text-green-400 rounded-2xl border border-green-400">completed</span>
                                )}
                                {value.commandemarket.status === "refused" && (
                                  <span class="bg-red-100 text-red-800  font-medium mr-2 px-5 py-2  dark:bg-gray-700 rounded-2xl dark:text-red-400 border border-red-400">refused</span>
                                )}



                              </p>
                            </td>

                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-900 whitespace-no-wrap">

                                <span>
                                  {new Date(value.createdAt).toLocaleDateString('en-US') + ' ' + new Date(value.createdAt).toLocaleTimeString('en-US')}
                                </span>

                              </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm pr-2">
                              <span class="relative inline-block  py-1 font-serif text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  class="absolute inset-0  opacity-50 rounded-full"
                                ><Link to={`/User/Orders/Detail/${value.commandemarket.id}/${value.produit.id}`} class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-orange-500 hover:text-white focus:outline-none">
                                    Details
                                  </Link>
                                </span>
                              </span>
                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>






                  </div>
                </div>
                <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                  <span className=" xs:text-sm text-gray-900">
                    Affichage de {firstEntry} à {lastEntry} sur {element} entrées
                  </span>

                  <div className="inline-flex mt-2 xs:mt-0">
                    <button
                      className="text-sm bg-gray-300 cursor-pointer hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Prec
                    </button>
                    <button
                      className="text-sm bg-gray-300 hover:bg-gray-400 cursor-pointer text-gray-800 font-semibold py-2 px-4 rounded-r"
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Suiv
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>) :
        <>
          <Empty item='order' />
        </>}
    </div>
  );
}

export default MarketList;
