/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerDemarage from "../../SpinnerDemarage/SpinnerDemarage";
import { FadeLoader } from 'react-spinners'
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function InscriptionLivreur({ getThemes, LoginPopUp, SetLoginPopUp, SetSignPopUp, SignPopUp }) {
  const [boutLoading, setboutLoading] = useState(false);
  const history = useNavigate()
  const { id } = useParams()
  const [nomSociete, SetnomSociete] = useState('')
  const [animationClassnomSociete, setAnimationClassnomSociete] = useState('');
  const elemetRefnomSociete = useRef(null)

  const [NIF, SetNIF] = useState('')
  const [animationClassNIF, setAnimationClassNIF] = useState('');
  const elemetRefNIF = useRef(null)


  const [typeLivraison, SettypeLivraison] = useState('')
  const [animationClasstypeLivraison, setAnimationClasstypeLivraison] = useState('');


  const [RC, SetRC] = useState('')
  const [animationClassRC, setAnimationClassRC] = useState('');
  const elemetRefRC = useRef(null)



  const [Adresse, SetAdresse] = useState('')
  const [animationClassAdresse, setAnimationClassAdresse] = useState('');
  const elemetRefAdresse = useRef(null)




  const [comptBank, SetcomptBank] = useState('')
  const [animationClasscomptBank, setAnimationClasscomptBank] = useState('');
  const elemetRefcomptBank = useRef(null)

  let [loading, setLoading] = useState(false);



  const handleSubmit = (e) => {
    e.preventDefault()
    if (nomSociete.trim() === '') {
      toast.warning('Le nom de la société est obligatoire', {
        autoClose: 2000,
        position: "top-center",
      });
      setAnimationClassnomSociete('animate__animated animate__shakeX placeholder-shown:border-red-500')
      setTimeout(() => {
        setAnimationClassnomSociete(' ')
      }, 3000)
      elemetRefnomSociete.current.focus()
      return
    }

    else if (NIF.trim() == '') {
      toast.warning("Votre NIF est obligatoire !!", {
        autoClose: 2000,
        position: "top-center",
      });
      setAnimationClassNIF('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
      setTimeout(() => {
        setAnimationClassNIF(' ')
      }, 3000)
      elemetRefNIF.current.focus();
      return
    }


    else if (typeLivraison == '') {
      toast.warning("Le type de livraison est obligatoire !!", {
        autoClose: 2000,
        position: "top-center",
      });
      setAnimationClasstypeLivraison('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-700')
      setTimeout(() => {
        setAnimationClasstypeLivraison(' ')
      }, 3000)

      return
    }
    else
      if (RC.trim() == '') {
        toast.warning("Le numero RC est obligatoire !!", {
          autoClose: 2000,
          position: "top-center",
        });
        setAnimationClassRC('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
        setTimeout(() => {
          setAnimationClassRC(' ')
        }, 3000)
        elemetRefRC.current.focus();
        return

      }
    if (Adresse == '') {
      toast.warning("L'adressb physique est obligatoire !!", {
        autoClose: 2000,
        position: "top-center",
      });
      setAnimationClassAdresse('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
      setTimeout(() => {
        setAnimationClassAdresse(' ')
      }, 3000)
      elemetRefAdresse.current.focus();
      return
    }

    const data = {
      comptBank,
      RC,
      nomSociete,
      NIF,
      typeLivraison,
      Adresse,
      usersId: id
    }
    console.log(data)
    axios.post('https://apiv1.burundientempsreel.com/user/newlivreur', data).then((response) => {
      toast.success('vous avec ajouter le nouveau Livreur')

      history('/Admin/Livreurs')
    }).catch((err) => {
      if (err.response) {
        toast.warning(err.response.data)
      } else {
        toast.warning('Echec d\'ajouter le nouveau Livreur')
        console.log(err.message)
      }

    })

  }



  const [imageLoading, SetImageLoading] = useState(true)


  return (
    <>
      {loading && <SpinnerDemarage />}
      <div className={` flex justify-center relative rounded-2xl overflow-hidden items-center `}>
        <Link to='/Admin/Livreurs' className="absolute top-1 left-1 bg-orange-400 text-white hover:bg-orange-600 transition-all px-2 py-1 rounded">Retour</Link>
        <div className='w-full  h-full overflow-auto flex justify-center  p-4'>
          <div className={`${getThemes ? 'bg-black' : 'bg-white'} rounded-3xl p-2 border shadow-2xl border-orange-600 w-[30em] h-max`}>
            <div className="hidden justify-end px-4 items-center">
              <div onClick={() => { SetSignPopUp(false) }} className="bg-orange-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>
            </div>

            <div className="w-full flex justify-center rounded-full overflow-hidden items-center">
              <div className="h-[10em]  w-[10em] transition-all   relative overflow-hidden ml-3 rounded ">
                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                <img draggable='false' onLoad={() => {
                  setTimeout(() => {
                    SetImageLoading(false)
                  }, 1000)
                }} src='image/OnexS.png' alt="" className='h-full w-full  object-cover object-center' />
              </div>
            </div>
            <h2 className=' p-2 text-center  font-serif text-gray-400 '>Formulaire d'enregistrement du livreur</h2>

            <div className="p-2 my-2 rounded w-full  h-max ">
              <form onSubmit={handleSubmit}>
                <div className="flex mb-6 flex-col">
                  <div className=" transition-all flex  justify-between  ">
                    <div class="relative h-11 w-1/2    mr-2 ">
                      <input ref={elemetRefnomSociete} value={nomSociete} onInput={(e) => SetnomSociete(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''}  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassnomSociete}`} placeholder=" " />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Nom societe de la société
                      </label>
                    </div>

                    <div class="relative h-11  w-1/2    mr-2 ">
                      <input value={NIF} ref={elemetRefNIF} type='tel' onInput={(e) => SetNIF(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''} h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNIF}`} placeholder=" " />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        NIF
                      </label>
                    </div>
                  </div>
                  <div className=" mt-10 transition-all flex  items-end justify-between  ">
                    <div class={`relative h-11  w-1/2 border-b-2 border-gray-700   mr-2  ${animationClasstypeLivraison} `}>
                      <select onChange={(e) => { SettypeLivraison(e.target.value) }} id="Livraison" name="Livraison" class="w-full text-gray-600 bg-white px-1 py-2 border rounded-t-lg shadow-sm focus:outline-none  focus:border-orange-300" >
                        <option value="" className="text-gray-600" desable hidden>Moyen de déplacement</option>
                        <option value="Livraison à pied" className="text-gray-600">Livraison à pied</option>
                        <option value="Livraison à vélo" className="text-gray-600">Livraison à vélo</option>
                        <option value="Livraison en scooter" className="text-gray-600">Livraison en scooter</option>
                        <option value="Livraison en moto" className="text-gray-600">Livraison en moto</option>
                        <option value="Livraison en Tukutuku" className="text-gray-600">Livraison en Tukutuku</option>
                        <option value="Livraison en voiture" className="text-gray-600">Livraison en voiture</option>
                        <option value="Livraison en camion" className="text-gray-600">Livraison en camion </option>
                      </select>
                    </div>
                    <div class="relative h-11 w-1/2    mr-2 ">
                      <input value={RC} ref={elemetRefRC} type="text" onInput={(e) => SetRC(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''}  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassRC}`} placeholder=" " />
                      <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Numéro RC
                      </label>
                    </div>
                  </div>





                  <div className=" transition-all mt-10 flex  justify-between ">
                    <div className={`flex items-center  relative w-full`}>
                      <input value={Adresse} ref={elemetRefAdresse}
                        onInput={(e) => SetAdresse(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''} h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassAdresse}`} placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Adresse
                      </label>
                    </div>

                  </div>

                  <div className=" transition-all mt-5 flex  justify-between ">
                    <div className={`flex items-center  relative w-full`}>
                      <input value={comptBank} ref={elemetRefcomptBank}
                        onInput={(e) => SetcomptBank(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''} h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClasscomptBank}`} placeholder=" "
                      />
                      <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                        Numéro de Compte Bancaire
                      </label>
                    </div>

                  </div>
                </div>


                <div className="flex justify-end items-center">
                  {boutLoading ? (
                    <>
                      <label className="  cursor-pointer w-max relative  flex justify-center  items-center   bg-blue-800 pointer-events-none   p-2 rounded  text-white">
                        <input type="submit" id="send" value='Enregistre' className='cursor-pointer' />
                        <i class="bi bi-send ml-2 "></i>
                        <div className='absolute bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                          <FadeLoader
                            color="rgb(255, 255, 255)"
                            height={10}
                            margin={-9}
                            radius={100}
                            speedMultiplier={1}
                            width={1}
                          /></div>
                      </label>
                    </>
                  ) : (<>
                    <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                      <input type="submit" id="send" value='Enregistre' className='cursor-pointer'></input>
                      <i class="bi bi-send ml-2 "></i>
                    </label>
                  </>)}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default InscriptionLivreur;















