import React from "react";

import HeadProductBonus from "./HeadProductBonus";
import ProductDetailBonus from "./ProductDetailBonus";
import SimilarProductBonus from "./SimilarProductBonus";

function Content_ContainerSellerDetaill({ isVisitorLogged, SetLoginPopUp }) {
  return (
    <>
      <div class="bg-white pb-32 mx-auto px-5  text-gray-700 overflow-x-hidden">
        <HeadProductBonus />
        <ProductDetailBonus isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />

      </div>
    </>
  );
}

export default Content_ContainerSellerDetaill;
