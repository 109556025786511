import React, { useEffect, useState } from "react";
import ContainerContentPayment from "./ContainerContentPayment";
import OurPaymentMethod from "./OurPaymentMethod";
import { Link } from "react-router-dom";
function PaymentComponent({ getThemes, isVisitorLogged }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div >
      <div class=" py-2 border border-gray-300  px-4 w-full  rounded-b-2xl justify-between flex items-center gap-3">
        <Link to='/' className="flex py-1 px-2 justify-center items-center rounded bg-orange-500 text-white">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi mr-2 bi-arrow-return-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
          </svg>
          <p class=" font-bold">Retour</p>
        </Link>
        <p class="text-gray-400 font-serif text-center max-sm:text-xs">Online Exchange Solution</p>
      </div>
      <div className={isMobile && isVisitorLogged ? ` mt-[91px] ${getThemes ? 'bg-[#00000094]' : ''} md:mt-[125px] p-3 ` : ` mt-[53px] ${getThemes ? 'bg-[#000000a4]' : ''} md:mt-[125px] `}>

        <OurPaymentMethod getThemes={getThemes} />
        <ContainerContentPayment getThemes={getThemes} />
      </div>
    </div>
  );
}

export default PaymentComponent;




