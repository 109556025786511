/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
function NaVbarsOngletOnly() {
    const { pathname } = useLocation();


    const GereProduit = /^\/UserOnly\/Marche.*/
    const AddProduct = /^\/UserOnly\/enregist.*/
    const Orders = /^\/UserOnly\/Order.*/
    return (
        <div className="w-full h-1/2  flex  flex-col justify-center  items-center  text-[12px] Barshiddess ">
            <div className={`  w-[100%] h-full only:flex justify-around items-end  text-[15px]`}>
                <div className="  flex px-4 w-[100%] justify-around ">

                    <div title="Gérer les produits" class="flex ">
                        {GereProduit.test(pathname) ? (
                            <Link
                                to="/UserOnly/Marche"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <i className="bi bi-file-earmark-ppt mr-2"></i>
                                <span class="hiddensss text-center">
                                    Gérer_produit
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/UserOnly/Marche"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-window-split text-[22px] mr-2"></i>

                                <span class="hiddensss text-center">
                                    Gérer_produit
                                </span>
                            </Link>

                        )}
                    </div>
                    <div title="Ajouter un produit" class="flex">
                        {AddProduct.test(pathname) ? (
                            <Link
                                to="/UserOnly/enregist"
                                class="no-underline text-white bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <i class="bi bi-plus-circle-dotted mr-2"></i>
                                <span class="hiddensss text-center">Ajout_produit</span>
                            </Link>
                        ) : (
                            <Link
                                to="/UserOnly/enregist"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-plus-circle-dotted mr-2"></i>
                                <span class="hiddensss text-center">Ajout_produit</span>
                            </Link>
                        )}
                    </div>

                    <div title="Orders" class="flex ">
                        {Orders.test(pathname) ? (
                            <Link
                                to="UserOnly/Order"
                                class="no-underline text-white bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <i class="bi bi-list-ul  mr-2"></i>
                                <span class="hiddensss text-center">
                                    Commande</span>
                            </Link>
                        ) : (
                            <Link

                                to="UserOnly/Order"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-list-ul   mr-2"></i>

                                <span class="hiddensss text-center">Commande</span>
                            </Link>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NaVbarsOngletOnly
