/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import { toast } from 'react-toastify';
function ConfirmLivraison() {

    const [UrlPhotoLivraison, getUrlPhotoLivraison] = useState('');
    const [isTextarea, setIsTextarea] = useState('');
    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const [code, setcode] = useState('');
    const [animationClasscode, setAnimationClasscode] = useState('');
    const codeRef = useRef(null)

    const [spinnerButton, SetSpinnerButton] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();

        if (code.trim() === '') {
            toast.warning('Le code est obligatoire', {
                className: 'custom-toast',
                autoClose: 2000
            });
            setAnimationClasscode('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClasscode('');
            }, 3000);
            codeRef.current.focus()
            return;
        }

    };

    useEffect(() => {
        if (valueTextarea == 0) {
            setAutoScrollHeight(50);
        }
    }, [valueTextarea])

    const [showSignature, SetshowSignature] = useState(false)
   

    return (
        <div className='p-4 bg-white'>
            {/* pour le document electronoque */}
            <div className='min-h-[90vh] bg-white flex justify-center items-center select-none'>
                <div className='w-max h-max m-4 bg-white border rounded-2xl border-orange-600 p-4 flex flex-col items-center leading-8'>
                    <p className='text-[25px] mb-3 font-serif font-bold text-gray-700 '>Confirmation de Livraison</p>
                    <div className='h-[2px] bg-gray-300 w-full'></div>
                    <div className='w-full mb-8'>
                        <h2 className='text-[20px] font-semibold'>**Informations du Client** :</h2>
                        <p className='font-bold text-gray-600 ml-5'>Nom complet du client:<span className='font-normal pl-2'>MANIRAKIZA Belyse</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Adresse e-mail du client:<span className='font-normal pl-2'>beluse@gmail.com</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Addresse:<span className='font-normal pl-2'>Kinama avenu Muyinga 03</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Numéro de commande:<span className='font-normal pl-2'>89H</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Date de la Commande:<span className='font-normal pl-2'>20/09/2023</span></p>
                    </div>

                    <div className='w-full mb-8'>
                        <h2 className='text-[20px] font-semibold'>**Détails de la Livraison**:</h2>
                        <p className='font-bold text-gray-600 ml-5'>Date de la livraison:<span className='font-normal pl-2'>25/09/2023</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Nom du livreur:<span className='font-normal pl-2'>MUKESHI Anita</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Numéro de suivi de la livraison:<span className='font-normal pl-2'>AXT</span></p>

                        <p className='font-bold text-gray-600 ml-5'>Numéro de commande:<span className='font-normal pl-2'>89</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Heure de Départ:<span className='font-normal pl-2'>10h 00min</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Heure Estimée d'Arrivée:<span className='font-normal pl-2'>11h00</span></p>
                        <p className='font-bold text-gray-600 ml-5'>Heure total:<span className='font-normal pl-2'>1h</span></p>
                    </div>

                    <div className='w-full mb-8'>
                        <h2 className='text-[20px] font-semibold'>** Produit(s) Reçu(s)**:</h2>
                        <table className=' w-full'>
                            <thead>
                                <tr className='bg-[white]'>
                                    <th className='border py-1 px-2'>Nom du Produit</th>
                                    <th className='border py-1 px-2'>Quantité reçue</th>
                                    <th className='border py-1 px-2'>Date de commande</th>
                                    <th className='border py-1 px-2'>État de l'article</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-b border-gray-200'>
                                    <td className='border-r border-gray-300 p-1'>Camion</td>
                                    <td className='border-r border-gray-300 p-1'>2</td>
                                    <td className='border-r border-gray-300 p-1'>20/09/2023 à 12h 50min</td>
                                    <td className='border-r border-gray-300 p-1'>En bon état</td>

                                </tr>
                                <tr className='border-b border-gray-200'>
                                    <td className='border-r border-gray-300'>Ordinateur</td>
                                    <td className='border-r border-gray-300'>5</td>
                                    <td className='border-r border-gray-300'>20/09/2023 à 15h 50min</td>
                                    <td className='border-r border-gray-300'>En bon état</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className=' w-full'>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div>
                                    <div className=' w-max cursor-pointer'>
                                        <label htmlFor='PhotosLivraison' className='cursor-pointer'>Photos de la livraison</label>
                                        <input id='PhotosLivraison' accept='image/*' onChange={(e) => getUrlPhotoLivraison(URL.createObjectURL(e.target.files[0]))} type='file' hidden />
                                    </div>
                                    <div onClick={() => { SetshowSignature(true) }} className={`w-[5em] ${UrlPhotoLivraison == '' ? null : 'h-[5em] border rounded-2xl cursor-pointer'} `}>
                                        <img src={`${UrlPhotoLivraison}`} />
                                    </div>

                                    <div className={`fixed w-full h-full  ${showSignature ? 'flex' : 'hidden'} justify-center items-center top-0 left-0 bg-[#05030383] z-[500000000000]`}>
                                        <di class='w-[95%] h-[95%] bg-white rounded-2xl relative'>
                                            <div onClick={() => { SetshowSignature(false) }} className='absolute top-1 right-1 w-[30px] rounded-full bi-x-circle flex justify-center items-center h-[30px] bg-orange-600 text-white cursor-pointer'></div>
                                            <img src={`${UrlPhotoLivraison}`} className='w-full h-full object-contain object-center' />
                                        </di>
                                    </div>

                                </div>
                            </div>

                            <div className="relative h-full w-full mt-[2em]">
                                <textarea
                                    value={isTextarea}
                                    onChange={(e) => {
                                        setIsTextarea(e.target.value);
                                        setAutoScrollHeight(e.target.scrollHeight);
                                        setValueTextarea(e.target.value.trim().length);

                                    }}
                                    placeholder=" "
                                    style={{ height: `${autoScrollHeight}px` }}
                                    className={`peer personalTextarea leading-none  w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent `}
                                />
                                <label
                                    className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                                >
                                    Suggestion sur la livraison terminée
                                </label>
                            </div>
                            <div className="relative h-11 w-full mt-5 ">
                                <input ref={codeRef}
                                    value={code}
                                    onInput={(e) => setcode(e.target.value)}
                                    className={`peer h-full w-full border-b-2 border-green-700 bg-transparent pt-4 font-serif text-1xl font-normal outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 ${animationClasscode}`}
                                    placeholder=" "
                                />
                                <label className="text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Code envoyer sur votre email valide
                                </label>
                            </div>
                            <div className='font-bold text-gray-600 mt-5'>Date de confirmation : <span className='font-normal pl-2'>Le 10/10/2023 à 10h 45min</span></div>
                            <div className="flex justify-end items-center mt-[20px]">
                                {spinnerButton ? (
                                    <>
                                        <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                            <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                /></div>
                                            <label htmlFor="send" className="w-max flex justify-end p-1 mx-1">
                                                <i itle="Confirmer" className="bi bi-send bg-orange-600 rounded cursor-pointer text-white"> </i>
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    <>

                                        <div className="flex bg-orange-600 rounded cursor-pointer pl-2 py-1 text-white">
                                            <input type="submit" id="send" value="Confirmer" className='cursor-pointer ' />
                                            <label htmlFor="send" className="w-max flex justify-end p-1 mx-1 cursor-pointer  ">
                                                <i title="Confirmer" className="bi bi-send bg-orange-600 pointer-events-none rounded cursor-pointer text-white"> </i>
                                            </label>
                                        </div>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>




            {/* pour le papier */}

            <div class="bg-white p-8  border rounded  w-max mx-auto">
                {/* <!-- Logo de l'Entreprise --> */}
                <div className='w-full text-center text-[3em] mb-10 font-serif italic underline'>Bon livraison</div>

                <div className='flex  items-center w-max justify-center'>
                    <div class="flex flex-col  justify-center items-center mb-4">

                        <div class="w-[10em] h-[10em] border rounded-2xl">
                            <img src="image/btr.png" alt="Logo de l'Entreprise" />
                        </div>

                        <div class="mb-8 pl-16">
                            <div>Nom de l'entreprise:</div>
                            <div>Adresse de l'entreprise:...</div>
                            <div>Téléphone de l'entreprise:....</div>
                            <div>Site Web de l'entreprise: <a className='text-blue-600' href='https://www.onex-s.com'>https://www.onex-s.com</a></div>
                        </div>
                    </div>

                    <div class="w-3/4 text-right">
                        <p class="text-sm mb-4"><strong>Numéro de Livraison: </strong> LD001</p>
                        <p class="text-sm mb-4"><strong>Date et Heure de Livraison: </strong> 2023-09-15 14:30</p>
                        <p class="text-sm mb-4"><strong>Nom du Livreur: </strong> Jane Smith</p>
                        <p class="text-sm mb-4"><strong>État de la Livraison: </strong> Réussie</p>
                    </div>
                </div>

                <hr class="my-4 border-t-2 border-gray-300" />

                {/* <!-- Détails du Destinataire --> */}
                <div class="mb-4">
                    <h2 class="text-xl font-semibold mb-2">Détails du Destinataire</h2>
                    <p class="text-sm"><strong>Nom du Destinataire: </strong> John Doe</p>
                    <p class="text-sm"><strong>Adresse de Livraison: </strong> 123 Main St, City A</p>
                    <p class="text-sm"><strong>Numéro de Téléphone du Destinataire: </strong> +257 89541309</p>
                </div>

                <hr class="my-4 border-t-2 border-gray-300" />

                {/* <!-- Détails de la Commande --> */}
                <div class="mb-4">
                    <h2 class="text-xl font-semibold mb-2">Détails de la Commande</h2>
                    <p class="text-sm">Liste des Articles:</p>
                    <div className='w-full mb-8'>
                        <table className=' w-full'>
                            <thead>
                                <tr className='bg-[white]'>
                                    <th className='border py-1 px-2'>Numéro de Commande</th>
                                    <th className='border py-1 px-2'>Nom du Produit</th>
                                    <th className='border py-1 px-2'>Quantité reçue</th>
                                    <th className='border py-1 px-2'>Date de commande</th>
                                    <th className='border py-1 px-2'>État de l'article</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-b border-gray-200'>
                                    <td className='border-r border-gray-300 p-1'>CMD001</td>
                                    <td className='border-r border-gray-300 p-1'>Camion</td>
                                    <td className='border-r border-gray-300 p-1'>2</td>
                                    <td className='border-r border-gray-300 p-1'>20/09/2023 à 12h 50min</td>
                                    <td className='border-r border-gray-300 p-1'>En bon état</td>

                                </tr>
                                <tr className='border-b border-gray-200'>
                                    <td className='border-r border-gray-300'>CMD006</td>
                                    <td className='border-r border-gray-300'>Ordinateur</td>
                                    <td className='border-r border-gray-300'>5</td>
                                    <td className='border-r border-gray-300'>20/09/2023 à 15h 50min</td>
                                    <td className='border-r border-gray-300'>En bon état</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p class="text-sm"><strong>Poids Total de la Commande: </strong> [Poids Total]</p>
                    <p class="text-sm"><strong>Prix Total de la Commande: </strong> [Prix Total]</p>
                    <p class="text-sm"><strong>Moyen de Paiement utilisé pour la Commande: </strong> [Moyen de Paiement]</p>
                </div>

                <hr class="my-4 border-t-2 border-gray-300" />

                {/* <!-- Détails du Livreur --> */}
                <div class="mb-4">
                    <h2 class="text-xl font-semibold mb-2">Détails du Livreur</h2>
                    <p class="text-sm"><strong>Nom du Livreur: </strong> Jane Smith</p>
                    <p class="text-sm"><strong>Type de Véhicule de Livraison Utilisé: </strong> Moto</p>
                    <p class="text-sm"><strong>Coordonnées GPS de l'Endroit de Livraison: </strong> [Coordonnées GPS]</p>
                    <p class="text-sm"><strong>Identifiant Unique du Livreur: </strong> G000HG</p>
                </div>

                <hr class="my-4 border-t-2 border-gray-300" />

                {/* <!-- Informations de Gestion --> */}
                <div class="mb-4">
                    <h2 class="text-xl font-semibold mb-2">Détails de la Livraison:</h2>
                    <p class="text-sm"><strong>Temps Total de Livraison: </strong> [Temps Total]</p>
                    <p class="text-sm"><strong>Distance Parcourue pour la Livraison: </strong> [Distance]</p>
                    <p class="text-sm"><strong>Heure de Départ du Livreur pour la Livraison: </strong> [Heure de Départ]</p>
                    <p class="text-sm"><strong>Heure Estimée d'Arrivée: </strong> [Heure d'Arrivée Estimée]</p>
                    <p class="text-sm"><strong>Numéro de Compte Client (si applicable): </strong> [Numéro de Compte]</p>
                    <p class="text-sm"><strong>Nom de la Société de Livraison (si applicable): </strong> [Nom de la Société]</p>

                </div>

                <hr class="my-4 border-t-2 border-gray-300" />

                {/* <!-- Notes et Instructions --> */}
                <div class="mb-4">
                    <h2 class="text-xl font-semibold mb-2">Notes et Instructions</h2>
                    <p class="text-sm">[Espace pour Ajouter des Notes Supplémentaires, des Instructions Spéciales ou des Informations Importantes]</p>
                </div>

                <hr class="my-4 border-t-2 border-gray-300" />

                {/* <!-- Pied de Page --> */}
                <div class="flex justify-between items-center">
                    <div className='w-[45%]'>
                        <p class="text-sm">Coordonnées de l'Entreprise:</p>
                        <p>Adresse: CARAMA</p>
                        <p>Téléphone: +275 9028 2683 23</p>
                        <p>E-mail: contact@onex-S.com</p>
                        <div class="w-[5em] h-[5em] border rounded-2xl">
                            <img src="image/btr.png" alt="Logo de l'Entreprise" />
                        </div>
                    </div>
                    <div className='w-[45%]'>

                        <p class="text-sm text-center">Signe ci-dessous pour confirmer la réception de la livraison</p>
                        <p class="text-sm">[Signature du Destinataire]    [Date de la Signature]</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmLivraison
