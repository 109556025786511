import React from 'react'
import DetailPoductor from './DetailPoductor'

export default function DetailPoductorComponent() {
    return (
        <>

            <div>
                <DetailPoductor />
            </div>
        </>
    )
}
