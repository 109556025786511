import React from "react";
import UpdateFormPlusOnly from "./UpdateFormPlusOnly";
function UpdatePhotoComponentOnly() {
  return (
    <>
      <div className="w-full  flex  h-max justify-center ">
        <UpdateFormPlusOnly />
      </div>
    </>
  );
}

export default UpdatePhotoComponentOnly;
