/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
import { toast } from 'react-toastify';
import swal from 'sweetalert2';

import { FadeLoader } from 'react-spinners'
function DepenseMarcheUserDetaill({ isdivScroll }) {
    const [boutLoading, setboutLoading] = useState(false);
    const { id } = useParams();

    const [loading, Setloaading] = useState(true)

    const [montant, setmontant] = useState('');

    const [description, setdescription] = useState('');




    const [depense, setDepense] = useState({});

    const formatDate = (dateString) => {
        const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit' };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('fr-FR', optionsDate);
        const formattedTime = date.toLocaleTimeString('fr-FR', optionsTime);

        return `Le ${formattedDate} à ${formattedTime}`;
    };
    const formatAmount = (amount) => {
        // Convertir le montant en nombre à virgule flottante
        const numberAmount = parseFloat(amount);

        // Vérifier si le montant est un nombre valide
        if (isNaN(numberAmount)) {
            return "Montant invalide";
        }

        // Formater le montant en utilisant les séparateurs de milliers et la virgule décimale
        const formattedAmount = numberAmount.toLocaleString('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formattedAmount;
    };
    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/depense/ById/${id}`).then((response) => {
            setDepense(response.data);
            setdescription(response.data.motif);
            setmontant(response.data.montant);
            setTimeout(() => {
                Setloaading(false)
            }, 1000);

        }).catch((error) => {
            console.log(error.message);
            if (error.response) {
                console.log(error.response.data)
            }
        });
    }, [id])





    useEffect(() => {
        setTimeout(() => {
            isdivScroll.current.style.scrollBehavior = 'smooth';
        }, 0);
    }, []);


    return (
        <div className='min-h-[30em]'>
            <div className='mt-3'>
                <div className='ml-5 rounded px-2 py-1 bg-orange-600 w-max items-center text-white flex justify-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                    </svg>
                    <Link to='/User/Depenses' className='ml-1 px-2 py-1 bg-orange-600 text-white'>Retour</Link>
                </div>
            </div>

            <div className="my-8">

                <section className="flex  justify-center">
                    <div className="bg-white  w-full  border border-orange-700 shadow-2xl rounded-2xl flex flex-col max-w-7xl sm:p-5 items-start">
                        <div className="py-2 flex flex-col items-start px-2 w-full">
                            <div>
                                <div className='overflow-hidden leading-6'>

                                    <p><b>Montant:</b> {formatAmount(depense.montant)} Fb</p>
                                    <p><b>Date du depense:</b> {depense && formatDate(depense.createdAt)}</p>
                                    <p className='flex'><b>description:</b><br /> <div className='text-gray-600'>{depense.motif}</div> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}

export default DepenseMarcheUserDetaill
