/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
import PropTypes from 'prop-types'
function OrderDetailUserMarcheur() {
    const [price, setPrice] = useState(0);
    const history = useNavigate();
    const { ida, idb } = useParams();
    const [Payment, setPayment] = useState(false);
    const [Shipping, setShipping] = useState(false);
    const [Received, setReceived] = useState(false);
    const [Commande, setCommande] = useState(true);
    const [loading, setLoading] = useState(true);
    const [imageLoading, SetImageLoading] = useState(true)
    const [data, setData] = useState({});
    const [status, setStatus] = useState("")
    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/market/seller/Orders/${ida}/${idb}`)
            .then((response) => {
                setData(response.data);
                setStatus(response.data.status)
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            }).catch((error) => {
                if (error.response) {
                    console.log(error.response.data);
                }
            });
        if (status === "pending") {
            setCommande(true);
        } else if (status === "payed") {
            setPayment(true)
        } else if (status == "shipping") {
            setShipping(true)
        } else if (status == "completed") {
            setReceived(true)
        }
    }, [ida, idb, status]);




    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };

    const [mobilem, Setmobile] = useState(window.innerWidth < 650)
    const [mobilem1, Setmobile1] = useState(window.innerWidth < 424)

    useEffect(() => {
        const hundleSIze = () => {
            Setmobile(window.innerWidth < 650)
            Setmobile1(window.innerWidth < 424)
        }
        window.addEventListener('resize', hundleSIze)
        return () => {
            window.removeEventListener('resize', hundleSIze)
        }
    }, [])
    return (
        <div>
            {loading && <SpinnerDemarage />}
            {data ? (
                <>
                    <div className="my-8">
                        <section className="flex flex-col md:flex-row  justify-center">
                            <div className="bg-gray-100 w-full shadow-lg border rounded-2xl flex flex-col max-w-5xl sm:p-5 items-center">
                                <div className="py-8 px-8 w-full">
                                    <h2 className="font-bold text-3xl text-orange-500">

                                        Détails d'expédition BTR
                                    </h2>
                                    <p className="text-sm mt-4 text-orange-500">

                                        Informations d'expédition et de livraison au client
                                    </p>

                                    <div
                                        className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4"
                                    >
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Pays</div><div class="px-4 py-2">{data.pays}</div></div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Province</div><div class="px-4 py-2">{data.province}</div></div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Commune</div><div class="px-4 py-2">{data.commune}</div></div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Zone</div><div class="px-4 py-2">{data.zone}</div></div>
                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Quartier</div><div class="px-4 py-2">{data.quartier}</div></div>

                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Avenue</div><div class="px-4 py-2">{data.avenue}</div></div>

                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">HouseNumber</div><div class="px-4 py-2">{data.housernamber}</div></div>


                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">MobileNumber</div><div class="px-4 py-2">{data.mobile}</div></div>


                                        </div>
                                        <div className="flex flex-row">
                                            <div class="flex flex-row"><div class="px-4 py-2 font-semibold">Description</div><div class="px-4 py-2">{data.description}</div></div>


                                        </div>
                                    </div>


                                    <hr class="h-px my-8 bg-gray-50 border-0 dark:bg-gray-700" />

                                    {data.commandemarketcontents ? (
                                        <div className="h-full ">
                                            <h1 className="mb-10 text-left text-2xl hidden font-bold">
                                                Tous les articles du panier
                                            </h1>
                                            <div className="">
                                                <h2 className="font-bold text-2xl text-orange-500">

                                                    Produits commandés
                                                </h2>

                                            </div>
                                            <hr class="h-px my-8 bg-gray-300 border-0 dark:bg-gray-700" />
                                            <div className="mx-auto max-w-2xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
                                                <div className="rounded-lg w-full">
                                                    {data.commandemarketcontents.map((e, index) => {
                                                        return (
                                                            <div className='relative overflow-hidden'>

                                                                <div className="justify-between mb-6 rounded-lg border border-gray-200 bg-gray-100 p-6 shadow-md sm:flex sm:justify-start">

                                                                    <div key={e.id} className='relative  h-full w-full '>
                                                                        {loadingImages[index] ? (
                                                                            <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                                        ) :
                                                                            (
                                                                                <LoadingImage
                                                                                    draggable='false'
                                                                                    src={`https://apiv1.burundientempsreel.com/uploads/market/${e.produit.profil}`}
                                                                                    alt="Image"
                                                                                    className=" w-full h-full  sm:w-40 object-contain object-center"

                                                                                    onLoad={() => handleImageLoad(index)}
                                                                                />
                                                                            )}

                                                                    </div>


                                                                    <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                                                        <div className="mt-5 sm:mt-0 md:w-48">

                                                                            <div className="flex flex-row justify-between "><h2 className="text-lg font-bold text-gray-900">
                                                                                {e.produit.titre.length >= 40
                                                                                    ? e.produit.titre.substring(0, 40) + "...."
                                                                                    : e.produit.titre}
                                                                            </h2>
                                                                            </div>
                                                                            <p className="mt-1 text-base text-gray-700">
                                                                                Quantite: {e.quantite}
                                                                            </p>
                                                                            <p className="mt-1 text-base text-gray-700">
                                                                                Prix utitaire: {Math.floor(e.produit.prix).toLocaleString("fr-FR")} Fbu
                                                                            </p>
                                                                            <p className="mt-1 text-base text-gray-700">
                                                                                Categories:   {e.produit.categorie}
                                                                            </p>
                                                                            <p className="mt-1 text-base text-gray-700">
                                                                                Déscription:   {e.produit.description.length >= 70
                                                                                    ? e.produit.description.substring(0, 70) + "...."
                                                                                    : e.produit.description}
                                                                            </p>

                                                                        </div>
                                                                        <div className="mt-4  flex  md:flex-row flex-row-reverse justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">

                                                                            <div className="flex items-center space-x-4">
                                                                                <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
                                                                                    <p class="text-xl text-primary font-semibold">
                                                                                        {Math.floor(e.quantite * e.produit.prix).toLocaleString("fr-FR")} Fbu
                                                                                    </p>
                                                                                    <p class="text-base text-gray-400 line-through">
                                                                                        {Math.floor((e.produit.prix * e.quantite) + ((e.quantite * e.produit.prix) * 0.2)).toLocaleString("fr-FR")} Fbu
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div class="relative mb-4 mt-8 flex max-w-xs flex-col space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                                    <p class="font-bold text-gray-400">
                                        Suivi du processus de livraison</p>
                                </div>

                                <div class="relative flex max-w-xs 0  sm:mb-8 flex-col w-full items-start space-y-3 rounded-2xl border border-gray-300 p-3 shadow-lg md:max-w-3xl md:space-x-5 md:space-y-0">
                                    <div class="relative flex max-w-xs w-full flex-col items-start space-y-3 rounded-2xl p-3 md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                                        {/* <!-- component --> */}
                                        <div class="w-full py-6">
                                            <div class="flex">
                                                <div class="w-1/4">
                                                    <div class="relative mb-2">
                                                        <div
                                                            class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Commande || Payment || Shipping || Received
                                                                ? " bg-orange-500"
                                                                : " border-2 border-gray-200 bg-white text-gray-600 "
                                                                }`}
                                                        >
                                                            <span class="w-full text-center text-white">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke-width="3"
                                                                    stroke="currentColor"
                                                                    class="w-full p-2"
                                                                >
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M4.5 12.75l6 6 9-13.5"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div class="text-center text-xs md:text-base">Commande</div>
                                                </div>

                                                <div class="w-1/4">
                                                    <div class="relative mb-2">
                                                        <div
                                                            class="align-center absolute flex content-center items-center align-middle"
                                                            style={{
                                                                width: " calc(100% - 2.5rem - 1rem)",
                                                                top: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                            }}
                                                        >
                                                            <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                                                                <div
                                                                    class={` rounded bg-orange-300 py-1 ${Commande || Payment || Shipping || Received ? "w-[100%]" : "w-[0%]"
                                                                        }`}
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Payment || Shipping || Received
                                                                ? " bg-orange-500"
                                                                : " border-2 border-gray-200 bg-white "
                                                                }`}
                                                        >
                                                            {Payment || Shipping || Received ? (
                                                                <span class="w-full text-center   text-white">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke-width="3"
                                                                        stroke="currentColor"
                                                                        class="w-full p-2"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M4.5 12.75l6 6 9-13.5"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            ) : (
                                                                <span class="w-full text-center  text-gray-600">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke-width="1.5"
                                                                        stroke="currentColor"
                                                                        class="p-2"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="text-center text-xs md:text-base">
                                                        Paiement</div>
                                                </div>

                                                <div class="w-1/4">
                                                    <div class="relative mb-2">
                                                        <div
                                                            class="align-center absolute flex content-center items-center align-middle"
                                                            style={{
                                                                width: " calc(100% - 2.5rem - 1rem)",
                                                                top: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                            }}
                                                        >
                                                            <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                                                                <div
                                                                    class={` rounded bg-orange-300 py-1 ${Payment || Shipping || Received ? "w-[100%]" : "w-[0%]"
                                                                        }`}
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Shipping || Received
                                                                ? " bg-orange-500"
                                                                : " border-2 border-gray-200 bg-white "
                                                                }`}
                                                        >
                                                            {Shipping || Received ? (
                                                                <span class="w-full text-center   text-white">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke-width="3"
                                                                        stroke="currentColor"
                                                                        class="w-full p-2"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M4.5 12.75l6 6 9-13.5"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            ) : (
                                                                <span class="w-full text-center  text-gray-600">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke-width="1.5"
                                                                        stroke="currentColor"
                                                                        class="p-2"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="text-center text-xs md:text-base">
                                                        Expédition</div>
                                                </div>

                                                <div class="w-1/4">
                                                    <div class="relative mb-2">
                                                        <div
                                                            class="align-center absolute flex content-center items-center align-middle"
                                                            style={{
                                                                width: "calc(100% - 2.5rem - 1rem)",
                                                                top: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                            }}
                                                        >
                                                            <div class="align-center w-full flex-1 items-center rounded bg-gray-200 align-middle">
                                                                <div
                                                                    class={` rounded bg-orange-300 py-1 ${Shipping || Received ? "w-[100%]" : "w-[0%]"
                                                                        }`}
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class={`mx-auto flex h-10 w-10 items-center rounded-full text-lg text-white ${Received
                                                                ? " bg-orange-500"
                                                                : " border-2 border-gray-200 bg-white text-gray-600 "
                                                                }`}
                                                        >
                                                            {Received ? (
                                                                <span class="w-full text-center   text-white">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke-width="3"
                                                                        stroke="currentColor"
                                                                        class="w-full p-2"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M4.5 12.75l6 6 9-13.5"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            ) : (
                                                                <span class="w-full text-center  text-gray-600">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke-width="1.5"
                                                                        stroke="currentColor"
                                                                        class="p-2"
                                                                    >
                                                                        <path
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div class="text-center text-xs md:text-base">
                                                        Reçue</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            ) : "jeftruweitgyesgyrhgyeosiu"}

        </div>
    )
}

export default OrderDetailUserMarcheur
