/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerDemarage from "../../SpinnerDemarage/SpinnerDemarage";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners'
function InscriptionSellerMarche({ photos, getThemes, SetSignPopUpSeller, SetLoginPopUp, SignPopUp, isVisitorLogged }) {
    const [boutLoading, setboutLoading] = useState(false);
    const [textarea, setTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');

    const [adrress, Setadrress] = useState('')
    const [animationClassadrress, setAnimationClassadrress] = useState('');
    const elemetRefadrress = useRef(null)

    const [CNI, setCNI] = useState('')
    const [animationClassCNI, setAnimationClassCNI] = useState('');
    const elemetRefCNI = useRef(null)

    const [PhotoAvantCNI, SetPhotoAvantCNI] = useState("")
    const [animationClassPhotoAvantCNI, setAnimationClassPhotoAvantCNI] = useState('');
    const elemetRefPhotoAvantCNI = useRef(null)

    const [PhotoAprestCNI, SetPhotoAprestCNI] = useState("")
    const [animationClassPhotoAprestCNI, setAnimationClassPhotoAprestCNI] = useState('');
    const elemetRefPhotoAprestCNI = useRef(null)

    const [MethodePaiemen, setMethodePaiemen] = useState('')
    const [animationClassMethodePaiemen, setAnimationClassMethodePaiemen] = useState('');
    const elemetRefMethodePaiemen = useRef(null)


    const [PhotoMagazin, SetPPhotoMagazin] = useState("")
    const [animationClassPhotoMagazin, setAnimationClassPhotoMagazin] = useState('');
    const elemetRefPhotoMagazin = useRef(null)

    const [NomMagazin, setNomMagazin] = useState('')
    const [animationClassNomMagazin, setAnimationClassNomMagazin] = useState('');
    const elemetRefNomMagazin = useRef(null)

    const [NIF, setNIF] = useState('')
    const [animationClassNIF, setAnimationClassNIF] = useState('');
    const elemetRefNIF = useRef(null)



    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)

    let [loading, setLoading] = useState(false);



    const [cnii, setcnii] = useState(null);
    const [cnio, setcnio] = useState(null);
    const [logo, setLogo] = useState(null);

    const id = localStorage.getItem("userid");
    const history = useNavigate();
    const [UserisSeller, SetUserisSeller] = useState(false);
    const [user, SetUser] = useState({});

    const handleSubmit = (e, values) => {
        e.preventDefault()
        if (textarea.trim() === '') {
            toast.warning("L'address  est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            return;
        }
        else if (CNI.trim() == '') {
            toast.warning("Le numéro national  d'identité est obligatoire !! ", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassCNI('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassCNI(' ')
            }, 3000)
            elemetRefCNI.current.focus();
            return
        }

        else if (PhotoAvantCNI == "") {
            toast.warning(`Photo face avant du C.N.I est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoAvantCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
            setTimeout(() => {
                setAnimationClassPhotoAvantCNI(' ')
            }, 5000)
            return
        }
        else if (PhotoAprestCNI == "") {
            toast.warning(`Photo face arrière du C.N.I est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoAprestCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
            setTimeout(() => {
                setAnimationClassPhotoAprestCNI(' ')
            }, 5000)
            return
        }
        else if (MethodePaiemen.trim() == "") {
            toast.warning("Votre methode de paiement est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassMethodePaiemen('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassMethodePaiemen(' ')
            }, 3000)
            elemetRefMethodePaiemen.current.focus()

            return
        }
        else if (PhotoMagazin == "") {
            toast.warning(`Photo du magazin ou logo est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoMagazin('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
            setTimeout(() => {
                setAnimationClassPhotoMagazin(' ')
            }, 5000)
            return
        }

        else if (NomMagazin.trim() == "") {
            toast.warning("Le nom du magazin est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassNomMagazin('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassNomMagazin(' ')
            }, 3000)
            elemetRefNomMagazin.current.focus()

            return
        }
        else if (NIF.trim() == "") {
            toast.warning("Le NIF est obligatoire !!", {
                autoClose: 2000,
                position: "top-center"
            });
            setAnimationClassNIF('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassNIF(' ')
            }, 3000)
            elemetRefNIF.current.focus()
            return
        }
        setboutLoading(true)
        const formData = new FormData();
        formData.append("usersId", id);
        formData.append("address", textarea);
        formData.append("CNI", CNI);
        formData.append("paymentmethod", MethodePaiemen);
        formData.append("nomshop", NomMagazin);
        formData.append("NIF", NIF);
        formData.append("logo", logo);
        formData.append("cnii", cnii);
        formData.append("cnio", cnio);

        axios.post(
            "https://apiv1.burundientempsreel.com/market/seller/autadd",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        ).then((response) => {
            toast.success("Vous avez déjà enregistre comme un vendeur ! ✅", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClick: handleClose,
            });
            history('/')
            const timer = setTimeout(() => {
                setLoading(false);
                SetSignPopUpSeller(false);
            }, 300);
            return () => clearTimeout(timer);
        }).catch((error) => {
            if (error.response) {
                const errorMessage = error.response.data.error;

                toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    onClick: handleClose,
                });
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
        }).finally(() => {
            setboutLoading(false)
        });
    }






    useEffect(() => {
        if (isVisitorLogged) {
            axios.get(`https://apiv1.burundientempsreel.com/market/usersnotseller/Byid/${id}`)
                .then((response) => {
                    SetUser(response.data);
                    const timer = setTimeout(() => {
                        setLoading(false);
                    }, 100);
                    return () => clearTimeout(timer);
                }).catch((error) => {
                    console.log(error.response.data.error);
                    if (error.response.data.error === "isSeller") {
                        SetUserisSeller(true);
                    }
                    setLoading(false);
                });
        }
    }, [isVisitorLogged, id, loading]);

    const handleClose = () => {
        toast.dismiss();
    };
    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false)
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);

    const [imageLoading, SetImageLoading] = useState(true)
    const [imageLoading1, SetImageLoading1] = useState(true)
    const [imageLoading3, SetImageLoading3] = useState(true)


    return (
        <>
            {loading && <SpinnerDemarage />}

            {UserisSeller ? (
                <div
                    className="login-popup fixed inset-0 z-[60] flex flex-col items-center justify-center" style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
                >                    <section class=" md:w-[50em] max-md:w-[90%] flex box-border justify-center items-center">
                        <div class={`${getThemes ? 'bg-black' : 'bg-gray-50'} shadow-lg border rounded-2xl flex max-w-3xl max-sm:p-2 p-5 items-center`}>
                            <div class=" max-sm:pb-2 max-sm:px-2  pb-8 px-8">
                                <div className="flex items-center  p-1 justify-end rounded-xl">
                                    <button onClick={() => { SetUserisSeller(false); SetSignPopUpSeller(false) }}
                                        className="ml-4  bg-orange-300 transition-all hover:bg-orange-800 hover:text-white font-semibold py-1 px-2 border border-orange-700 rounded shadow"
                                    > Ok</button>
                                </div>
                                <h2 class="font-bold max-sm:text-[20px] text-3xl text-center mb-2 text-gray-500">
                                    Vous êtes déjà vendeur contactez simplement <br /> <span className=" font-serif text-orange-700">Online Exchange Solutions !</span>
                                    <p>Vous devrez faire une presence physique</p>
                                </h2>

                            </div>
                        </div>
                    </section>
                </div>

            ) : (
                <div className={`fixed w-full h-full z-[2000000000000000] top-0 left-0 flex justify-center overflow-hidden items-center  ${getThemes ? 'bg-[#0000009a]' : 'bg-[#808080a8]'}`}>
                    <div className='w-full  h-full overflow-auto flex justify-center p-4'>
                        <div className={`${getThemes ? 'bg-black' : 'bg-white'} rounded-3xl p-2 border  border-orange-600 w-[35em] h-max`}>
                            <div className="flex justify-end px-4 items-center">
                                <div onClick={() => { SetSignPopUpSeller(false) }} className="bg-orange-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>
                            </div>
                            <div className="w-full h-max flex justify-center items-center"><div className="w-[10em] h-[10em] rounded-2xl border bg-white"><img src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${photos}`} className="w-full h-full object-contain object-center" /></div></div>
                            <div className="p-2   flex  ">
                                <div className={`mr-6 ${getThemes ? 'text-white' : 'text-gray-700'} text-[18px] `}>{user.nom} {user.prenom}</div>
                                <div className="text-gray-400 font-light text-[17px]">{user.email}</div>
                            </div>
                            <h2 className=' p-2 text-center text-[20px] font-serif text-gray-400 '>S'inscrire</h2>

                            <p className='text-[15px] text-gray-500 pl-3 text-center'>Entrez vos informations d'inscription si vous voulez etre un(e) vendeur(vendeuse) de notre site web</p>
                            <div className="p-2 relative my-2 rounded w-full  h-max ">
                                <form onSubmit={handleSubmit}>
                                    <div className="flex mb-4 flex-col">

                                        <div className="relative h-full w-full mt-[2em]">
                                            <textarea ref={textaresRef}
                                                value={textarea}
                                                onChange={(e) => {
                                                    setTextarea(e.target.value);
                                                    setAutoScrollHeight(e.target.scrollHeight);
                                                    setValueTextarea(e.target.value.trim().length);
                                                    if (!valueTextarea) {
                                                        setAutoScrollHeight(50);
                                                    }
                                                }}
                                                placeholder=" "
                                                style={{ height: `${autoScrollHeight}px` }}
                                                className={`peer ${getThemes ? 'text-white' : ''}  personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                            />
                                            <label
                                                className={`pointer-events-none absolute left-1 -top-[25px] ${getThemes ? 'text-white' : ''} peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-orange-gray-500`}
                                            >
                                                L'address physique
                                            </label>
                                        </div>

                                        <div className=" transition-all mt-5 flex  justify-between ">
                                            <div className={`flex items-center  relative w-full`}>
                                                <input value={CNI} ref={elemetRefCNI}
                                                    onInput={(e) => setCNI(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''}  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassCNI}`} placeholder=" "
                                                />
                                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                    Carte National d'identité
                                                </label>
                                            </div>
                                        </div>

                                        <div className=" mt-[10px] transition-all flex flex-wrap  ">
                                            <div class={`relative h-max mt-3 bg-gray-100 p-2 text-gray-700  mr-2 ${animationClassPhotoAvantCNI}`}>
                                                <input type='file' id="CNIAvant" hidden onChange={(e) => { const PhotosAvantCNI = e.target.files[0]; setcnii(PhotosAvantCNI); SetPhotoAvantCNI(URL.createObjectURL(PhotosAvantCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPhotoAvantCNI}`} />
                                                <label htmlFor="CNIAvant" class={`px-2  py-1 rounded cursor-pointer sm:text-center `}>
                                                    Photo face avant du C.N.I
                                                </label>
                                                <div className={` ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-gray-300 border-2 border-orange-500`}>
                                                    {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                                    <img draggable='false' onLoad={() => {
                                                        setTimeout(() => {
                                                            SetImageLoading(false)
                                                        }, 1000)
                                                    }} src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                                                </div>
                                            </div>
                                            <div class={`relative h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 ${animationClassPhotoAprestCNI}`}>
                                                <input type='file' id="CNIArrier" hidden onChange={(e) => { const PhotosApprestCNI = e.target.files[0]; setcnio(PhotosApprestCNI); SetPhotoAprestCNI(URL.createObjectURL(PhotosApprestCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500  ${animationClassPhotoAprestCNI}`} placeholder=" " />
                                                <label htmlFor="CNIArrier" class={` px-2 t max-sm:text-center py-1 rounded cursor-pointer `}>
                                                    Photo face arrière du C.N.I
                                                </label>
                                                <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0' : 'w-[12.5em] block h-[12.5em] opacity-100'} transition-all   mt-1 rounded  bg-gray-300 border-2 border-orange-500`}>
                                                    {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                                    <img draggable='false' onLoad={() => {
                                                        setTimeout(() => {
                                                            SetImageLoading1(false)
                                                        }, 1000)
                                                    }} src={PhotoAprestCNI} className="w-full h-full object-contain" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" transition-all  flex  mt-[20px] justify-between ">
                                            <div className={`flex items-center  relative w-full`}>
                                                <input value={MethodePaiemen} ref={elemetRefMethodePaiemen}
                                                    onInput={(e) => setMethodePaiemen(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''}  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassMethodePaiemen}`} placeholder=" "
                                                />
                                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                    Méthode de paiement
                                                </label>
                                            </div>
                                        </div>


                                        <div class={`relative h-max w-max bg-gray-100 text-gray-700 p-2   mt-[20px] ${animationClassPhotoMagazin}`}>
                                            <input type='file' id="logoShop" hidden onChange={(e) => { const PhotosSphop = e.target.files[0]; setLogo(PhotosSphop); SetPPhotoMagazin(URL.createObjectURL(PhotosSphop)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500`} placeholder=" " />
                                            <label htmlFor="logoShop" class={` px-2 py-1 rounded cursor-pointer`}>Photo du magazin ou logo</label>
                                            <div className={` ${!PhotoMagazin ? 'w-[0em] h-[0em] opacity-0' : 'w-[20em] block h-[20em] opacity-100 respPhotos'} transition-all    mt-1 rounded  bg-gray-300 border-2 border-orange-500 settWidthShp`}>
                                                {imageLoading3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                                <img draggable='false' onLoad={() => {
                                                    setTimeout(() => {
                                                        SetImageLoading3(false)
                                                    }, 1000)
                                                }} src={PhotoMagazin} className="w-full h-full object-contain" />
                                            </div>
                                        </div>

                                        <div className=" transition-all flex mt-3  justify-between  ">
                                            <div class="relative h-11 w-1/2  mr-2 ">
                                                <input ref={elemetRefNomMagazin} value={NomMagazin} onInput={(e) => setNomMagazin(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''} h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNomMagazin}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                    Nom du magazin
                                                </label>
                                            </div>

                                            <div class="relative h-11 w-1/2   mr-2 ">
                                                <input value={NIF} ref={elemetRefNIF}
                                                    onInput={(e) => setNIF(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''} h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNIF}`} placeholder=" "
                                                />
                                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                    NIF
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center">


                                        {boutLoading ? (
                                            <>
                                                <label className="  cursor-pointer w-max relative  flex justify-center  items-center   bg-blue-800 pointer-events-none   p-2 rounded  text-white">
                                                    <input type="submit" id="send" value='Creer' className='cursor-pointer' />
                                                    <i class="bi bi-send ml-2 "></i>
                                                    <div className='absolute bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                        <FadeLoader
                                                            color="rgb(255, 255, 255)"
                                                            height={10}
                                                            margin={-9}
                                                            radius={100}
                                                            speedMultiplier={1}
                                                            width={1}
                                                        /></div>
                                                </label>
                                            </>
                                        ) : (<>
                                            <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Créer' className='cursor-pointer'></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </label>
                                        </>)}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default InscriptionSellerMarche;