/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import { FadeLoader } from 'react-spinners'


import { Link, useLocation } from 'react-router-dom';


function SeconnectMArcheUser({
    SetLoginPopUp,
    SetSignPopUp,
    SetisMarcheUser,
    setAdminmarket,
    SetisVisitor,
    SetisLivreur,
    SetisInspecteur,
    SetisVisitorLogged,
    SetisSellerOnly,
    getThemes,
}) {

    const [boutLoading, setboutLoading] = useState(false);

    const history = useNavigate();
    localStorage.removeItem("userRole");
    const handleClose = () => {
        toast.dismiss();
    };

    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [emailValue, setEmailValue] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetRefEmail = useRef(null)

    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [passwordValue, setPasswordValue] = useState('')
    const [animationClassPassword, setAnimationClassPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const elemetRefPass = useRef(null)




    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false)
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);




    const handleSubmit = (e) => {
        e.preventDefault()

        if (emailValue.trim() == '') {
            toast.warning("L'address email est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-orange-500 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();
            return
        } else if (!emailValue.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();

            return
        } else if (passwordValue.trim() == "") {
            toast.warning("Le mot de passe est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 3000)
            elemetRefPass.current.focus()

            return
        } else if (!passwordValue.trim().match(PasswordRegex)) {

            toast.error(`Le mot de passe doit contenir au moins
                         une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                       `, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()
            return
        } else {
            setboutLoading(true)
            axios
                .post("https://apiv1.burundientempsreel.com/user/login", {
                    email: emailValue,
                    password: passwordValue
                })
                .then((response) => {
                    toast.success("Content vous te revoir! Vous êtes maintenant connecté!", {
                        position: "top-center",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClick: handleClose,
                    });

                    if (response.data.isAdminmarket === 1) {
                        setAdminmarket(true);
                        SetisVisitor(false);
                        localStorage.setItem("userRole", "admin");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        SetLoginPopUp(false);
                        history("/Admin/Market");
                    } else if (response.data.isSeller === 1) {
                        SetisMarcheUser(true);
                        SetisVisitor(false);
                        localStorage.setItem("userRole", "seller");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        SetLoginPopUp(false);
                        history("/seller");
                    } else if (response.data.isvendeur === 1) {
                        SetisSellerOnly(true);
                        SetisVisitor(false);
                        localStorage.setItem("userRole", "vendeur");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        SetLoginPopUp(false);
                        history("/UserOnly/Marche");
                    } else if (response.data.isLivreur === 1) {
                        SetisLivreur(true);
                        SetisVisitor(false);
                        localStorage.setItem("userRole", "livreur");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        SetLoginPopUp(false);
                        history("/liv");
                    } else if (response.data.isAgentTerait === 1) {
                        SetisInspecteur(true);
                        SetisVisitor(false);
                        localStorage.setItem("userRole", "agent");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        SetLoginPopUp(false);
                        history("/ins");
                    } else {
                        SetisVisitor(true);
                        SetisVisitorLogged(true);
                        localStorage.setItem("userRole", "Visitor");
                        localStorage.setItem("userid", response.data.userId);
                        localStorage.setItem("t", response.data.token);
                        document.body.style.overflow = "auto";
                        SetLoginPopUp(false);
                        history("/");
                    }


                })
                .catch((error) => {

                    if (error.response) {

                        const errorMessage = error.response.data.error;
                        setTimeout(() => {
                            toast.error(errorMessage, {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                onClick: handleClose,
                            });
                        }, 500);


                    } else if (error.request) {

                        console.log("Error from bac", error.request);
                    } else {

                        console.log("Error", error.message);
                    }
                }).finally(() => {
                    setTimeout(() => {
                        setboutLoading(false)
                    }, 1000);
                })

        }
    }
    const [imageLoading, SetImageLoading] = useState(true)

    return (
        <>

            <div className={`fixed w-full h-full z-[900000000000] top-0 left-0 flex justify-center overflow-hidden items-center ${getThemes ? 'bg-[#0000009a]' : 'bg-[#808080a8]'} `}>
                <div className='w-full  h-full overflow-auto flex justify-center items-center p-4'>
                    <div className={` ${getThemes ? 'bg-black' : 'bg-white'}  p-2  border shadow-2xl border-orange-600 w-[25em] rounded-3xl h-max`}>
                        <div className="flex justify-end px-1 items-center">
                            <div onClick={(e) => { SetLoginPopUp(false); e.stopPropagation() }} className="bg-orange-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <div className="h-[10em]  w-[10em] transition-all  relative overflow-hidden ml-3 rounded ">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }} src={`https://apiv1.burundientempsreel.com/uploads/logo/OnexS.png`} alt="" className='h-full w-full  object-cover object-center' />
                            </div>
                        </div>
                        <h2 className=' p-2 text-center  font-serif text-gray-400 '>Se connecter</h2>
                        <p className='text-[15px] text-gray-500 pl-3 text-center'>Si vous êtes déjà membre, connectez-vous facilement maintenant.</p>
                        <div className="p-2 my-2 rounded w-full  h-max">
                            <form onSubmit={handleSubmit}>
                                <div className="flex mb-6 flex-col ">
                                    <div className=" mt-[10px] transition-all  flex">
                                        <div class="relative h-11 w-full">
                                            <input value={emailValue} ref={elemetRefEmail}
                                                onInput={(e) => setEmailValue(e.target.value)} className={`peer ${getThemes ? 'text-white' : ''}  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                Email valide
                                            </label>
                                        </div>
                                    </div>

                                    <div className=" transition-all mt-5 flex  justify-between flexpass ">
                                        <div className={`flex items-center  w-full  relative gapps`}>
                                            <div class="relative h-11 w-full bloksInput mr-2 ">
                                                <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer ${getThemes ? 'text-white' : ''}  pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                                    Mot de passe
                                                </label>
                                            </div>
                                            <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] absolute flex justify-center top-[20px] items-center right-2 ">
                                                <i class={`bi ${passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'} ${getThemes ? 'text-white' : ''} absolute`}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center relative">
                                    {boutLoading ? (
                                        <>
                                            <label className="  cursor-pointer w-max relative  flex justify-center  items-center   bg-blue-800 pointer-events-none   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Se connecter' className='cursor-pointer' />
                                                <i class="bi bi-send ml-2 "></i>
                                                <div className='absolute bg-transparent pt-4   w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    /></div>
                                            </label>
                                        </>
                                    ) : (<>
                                        <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-700   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Se connecter' className='cursor-pointer'></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </>)}

                                </div>
                            </form>
                        </div>
                        <div className="pl-3 text-gray-500">Vous n'avez pas un compte ? <div onClick={() => { SetSignPopUp(true); SetLoginPopUp(false) }} className='text-orange-600 cursor-pointer underline'> S'inscrire</div></div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default SeconnectMArcheUser;














