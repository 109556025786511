/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useRef, useState } from "react";
import { BarLoader } from 'react-spinners'
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ADD } from "../../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dechiffrement } from "../../../../module";
function ProductDetail({ getThemes, isVisitorLogged, SetLoginPopUp }) {

  const getData = useSelector((state) => state.CartsReducer.Carts);
  const [LoadingPagePhotos, SetLoadingPagePhotos] = useState(true)
  const [LoadingPagePhotos1, SetLoadingPagePhotos1] = useState(true)
  const [LoadingPagePhotos2, SetLoadingPagePhotos2] = useState(true)
  const [LoadingPagePhotos3, SetLoadingPagePhotos3] = useState(true)
  const [LoadingPagePhotos4, SetLoadingPagePhotos4] = useState(true)
  const [LoadingPagePhotos5, SetLoadingPagePhotos5] = useState(true)
  const [LoadingPagePhotos6, SetLoadingPagePhotos6] = useState(true)

  const [mobile, Setmobile] = useState(window.innerWidth < 327.9292828290293)
  const [zoom, SetZoom] = useState(window.innerWidth < 768)

  const [Quantity, SetQuantity] = useState(1);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const hundleResize = () => {
      Setmobile(window.innerWidth < 327.9292828290293)
      SetZoom(window.innerWidth < 768)
    }

    window.addEventListener('resize', hundleResize)

    return () => {
      window.removeEventListener('resize', hundleResize)
    }
  }, [])


  function send(e, qty) {
    dispatch(ADD(e, qty));
    toast.success(
      `Felicitation vous avez ajouter ${e.titre} de nos produit au panier`,
      {
        position: "top-center",
        autoClose: 1000,
      }
    );
  }


  if (Quantity === 0) {
    navigate('/')
  }
  const { idp } = useParams();
  const id = dechiffrement(idp);
  const [product, setProduct] = useState({});
  const [photo, setPhoto] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/user/getonproduct/${id}`)
      .then((response) => {
        setProduct(response.data);
        setPhoto(response.data.produitfiles);
      });
  }, [id]);

  localStorage.setItem("categorysimulation", product.categorie);
  localStorage.setItem("idsimulation", product.id);



  function AddtoCarts() {

    if (isVisitorLogged) {


      if (getData.length) {
        const itemesIndex = getData.findIndex(
          (iteam) => iteam.id === product.id
        );

        if (itemesIndex != -1) {
          if (product.quanite > (getData[itemesIndex].quantity + Quantity)) {
            send(product, Quantity)

          }
          else {
            toast.error(`Vous avez déjà une limite de produits de ${product.titre}`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              onClick: handleClose,
            });
          }
        }
        else { send(product, Quantity) }

      }
      else {
        send(product, Quantity)

      }

    }
    else {
      SetLoginPopUp(true)

    }
  }











  const handleClose = () => {
    toast.dismiss();
  };

  const LazyImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };

    const lazyLoad = () => {
      setImgSrc(src);
    };


    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };


  const [zoomedImageStyle, setZoomedImageStyle] = useState({});
  const [showZoomBox, setShowZoomBox] = useState(false);
  const zoomFactor = 2;

  const handleMouseMove = (e) => {
    const { top, left, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomedImageStyle({
      transform: `scale(${zoomFactor})`,
      transformOrigin: `${x * 100}% ${y * 100}%`,
    });

    setShowZoomBox(true);
  };

  const handleMouseLeave = () => {
    setShowZoomBox(false);
  };



  const [troisBlock1, settroisBlock1] = useState(window.innerWidth < 843)
  const [motPayement, setmotPayement] = useState(window.innerWidth < 699)

  const [troisBlock2, settroisBlock2] = useState(window.innerWidth < 310)
  useEffect(() => {
    const handleResize = () => {
      setmotPayement(window.innerWidth < 699)
      settroisBlock1(window.innerWidth < 843)
      settroisBlock2(window.innerWidth < 310)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])





  return (
    <div className={`${getThemes ? 'bg-black' : 'bg-gray-200'} m-4  pb-10 pt-10 elative rounded-2xl `}>
      <div class={` ${getThemes ? 'bg-black' : 'bg-gray-200'}  flex  gap-6 ${troisBlock1 ? ' flex-col' : ''}  r p-3`}>
        <div className="w-full">
          <div class="relative z-0 h-72 border border-orange-600 rounded-2xl overflow-hidden   md:h-96  ">
            {LoadingPagePhotos && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              draggable='false'
              src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
              alt="product 1"
              class="object-cover object-center h-full w-full rounded-2xl"
              onLoad={
                () => {
                  setTimeout(() => {
                    SetLoadingPagePhotos(false)
                  }, 1000)
                }
              }
            />
          </div>
          <div className=" rounded-lg pl-2 h-max top-5 relative w-full ">
            <span className="font-serif font-bold text-gray-600">Autres Photos </span>
            <div className="w-full flex">


              {photo.map((photo, index) => {
                return (
                  <div className={`${motPayement ? 'w-10 rounded h-10' : 'w-20 h-20 rounded-lg'} bg-orange-600 m-1 cursor-pointer overflow-hidden border border-orange-700`}><img draggable='false' className="w-full h-full object-contain bg-white object-center" src={`https://apiv1.burundientempsreel.com/uploads/market/${photo.file}`} /> </div>

                );
              })}
            </div>
          </div>

          {showZoomBox && (
            <div className={` ${troisBlock1 ? 'hidden' : 'block'}  w-[50%] shadow-2xl  bg-white border border-orange-500  pointer-events-none absolute rounded z-[100] overflow-hidden  <div class="relative  h-72   md:h-96  top-10 right-2 `}>
              <div
                className=""
                style={zoomedImageStyle}
              >
                <img
                  src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
                  className='w-full h-full object-contain object-center'
                />
              </div>
            </div>
          )}
        </div>

        <div className={`${getThemes ? 'text-white' : 'text-gray-800'} `}>

          {product.titre ? (
            <>
              <h4 class="font-medium text-xs md:text-3xl mb-2">{product.titre}</h4>

            </>
          ) : (
            <>
              <BarLoader color="blue" width={30} height={2} />
            </>
          )}

          <div class="space-y-2">
            <p class=" text-xs md:text-base font-semibold space-x-2">
              <span>Disponibilité: </span>
              <span class="text-olive-500">
                {product.quanite ? (
                  <span className={`${getThemes ? 'text-gray-300' : 'text-gray-600'}`}>
                    {product.quanite} articles en stock
                  </span>
                ) : (
                  <>
                    <BarLoader color="blue" width={30} height={2} />
                  </>
                )}

              </span>
            </p>
            <p class="space-x-2 text-xs md:text-base">
              <span class=" font-semibold">Categories: </span>


              {product.categorie ? (
                <>
                  <span class={`${getThemes ? 'text-gray-300' : 'text-gray-600'}`}>{product.categorie}</span>
                </>
              ) : (
                <>
                  <BarLoader color="blue" width={30} height={2} />
                </>
              )}

            </p>
          </div>
          <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
            <p class="text-base text-orange-500 md:text-xl text-primary font-semibold">




              {product.prix ? (
                <>
                  {product.prix}
                </>
              ) : (
                <>
                  <BarLoader color="blue" width={30} height={2} />
                </>
              )}
              Fbu
            </p>
            <p class="text-sm text-right md:text-base text-red-400 line-through">

              {product.prix ? (
                <>
                  {product.prix + (product.prix * 20) / 100}
                </>
              ) : (
                <>
                  <BarLoader color="blue" width={30} height={2} />
                </>
              )}
              Fbu
            </p>
          </div>

          {product.description ? (
            <>
              <p class={`mt-4 text-xs  font-bold ${getThemes ? 'text-white' : 'text-gray-800 '} md:text-base `}>Déscription du produit:<br /><span className={`${getThemes ? 'text-gray-300' : 'text-gray-600'} font-normal`}>

                {product.description}


              </span></p>

            </>
          ) : (
            <>
              <BarLoader color="blue" width={30} height={2} />
            </>
          )}
          <div class="mt-4">
            <h3 class="text-xs md:text-sm  mb-1">Quantites</h3>
            <div class="flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max">




              <div
                onClick={() => {
                  if (Quantity > 0) {
                    SetQuantity(Quantity - 1);
                  };

                }}

                class="h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none"
              >
                -
              </div>
              <div class="h-6 w-6 md:h-8 md:w-8 text-base flex items-center justify-center">
                {Quantity ? (
                  <>
                    {Quantity}
                  </>
                ) : (
                  <>
                    <BarLoader color="blue" width={30} height={2} />
                  </>
                )}

              </div>
              <div
                onClick={() => {
                  if (Quantity < product.quanite) {
                    SetQuantity(Quantity + 1);
                  }
                  else {
                    toast.error("Vous avez atteint la limite maximale de quantité de stock ", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      onClick: handleClose,
                    });
                  }
                }}
                class={` h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none`}
              >
                +
              </div>
            </div>
            <div className=" hidden items-center border-gray-500">
              <span
                onClick={() => {
                  if (Quantity > 0) {
                    SetQuantity(Quantity - 1);
                  }
                }}
                className="cursor-pointer rounded-l bg-gray-200 py-1 px-3.5 duration-500 hover:bg-orange-500 hover:text-orange-50"
              >
                {" "}
                -{" "}
              </span>
              <input
                className="h-8 w-8 border bg-white text-center text-xs outline-none"
                type="number"
                value={Quantity}
                min="1"
              />
              <span
                onClick={() => {
                  SetQuantity(Quantity + 1);
                }}
                className="cursor-pointer rounded-r bg-gray-200 py-1 px-3 duration-500 hover:bg-orange-500 hover:text-orange-50"
              >
                {" "}
                +{" "}
              </span>
            </div>
          </div>

          <div class="mt-3 md:mt-6 flex flex-col md:flex-row  gap-3  border-b border-gray-200 pb-5 pt-5">
            <button
              onClick={AddtoCarts}
              class=" w-max py-2  flex px-4  justify-center items-center  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart4 mr-3" viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
              </svg>
              <span> Ajouter au panier</span>
            </button>
            {isVisitorLogged ? <Link
              to="/Orders"
              onClick={AddtoCarts}
              class="flex justify-center items-center w-max px-4  py-2  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart4 mr-3" viewBox="0 0 16 16">
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
              </svg>
              <span> Acheter maintenant</span>

            </Link>
              :
              <Link
                onClick={AddtoCarts}
                class="flex justify-center items-center w-mx  py-2  px-4 text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
              >
                Acheter maintenant
              </Link>}
          </div>








        </div>


      </div>
      <div class="flex flex-col items-center   justify-center mt-9 w-full  ">
        <p class={` font-serif mt-2 italic ${motPayement ? 'text-center text-[25px]' : 'text-[30px]'}  font-bold`}>Methode de paiement:</p>
        <p className={`${motPayement ? 'w-[95%] ' : 'w-[40em] '} text-gray-600 font-sans text-center`}>
          Les moyens de paiement en ligne offrent aux clients une manière pratique et accessible de régler leurs achats. Les clients peuvent effectuer des paiements depuis chez eux, au travail ou même en déplacement, ce qui simplifie le processus d'achat.
        </p>

        <div class={`  mt-8 ${mobile ? 'grid grid-cols-2' : 'flex-wrap flex justify-center items-center'}  gap-2`}>
          <a href="#" class={` ${mobile ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
            {LoadingPagePhotos1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false'
              className="rounded-xl object-contain w-full h-full"
              onLoad={() => {
                SetLoadingPagePhotos1(false)
              }}
              src="../../../image/ecocashe.jpg" />
          </a>

          <a href="#" class={` ${mobile ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-gray-200 rounded-2xl`}>
            {LoadingPagePhotos2 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false'
              className="rounded-xl object-contain w-full h-full"
              onLoad={() => {
                SetLoadingPagePhotos2(false)
              }} src="../../../image/lumitel.png" />
          </a>
          <a href="#" class={` ${mobile ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-white rounded-2xl`}>
            {LoadingPagePhotos3 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false'
              className="rounded-xl object-contain w-full h-full"
              onLoad={() => {
                SetLoadingPagePhotos3(false)
              }}
              src="../../../image/mobinotel.png"
            />
          </a>
          <a href="#" class={` ${mobile ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-white rounded-2xl`}>
            {LoadingPagePhotos4 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false'
              className="rounded-xl object-contain w-full h-full"
              onLoad={() => {
                SetLoadingPagePhotos4(false)
              }}
              src="../../../image/feza.png"
            />
          </a>
          <a href="#" class={` ${mobile ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-white rounded-2xl`}>
            {LoadingPagePhotos5 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false'
              className="rounded-xl object-contain w-full h-full scale-[1.3]"
              onLoad={() => {
                SetLoadingPagePhotos5(false)
              }} src="../../../image/New-logo-CECM-01.jpg" />
          </a>
          <a href="#" class={` ${mobile ? 'w-24 p-1 h-24' : 'w-[7em] p-1 h-[7em]'} flex relative overflow-hidden justify-center items-center  bg-white rounded-2xl`}>
            {LoadingPagePhotos6 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img draggable='false'
              className="rounded-xl object-contain w-full h-full"
              onLoad={() => {
                SetLoadingPagePhotos6(false)
              }} src="../../../image/BancobueNoti.jpg" />
          </a>



        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
