/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import Empty from '../../../User/UserMarket/Empty/Empty';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';
function HistoryAdminMarket() {
    const history = "historique"
    const [listoffOrders, setListOffOrders] = useState([]);
    const [element, setElement] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setloading] = useState(true);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (event) => {
        const selectedItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    const firstEntry = (currentPage - 1) * itemsPerPage + 1;
    const lastEntry = Math.min(currentPage * itemsPerPage, element);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        axios
            .get(`https://apiv1.burundientempsreel.com/market/admin/historyorders?page=${currentPage}&size=${itemsPerPage}`, {
                headers: {
                    accessToken: localStorage.getItem("t"),
                }
            })
            .then((response) => {
                setElement(response.data.totalItems);
                setListOffOrders(response.data.orders);
                setTotalPages(response.data.totalPages);
                setTimeout(() => {
                    setloading(false)
                }, 1000);
            }).catch((err) => {
                console.log(err.message);
                console.log(err.response);
            })
    }, [currentPage, itemsPerPage]);




    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const filterData = (value, searchValue) => {
        if (!searchValue.trim()) {
            return value; // Aucune recherche en cours, retourner toutes les données
        }

        return value.filter((value) => {

            if (value.id.toString().includes(searchValue)) {
                return true;
            }

            // Vérifier si le montant payé (colonne "Montant payé") contient la valeur de recherche
            if (value.prixtotal.toString().includes(searchValue)) {
                return true; // Correspondance trouvée dans la colonne "Montant payé"
            }


            // Vérifier si le statut (colonne "Status") contient la valeur de recherche
            if (value.status.toLowerCase().includes(searchValue.toLowerCase())) {
                return true; // Correspondance trouvée dans la colonne "Status"
            }

            // Vérifier si la date (colonne "Date") contient la valeur de recherche
            const formattedDate = new Date(value.createdAt).toLocaleDateString('en-US') + ' ' + new Date(value.createdAt).toLocaleTimeString('en-US');
            if (formattedDate.includes(searchValue)) {
                return true; // Correspondance trouvée dans la colonne "Date"
            }

            // Aucune correspondance trouvée dans toutes les colonnes
            return false;
        });
    };

    const filteredData = filterData(listoffOrders, searchValue);

    const [isMobile1, setIsMobile1] = useState(window.innerWidth < 818);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile1(window.innerWidth < 818);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);



    return (
        <div className='min-h-[90vh]'>
            {loading && <SpinnerDemarage />}
            {listoffOrders.length > 0 ? (
                <div class="bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">

                    <div class=" mx-auto px-4 ">
                        <div class="py-8">
                            <div>
                                <h2 class="text-2xl font-semibold  text-orange-500 leading-tight">
                                    Historique des commandes
                                </h2>
                            </div>
                            <div class="my-2 flex flex-row ">
                                <div class="flex flex-row mb-1 sm:mb-0">
                                    <div className="relative">
                                        <select
                                            className="appearance-none h-full rounded-l-2xl border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            value={itemsPerPage}
                                            onChange={handleItemsPerPageChange}
                                        >
                                            <option>5</option>
                                            <option>10</option>
                                            <option>20</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                className="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div class="relative">
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                class="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="block relative">
                                    <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                        <svg
                                            viewBox="0 0 24 24"
                                            class="h-4 w-4 fill-current text-black"
                                        >
                                            <path d="M10 4a6 6 0100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                                        </svg>
                                    </span>
                                    <input
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                        placeholder="Recherche.."
                                        class="appearance-none rounded-r-2xl rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                    />
                                </div>
                            </div>
                            <div class=" px-4  overflow-x-auto">
                                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table class={` ${isMobile1 ? 'w-[50em]' : 'min-w-full'} leading-normal`}>

                                        <thead>
                                            <tr>
                                                <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                    Numéro de commande
                                                </th>
                                                <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                    Montant payé
                                                </th>
                                                <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                    Statut
                                                </th>
                                                <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                    Date
                                                </th>

                                                <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>


                                        <tbody>
                                            {filteredData.length === 0 ? (<>
                                                <tr >
                                                    <td colSpan="6"><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                                                        Aucune correspondance à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                                                    </div></td>
                                                </tr>
                                            </>) : (<></>)
                                            }
                                            {filteredData && (
                                                filteredData.map((value, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                <p class="text-gray-900 whitespace-no-wrap">
                                                                    {value.id}
                                                                </p>
                                                            </td>
                                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                <p class="text-gray-900 whitespace-no-wrap">
                                                                    {value.prixtotal}</p>
                                                            </td>
                                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                <p class="text-gray-900 whitespace-no-wrap">
                                                                    {value.status === "pending" && (
                                                                        <span class="bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-5 py-2 dark:bg-gray-700 dark:text-orange-300 rounded-2xl border border-orange-300">pending</span>
                                                                    )}
                                                                    {value.status === "payed" && (
                                                                        <span class="bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-5 py-2 dark:bg-gray-700 dark:text-orange-300 rounded-2xl border border-orange-300">payed</span>
                                                                    )}
                                                                    {value.status === "shipping" && (
                                                                        <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-5 py-2 dark:bg-gray-400 dark:text-green-800 rounded-2xl border border-green-400">Shipping</span>
                                                                    )}
                                                                    {value.status === "completed" && (
                                                                        <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-5 py-2 dark:bg-gray-700 dark:text-green-400 rounded-2xl border border-green-400">completed</span>
                                                                    )}
                                                                    {value.status === "refused" && (
                                                                        <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-5 py-2 dark:bg-gray-700 rounded-2xl dark:text-red-400 border border-red-400">refused</span>
                                                                    )}



                                                                </p>
                                                            </td>

                                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                <p class="text-gray-900 whitespace-no-wrap">

                                                                    <tr >
                                                                        {new Date(value.createdAt).toLocaleDateString('en-US') + ' ' + new Date(value.createdAt).toLocaleTimeString('en-US')}
                                                                    </tr>

                                                                </p>
                                                            </td>


                                                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                                <span class="relative inline-block  py-1 font-semibold text-green-900 leading-tight">
                                                                    <span
                                                                        aria-hidden
                                                                        class="absolute inset-0  opacity-50 rounded-full"
                                                                    ><Link to={`/Admin/market/History/Detail/${value.id}`} class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-orange-500 hover:text-white focus:outline-none">
                                                                            Details
                                                                        </Link>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }))}
                                        </tbody>


                                    </table>

                                </div>
                            </div>
                            <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Affichage de {firstEntry} à {lastEntry} sur {element} entrées     </span>

                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                    <button
                                        className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Suiv
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            ) : (<>
                <div className='flex border justify-center items-center h-[75vh]'>
                    <Empty items={history} />
                </div>
            </>)}
        </div>
    )
}

export default HistoryAdminMarket
