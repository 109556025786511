
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
function LivreursAdminDetail() {

    const { id } = useParams()
    const [livreur, setLivreur] = useState({})
    const [mobile, SetMobile] = useState(window.innerWidth < 774)

    useEffect(() => {

        const hundleSize = () => {
            SetMobile(window.innerWidth < 774)

        }
        window.addEventListener('resize', hundleSize)
        return () => {

            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    useEffect(() => {
        axios
            .get(`https://apiv1.burundientempsreel.com/market/admin/getLivreurById/${id}`)
            .then((response) => {
                setLivreur(response.data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [id]);

    function formatDateStringToReadableDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    }

    return (
        <div className="min-h-[90vh] pt-2 select-none">

            <Link className=" px-2 py-1 mt-2 ml-2 text-white bg-orange-700 rounded" to='/Admin/Livreurs'>Retour</Link>
            <div class=" bg-white mt-3  text-gray-700 border-b shadow mb-6">

                <div class="w-full flex p-4 flex-col  items-center">
                    <div class="relative my-4 flex  flex-col space-y-3 rounded-2xl  md:max-w-3xl md:flex-row md:space-x-5 md:space-y-0">
                        <div>
                            <h2 class="text-[30px] font-semibold text-left  leading-tight">
                                Informations sur le livreur
                            </h2>
                        </div>
                    </div>
                    <div class="relative flex w-full flex-col  items-start space-y-3 rounded-2xl border border-gray-300  shadow-lg ">

                        <div className={`flex justify-center ${mobile ? 'flex-col p-4' : ''}  w-full`}>

                            <div class="bg-white p-3 border border-orange-700 mt-4 shadow-sm rounded-2xl">
                                <div className='w-full text-center font-bold text-xl mb-3'>Information personnel</div>

                                <div class="flex items-center  justify-center">
                                    <div class="flex-shrink-0 border rounded-xl w-32 h-32">

                                        <img
                                            draggable='false'

                                            src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${livreur.photo}`}

                                            className=" w-full h-full rounded object-contain object-center"

                                        />

                                    </div>

                                </div>
                                <div class="text-gray-700">
                                    <div class="grid text-sm">
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 ">
                                                Nom
                                            </div>
                                            <div class="px-4 py-2 font-bold">{livreur.nom}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 ">
                                                Prenom
                                            </div>
                                            <div class="px-4 py-2  font-bold">{livreur.prenom}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 ">
                                                Tel
                                            </div><div class="px-4 py-2  font-bold">{livreur.tel}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">
                                                Address
                                            </div>
                                            <div class="px-4 py-2  font-bold">
                                                {livreur.address}
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="px-4 py-2 font-semibold">
                                                Email.
                                            </div>
                                            <div class="px-4 py-2  font-bold">
                                                <a
                                                    class="text-orange-800"
                                                    href={`mailto: ${livreur.email}`}
                                                >
                                                    {livreur.email}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {livreur.livreur && (
                                <div class="bg-white p-3 border border-orange-700 mt-4 ml-3 shadow-sm rounded-2xl">
                                    <div className='w-full text-center font-bold text-xl mb-3'>Information professionel</div>
                                    <div class="grid text-sm">
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">
                                                Nom de la société
                                            </div>
                                            <div class="px-4 py-2  font-bold"> {livreur.livreur.societe}</div>
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">
                                                NIF
                                            </div>
                                            <div class="px-4 py-2 font-bold uppercase">{livreur.livreur.NIF}</div>
                                        </div>

                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">
                                                Moyen de déplacement
                                            </div>
                                            <div class="px-4 py-2  font-bold">{livreur.livreur.moyenT}</div>
                                        </div>

                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 lowercase first-letter:uppercase font-semibold">
                                                Numéro RC
                                            </div>
                                            <div class="px-4 py-2  font-bold">{livreur.livreur.RC}</div>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-2">
                                        <div class="px-4 py-2 lowercase first-letter:uppercase font-semibold">
                                            Numero Compte
                                        </div>
                                        <div class="px-4 py-2  font-bold">
                                            {livreur.livreur.compte}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>


                        <div class="bg-white rounded-lg shadow-md w-full  p-6">
                            <h2 class="text-xl font-bold mb-4">Liste des livraisons effectuées par NDAYIZEYE Telecom</h2>
                            <div className='w-full overflow-x-auto overflow-y-hidden'>
                                <div className='w-[100em]'>
                                    <table class="w-full">
                                        <thead >
                                            <tr>
                                                <th class=" border px-4 py-2 text-left">Numéro de commande</th>
                                                <th class=" border px-4 py-2 text-left">Produit</th>
                                                <th class=" border px-4 py-2 text-left">Quantite</th>
                                                <th class=" border px-4 py-2 text-left">Adresse de livraison</th>
                                                <th class=" border px-4 py-2 text-left">Date de commande</th>
                                                <th class=" border px-4 py-2 text-left">Date de livraison</th>
                                                <th class=" border px-4 py-2 text-left">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {livreur.livreur && livreur.livreur.livrasons && livreur.livreur.livrasons.map((livraison, index) => (
                                                <tr key={index}>
                                                    <td class="border px-4 py-2">{livraison.commandemarketsId}</td>
                                                    <td class="border px-4 py-2">{livraison.commandemarket.commandemarketcontents[0].produit.titre}</td>
                                                    <td class="border px-4 py-2">{livraison.commandemarket.commandemarketcontents[0].quantite}</td>
                                                    <td class="border px-4 py-2">{livraison.commandemarket.zone}, {livraison.commandemarket.quartier}, {livraison.commandemarket.commune}, {livraison.commandemarket.province}, {livraison.commandemarket.pays}</td>


                                                    <td class="border px-4 py-2">{formatDateStringToReadableDate(livraison.commandemarket.createdAt)}</td>

                                                    <td class="border px-4 py-2">{formatDateStringToReadableDate(livraison.updatedAt)}</td>

                                                    <td class="border px-4 py-2">
                                                        <Link to={`/Admin/Livreurs/Details/Detail/${livraison.commandemarketsId}`}>Détail</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default LivreursAdminDetail
