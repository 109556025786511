/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import { chiffrement, dechiffrement } from '../../../../module'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment';
import Empty from '../Empty/Empty'
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage'
function DetteMarcheUser() {
    const { pathname } = useLocation()

    const [data, setData] = useState([])
    const [loading, Setloading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(36);

    const [ascending, setAscending] = useState(true);
    const [sortColumn, setSortColumn] = useState(null);
    const [datas, setdatas] = useState(data);



    const handleItemsPerPageChange = (membre) => {
        const selectedItemsPerPage = parseInt(membre.target.value);
        setItemsPerPage(selectedItemsPerPage);
    };
    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/autovente/alldettes?page=${currentPage}&size=${itemsPerPage}`).then((response) => {
            const { dettes, totalPages } = response.data;
            setData(dettes);

            setTotalPages(totalPages);
            setTimeout(() => {
                Setloading(false)
            }, 1000);
        }).catch((error) => {
            console.log(error.message)
            if (error.response) {
                console.log(error.response.data)
            }
        })
    }, [currentPage, itemsPerPage])


    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1)
        }
    }

    // Fonction pour passer à la page précédente
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page)
    }
    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = []
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={
                        currentPage === i
                            ? 'text-sm font-medium leading-none cursor-pointer bg-orange-600 text-white w-[30px] h-[30px] pb-[20px] rounded-lg dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
                            : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-2xl dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-orange-400 pt-3 mr-4 px-2'
                    }
                >
                    {i}
                </button>,
            )
        }
        return pageNumbers
    }

    const [searchValue, setSearchValue] = useState('');
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const filterData = (data, searchValue) => {
        if (!searchValue.trim()) {
            return data;
        }

        return data.filter((item) => {
            // Parcourir toutes les propriétés de l'objet client
            for (const key in item.vente.client) {
                const value = item.vente.client[key];

                // Vérifier si la valeur est une chaîne de caractères
                if (typeof value === 'string' && value.includes(searchValue)) {
                    return true; // Correspondance trouvée dans une colonne de type chaîne de caractères
                }
            }

            // Gestion spécifique pour la colonne "Date"
            const dateValue = item.vente.createdAt;
            const formattedDate = moment(dateValue).locale('fr').format('DD/MM/YYYY HH:mm:ss');
            if (formattedDate.includes(searchValue)) {
                return true; // Correspondance trouvée dans la colonne "Date"
            }

            // Gestion spécifique pour la colonne "Dette" (comme un nombre)
            const montantValue = item.montant.toString();
            if (montantValue.includes(searchValue)) {
                return true; // Correspondance trouvée dans la colonne "Dette"
            }

            // Aucune correspondance trouvée dans toutes les colonnes
            return false;
        });
    };

    const filteredData = filterData(data, searchValue);

    const [sizeTable, SetSizeTable] = useState(window.innerWidth < 967)
    useEffect(() => {
        const hundleSize = () => {
            SetSizeTable(window.innerWidth < 967)

            window.addEventListener('resize', hundleSize)

            return () => {
                window.removeEventListener('resize', hundleSize)
            }
        }
    }, [])

    return (
        <div className='min-h-[90vh] '>
            {loading && <SpinnerDemarage />} {
                data.length > 0 ? (
                    <>

                        <div class={` flex flex-col px-2 items-end`}>

                            <div className={`h-11 flex mt-2  justify-between ${sizeTable ? '' : 'mr-3'} `}>
                                <div className="w-full h-10 overflow-hidden">
                                    <form className='flex  h-full  w-full rounded-l-[50px] '>
                                        <input type="text"
                                            placeholder='Rechercher...'
                                            value={searchValue}
                                            onChange={handleSearchChange}
                                            className='p-1  border border-orange-700 rounded-l-[50px] pl-4 rounded transition-all outline-none focus:border focus:border-orange-500' id="" />
                                    </form>
                                </div>

                                <div>
                                    <select
                                        class="px-2 h-10 text-black rounded-r-[50px] border border-orange-700  relative font-serif rounded transition cursor-pointer"
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                    >
                                        <option>5</option>
                                        <option>10</option>
                                        <option>12</option>
                                        <option>20</option>
                                        <option>24</option>
                                        <option>30</option>
                                        <option>36</option>
                                        {data.length > 36 && (
                                            <option>{data.length}</option>
                                        )}
                                    </select>
                                </div>

                            </div>
                            <div className={` w-full  overflow-x-auto mt-4`}>
                                <div className={`  ${sizeTable ? 'w-[70em]' : 'w-full '} overflow-hidden `}>

                                    <table className="w-full mx-2" cellspacing="0 " border="0 ">
                                        <thead class=" h-11 select-none bg-orange-500 rounded-t-[50px] overflow-hidden border-b-2 border-orange-400 ">
                                            <tr>
                                                <th className='border-r border-gray-300 text-[white] w-[7em]'>Nom  du client  </th>
                                                <th className='border-r border-gray-300 text-[white] w-[3px]'>Tel   </th>
                                                <th className='border-r border-gray-300 text-[white] w-[3em]'>Email </th>
                                                <th className='border-r border-gray-300 text-[white] w-[3em]'>Adresse </th>
                                                <th className='border-r border-gray-300 text-[white] w-[3em]'>Date </th>
                                                <th className='border-r border-gray-300 text-[white] w-[3em]'>Dette </th>
                                                <th className='border-r border-gray-300 text-[white] w-[1em]'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-gray-5000">
                                            {filteredData.length === 0 ? (
                                                <>
                                                    <tr>
                                                        <td colSpan="7"><div className='h-[75vh] text-gray-600 w-full flex justify-center items-center text-[22px]'>
                                                            Aucune correspondanct à <div className='ml-1 font-serif font-bold'> {searchValue}</div>
                                                        </div></td>
                                                    </tr>
                                                </>
                                            ) : (<>
                                                {filteredData && filteredData.map((data, posi) => {
                                                    return (
                                                        <tr key={posi} className='border-r  border-b border-gray-300 h-[35px] text-gray-600 '>
                                                            <td className=' border-r border-gray-300'>{data.vente.client.nom}</td>
                                                            <td className=' border-r border-gray-300'>{data.vente.client.tel}</td>
                                                            <td className=' border-r border-gray-300 w-[3px]'>{data.vente.client.email}</td>
                                                            <td className=' border-r border-gray-300'>{data.vente.client.address}</td>
                                                            <td className=' border-r border-gray-300'>Le {moment(data.vente.createdAt).locale('fr').format('DD/MM/YYYY HH:mm:ss')}</td>
                                                            <td className='border-r border-gray-300'>{data.montant} Fbu</td>
                                                            <td className='flex justify-center items-center h-10'>
                                                                <div class="flex justify-center items-center h-full w-[70px] px-4 border rounded-lg border-orange-400 pt-2 ">
                                                                    <Link to={`/User/Dettes/Details/${chiffrement(data.vente.id)}`} class=" w-full  h-full flex justify-around">
                                                                        Détaill
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>)}


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className="flex  w-full items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
                                <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                                    <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-orange-700 cursor-pointer">
                                        <svg
                                            width="14"
                                            height="8"
                                            viewBox="0 0 14 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.1665 4H12.8332"
                                                stroke="currentColor"
                                                stroke-width="1.25"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M1.1665 4L4.49984 7.33333"
                                                stroke="currentColor"
                                                stroke-width="1.25"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M1.1665 4.00002L4.49984 0.666687"
                                                stroke="currentColor"
                                                stroke-width="1.25"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <button
                                            onClick={previousPage}
                                            disabled={currentPage === 1}
                                            className="text-sm ml-3 text-black  hover:text-orange-700 cursor-pointer"
                                        >
                                            Prec
                                        </button>
                                    </div>
                                    <div className="sm:flex mt-3">{generatePageNumbers()}</div>
                                    <div className="flex items-center pt-3 ml-4 text-black  hover:text-orange-700 cursor-pointer">
                                        <button
                                            onClick={nextPage}
                                            disabled={currentPage === totalPages}
                                            className="text-sm font-medium leading-none mr-3"
                                        >
                                            Suiv
                                        </button>
                                        <svg
                                            width="14"
                                            height="8"
                                            viewBox="0 0 14 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.1665 4H12.8332"
                                                stroke="currentColor"
                                                stroke-width="1.25"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M9.5 7.33333L12.8333 4"
                                                stroke="currentColor"
                                                stroke-width="1.25"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M9.5 0.666687L12.8333 4.00002"
                                                stroke="currentColor"
                                                stroke-width="1.25"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (<>
                    <Empty item='dette' />
                </>)
            }
        </div>
    )
}

export default DetteMarcheUser

// import React, { useState } from "react";

// function SortableTable() {
//     const initialData = [
//         {
//             Nom: "John Doe",
//             Date: "2023-09-15",
//             Heure: "09:30:00",
//         },
//         {
//             Nom: "Alice Smith",
//             Date: "2023-09-14",
//             Heure: "13:45:00",
//         },
//         {
//             Nom: "Bob Johnson",
//             Date: "2023-09-16",
//             Heure: "10:15:00",
//         },
//     ];

//     const [data, setData] = useState(initialData);
//     const [sortConfig, setSortConfig] = useState({
//         key: null,
//         direction: "ascending",
//     });

//     const sortData = (key) => {
//         let direction = "ascending";
//         if (sortConfig.key === key && sortConfig.direction === "ascending") {
//             direction = "descending";
//         }

//         const sortedData = [...data].sort((a, b) => {
//             const valueA = key === "Date" ? new Date(a[key]) : key === "Heure" ? new Date(`1970-01-01T${a[key]}`) : a[key];
//             const valueB = key === "Date" ? new Date(b[key]) : key === "Heure" ? new Date(`1970-01-01T${b[key]}`) : b[key];

//             if (valueA < valueB) return direction === "ascending" ? -1 : 1;
//             if (valueA > valueB) return direction === "ascending" ? 1 : -1;
//             return 0;
//         });

//         setData(sortedData);
//         setSortConfig({ key, direction });
//     };

//     return (
//         <div>
//             <table>
//                 <thead>
//                     <tr>
//                         <th onClick={() => sortData("Nom")}>Nom</th>
//                         <th onClick={() => sortData("Date")}>Date</th>
//                         <th onClick={() => sortData("Heure")}>Heure</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {data.map((item, index) => (
//                         <tr key={index}>
//                             <td>{item.Nom}</td>
//                             <td>{item.Date}</td>
//                             <td>{item.Heure}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// }

// export default SortableTable;
