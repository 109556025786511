import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
function LivraireurAcceuil() {
  const [mobilemTable, SetmobileTable] = useState(window.innerWidth < 997);
  const id = localStorage.getItem('userid');
  const [listoffOrders, setListOffOrders] = useState([]);
  const [element, setElement] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const hundleSize = () => {
      SetmobileTable(window.innerWidth < 997);
    }
    window.addEventListener('resize', hundleSize);
    return () => {
      window.removeEventListener('resize', hundleSize);
    }
  }, []);

  useEffect(() => {
    axios.get(`https://apiv1.burundientempsreel.com/market/livreur/tasks/${id}?page=${currentPage}&size=${itemsPerPage}`)
      .then((response) => {
        setElement(response.data.count);
        setListOffOrders(response.data.rows);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [id, currentPage, itemsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };

  const firstEntry = (currentPage - 1) * itemsPerPage + 1;
  const lastEntry = Math.min(currentPage * itemsPerPage, element);
  return (
    <div className="min-h-[95vh] p-4">

      <div class="bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
        <div class=" mx-auto px-2">
          <div class="py-8">
            <div>
              <h2 class="text-2xl font-semibold  text-orange-500 leading-tight">
                Commande en attente
              </h2>
            </div>
            <div class="my-2 flex sm:flex-row flex-col space-x-2">
              <div class="flex flex-row mb-1 sm:mb-0">


                <div class="my-2 flex flex-row ">
                  <div class="flex flex-row mb-1 sm:mb-0">
                    <div className="relative">
                      <select
                        className="appearance-none h-full rounded-l-2xl border block  w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"

                      >
                        <option>5</option>
                        <option>10</option>
                        <option>20</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>

                    <div class="relative">
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          class="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="block relative">
                    <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        viewBox="0 0 24 24"
                        class="h-4 w-4 fill-current text-black"
                      >
                        <path d="M10 4a6 6 0100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                      </svg>
                    </span>
                    <input
                      placeholder="recherche.."

                      class="appearance-none rounded-r-2xl rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-2 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class=" w-full overflow-x-auto">
              <div class={`inline-block ${mobilemTable ? 'w-[75em]' : 'w-full '} shadow rounded-lg overflow-hidden`}>
                <table class="min-w-full leading-normal">
                  <thead>

                    <tr>



                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Nom_complet du client
                      </th>

                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Heure de commande
                      </th>
                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Tel
                      </th>

                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Adresse de Livraison
                      </th>

                      <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {listoffOrders.map((order) => (
                      <tr>


                        <td class="px-4 py-2 border-b border-l border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {order.commandemarket.user.nom} {order.commandemarket.user.prenom}
                          </p>
                        </td>

                        <td class="px-4 py-2 border-b border-l border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">

                            {format(new Date(order.commandemarket.createdAt), "dd/MM/yyyy 'à' HH'h' mm'min'")}

                          </p>
                        </td>

                        <td class="px-4 py-2 border-b border-l border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {order.commandemarket.mobile}
                          </p>
                        </td>

                        <td class="px-4 py-2 border-b border-l border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {order.commandemarket.province}-
                            {order.commandemarket.commune}-
                            {order.commandemarket.zone}-
                            {order.commandemarket.quartier}
                          </p>
                        </td>


                        <td class="px-4 py-2 border-b border-l border-gray-200 bg-white text-sm">
                          <span class="relative inline-block  py-1 font-semibold text-green-900 leading-tight">
                            <Link to={`/livraireur/Detail/${order.commandemarket.id}`}
                              class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-yellow-500hover:text-white focus:outline-none"
                            >
                              Details
                            </Link>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
            <div class="px-4 py-2 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">
                {`Affichage de ${firstEntry} à ${lastEntry} sur ${element} entrées`}
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button
                  className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default LivraireurAcceuil;
