import React, { useEffect, useRef, useState } from "react";
import { BarLoader } from 'react-spinners'
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FadeLoader } from 'react-spinners'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
function ValidationProduct() {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [photo, setPhoto] = useState([]);
    const history = useNavigate();

    const [loading, SetLoading] = useState(true)

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/market/agent/getonproduct/${id}`).then((response) => {

            setProduct(response.data);
            setPhoto(response.data.produitfiles);
            setTimeout(() => {
                SetLoading(false)
            }, 1000);
        }, []).catch((error) => {
            console.error(error.message);
            if (error.response) {
                console.error(error.response.data);
            }
        });
    }, [id]);





    const [LoadingPagePhotos, SetLoadingPagePhotos] = useState(true)
    const [mobile, Setmobile] = useState(window.innerWidth < 327.9292828290293)


    useEffect(() => {
        const hundleResize = () => {
            Setmobile(window.innerWidth < 327.9292828290293)

        }

        window.addEventListener('resize', hundleResize)

        return () => {
            window.removeEventListener('resize', hundleResize)
        }
    }, [])



    const [spinnerButton, SetSpinnerButton] = useState(false)


    const [quantit, Setquantit] = useState(null)
    const [animationClassquantit, setAnimationClassquantit] = useState('');
    const elemetRefquantit = useRef(null)

    const [SelectValueEtat, SetSelectValueEtat] = useState()
    const [animationClassSelecteValueEtat, setanimationClassSelecteValueEtat] = useState('');



    const [SelectValuePriot, SetSelectValuePriot] = useState()
    const [animationClassSelecteValuePriot, setanimationClassSelecteValuePriot] = useState('');



    const [isTextarea, setIsTextarea] = useState('');
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');
    const textaresRef = useRef(null)

    const [autoScrollHeight, setAutoScrollHeight] = useState(50);
    const [valueTextarea, setValueTextarea] = useState('');



    const userId = localStorage.getItem('userid')

    const handleSubmit = (e) => {
        e.preventDefault()
        if (quantit == null) {
            toast.warning("Entre la quanatité du produits inspectionne !!", {
                autoClose: 2000
            });
            setAnimationClassquantit('animate__animated animate__shakeX placeholder-shown:border-orange-700 text-orange-500 border-orange-700')
            setTimeout(() => {
                setAnimationClassquantit(' ')
            }, 3000)
            elemetRefquantit.current.focus()

            return false
        }
        else if (SelectValueEtat == undefined) {
            toast.warning('Sélectionnez Etat du produit', {
                autoClose: 2000
            });
            setanimationClassSelecteValueEtat('animate__animated animate__shakeX border-b-2 border-red-500')
            setTimeout(() => {
                setanimationClassSelecteValueEtat(' ')
            }, 3000)
            return;
        }

        else if (SelectValuePriot == undefined) {
            toast.warning('Sélectionnez la priorité du produit', {
                autoClose: 2000
            });
            setanimationClassSelecteValuePriot('animate__animated animate__shakeX border-b-2 border-red-500')
            setTimeout(() => {
                setanimationClassSelecteValuePriot(' ')
            }, 3000)
            return;
        } else if (isTextarea.trim() === '') {
            toast.warning('La commentaire  d\'inspection', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500');
            setTimeout(() => {
                setAnimationClassTextarea('');
            }, 3000);
            textaresRef.current.focus()
            return;
        }
        const data = {
            quantit,
            SelectValueEtat,
            SelectValuePriot,
            isTextarea, userId
        }
        axios.post(`https://apiv1.burundientempsreel.com/market/agent/inspectproduct/${id}`, data).then((response) => {
            toast.success('Operation a été faite avec succes')
            history('/')
        }).catch((err) => {
            toast.error('Une erreur s\'est produite au niveau du serveur')
        })
    }






    const LazyImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };

        const lazyLoad = () => {
            setImgSrc(src);
        };


        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };






    const [troisBlock1, settroisBlock1] = useState(window.innerWidth < 843)
    const [motPayement, setmotPayement] = useState(window.innerWidth < 699)

    const [troisBlock2, settroisBlock2] = useState(window.innerWidth < 310)
    useEffect(() => {
        const handleResize = () => {
            setmotPayement(window.innerWidth < 699)
            settroisBlock1(window.innerWidth < 843)
            settroisBlock2(window.innerWidth < 310)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])





    return (
        <>
            <div className="w-full h-11 flex justify-start  items-center pl-2"><Link to='/' className="bg-orange-600 rounded-lg text-white px-2 py-1 h-max">Retour</Link></div>
            {product && (

                <div className={` m-4  pb-10 pt-10  rounded-2xl bg-white `}>
                    <div class={`  flex  gap-6  flex-col  r p-3`}>
                        <div className="w-max">
                            <div class="relative z-0 h-72 border border-orange-600 rounded-2xl overflow-hidden   md:h-96  ">
                                {LoadingPagePhotos && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img

                                    draggable='false'
                                    src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
                                    alt="product 1"
                                    class="object-contain object-center h-full w-full rounded-2xl"
                                    onLoad={
                                        () => {
                                            setTimeout(() => {
                                                SetLoadingPagePhotos(false)
                                            }, 1000)
                                        }
                                    }
                                />
                            </div>



                        </div>

                        <div className={`} `}>
                            {product && product.seller && (
                                <div class="space-y-2 text-gray-700">
                                    <div class=" text-xs md:text-base  space-x-2">
                                        <span className="font-bold">Nom  du magazin: </span>
                                        <span class="text-olive-500">
                                            {product.seller.nomshop}
                                        </span>
                                    </div>
                                    {product.seller.user && (
                                        <>
                                            <div class=" text-xs md:text-base  mt-5 block space-x-2">
                                                <span className="font-bold">Nom  du vendeur: </span>
                                                <span class="text-olive-500">
                                                    {product.seller.user.nom}
                                                    {product.seller.user.prenom}
                                                </span>
                                            </div>
                                            <p class=" text-xs md:text-base  space-x-2">
                                                <span className="font-bold">Tel: </span>
                                                <span class="text-olive-500">
                                                    {product.seller.user.tel}
                                                </span>
                                            </p>
                                        </>
                                    )}




                                    <p class=" text-xs md:text-base  space-x-2">
                                        <span className="font-bold">Lieu: </span>
                                        <span class="text-olive-500">
                                            {product.seller.address}
                                        </span>
                                    </p>

                                    <p class=" text-xs md:text-base  space-x-2">
                                        <span className="font-bold">Nom du produit: </span>
                                        <span class="text-olive-500">
                                            {product.titre}
                                        </span>
                                    </p>

                                    <p class="space-x-2 text-xs md:text-base">
                                        <span class=" font-bold">Categories: </span>
                                        {product.categorie}
                                    </p>
                                    <p class="space-x-2 text-xs md:text-base">
                                        <span class=" font-bold">Frais: </span>
                                        {product.prix} Fbu

                                    </p>
                                </div>
                            )}
                            <div class={`mt-2  text-xs  font-bold text-gray-700  md:text-base `}>Déscription du produit:<br /><span className={`$'text-gray-300' : 'text-gray-600'} font-normal`}>
                                {product.description}
                            </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10 font-bold   font-serif text-gray-500   ml-3 ">
                        Formulaire de validation
                    </div>
                    <div className="w-full flex ml-2 justify-start bg-white py-8">
                        <div class="rounded-2xl flex flex-col items-center border h-max  border-orange-500 w-[400px] p-2 shadow-sm">
                            <div
                                class="flex items-center space-x-1 font-semibold  text-gray-900"
                            >
                                <h2 class="my-1 text-lg leading-8 tracking-wide text-orange-500">
                                    Nouvelle inspection
                                </h2>
                            </div>
                            <form onSubmit={handleSubmit} className="mt-4  w-full">


                                <div className=" transition-all flex w-[100%] justify-between flexFormul conctat">
                                    <div class="relative h-11 w-full mb-6  flexFormulInput ">
                                        <input type="number" min='0' ref={elemetRefquantit} value={quantit} onInput={(e) => Setquantit(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassquantit}`} placeholder=" " />
                                        <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-orange-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-orange-gray-500">
                                            Le quantite du produit
                                        </label>
                                    </div>
                                </div>




                                <div class={`relative h-11  w-full mt-[20px] ${animationClassSelecteValueEtat}`}>
                                    <select onChange={(e) => { SetSelectValueEtat(e.target.value) }} class="focus:border border focus:border-orange-600 bg-white rounded cursor-pointer px-3 py-2 w-full" id="productCondition" name="productCondition">
                                        <option value="" hidden>État du Produit</option>
                                        <option value="Present">Présent</option>
                                        <option value="Absent">Absent</option>
                                    </select>
                                </div>

                                <div class={`relative h-11  w-full mt-[50px] ${animationClassSelecteValuePriot}`}>
                                    <select onChange={(e) => { SetSelectValuePriot(e.target.value) }} class="focus:border border focus:border-orange-600 bg-white rounded cursor-pointer px-3 py-2 w-full" id="productCondition" name="productCondition">
                                        <option value="" hidden>Priorité  du Produit</option>
                                        <option value="Haute priorité">Haute priorité</option>
                                        <option value="Moyenne priorité">Moyenne priorité</option>
                                        <option value="Normale">Normale</option>
                                    </select>
                                </div>

                                <div className="relative h-full w-full mt-[3.5em]">
                                    <textarea ref={textaresRef}
                                        value={isTextarea}
                                        onChange={(e) => {
                                            setIsTextarea(e.target.value);
                                            setAutoScrollHeight(e.target.scrollHeight);
                                            setValueTextarea(e.target.value.trim().length);
                                            if (!valueTextarea) {
                                                setAutoScrollHeight(50);
                                            }
                                        }}
                                        placeholder=" "
                                        style={{ height: `${autoScrollHeight}px` }}
                                        className={`peer personalTextarea leading-none w-full border-b-2 border-green-700 py-2 px-1 font-normal resize-none outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0 disabled:bg-red-500 bg-transparent ${animationClassTextarea}`}
                                    />
                                    <label
                                        className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px] peer-placeholder-shown:-top-[10px] flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all -bottom-1.5 blockw-full duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-orange-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-orange-gray-500`}
                                    >
                                        Commentaire d'inspection
                                    </label>
                                </div>

                                <div className="flex justify-end items-center">
                                    {spinnerButton ? (
                                        <>
                                            <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                                <div className='absolute bg-transparent   pt-5 pl-5  w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    /></div>
                                                <input type="submit" id="send" value="Valider" class=" transition-all "></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-orange-600  mt-4  p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Valider' className='cursor-pointer '></input>
                                                <i class="bi bi-send ml-2 "></i>
                                            </label>

                                        </>
                                    )}


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default ValidationProduct;
