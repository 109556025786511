/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types'
function DetailProducteUserMarket() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [photo, setPhoto] = useState([]);

  let [loading, setLoading] = useState(true);
  const [imageLoading, SetImageLoading] = useState(true)

  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/seller/getonproduct/${id}`)
      .then((response) => {
        setProduct(response.data);
        setPhoto(response.data.produitfiles);
        const timer = setTimeout(() => {
          setLoading(false);
        }, 100);
        return () => clearTimeout(timer);
      });
  }, [id]);

  function handleDelete(id, index) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Etes-vous sûr que vous voulez supprimer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'SUPPRIMER',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'swal-confirm-button',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://apiv1.burundientempsreel.com/market/productfile/ById/${id}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success('le fichier a été supprimé avec succès');
              const allPhotos = [...photo]
              allPhotos.splice(index, 1)
              setPhoto(allPhotos)
            } else {
              toast.error("Une erreur s'est produite lors de la suppression du fichier");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Une erreur s'est produite lors de la suppression du fichier");
          });
      }
    });

  }

  const [mobile, SetMobile] = useState(window.innerWidth < 949.9374464939393928)
  const [mobile1, SetMobile1] = useState(window.innerWidth < 487.9374464939393928)

  useEffect(() => {

    const hundleZise = () => {
      SetMobile(window.innerWidth < 949.9374464939393928)
      SetMobile1(window.innerWidth < 487.9374464939393928)
    }
    window.addEventListener('resize', hundleZise);

    return () => {
      window.removeEventListener('resize', hundleZise);
    }
  }, [])



  const [loadingImages, setLoadingImages] = useState([]);

  const handleImageLoad = (index) => {

    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      updatedLoadingImages[index] = true;
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 50000);
      return updatedLoadingImages;
    });

  };





  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };


  const [mobilem1, Setmobile1] = useState(window.innerWidth < 416)

  useEffect(() => {
    const hundleSIze = () => {
      Setmobile1(window.innerWidth < 416)
    }
    window.addEventListener('resize', hundleSIze)
    return () => {
      window.removeEventListener('resize', hundleSIze)
    }
  }, [])


  return (
    <>

      <div class="rounded-2xl w-full relative h-full  m-4 ">
        {loading && <SpinnerDemarage />}
        <Link to='/User/Marche' className=" bg-orange-500 py-1 px-2 w-max -top-10 text-white rounded">Retour</Link>
        <div className={`flex my-10  w-full ${mobile ? 'flex-col' : 'pb-5'}  h-full border border-orange-500 rounded-2xl`}>
          <div className={` ${mobile ? 'w-full flex justify-center items-center' : 'w-max'} h-full  p-4`}>
            <div class={` ${mobile ? '' : 'w-[30em] h-[30em]'}  border border-orange-500 rounded-2xl relative overflow-hidden userPhotos`}>
              {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}

              <img draggable='false'
                onLoad={() => {
                  setTimeout(() => {
                    SetImageLoading(false)
                  }, 1000)
                }}
                src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
                alt="product 1"
                class="object-cover h-full w-full"
              />
            </div>
          </div>

          <div className="ml-4">
            <h4 class="font-medium text-3xl mb-2 text-gray-600">
              {product.titre}
            </h4>

            <div class="space-y-2">
              <p class="text-gray-800 font-semibold space-x-2">
                <span>Disponibilité: </span>
                <span class="text-olive-500">
                  {product.quanite} articles en stock
                </span>
              </p>

              <p class="space-x-2">
                <span class="text-gray-800 font-semibold">Catégorie: </span>
                <span class="text-gray-600">{product.categorie}</span>
              </p>
            </div>
            <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">


              <p class="text-xl text-primary font-semibold">
                <span class="text-gray-800 font-semibold text-[16px]">Prix unitaire:  </span>
                <span class="text-gray-600">{product.prix} Fbu</span>

              </p>
              <p class="text-base text-gray-400 line-through">
                {product.prix + (product.prix * 20) / 100} Fbu
              </p>
            </div>

            <p class="mt-4 text-gray-600"><p className=" font-roboto font-semibold text-[17px]">Déscription du produits: </p>{product.description}</p>
          </div>
        </div>
        {photo.length > 0 && (
          <>
            <span className="  ml-2 text-orange-600 font-serif text-center  textMargine">
              Les photos supplémentaire de <b>{product.titre}</b>
            </span>
            <div class="w-full mt-1 flex flex-wrap  justify-start">


              {photo.map((photo, index) => {
                return (


                  <><div key={index} className={`relative  ${mobilem1 ? 'w-full h-max' : 'h-[10em] w-[10em]'}  m-2 border rounded-2xl bg-white  overflow-hidden border-orange-700`}>
                    <div className="absolute  text-white text-sm  ">
                      <button
                        onClick={() => handleDelete(photo.id, index)}
                        className="bg-red-400   hover:bg-red-700 px-4 py-1   cursor-pointer"
                      >
                        supprimer
                      </button>
                    </div>
                    {loadingImages[index] ? (
                      <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                    ) :
                      (
                        <LoadingImage
                          draggable='false'
                          src={`https://apiv1.burundientempsreel.com/uploads/market/${photo.file}`}
                          alt="Image"
                          className=" w-full h-full rounded  object-contain object-center"
                          onLoad={() => handleImageLoad(index)} />
                      )}

                  </div>
                  </>
                );
              })}


            </div>
          </>
        )}

      </div>
    </>

  );
}

export default DetailProducteUserMarket;
