/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { lazy, Suspense } from "react";
import { BarLoader } from 'react-spinners'

import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ADD } from "../../../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from 'react-toastify';
import SpinnerDemarage from "../../../../SpinnerDemarage/SpinnerDemarage";
import 'react-toastify/dist/ReactToastify.css';
function ProductDetailSeller({ data }) {

  const getData = useSelector((state) => state.CartsReducer.Carts);
  const [LoadingPagePhotos, SetLoadingPagePhotos] = useState(true)

  const [mobile, Setmobile] = useState(window.innerWidth < 327.9292828290293)

  const [Quantity, SetQuantity] = useState(1);
  const [loading, SetLoading] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const hundleResize = () => {
      Setmobile(window.innerWidth < 327.9292828290293)
    }

    window.addEventListener('resize', hundleResize)

    return () => {
      window.removeEventListener('resize', hundleResize)
    }
  }, [])


  function send(e, qty) {
    dispatch(ADD(e, qty));
    toast.success(
      `Felicitation vous avez ajouter ${e.titre} de nos produit au panier`,
      {
        position: "top-center",
        autoClose: 1000,
      }
    );
  }


  if (Quantity === 0) {
    navigate('/User/Ventes')
  }
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [photo, setPhoto] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/user/getonproduct/${id}`)
      .then((response) => {
        setProduct(response.data);
        setPhoto(response.data.produitfiles);
        setTimeout(() => {
          SetLoading(false)
        }, 1000);
      });
  }, [id]);

  localStorage.setItem("categorysimulation", product.categorie);
  localStorage.setItem("idsimulation", product.id);



  function AddtoCarts() {
    if (getData.length) {
      const itemesIndex = getData.findIndex(
        (iteam) => iteam.id === product.id
      );

      if (itemesIndex != -1) {
        if (product.quanite > (getData[itemesIndex].quantity + Quantity)) {
          send(product, Quantity)

        }
        else {
          toast.error(`Vous avez déjà une limite de produits de ${product.titre}`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose,
          });
        }
      }
      else { send(product, Quantity) }

    }
    else {
      send(product, Quantity)

    }
  }


  const handleClose = () => {
    toast.dismiss();
  };

  const LazyImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };

    const lazyLoad = () => {
      setImgSrc(src);
    };

    const elementInViewPort = () => {
      // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
      const clientRect = imageRef.current.getBoundingClientRect();

      return (
        clientRect.top >= 0 &&
        clientRect.left >= 0 &&
        clientRect.bottom - 100 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        clientRect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };

  const [zoom, SetZoom] = useState(window.innerWidth < 768)
  const [zoomedImageStyle, setZoomedImageStyle] = useState({});
  const [showZoomBox, setShowZoomBox] = useState(false);
  const zoomFactor = 3;

  useEffect(() => {
    const hundleResize = () => {
      Setmobile(window.innerWidth < 327.9292828290293)
      SetZoom(window.innerWidth < 768)
    }

    window.addEventListener('resize', hundleResize)

    return () => {
      window.removeEventListener('resize', hundleResize)
    }
  }, [])
  const handleMouseMove = (e) => {
    const { top, left, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;
    setZoomedImageStyle({
      transform: `scale(${zoomFactor})`,
      transformOrigin: `${x * 100}% ${y * 100}%`,
    });

    setShowZoomBox(true);
  };

  const handleMouseLeave = () => {
    setShowZoomBox(false);
  };


  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class=" relative  grid grid-cols-1 md:grid-cols-2 gap-6 mt-4 pb-10 pt-10  rounded-2xl bg-gray-50 p-3">
        <div>
          <div class="relative z-0 h-72   md:h-96  ">
            {LoadingPagePhotos && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
            <img
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              draggable='false'
              src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
              alt="product 1"
              class="object-cover object-center h-full w-full rounded-2xl"
              onLoad={
                () => {
                  setTimeout(() => {
                    SetLoadingPagePhotos(false)
                  }, 1000)
                }
              }
            />
          </div>
          {showZoomBox && (
            <div className={` ${zoom ? 'hidden' : 'block'}  w-[50%] shadow-2xl  border border-orange-500  pointer-events-none absolute rounded z-[100] overflow-hidden  <div class="relative  h-72   md:h-96  top-10 right-2 `}>
              <div
                className=""
                style={zoomedImageStyle}
              >
                <img
                  src={`https://apiv1.burundientempsreel.com/uploads/market/${product.profil}`}
                  className='w-full h-full object-contain object-center'
                />
              </div>
            </div>
          )}
          <div class="hidden  grid-cols-5 gap-4 mt-4">
            {photo.map((photo, index) => {
              return (
                <div className="h-72 w-32 md:h-96 md:w-32">
                  <LazyImage
                    key={index}
                    alt={`product `}
                    src={` htt://localhost:8080/uploads/market/${product.file}`}

                  />
                </div>
              );
            })}
          </div>
        </div>


        <div>

          {product.titre ? (
            <>
              <h4 class="font-medium text-xs md:text-3xl mb-2">{product.titre}</h4>

            </>
          ) : (
            <>
              <BarLoader color="blue" width={30} height={2} />
            </>
          )}
          <div class="space-y-2">
            <p class="text-gray-800 text-xs md:text-base font-semibold space-x-2">
              <span>Disponibilité: </span>
              <span class="text-olive-500">
                {product.quanite ? (
                  <>
                    {product.quanite} articles en stock
                  </>
                ) : (
                  <>
                    <BarLoader color="blue" width={30} height={2} />
                  </>
                )}

              </span>
            </p>
            <p class="space-x-2 text-xs md:text-base">
              <span class="text-gray-800 font-semibold">Categories: </span>


              {product.categorie ? (
                <>
                  <span class="text-gray-600">{product.categorie}</span>
                </>
              ) : (
                <>
                  <BarLoader color="blue" width={30} height={2} />
                </>
              )}

            </p>
          </div>
          <div class="flex items-baseline mb-1 space-x-2 font-roboto mt-4">
            <p class="text-base text-orange-500 md:text-xl text-primary font-semibold">




              {product.prix ? (
                <>
                  {product.prix}
                </>
              ) : (
                <>
                  <BarLoader color="blue" width={30} height={2} />
                </>
              )}
              Fbu
            </p>
            <p class="text-sm text-right md:text-base text-red-400 line-through">

              {product.prix ? (
                <>
                  {product.prix + (product.prix * 20) / 100}
                </>
              ) : (
                <>
                  <BarLoader color="blue" width={30} height={2} />
                </>
              )}
              Fbu
            </p>
          </div>

          {product.categorie ? (
            <>
              <p class="mt-4 text-xs md:text-base text-gray-600">{product.categorie}</p>

            </>
          ) : (
            <>
              <BarLoader color="blue" width={30} height={2} />
            </>
          )}
          <div class="mt-4">
            <h3 class="text-xs md:text-sm text-gray-800 uppercase mb-1">Quantites</h3>
            <div class="flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max">




              <div
                onClick={() => {
                  if (Quantity > 0) {
                    SetQuantity(Quantity - 1);
                  };

                }}

                class="h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none"
              >
                -
              </div>
              <div class="h-6 w-6 md:h-8 md:w-8 text-base flex items-center justify-center">
                {Quantity ? (
                  <>
                    {Quantity}
                  </>
                ) : (
                  <>
                    <BarLoader color="blue" width={30} height={2} />
                  </>
                )}

              </div>
              <div
                onClick={() => {
                  if (Quantity < product.quanite) {
                    SetQuantity(Quantity + 1);
                  }
                  else {
                    toast.error("Vous avez atteint la limite maximale de quantité de stock ", {
                      position: "top-center",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      onClick: handleClose,
                    });
                  }
                }}
                class={` h-6 w-6 md:h-8 md:w-8 text-xl flex items-center justify-center cursor-pointer select-none`}
              >
                +
              </div>
            </div>
            <div className=" hidden items-center border-gray-500">
              <span
                onClick={() => {
                  if (Quantity > 0, 1) {
                    SetQuantity(Quantity - 1);
                  }
                }}
                className="cursor-pointer rounded-l bg-gray-200 py-1 px-3.5 duration-500 hover:bg-orange-500 hover:text-orange-50"
              >
                {" "}
                -{" "}
              </span>
              <input
                className="h-8 w-8 border bg-white text-center text-xs outline-none"
                type="number"
                value={Quantity}
                min="1"
              />
              <span
                onClick={() => {
                  SetQuantity(Quantity + 1);
                }}
                className="cursor-pointer rounded-r bg-gray-200 py-1 px-3 duration-500 hover:bg-orange-500 hover:text-orange-50"
              >
                {" "}
                +{" "}
              </span>
            </div>
          </div>

          <div class="mt-3 md:mt-6 flex flex-col md:flex-row  gap-3  border-b border-gray-200 pb-5 pt-5">
            <button
              onClick={AddtoCarts}
              class="block w-full py-2  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
            >
              Ajouter au panier
            </button>
            <Link
              to="/User/Ventes/Carts"
              onClick={AddtoCarts}
              class="block w-full  py-2  text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
            >
              Acheter maintenant
            </Link>
          </div>
        </div>
      </div>

    </>
  );
}

export default ProductDetailSeller;
