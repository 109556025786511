/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
function NaVbarsOnglet() {
    const { pathname } = useLocation();

    const Ventes = /^\/User\/Ventes.*/
    const Dettes = /^\/User\/Dettes.*/
    const Depenses = /^\/User\/Depenses.*/
    const Bonus = /^\/User\/Bonus.*/
    const Jours = /^\/User\/Jours.*/

    const AfficheProduct = /^\/User\/AffichProduct.*/
    const ManageProduct = /^\/User\/ProduitManager.*/
    const GereProduit = /^\/User\/Marche.*/

    const AddProduct = /^\/User\/AjouProduct.*/
    const Orders = /^\/User\/Orders.*/
    return (
        <div className="w-full h-1/2  flex  flex-col justify-center  items-center  text-[12px] Barshiddess ">
            <div className={`  w-[100%] h-full only:flex justify-around items-end  text-[15px]`}>
                <div className="  flex px-4 w-[100%] justify-around ">
                    <div title="Acceuil" class="flex">

                        {pathname == "/" || AfficheProduct.test(pathname) ? (
                            <Link
                                to="/"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z" />
                                    <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z" />
                                </svg>
                                <span class="hiddensss text-center ml-2">
                                    Acceuil
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z" />
                                    <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z" />
                                </svg>

                                <span class="hiddensss text-center ml-2">
                                    Acceuil
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Ventes" class="flex">
                        {Ventes.test(pathname) ? (
                            <Link
                                to="/User/Ventes"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cloud-minus-fill" viewBox="0 0 16 16">
                                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zM6 7.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1 0-1z" />
                                </svg>
                                <span class="hiddensss text-center ml-2">
                                    Ventes
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Ventes"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cloud-minus-fill" viewBox="0 0 16 16">
                                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zM6 7.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1 0-1z" />
                                </svg>
                                <span class="hiddensss text-center ml-2">
                                    Ventes
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Dettes" class="flex ">
                        {Dettes.test(pathname) ? (
                            <Link
                                to="/User/Dettes"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >

                                <i class="bi bi-credit-card-2-front-fill text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Dettes
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Dettes"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-credit-card-2-front-fill text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Dettes
                                </span>
                            </Link>

                        )}
                    </div>


                    <div title=" Depense" class="flex ">
                        {Depenses.test(pathname) ? (
                            <Link
                                to="/User/Depenses"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >

                                <i class="bi bi-layers-fill text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Depense
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Depenses"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-layers-fill text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Depense
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Bonus" class="flex ">
                        {Bonus.test(pathname) ? (
                            <Link
                                to="/User/Bonus"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >

                                <i class="bi bi-cash-coin text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Bonus
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Bonus"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-cash-coin text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Bonus
                                </span>
                            </Link>

                        )}
                    </div>


                    <div title="Action du jour" class="flex ">
                        {Jours.test(pathname) ? (
                            <Link
                                to="/User/Jours"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >

                                <i class="bi bi-activity text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Statistique
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Jours"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-activity text-[22px] mr-2"></i>
                                <span class="hiddensss text-center">
                                    Statistique
                                </span>
                            </Link>

                        )}
                    </div>

                    <div title="Gérer les produits" class="flex ">
                        {GereProduit.test(pathname) ? (
                            <Link
                                to="/User/Marche"
                                class="no-underline text-white mr-1 bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <i className="bi bi-file-earmark-ppt mr-2"></i>
                                <span class="hiddensss text-center">
                                    Gérer_produit
                                </span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/Marche"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-window-split text-[22px] mr-2"></i>

                                <span class="hiddensss text-center">
                                    Gérer_produit
                                </span>
                            </Link>

                        )}
                    </div>
                    <div title="Ajouter un produit" class="flex">
                        {AddProduct.test(pathname) ? (
                            <Link
                                to="/User/AjouProduct"
                                class="no-underline text-white bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <i class="bi bi-plus-circle-dotted mr-2"></i>
                                <span class="hiddensss text-center">Ajout_produit</span>
                            </Link>
                        ) : (
                            <Link
                                to="/User/AjouProduct"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-plus-circle-dotted mr-2"></i>
                                <span class="hiddensss text-center">Ajout_produit</span>
                            </Link>
                        )}
                    </div>

                    <div title="Orders" class="flex ">
                        {Orders.test(pathname) ? (
                            <Link
                                to="/User/Orders"
                                class="no-underline text-white bg-orange-500 md:text-white px-4 rounded-t-lg flex items-center py-2 "
                            >
                                <i class="bi bi-list-ul  mr-2"></i>
                                <span class="hiddensss text-center">
                                    Commande</span>
                            </Link>
                        ) : (
                            <Link

                                to="/User/Orders"
                                class="no-underline mr-1 text-gray-500  rounded-t-lg flex items-center hover:text-white px-4  py-2 hover:bg-orange-400  "
                            >
                                <i class="bi bi-list-ul   mr-2"></i>

                                <span class="hiddensss text-center">Orders</span>
                            </Link>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NaVbarsOnglet
