/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Import des dépendances
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast, } from 'react-toastify'
import SpinnerDemarage from '../../../../SpinnerDemarage/SpinnerDemarage'
import { BarLoader } from 'react-spinners'
import Empty from '../../Empty/Empty'

function Product_ListBonus({ isdivScroll }) {



  const [NotFound, SetNotFound] = useState(false)
  let [loading, setLoading] = useState(true)
  const [selectcategorie, setSelectcategorie] = useState('')
  const [titre, setTitre] = useState('')
  const [productSearch, setproductSearch] = useState([])
  const [searching, setSearching] = useState(false);




  useEffect(() => {

    const hundleScroll = () => {
      setcategoryMenu(false);
    }
    if (isdivScroll.current) {
      isdivScroll.current.addEventListener('scroll', hundleScroll)
    }
    return () => {
      isdivScroll.current.removeEventListener('scroll', hundleScroll)
    }
  }, [])

  const LoadingImage = ({ src, alt, loaderIcon }) => {
    const loaderSrc = loaderIcon
      ? loaderIcon
      : "https://www.eliananunes.com/images/lazy_loader.gif",
      [imgSrc, setImgSrc] = useState(loaderSrc),
      imageRef = useRef(null),
      loader = new Image();

    useEffect(() => {
      //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
      loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
      loader.addEventListener("load", checkImage);

      // add the lazyLoad method onscroll
      window.onscroll = window.addEventListener("scroll", lazyLoad);

      return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
      };
    }, []);

    const checkImage = () => {
      if (loader.complete) {
        lazyLoad();
      }
    };
    const lazyLoad = () => {
      setImgSrc(src);
    };



    return (
      <img draggable='false'
        ref={imageRef}
        src={imgSrc}
        alt={alt}
        className="object-cover border h-full w-full rounded-2xl"
      />
    );
  };

  LoadingImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    loaderIcon: PropTypes.string,
  };


  const history = useNavigate()
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [categoryMenu, setcategoryMenu] = useState(false)
  const [category, setCategory] = useState('')
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [datanotsearch, setdatanotsearch] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(72);
  const [titreseach, settitreseach] = useState("");
  const handleItemsPerPageChange = (membre) => {
    const selectedItemsPerPage = parseInt(membre.target.value);
    setItemsPerPage(selectedItemsPerPage);
  };


  const iduser = localStorage.getItem("userid");
  useEffect(() => {
    const fetchData = async () => {
      setproductSearch([])
      try {
        if (category) {
          const response = await axios.get(`https://apiv1.burundientempsreel.com/market/seller/productsbycategory/${iduser}/${category}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(false)
        } else {
          const response = await axios.get(`https://apiv1.burundientempsreel.com/market/seller/getallproduct/${iduser}?page=${currentPage}&size=${itemsPerPage}`);
          const { products, totalPages } = response.data;
          setData(products);
          setTotalPages(totalPages);
          setdatanotsearch(true)
          const timer = setTimeout(() => {
            setLoading(false);
          }, 1000);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, [category, currentPage, itemsPerPage]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  // Fonction pour passer à la page précédente
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  // Fonction pour changer de page lors du clic sur un numéro de page spécifique
  const goToPage = (page) => {
    setCurrentPage(page)
  }

  // Générer la liste de numérotation des pages
  const generatePageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={
            currentPage === i
              ? 'text-sm font-medium leading-none cursor-pointer bg-orange-600 text-white w-[30px] h-[30px] pb-[20px] rounded-lg dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-red-500 pt-3 mr-4 px-2'
              : 'text-sm font-medium  leading-none cursor-pointer w-[30px] h-[30px] pb-[20px] bg-gray-400 transition-all  text-white hover:bg-gray-600  rounded-2xl dark:text-gray-200  dark:hover:text-orange-400 border-t border-transparent hover:border-orange-400 pt-3 mr-4 px-2'
          }
        >
          {i}
        </button>,
      )
    }
    return pageNumbers
  }

  const search = (titre) => {
    if (titre) {
      setSearching(true);
      axios
        .get("https://apiv1.burundientempsreel.com/market/user/searchproductbuytitle", {
          params: { titre: titre },
        })
        .then((response) => {
          if (response.data === "Produit introuvable !") {
            toast.error("Produit est introuvable", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setproductSearch([]); // Mettre à jour avec un tableau vide
          } else {
            setproductSearch(response.data);
          }
          setSearching(false); // Indiquer que la recherche est terminée
        })
        .catch((err) => {
          console.error("Erreur lors de la recherche du produit", err);
          setproductSearch([]);
          setSearching(false);
        });
    } else {
      setproductSearch([]);
    }
  };

  const [loadingImages, setLoadingImages] = useState([]);


  const handleImageLoad = (index) => {
    setLoadingImages((prevLoadingImages) => {
      const updatedLoadingImages = [...prevLoadingImages];
      setTimeout(() => {
        updatedLoadingImages[index] = false;
      }, 5000);
      return updatedLoadingImages;
    });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }
  const [listoffInvestors, setListOffInvestors] = useState([]);
  const [element, setElement] = useState(0);

  const [currentPagep, setCurrentPagep] = useState(1);
  const [itemsPerPagep, setItemsPerPagep] = useState(10);
  const [totalPagesp, setTotalPagesp] = useState(0);

  const handlePageChange = (page) => {
    setCurrentPagep(page);
  };

  const handleItemsPerPageChangep = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value);
    setItemsPerPagep(selectedItemsPerPage);
  };
  const firstEntry = (currentPagep - 1) * itemsPerPagep + 1;
  const lastEntry = Math.min(currentPagep * itemsPerPagep, element);
  useEffect(() => {
    axios
      .get(`https://apiv1.burundientempsreel.com/market/user/getallseller?page=${currentPagep}&size=${itemsPerPagep}`)
      .then((response) => {
        setElement(response.data.totalItems);
        setListOffInvestors(response.data.users);
        setTotalPagesp(response.data.totalPages);

      });
  }, [currentPagep, itemsPerPagep]);


  const [produittendance, setProduittendance] = useState([])
  useEffect(() => {
    axios.get('https://apiv1.burundientempsreel.com/market/users/getproduittendance').then((response) => {
      setProduittendance(response.data)
    })
  }, [])





  const handleCategoryClick = (category) => {
    setCategory(category);
    setcategoryMenu(false);
  };




  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedTheme = localStorage.getItem("mode");
    return storedTheme === "ombre";
  });

  // getTheme(isDarkMode);
  const InputCheck = useRef(null);

  useEffect(() => {
    const handleCheckboxChange = (event) => {
      if (event.target.checked) {
        localStorage.setItem("mode", "ombre");
        setIsDarkMode(event.target.checked);
      } else {
        localStorage.removeItem("mode");
        setIsDarkMode(event.target.checked);
      }
    };

    const handleLocalStorage = () => {
      const mode = localStorage.getItem("mode");

      if (mode == "ombre") {
        InputCheck.current.checked = true;
        setIsDarkMode(mode === "ombre");
      } else {
        InputCheck.current.checked = false;
      }
    };

    if (InputCheck.current) {
      InputCheck.current.addEventListener("change", handleCheckboxChange);
    }

    handleLocalStorage();

    return () => {
      if (InputCheck.current) {
        InputCheck.current.removeEventListener("change", handleCheckboxChange);
      }
    };
  }, []);

  const [showVetem, SetshowVetem] = useState(false)
  const [showAlimentation, SetshowAlimentation] = useState(false)
  const [showAccessoires, SetshowAccessoires] = useState(false)
  const [showAppariels, SetshowAppariels] = useState(false)
  const [showMobilier, SetshowMobilier] = useState(false)
  const [showVéhicules, SetshowVéhicules] = useState(false)

  return (
    <>
      {loading && <SpinnerDemarage />}
      <div class="w-full relative ">
        <div class="flex items-center sticky top-0 space-y-3 p-3 bg-gray-100 ">
          <div class="flex justify-between items-center gap-2  pb-1 w-full ml-auto">
            <div
              onClick={(e) => {
                setcategoryMenu(!categoryMenu)
                e.stopPropagation()
              }}
              class="bg-cream h-10           
              items-center bi bi-list-nested py-1 px-2 text-[30px] pb-11 bg-orange-400 text-white font-semibold rounded-lg hover:shadow-lg transition duration-3000 cursor-pointer"
            ></div>
            <label class="relative   hidden items-center mr-1 cursor-pointer">
              <input
                type="checkbox"
                value=""
                ref={InputCheck}
                class="sr-only peer"
              />

              <div class="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-1 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
            </label>
            <div className="flex">
              <div class="flex  h-12 bg-white border border-orange-600 px-2 rounded-l-2xl overflow-hidden sm:w-[20em] items-center  inputwidth ">
                <i class="bi bi-search"></i>
                <input
                  value={titreseach}
                  onChange={(e) => {
                    settitreseach(e.target.value);
                    search(e.target.value);
                  }}
                  class="bg-transparent outline-none w-full pl-2"
                  type="text"
                  placeholder="Nom de l'article ou titre..."

                />


              </div>
              <select
                class="px-2  text-black border border-orange-700 font-serif rounded-r-2xl transition cursor-pointer"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option>5</option>
                <option>10</option>
                <option>12</option>
                <option>20</option>
                <option>24</option>
                <option>30</option>
                <option>36</option>
                <option>40</option>
                <option>48</option>
                <option>50</option>
                <option>60</option>
                <option>70</option>
                <option>72</option>
                {data.length > 72 && (
                  <option>{data.length}</option>
                )}
              </select>







            </div>
          </div>
        </div>
        <hr />
        <>
          <div
            className={`sm:w-[20em] w-[100%] slideMarche ${categoryMenu
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
              }  top-[20vh]  h-[80vh] overflow-auto   left-0 fixed z-[2000]  ${isDarkMode ? "bg-black" : "bg-gray-100"
              } transition-all border-r-2 border-orange-500`}
          >
            <div className="w-full flex justify-between items-center pl-4 pr-2 h-4 my-3 mr-4">
              <span className="text-[23px] text-orange-500">Notre cotégories</span>
              <span onClick={(e) => {
                e.stopPropagation()
                setcategoryMenu(false)
              }} className="bg-orange-500 text-white  cursor-pointer p-2 rounded-full "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-skip-backward-fill" viewBox="0 0 16 16">
                  <path d="M.5 3.5A.5.5 0 0 0 0 4v8a.5.5 0 0 0 1 0V8.753l6.267 3.636c.54.313 1.233-.066 1.233-.697v-2.94l6.267 3.636c.54.314 1.233-.065 1.233-.696V4.308c0-.63-.693-1.01-1.233-.696L8.5 7.248v-2.94c0-.63-.692-1.01-1.233-.696L1 7.248V4a.5.5 0 0 0-.5-.5z" />
                </svg>
              </span>
            </div>
            <div className={` w-full flex overflow-hidden `} >
              <div className="w-full   font-serif">
                <div onClick={() => { SetshowVéhicules(false); SetshowMobilier(false); SetshowVetem(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(false); handleCategoryClick("") }} className={`cursor-pointer flex text-gray-500  justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50 ${category === "" ? "bg-orange-600 text-white hover:bg-orange-600" : ""}`} >
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Tous les catégories</p>
                </div>
                <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(!showVetem);; SetshowAlimentation(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Habits des Hommes" || category == 'Habits Enfants Féminins' || category == 'Habits Enfants Masculins' || category == 'Kit Nouveau Né Féminins' || category == 'Kit Nouveau Né Masculins' || category == 'Habits des bébés Féminins ' || category == 'Habits des bébés Masculins ' || category == 'Habits Sports ' || category == 'Chaussures des Femmes' || category == 'Chaussures des Hommes' || category == 'Habits des Femmes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Vêtements</p>

                  <div className={`absolute ${showVetem ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                </div>
                <div className={`text-gray-500 ${showVetem ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Hommes") }}>Habits des Hommes</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des Femmes") }}>Habits des Femmes</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Hommes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Hommes") }}>Chaussures des Hommes</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Chaussures des Femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Chaussures des Femmes") }}>Chaussures des Femmes</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Sports ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Sports ") }}>Habits Sports </div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Masculins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Masculins ") }}>Habits des bébés Masculins </div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits des bébés Féminins ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits des bébés Féminins ") }}>Habits des bébés Féminins </div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Masculins") }}>Kit Nouveau Né Masculins</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Kit Nouveau Né Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Kit Nouveau Né Féminins") }}>Kit Nouveau Né Féminins</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Masculins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Masculins") }}>Habits Enfants Masculins</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Habits Enfants Féminins' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Habits Enfants Féminins") }}>Habits Enfants Féminins</div>
                </div>

                <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowAlimentation(!showAlimentation); SetshowVetem(false); e.stopPropagation() }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les céréales" || category == 'Les légumineuses' || category == 'Les tubercules' || category == 'Les farines' || category == 'Les légumes' || category == 'Les fruits' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Alimentation</p>

                  <div className={`absolute ${showAlimentation ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                </div>
                <div className={`text-gray-500 ${showAlimentation ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les céréales' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les céréales") }}>Les céréales</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumineuses' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumineuses") }}>Les légumineuses</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les tubercules' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les tubercules") }}>Les tubercules</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les farines' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les farines") }}>Les farines</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les fruits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les fruits") }}>Les fruits</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les légumes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les légumes") }}>Les légumes</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les autres produits alimentaires' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les autres produits alimentaires") }}>Les autres produits alimentaires</div>
                </div>


                <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(!showAccessoires); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les valises" || category == 'Les sacs à main des femmes' || category == 'Les sacs à dos ' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Accessoires </p>

                  <div className={`absolute ${showAccessoires ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                </div>
                <div className={`text-gray-500 ${showAccessoires ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les valises' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les valises") }}>Les valises</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à main des femmes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à main des femmes") }}>Les sacs à main des femmes</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les sacs à dos ' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les sacs à dos ") }}>Les sacs à dos </div>
                </div>


                <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(false); SetshowAppariels(!showAppariels); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les appareils électroniques" || category == 'Les appareils électroménagers' || category == 'Autres équipements de ménage' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Électronique et électroménage</p>

                  <div className={`absolute ${showAppariels ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                </div>
                <div className={`text-gray-500 ${showAppariels ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroniques' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroniques") }}>Les appareils électroniques</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les appareils électroménagers' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les appareils électroménagers") }}>Les appareils électroménagers</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres équipements de ménage' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres équipements de ménage") }}>Autres équipements de ménage</div>
                </div>


                <div onClick={(e) => { SetshowVéhicules(false); SetshowMobilier(!showMobilier); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Matériels de décors" || category == 'Les salons' || category == 'Les salles à manger' || category == 'Les lits' || category == 'Les salons des visiteurs' || category == 'Autres produits de menuiserie et soudure' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Mobilier</p>

                  <div className={`absolute ${showMobilier ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                </div>
                <div className={`text-gray-500 ${showMobilier ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Matériels de décors' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Matériels de décors") }}>Matériels de décors</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons") }}>Les salons</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salles à manger' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salles à manger") }}>Les salles à manger</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les lits' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les lits") }}>Les lits</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les salons des visiteurs' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les salons des visiteurs") }}>Les salons des visiteurs</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Autres produits de menuiserie et soudure' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Autres produits de menuiserie et soudure") }}>Autres produits de menuiserie et soudure</div>
                </div>


                <div onClick={(e) => { SetshowVéhicules(!showVéhicules); SetshowMobilier(false); SetshowAppariels(false); SetshowAccessoires(false); SetshowVetem(false); e.stopPropagation(); SetshowAlimentation(false) }} className={`cursor-pointer flex text-gray-500  relative justify-start items-center  p-1 w-full h-12    pb-2 hover:bg-orange-400  transition-all hover:text-fuchsia-50  ${category == "Les automobiles" || category == 'Les motos et bicyclettes' ? "bg-orange-600 text-white hover:bg-orange-600" : ""} `}>
                  <i class="bi  bi-arrow-right-circle-fill text-[20px] mr-4"></i>
                  <p className="sizeText">Véhicules</p>

                  <div className={`absolute ${showVéhicules ? 'rotate-90 duration-75 transition-all' : 'rotate-0'} right-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                  </div>
                </div>
                <div className={`text-gray-500 ${showVéhicules ? 'duration-1000 transition-all' : 'h-0 pointer-events-none opacity-0'} ml-7   relative leading-10`}>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les automobiles' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les automobiles") }}>Les automobiles</div>
                  <div className={`cursor-pointer hover:bg-gray-400 pl-2 mr-1 ${category == 'Les motos et bicyclettes' ? 'bg-orange-400 text-white hover:bg-orange-400 bi bi-check' : ''}`} onClick={() => { handleCategoryClick("Les motos et bicyclettes") }}>Les motos et bicyclettes</div>

                </div>
              </div>
            </div>
          </div>
        </>


        <div className="Container_Market md:p-5 py-5 px-1 md:pt-10 pb-32 ">

          <div className=" border-t-2 ">
            <h2 class="text-base md:text-2xl font-bold py-4 ml-3 ">
              {productSearch.length > 0 || !datanotsearch
                ? 'Résultats de la recherche'
                : 'Magasinez les dernières nouveautés'}
            </h2>

            {productSearch.length > 0 ? (
              <div class="grid md:grid-cols-4 grid-cols-3 md:px-2 md:gap-6 gap-3">
                {

                  productSearch.map((item, index) => {
                    return (
                      <div class="border rounded-2xl p-2 block  overflow-hidden group">
                        <Link to={`/User/Bonus/Detail/${item.id}`}>
                          <div className="relative w-full  overflow-hidden">
                            <div className="h-24 md:h-56 ">
                              <LoadingImage
                                key={index}
                                alt=''
                                src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil}`}

                              />
                            </div>
                          </div>
                        </Link>
                        <div class="w-full ">
                          <div className="pt-3 md:pb-3 px-0 md:px-4">
                            {item.titre ? (
                              <>
                                <h4 className="font-medium text-xs md:text-base mb-2 text-gray-800 hover:text-orange-500 transition">
                                  {item.titre.length >= 40
                                    ? item.titre.substring(0, 40) + '....'
                                    : item.titre}
                                </h4>
                              </>
                            ) : (
                              <>
                                <BarLoader color="blue" width={30} height={2} />
                              </>
                            )}


                            <div className="md:flex flex flex-row md:flex-col  items-baseline mb-1 space-x-2">
                              <p className="text-[11px] md:text-base text-orange-500 font-semibold">

                                {item.prix ? (
                                  <>
                                    {item.prix} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                              <p className="text-[7px] text-right md:text-sm text-red-400 line-through">
                                {item.prix ? (
                                  <>
                                    {item.prix + (item.prix * 20) / 100} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            <div className="md:flex hidden items-baseline mb-1 space-x-2">
                              <p className="text-xs text-gray-400 font-thin">
                                Disponible en stock :
                                { }


                                {item.quanite ? (
                                  <>
                                    {item.quanite} articles
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="flex gap-1 text-sm text-orange-400">
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="w-full md:flex items-end hidden justify-end">
                            <Link
                              to={`/User/Bonus/Detail/${item.id}`}
                              class="block w-1/2  md:w-full py-1 text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
                            >
                              Commandez maintenant
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            ) : data.length > 0 ? (
              <div class="grid md:grid-cols-4 grid-cols-3 md:px-2 md:gap-6 gap-3">

                {
                  data.map((item, index) => {
                    return (
                      <div class="border rounded-2xl p-2 block  overflow-hidden group">
                        <Link to={`/User/Bonus/Detail/${item.id}`}>
                          <div className="relative w-full  overflow-hidden">
                            <div className="h-24 md:h-56 ">
                              <LoadingImage
                                key={index}
                                alt={`product ${index + 1}`}
                                src={`https://apiv1.burundientempsreel.com/uploads/market/${item.profil}`}

                              />
                            </div>
                          </div>
                        </Link>
                        <div class="w-full ">
                          <div className="pt-3 md:pb-3 px-0 md:px-4">
                            {item.titre ? (
                              <>
                                <h4 className="font-medium text-xs md:text-base mb-2 text-gray-800 hover:text-orange-500 transition">
                                  {item.titre.length >= 40
                                    ? item.titre.substring(0, 40) + '....'
                                    : item.titre}
                                </h4>
                              </>
                            ) : (
                              <>
                                <BarLoader color="blue" width={30} height={2} />
                              </>
                            )}

                            <div className="md:flex flex flex-row md:flex-col  items-baseline mb-1 space-x-2">
                              <p className="text-[11px] md:text-base text-orange-500 font-semibold">

                                {item.prix ? (
                                  <>
                                    {item.prix} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                              <p className="text-[7px] text-right md:text-sm text-red-400 line-through">
                                {item.prix ? (
                                  <>
                                    {item.prix + (item.prix * 20) / 100} Fbu
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}

                              </p>
                            </div>
                            <div className="md:flex hidden items-baseline mb-1 space-x-2">
                              <p className="text-xs text-gray-400 font-thin">
                                Disponible en stock :


                                {item.quanite ? (
                                  <>
                                    {item.quanite} articles
                                  </>
                                ) : (
                                  <>
                                    <BarLoader color="blue" width={30} height={2} />
                                  </>
                                )}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="flex gap-1 text-sm text-orange-400">
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star"></i>
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                                <span>
                                  <i className="fa-solid fa-star" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="w-full md:flex items-end hidden justify-end">
                            <Link
                              to={`/User/Bonus/Detail/${item.id}`}
                              class="block w-1/2  md:w-full py-1 text-center text-white bg-orange-500 border border-orange-500 rounded-2xl hover:bg-transparent hover:text-orange-500 transition"
                            >
                              Commandez maintenant
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            ) : (
              <div className='w-[100%] h-[70vh] flex justify-center items-center '>
                <Empty item="bonus" />
              </div>
            )}

          </div>
          {productSearch.length > 0 || (!datanotsearch && data.length === 0) ? (
            <></>
          ) : (
            <div className="flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4">
              <div className="lg:w-3/5 w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-700">
                <div className="flex items-center pt-3 mr-4 text-gray-600 dark:text-gray-200 hover:text-orange-700 cursor-pointer">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1665 4H12.8332"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.1665 4L4.49984 7.33333"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.1665 4.00002L4.49984 0.666687"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <button
                    onClick={previousPage}
                    disabled={currentPage === 1}
                    className="text-sm ml-3 text-black  hover:text-orange-700 cursor-pointer"
                  >
                    Prec
                  </button>
                </div>
                <div className="sm:flex mt-3">{generatePageNumbers()}</div>
                <div className="flex items-center pt-3 ml-4 text-black  hover:text-orange-700 cursor-pointer">
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="text-sm font-medium leading-none mr-3"
                  >
                    Suiv
                  </button>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1665 4H12.8332"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 7.33333L12.8333 4"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.5 0.666687L12.8333 4.00002"
                      stroke="currentColor"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Product_ListBonus
