/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import SpinnerDemarage from '../../../SpinnerDemarage/SpinnerDemarage';

import { GridLoader } from 'react-spinners'
function DetailleAdminManagent() {
  const [sellerData, setSellerData] = useState({});
  const [loading, setLoading] = useState(true);

  const [imageLoading, SetImageLoading] = useState(true)
  const [LoadingPagePhotos, SetLoadingPagePhotos] = useState(true)
  const [LoadingPagePhotos1, SetLoadingPagePhotos1] = useState(true)
  const [LoadingPagePhotos2, SetLoadingPagePhotos2] = useState(true)

  const [tropTail, SettropTail] = useState(window.innerWidth < 414.97364448849393)

  const { id } = useParams();
  useEffect(() => {
    // Fonction pour récupérer les données du vendeur en utilisant Axios
    const fetchSellerData = async () => {
      try {
        const response = await axios.get(`https://apiv1.burundientempsreel.com/market/admin/getoneseller/${id}`);
        setSellerData(response.data);
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        }

      }
    };

    fetchSellerData();
  }, [id]);

  const [mobile, SetMobile] = useState(window.innerWidth < 550)

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 550)
    }
    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])
  return (
    <div className="w-[100%]    pt-6 pb-8 px-2">
      {loading && <SpinnerDemarage />}
      <Link className="my-4 py-1 px-2 text-white bg-orange-700 rounded" to='/Admin/Market'>Retour</Link>

      <div className="w-full mt-3">
        <div className="w-full">
          <div className="w-full">
            <div className="md:flex no-wrap w-full md:-mx-2">
              { /* Left Side */}

              {sellerData.address && (
                <>
                  <div className="w-full md:w-4/12 md:mx-2">
                    {sellerData.user && (
                      <div className="bg-gray-100 rounded-2xl p-5">
                        <div className="h-[4em] w-[4em] border border-orange-500  transition-all relative overflow-hidden ml-3 rounded">
                          {imageLoading && <div className='w-full h-full flex bg-gray-200 justify-center items-center'>{tropTail ? (<GridLoader size='5' color='blue' />) : (<GridLoader size='10' color='blue' />)}</div>}
                          <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                              SetImageLoading(false);
                            }, 1000);

                          }} className='w-full h-full object-cover' src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${sellerData.user.photo}`} alt="dfrtete" />

                        </div>
                        <h1 className="text-orange-500 text-center font-bold text-xl leading-8 my-1">
                          {sellerData.user.nom}{" "} {sellerData.user.prenom}
                        </h1>
                        <h3 className="text-gray-600 text-center font-lg text-semibold leading-6">
                          {sellerData.user.address}
                        </h3>

                        <ul className="bg-gray-200  text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                          <li className="flex items-center py-3">
                            <span>Status</span>
                            <span className="ml-auto"><span className="bg-darken py-1 px-2 rounded text-green-600 text-sm">Active</span></span>
                          </li>
                          <li className="flex items-center py-3">
                            <span>Membre depuis</span>
                            <span className="ml-auto"> Le {new Date(sellerData.createdAt).toLocaleDateString('fr-FR') + ' ' + new Date(sellerData.createdAt).toLocaleTimeString('fr-FR')}</span>
                          </li>
                        </ul>
                      </div>
                    )}

                    <div className="my-4" />
                  </div>

                  <div className="w-full md:w-8/12 mx-2 ">
                    {sellerData && (
                      <div className="bg-gray-100 p-3 shadow-sm rounded-2xl">
                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                          <span className="text-orange-500">
                            <svg className="h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                          </span>
                          <h2 className="text-xl text-orange-500 tracking-wide leading-8 my-1">
                            À propos du vendeur
                          </h2>
                        </div>
                        <div className="text-gray-700">
                          <div className="grid text-sm">
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Nom
                              </div>
                              <div className="px-4 py-2">{sellerData.user.nom}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Prénom
                              </div>
                              <div className="px-4 py-2"> {sellerData.user.prenom}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Contact No.
                              </div>
                              <div className="px-4 py-2">{sellerData.user.tel}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Addresswe Actuel
                              </div>
                              <div className="px-4 py-2">
                                {sellerData.user.address}
                              </div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Email.
                              </div>
                              <div className="px-4 py-2">
                                <a className="text-orange-800 hover:underline" href={`mailto:${sellerData.user.email}`}>
                                  {sellerData.user.email}
                                </a>

                              </div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">
                                Mode de paiement
                              </div>
                              <div className="px-4 py-2">{sellerData.paymentmethod}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                N° carte nationale d'identité
                              </div>
                              <div className="px-4 py-2">{sellerData.CNI}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                Adresse Shop
                              </div>
                              <div className="px-4 py-2">
                                {sellerData.address}
                              </div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                Nom de Shop
                              </div>
                              <div className="px-4 py-2">{sellerData.nomshop}</div>
                            </div>
                            <div className="grid grid-cols-2">
                              <div className="px-4 py-2 font-semibold">

                                NIF
                              </div>
                              <div className="px-4 py-2">{sellerData.NIF}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div></>
              )}





            </div>
            <div className='w-full  mx-2  no-wrap md:-mx-2
            bg-gray-100 rounded-2xl '>
              <h1 class="text-orange-500 text-center font-bold text-xl  my-1">Les photos(Identite et logo de son magazin)</h1>
              {sellerData && (
                <>
                  <div className={`mt-5 flex  justify-center  gap-2 flex-wrap w-full p-4 `}>

                    <div className={` border  ${mobile ? 'flex-auto max-w-[15em] max-h-[15em]' : 'h-[15em] w-[15em]'} border-orange-700   bg-gray-100 rounded-2xl`}>
                      {LoadingPagePhotos && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                      <img draggable='false'
                        className="rounded-xl object-fill w-full h-full"
                        onLoad={() => {
                          SetLoadingPagePhotos(false)
                        }} src={`https://apiv1.burundientempsreel.com/uploads/market/${sellerData.cnii}`} alt="" />

                    </div>



                    <div className={` border  ${mobile ? 'flex-auto w-full max-h-[15em]' : 'h-[15em] w-[15em]'} border-orange-700   bg-gray-100 rounded-2xl`}>
                    </div>


                    <div className={` border  ${mobile ? 'flex-auto w-full max-h-[15em]' : 'h-[15em] w-[15em]'} border-orange-700   bg-gray-100 rounded-2xl`}>
                    </div>

                  </div>

                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4">
        <Link to="#" class="bg-orange-500  text-white py-2 px-5 rounded-xl hover:scale-105 duration-300 hover:bg-[#206ab1] font-medium" type="submit">
          Voir ses produits</Link>
      </div>
    </div>

  )
}

export default DetailleAdminManagent
