
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SpinnerDemarage from "../../../SpinnerDemarage/SpinnerDemarage";
import PropTypes from "prop-types";

function ListeEmployer() {

    const [notAgent, setNotgent] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        axios.get(`https://apiv1.burundientempsreel.com/user/notagent?page=${currentPage}&size=${itemsPerPage}`)
            .then((response) => {
                setNotgent(response.data.users);
                setTotalPages(response.data.totalPages);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [currentPage, itemsPerPage]);


    return (
        <>

            <div class=" bg-white border-t text-gray-700 border-b sm:border-l sm:border-r sm:rounded-xl shadow mb-6">
                <div class=" mx-auto px-2 ">
                    <div class=" mt-10">
                        <div>
                            <h2 class="text-2xl font-semibold  text-orange-500 leading-tight">
                                Disponible pour être inspecteurs.
                            </h2>
                        </div>
                        <div class="my-2 flex flex-row ">
                            <div class="flex flex-row mb-1 sm:mb-0">
                                <div className="relative">
                                    <select
                                        className="appearance-none h-full rounded-l-2xl border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={itemsPerPage}
                                        onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                    </select>

                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>

                                <div class="relative">
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                            class="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="block relative">
                                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                    <svg
                                        viewBox="0 0 24 24"
                                        class="h-4 w-4 fill-current text-black"
                                    >
                                        <path d="M10 4a6 6 0100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                                    </svg>
                                </span>
                                <input
                                    placeholder="Rechercher"


                                    class="appearance-none rounded-r-2xl rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                />



                            </div>
                        </div>
                        <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                            <div class="inline-blockw-full  relative shadow rounded-lg overflow-hidden">
                                <table class={` leading-normal w-full`}>
                                    <thead>
                                        <tr>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Vendeur
                                            </th>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Email
                                            </th>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Tel
                                            </th>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Addresse
                                            </th>
                                            <th class="px-5 py-3 border-r-2 border-gray-200 bg-orange-600 text-left text-xs font-semibold text-white font-serif tracking-wider">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {notAgent.map((user, index) => (
                                            <tr key={index} className="border-b">
                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <div class="flex items-center">
                                                        <div class="flex-shrink-0 w-10 h-10">
                                                            <div className='relative h-full w-full '>
                                                                <img
                                                                    draggable='false'
                                                                    src={`https://apiv1.burundientempsreel.com/uploads/photosuser/${user.photo}`}
                                                                    className="w-full h-full rounded object-contain object-center"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="ml-3">
                                                            <p class="text-gray-900 whitespace-no-wrap">{user.nom} {user.prenom}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                {/* Ajoutez les autres colonnes de données ici */}
                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p class="text-gray-900 whitespace-no-wrap">{user.email}</p>
                                                </td>
                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p class="text-gray-900 whitespace-no-wrap">{user.tel}</p>
                                                </td>
                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p class="text-gray-900 whitespace-no-wrap">{user.address}</p>
                                                </td>
                                                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <span class="relative inline-block  py-1 font-semibold text-green-900 leading-tight">
                                                        <span
                                                            aria-hidden
                                                            class="absolute inset-0  opacity-50 rounded-full"
                                                        ><Link to={`/Admin/Inspecteur/News/${user.id}`} class="px-5 py-2 border-olive-500 border text-olive-500 rounded-2xl transition duration-300 hover:bg-yellow-500 hover:text-white focus:outline-none">
                                                                Details
                                                            </Link>
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>




                            </div>
                        </div>
                        <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                            <span className="text-xs xs:text-sm text-gray-900">
                                Affichage de {Math.min(currentPage * itemsPerPage, notAgent.length)} à {Math.min(currentPage * itemsPerPage + itemsPerPage, notAgent.length)} sur {notAgent.length} entrées
                            </span>


                            <div className="inline-flex mt-2 xs:mt-0">
                                <button
                                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </button>
                                <button
                                    className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Suiv
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ListeEmployer;














