/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import Content_Container from "./Content_Container";
function ProductComponent({ getThemes, isVisitorLogged, SetLoginPopUp,getAddtoCarts }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className={`${getThemes} ? " bg-black " : "  "`}>
        <Content_Container
          getThemes={getThemes}
          isVisitorLogged={isVisitorLogged}
          SetLoginPopUp={SetLoginPopUp}
          getAddtoCarts={getAddtoCarts}
        />
      </div>
    </>
  );
}

export default ProductComponent;
