import React from "react";
import HeadProduct from "./HeadProduct";
import ProductDetail from "./ProductDetail";
import SimilarProduct from "./SimilarProduct";
import Description from "./Description";

function Content_Container({ getThemes, isVisitorLogged, SetLoginPopUp, getAddtoCarts }) {
  return (
    <>
      <div class={` ${getThemes ? 'bg-black' : 'bg-white'}  mx-auto text-gray-700 overflow-hidden`}>
        <HeadProduct getThemes={getThemes} />
        <ProductDetail getThemes={getThemes} getAddtoCarts={getAddtoCarts} isVisitorLogged={isVisitorLogged} SetLoginPopUp={SetLoginPopUp} />
        <Description getThemes={getThemes} />
        <SimilarProduct getThemes={getThemes} />
      </div>
    </>
  );
}

export default Content_Container;
