import React from "react";
import { Link } from "react-router-dom";
function HeadProduct({ getThemes }) {
  return (
    <>

      <div class={` ${getThemes ? 'bg-black' : ''} py-2 border border-gray-300 w-full px-4  rounded-b-2xl justify-between flex items-center gap-3`}>
        <Link to='/' className="flex py-1 px-2 rounded bg-orange-500 justify-center items-center text-white">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi mr-2 bi-arrow-return-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
          </svg>
          <p class=" font-bold">Retour</p>
        </Link>
        <p class="text-gray-400 font-serif text-center max-sm:text-xs">Online Exchange Solution</p>
      </div>

    </>
  );
}

export default HeadProduct;
