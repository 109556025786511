import React from "react";
import Myorders from "./Myorders";
function Content_Container({ getThemes }) {
  return (
    <>
      {/* <!-- container --> */}
      <div class={` ${getThemes ? 'bg-black' : 'bg-gray-100'} text-gray-700 min-h-[75vh] `}>
        <Myorders getThemes={getThemes} />
      </div>
    </>
  );
}

export default Content_Container;
